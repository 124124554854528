import React from "react";
import ScrollUp from "../components/ButtonComponent/ButtonScrollUp";
import Random from "../components/RandomComponent/Random";
import Header from "../components/HeaderComponent/Header";
import KundenListe from "../components/KundenComponent/KundenListe";
import SectionContact from "../components/SectionComponent/SectionContact";
import Footer from "../components/FooterComponent/Footer";

class Kunden extends React.Component {
  componentDidMount() {
    document.title = "Wir danken den besten Kunden der Welt";
  }

  componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <ScrollUp />
        <Random />
        <Header />
        <KundenListe />
        <SectionContact />
        <Footer />
      </React.Fragment>
    );
  }
}

export default Kunden;
