import React from "react";
import { connect } from "react-redux";

class Random extends React.Component {
  componentDidMount() {
    this.props.randomComponent();
  }

  render() {
    return <React.Fragment></React.Fragment>;
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispachToProps = dispatch => {
  return {
    randomComponent: () => dispatch({ type: "RANDOM_COMPONENT" })
  };
};
export default connect(mapStateToProps, mapDispachToProps)(Random);
