const initialState = {
  ZufallsZahl: 1,
  pageIndex: 0,
  laengeArray: 0,
  WortSpeicher: ["1", "2"],
  WortSpeicher2: ["1", "2"],
  Bildname: "1",
  ReferenzNummer: "0"
};

const reducer = (state = initialState, action) => {
  const newState = { ...state };

  switch (action.type) {
    case "RANDOM_COMPONENT":
      newState.laengeArray = newState.WortSpeicher.length;
      newState.ZufallsZahl = Math.floor(
        Math.random() * (newState.laengeArray - 1)
      );
      newState.Bildname = newState.WortSpeicher[newState.ZufallsZahl];
      newState.WortSpeicher.splice(newState.ZufallsZahl, 1);
      if (newState.laengeArray === 0) {
        newState.WortSpeicher = newState.WortSpeicher2.slice(0);
        newState.Bildname = "1";
      }

      break;

    case "PAGE_UP":
      newState.pageIndex += action.value;
      break;

    case "PAGE_SET":
      newState.pageIndex = action.value;
      break;

    case "PAGE_DOWN":
      newState.pageIndex -= action.value;
      break;

    case "FLEX":
      newState.pageIndex = action.value;
      break;

    case "GEBERIT":
      newState.pageIndex = action.value;
      break;

    case "REESE":
      newState.pageIndex = action.value;
      break;

    case "DUERR":
      newState.pageIndex = action.value;
      break;

    case "REFERENZCHANGE":
      newState.ReferenzNummer = action.value;
      break;

    default:
      break;
  }
  return newState;
};

export default reducer;
