import React from "react";

import ReferenzAnimation from "./ReferenzAnimation";

class ReferenzIntro extends React.Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { width } = this.state;
    const isMobile = width <= 812;

    if (isMobile) {
      return (
        <React.Fragment>
          <section id="section-kwasny">
            <div className="section__content columns container--padding">
              <div className="teaser__text column--6">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--belton">
                    PRODUKTMARKE BELTON
                  </p>
                  <h3 className="teaser__text-title">Consumer Marketing</h3>
                </div>
              </div>
              <div className="section__intro-content column--6">
                <p className="teaser__text-details">
                  Die Marke belton Lackspray wurde 1973 von der Peter Kwasny
                  GmbH ins Leben gerufen, um auch für den kreativen, dekorativen
                  und handwerklichen Teil der Lackspray-Welt passende Lösungen
                  zu entwickeln. Seit der Einführung hat belton ein breites
                  Produktspektrum aus Basislacken, Grundierungen, Spezial- und
                  Effektlacken sowie wasserbasierenden Lacksprays entwickelt.
                </p>
              </div>
            </div>
          </section>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <section id="section-kwasny" className="section--intro">
            <div className="section__content columns container--padding">
              <div className="column column--4"></div>
              <div className="column column--4" style={{ marginTop: "-8%" }}>
                <ReferenzAnimation />
              </div>
              <div className="column column--4"></div>
            </div>

            <div className="section__content columns container">
              <div className="teaser__text column--6">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--belton">
                    PRODUKTMARKE BELTON
                  </p>
                  <h3 className="teaser__text-title">Consumer Marketing</h3>
                </div>
              </div>
              <div className="section__intro-content column--6">
                <p className="teaser__text-details margin">
                  Die Marke belton Lackspray wurde 1973 von der Peter Kwasny
                  GmbH ins Leben gerufen, um auch für den kreativen, dekorativen
                  und handwerklichen Teil der Lackspray-Welt passende Lösungen
                  zu entwickeln. Seit der Einführung hat belton ein breites
                  Produktspektrum aus Basislacken, Grundierungen, Spezial- und
                  Effektlacken sowie wasserbasierenden Lacksprays entwickelt.
                </p>
              </div>
            </div>
          </section>
        </React.Fragment>
      );
    }
  }
}
export default ReferenzIntro;
