import React from "react";

// import { HomeTeaserXmas } from "./HomeTeaserXmas";
// import { HomeTeaserMobile } from "./HomeTeaserMobile.js";
// import { HomeTeaserDesktop } from "./HomeTeaserDesktop.js";
import { HomeTeaserVideo } from "./HomeTeaserVideo";

class HomeTeaser extends React.Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    return <HomeTeaserVideo />;
  }
}
export default HomeTeaser;
