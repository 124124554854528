import React from "react";

import SectionAnimation from "../SectionComponent/SectionAnimation";

import Mood_Bewerbung from "../../assets/images/Karriere/8_Mood_Bewerbung.jpg";
import Mood_BewerbungWebP from "../../assets/images/Karriere/8_Mood_Bewerbung.webp";

import Stellenanzeige_WebEntwickler_2019 from "../../assets/pdf/Stellenanzeige_Typo3_Entwickler_2019.pdf";

class JobComponentTypo3 extends React.Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { width } = this.state;
    const isMobile = width <= 812;

    if (isMobile) {
      return (
        <React.Fragment>
          <section id="section-about">
            <div className="section__content columns container--padding">
              <div className="teaser__text column--6">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle">Das Achte Gebot</p>
                  <h3 className="teaser__text-title">
                    Bereit für ein Abenteuer?
                  </h3>
                </div>
              </div>

              <div className="section__intro-content column--6">
                <p className="teaser__text-details">
                  Wir sind die Werbeagentur, die anders ist als all die
                  Werbeagenturen, die anders sind. Damit das so bleibt, brauchen
                  wir noch
                </p>
                <br />
                <h4 className="teaser__text-title">
                  TYPO3-Entwickler (m / w / d)
                </h4>
                <br />
                <nav
                  className="menu menu--career"
                  style={{ marginTop: "1rem" }}
                >
                  <div className="menu__item-container"></div>
                  <div className="menu__item-container">
                    <a
                      href={Stellenanzeige_WebEntwickler_2019}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="menu__item">
                        <span className="menu__item-name">
                          TYPO3-Entwickler (m/w/d) - PDF
                        </span>
                      </div>
                    </a>
                  </div>
                  <div className="menu__item-container">
                    <a href="mailto:bewerbung@dasachtegebot.de">
                      <div className="menu__item">
                        <span className="menu__item-name">Jetzt bewerben</span>
                      </div>
                    </a>
                  </div>
                  <br />
                  <div className="menu__item-container">
                    <div className="menu__item"></div>
                  </div>
                </nav>
              </div>
            </div>
          </section>
          <br />
          <section id="section-service">
            <div className="section__content columns container--padding">
              <div className="teaser__text column--6">
                <div className="teaser__text-content">
                  <h3 className="teaser__text-title">Wir so ...</h3>
                </div>
              </div>

              <div className="section__intro-content column--6">
                <p className="teaser__text-details">
                  Wir machen Marken- und Marketingkonzepte von der guten Idee
                  bis zur crossmedialen Umsetzung. Vorrangig für technologisch
                  spannende Branchen und Produkte. Und ausschließlich für
                  Unternehmen, für die wir gerne arbeiten. Neugierig, tatkräftig
                  und zuverlässig führen wir Projekte zum Erfolg. Dafür nehmen
                  wir uns alle Freiheiten, die wir brauchen, damit am Ende
                  ungelogen gute Werbung entsteht.
                </p>
              </div>
            </div>
          </section>
          <section id="section-service">
            <div className="section__content columns container--padding">
              <div className="teaser__text column--6">
                <div className="teaser__text-content">
                  <h3 className="teaser__text-title">Du so ...</h3>
                </div>
              </div>

              <div className="section__intro-content column--6">
                <p className="teaser__text-details">
                  Als digitaler Tempomacher für UX-Driven Design bist Du am Puls
                  der Zeit, entwickelst Frontends auf Basis gängiger Frameworks
                  (Foundation, React) und bringst neue Tools in unseren Workflow
                  mit ein. Beim Realisieren von innovativen Online-Anwendungen,
                  Sites und Portalen arbeitest Du eng mit unseren Kreativen und
                  Projektmanagern zusammen. Die aktuellsten Webtrends schüttelst
                  Du dabei locker aus dem Ärmel und bringst sie unseren Kunden
                  problemlos näher. Da Deine neuen Kollegen genauso ticken wie
                  Du, jubelt ihr Euch jeden Tag in neue Höhen und freut Euch
                  gemeinsam über begeisterte Kunden.
                </p>
              </div>
            </div>
            <div className="section__content columns container--padding">
              <div className="teaser__text column--6">
                <div className="teaser__text-content">
                  <h3 className="teaser__text-title">Pro/Contra gefällig?</h3>
                </div>
              </div>

              <div className="section__intro-content column--6">
                <div className="teaser__text-content">
                  <h4>Das gibt's bei uns nicht</h4>
                  <ul className="career__list">
                    <li>Tischkicker</li>
                    <li>Head of Irgendwas</li>
                    <li>Unbezahlte Praktikanten</li>
                    <li>„Work/Life-Balance“</li>
                  </ul>
                </div>
              </div>
              <div className="section__intro-content column--6">
                <div className="teaser__text-content">
                  <h4>Dafür gibt's</h4>
                  <ul className="career__list">
                    <li>Kunden, die uns lieben und die wir lieben</li>
                    <li>Parkplätze und eine grüne Wiese</li>
                    <li>Gut gelaunte Zusammenarbeit</li>
                    <li>Alles für ein gutes Leben</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section id="section-service">
            <div className="section__content columns container--padding">
              <div className="teaser__text column--6">
                <div className="teaser__text-content">
                  <h3 className="teaser__text-title">Und los ...</h3>
                </div>
              </div>
              <div className="column--6">
                <p>
                  Wir machen Marken- und Marketingkonzepte von der guten Idee
                  bis zur crossmedialen Umsetzung. Vorrangig für technologisch
                  spannende Branchen und Produkte. Und ausschließlich für
                  Unternehmen, für die wir gerne arbeiten. Neugierig, tatkräftig
                  und zuverlässig führen wir Projekte zum Erfolg. Dafür nehmen
                  wir uns alle Freiheiten, die wir brauchen, damit am Ende
                  ungelogen gute Werbung entsteht.
                </p>
                <strong>
                  <a
                    className="link"
                    href="mailto:bewerbung@dasachtegebot.de"
                    title="bewerbung@dasachtegebot.de"
                  >
                    bewerbung@dasachtegebot.de
                  </a>
                </strong>
              </div>
            </div>
            <div className="section__content-career columns infinite-container">
              <div className="column">
                <div
                  className="section__image-content-wrapper"
                  style={{ overflow: "hidden" }}
                >
                  <picture>
                    <source srcSet={Mood_BewerbungWebP} type="image/webp" />
                    <source srcSet={Mood_Bewerbung} type="image/jpeg" />
                    <img
                      src={Mood_BewerbungWebP}
                      style={{
                        width: "200%",
                        height: "100%",
                        overflow: "hidden",
                        left: "-100px",
                        top: "-50px",
                        position: "relative",
                      }}
                      alt="Bewerbung"
                    />
                  </picture>
                </div>
              </div>
            </div>
          </section>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <section id="section-about" className="section">
            <div className="section__content columns container--padding">
              <div className="column column--4"></div>
              <div className="column column--4" style={{ marginTop: "-8%" }}>
                <SectionAnimation />
              </div>
              <div className="column column--4"></div>
            </div>

            <div className="section__content columns container">
              <div className="teaser__text column--6">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle">Das Achte Gebot</p>
                  <h3 className="teaser__text-title">
                    Bereit für ein <br /> Abenteuer?
                  </h3>
                </div>
              </div>

              <div className="section__intro-content column--6">
                <p className="margin">
                  Wir sind die Werbeagentur, die anders ist als all die
                  Werbeagenturen, die anders sind. Damit das so bleibt, brauchen
                  wir noch
                </p>
                <h2 className="teaser__text-title">
                  TYPO3-Entwickler{" "}
                  <p className="teaser__text-subtitle">(m/w/d)</p>
                </h2>
                <nav className="menu menu--career">
                  <div className="menu__item-container"></div>
                  <div className="menu__item-container">
                    <a
                      href={Stellenanzeige_WebEntwickler_2019}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="menu__item">
                        <span className="menu__item-name">
                          TYPO3-Entwickler (m/w/d) - PDF
                        </span>
                      </div>
                    </a>
                  </div>
                  <div className="menu__item-container">
                    <a href="mailto:bewerbung@dasachtegebot.de">
                      <div className="menu__item">
                        <span className="menu__item-name">Jetzt bewerben</span>
                      </div>
                    </a>
                  </div>
                  {/* <p>
                    Mehr zu dieser Ausschreibung sowiefindest du auch auf den
                    Bewerberplattformen von <a href="#">LinkedIn</a>,{" "}
                    <a href="#">Indeed</a> oder <a href="#">Xing</a>.
                  </p> */}
                  <div className="menu__item-container">
                    <div className="menu__item"></div>
                  </div>
                </nav>
              </div>
            </div>
          </section>
          <section id="section-wirso">
            <div className="section__content columns container--padding">
              <div className="teaser__text column--6">
                <div className="teaser__text-content">
                  <h3 className="teaser__text-title">Wir so ...</h3>
                  <p className="teaser__text-details">
                    Wir machen Marken- und Marketingkonzepte von der guten Idee
                    bis zur crossmedialen Umsetzung. Vorrangig für technologisch
                    spannende Branchen und Produkte. Und ausschließlich für
                    Unternehmen, für die wir gerne arbeiten. Neugierig,
                    tatkräftig und zuverlässig führen wir Projekte zum Erfolg.
                    Dafür nehmen wir uns alle Freiheiten, die wir brauchen,
                    damit am Ende ungelogen gute Werbung entsteht.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section id="section-wirso">
            <div className="section__content columns container--padding">
              <div className="column--6"></div>
              <div className="teaser__text column--6">
                <div className="teaser__text-content">
                  <h3 className="teaser__text-title">Du so ...</h3>
                  <p className="teaser__text-details">
                    Als digitaler Tempomacher für UX-Driven Design bist Du am
                    Puls der Zeit, entwickelst Frontends auf Basis gängiger
                    Frameworks (Foundation, React) und bringst neue Tools in
                    unseren Workflow mit ein. Beim Realisieren von innovativen
                    Online-Anwendungen, Sites und Portalen arbeitest Du eng mit
                    unseren Kreativen und Projektmanagern zusammen. Die
                    aktuellsten Webtrends schüttelst Du dabei locker aus dem
                    Ärmel und bringst sie unseren Kunden problemlos näher. Da
                    Deine neuen Kollegen genauso ticken wie Du, jubelt ihr Euch
                    jeden Tag in neue Höhen und freut Euch gemeinsam über
                    begeisterte Kunden.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="section__content columns container--padding">
              <div className="teaser__text column--6">
                <div className="teaser__text-content">
                  <h3 className="teaser__text-title">Pro/Contra gefällig?</h3>
                </div>
              </div>
            </div>
            <div className="section__content columns container--padding">
              <div className="column--6">
                <div className="teaser__text-content">
                  <h4>Das gibt's bei uns nicht</h4>
                  <ul className="career__list">
                    <li>Tischkicker</li>
                    <li>Head of Irgendwas</li>
                    <li>Unbezahlte Praktikanten</li>
                    <li>„Work/Life-Balance“</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="section__content columns container--padding">
              <div className="column--6">
                <div className="teaser__text-content">
                  <h4>Dafür gibt's</h4>
                  <ul className="career__list">
                    <li>Kunden, die uns lieben und die wir lieben</li>
                    <li>Parkplätze und eine grüne Wiese</li>
                    <li>Gut gelaunte Zusammenarbeit</li>
                    <li>Alles für ein gutes Leben</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section id="section-duso" className="section__career-page-contact">
            <div className="section__content columns container--padding">
              <div className="column--6"></div>
              <div className="column--6">
                <p>
                  Wir machen Marken- und Marketingkonzepte von der guten Idee
                  bis zur crossmedialen Umsetzung. Vorrangig für technologisch
                  spannende Branchen und Produkte. Und ausschließlich für
                  Unternehmen, für die wir gerne arbeiten. Neugierig, tatkräftig
                  und zuverlässig führen wir Projekte zum Erfolg. Dafür nehmen
                  wir uns alle Freiheiten, die wir brauchen, damit am Ende
                  ungelogen gute Werbung entsteht.
                </p>
                <strong>
                  <a
                    className="link"
                    href="mailto:bewerbung@dasachtegebot.de"
                    title="bewerbung@dasachtegebot.de"
                  >
                    bewerbung@dasachtegebot.de
                  </a>
                </strong>
              </div>
            </div>
            <div className="section__content-career columns infinite-container">
              <div className="column">
                <div className="section__image-content-wrapper">
                  <picture>
                    <source srcSet={Mood_BewerbungWebP} type="image/webp" />
                    <source srcSet={Mood_Bewerbung} type="image/jpeg" />
                    <img
                      src={Mood_BewerbungWebP}
                      style={{ width: "100%", height: "100%" }}
                      alt="Bewerbung"
                    />
                  </picture>
                </div>
              </div>
            </div>
          </section>
        </React.Fragment>
      );
    }
  }
}
export default JobComponentTypo3;
