import React from "react";

import ReferenzAnimation from "./ReferenzAnimation";

class ReferenzIntro extends React.Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { width } = this.state;
    const isMobile = width <= 812;

    if (isMobile) {
      return (
        <React.Fragment>
          <section id="section-kwasny">
            <div className="section__content columns container--padding">
              <div className="teaser__text column--6">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--kwasny">SPRAYMAX</p>
                  <h3 className="teaser__text-title">Markenkommunikation</h3>
                </div>
              </div>
              <div className="section__intro-content column--6">
                <p className="teaser__text-details">
                  SprayMax ist eine Marke der Peter Kwasny GmbH, hinter der eine
                  einzigartige Technologie steckt: Dem Unternehmen ist es
                  gelungen eine handliche Spraydose zu entwickeln, die in der
                  Sprühqualität einem konventionellen Lackierwerkzeug in nichts
                  nachsteht. Damit eignet sich SprayMax als Profi-System für
                  kleinere Reparatur-Arbeiten am Lack. Die Vorteile und
                  Möglichkeiten, die sich aus dieser handlichen Lösung ergeben,
                  eignen sich für zahlreiche Branchen. Es gibt viel zu
                  kommunizieren!
                </p>
              </div>
            </div>
          </section>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <section id="section-kwasny" className="section--intro">
            <div className="section__content columns container--padding">
              <div className="column column--4"></div>
              <div className="column column--4" style={{ marginTop: "-8%" }}>
                <ReferenzAnimation />
              </div>
              <div className="column column--4"></div>
            </div>

            <div className="section__content columns container">
              <div className="teaser__text column--6">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--kwasny">SPRAYMAX</p>
                  <h3 className="teaser__text-title">Markenkommunikation</h3>
                </div>
              </div>
              <div className="section__intro-content column--6">
                <p className="teaser__text-details margin">
                  SprayMax ist eine Marke der Peter Kwasny GmbH, hinter der eine
                  einzigartige Technologie steckt: Dem Unternehmen ist es
                  gelungen eine handliche Spraydose zu entwickeln, die in der
                  Sprühqualität einem konventionellen Lackierwerkzeug in nichts
                  nachsteht. Damit eignet sich SprayMax als Profi-System für
                  kleinere Reparatur-Arbeiten am Lack. Die Vorteile und
                  Möglichkeiten, die sich aus dieser handlichen Lösung ergeben,
                  eignen sich für zahlreiche Branchen. Es gibt viel zu
                  kommunizieren!
                </p>
              </div>
            </div>
          </section>
        </React.Fragment>
      );
    }
  }
}
export default ReferenzIntro;
