import React from "react";

import FooterDesktop from "./FooterDesktop";
import FooterMobile from "./FooterMobile";

class Footer extends React.Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { width } = this.state;
    const isMobile = width <= 1150;

    if (isMobile) {
      return (
          <FooterMobile />
      );
    } else {
      return (
          <FooterDesktop />
      );
    }
  }
}
export default Footer;
