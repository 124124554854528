import React from "react";

import ReferenzAnimation from "../EWGComponent/ReferenzAnimation";

class ReferenzIntro extends React.Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { width } = this.state;
    const isMobile = width <= 812;

    if (isMobile) {
      return (
        <React.Fragment>
          <section id="section-ewg">
            <div className="section__content columns container--padding">
              <div className="teaser__text column--6">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--ewg">
                    Eisenwerk Würth
                  </p>
                  <h3 className="teaser__text-title">Marke neu aufgeladen</h3>
                </div>
              </div>
              <div className="section__intro-content column--6">
                <p className="teaser__text-details">
                  Dass in einem Technologie-Unternehmen 100 Jahre Tradition
                  stecken, ist eine wichtige Basis für die Firmenkultur, aber
                  nicht unbedingt ein Attribut, das man dem Markenauftritt
                  ansehen muss. Strahlmittel kommen international in vielen
                  Branchen zum Einsatz und sind in der Regel Teil eines
                  Gesamtprozesses, der durch das Zusammenspiel mit Mensch und
                  Maschine bestimmt wird. Würth liefert hier das Know-how für
                  den gesamten Prozess und weiß, wie man diesen noch
                  wirtschaftlicher und ergebnissicherer gestaltet.
                </p>
              </div>
            </div>
          </section>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <section id="section-ewg" className="section--intro">
            <div className="section__content columns container--padding">
              <div className="column column--4"></div>
              <div className="column column--4" style={{ marginTop: "-8%" }}>
                <ReferenzAnimation />
              </div>
              <div className="column column--4"></div>
            </div>

            <div className="section__content columns container">
              <div className="teaser__text column--6">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--ewg">
                    Eisenwerk Würth
                  </p>
                  <h3 className="teaser__text-title">Marke neu aufgeladen</h3>
                </div>
              </div>
              <div className="section__intro-content column--6">
                <p className="teaser__text-details margin">
                  Dass in einem Technologie-Unternehmen 100 Jahre Tradition
                  stecken, ist eine wichtige Basis für die Firmenkultur, aber
                  nicht unbedingt ein Attribut, das man dem Markenauftritt
                  ansehen muss. Strahlmittel kommen international in vielen
                  Branchen zum Einsatz und sind in der Regel Teil eines
                  Gesamtprozesses, der durch das Zusammenspiel mit Mensch und
                  Maschine bestimmt wird. Würth liefert hier das Know-how für
                  den gesamten Prozess und weiß, wie man diesen noch
                  wirtschaftlicher und ergebnissicherer gestaltet.
                </p>
              </div>
            </div>
          </section>
        </React.Fragment>
      );
    }
  }
}
export default ReferenzIntro;
