import React from "react";
import Durr_Referenzen_Mood_Gamingpsd from "../../../assets/images/Referenzen/Duerr/Durr_Referenzen_Mood_Gamingpsd.jpg";
import Durr_Azubiansprache_Banner from "../../../assets/images/Referenzen/Duerr/Durr_Azubiansprache_Banner.jpg";
import Durr_Azubiansprache_Mockup_Mappe from "../../../assets/images/Referenzen/Duerr/Durr_Azubiansprache_Mockup_Mappe.jpg";
import Durr_Anzeige_Azubiansprache_A4_hoch from "../../../assets/images/Referenzen/Duerr/Durr_Anzeige_Azubiansprache_A4_hoch.jpg";
import Duerr_Video_Ansprache from "../../../assets/images/Referenzen/Duerr/video/Duerr_Azubiansprache_2022.mp4";

export default class ReferenzContent extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section id="section-duerr-1" className="section--content">
          <div className="section__content columns infinite__container">
            <div className="column column--6">
              <div className="teaser__text--intro padding">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--flv">KAMPAGNE</p>
                  <h3 className="teaser__text-title">
                    Recruiting-Kampagne <br /> für Azubis
                  </h3>
                  <div>
                    <p className="teaser__text-details">
                      Die unternehmerische Zukunft sichert Dürr mit intensivem
                      Recruiting nach Talenten mit hoher Affinität zu
                      Technologie. Wer den Industrie 4.0-Ansatz leben will,
                      braucht Teams mit den notwendigen Skills. Hierzu gehört
                      IT-Kompetenz, die Freude am vernetzten Arbeiten sowie die
                      Fähigkeit digital und virtuell zu abstrahieren. DAS ACHTE
                      GEBOT entwickelte deshalb für die anvisierte Zielgruppe
                      eine Kampagne, die den Nachwuchs direkt vom Gaming-Chair
                      weg rekrutiert.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="column column--6"></div>
                  <div className="column column--6">
                    <img
                      ref="image"
                      src={Durr_Referenzen_Mood_Gamingpsd}
                      className="teaser__image-right"
                      alt="Referenz Durr"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="column column--6"></div>
                  <div className="column column--6">
                    <img
                      ref="image"
                      src={Durr_Azubiansprache_Banner}
                      className="teaser__image-right"
                      alt="Referenz Durr Azubiansprache Banner"
                      style={{ border: "solid 1px #000" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="section__content columns container--padding"
            style={{ marginTop: "2rem" }}
          >
            <div className="column column--4">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="teaser__text">
                    <div className="teaser__text-content">
                      <h5 className="teaser__text-title">Großfläche</h5>
                      <div>
                        <p className="teaser__text-details">
                          Das Key visual lässt sich auf unterschiedliche
                          OOH-Medien adaptieren und entwickelt seine Wirkung auf
                          Plakatwänden, Messen und bei Berufsanfänger- Events.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column column--8"></div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <video
                id="BeltonAZVideo"
                autostart="true"
                playsInline
                autoPlay
                muted
                loop
                type="video/mp4"
                src={Duerr_Video_Ansprache}
                style={{
                  width: "100%",
                  height: "auto",
                }}
              />
            </div>
          </div>

          <div className="padding-small">
            <div className="section__content columns container--padding">
              <div className="column column--4">
                <div className="section__image-content">
                  <div className="section__image-content-wrapper">
                    <div className="teaser__text">
                      <div className="teaser__text-content">
                        <h5 className="teaser__text-title">Teaser</h5>
                        <div>
                          <p className="teaser__text-details">
                            Bewegtbild first: Ein Kurzclip kann sowohl auf
                            Dürr-eigenen Medien wie auch auf Social Media
                            genutzt werden und sagt mehr als umfangreiche
                            Broschüren, die bei der Zielgruppe direkt zum
                            Altpapier wandern.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column column--8"></div>
            </div>
          </div>

          {/* Next Content */}

          <div className="section__content columns container--padding">
            <div className="column column--8"></div>
            <div className="column column--4">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="teaser__text">
                    <div className="teaser__text-content">
                      <h5 className="teaser__text-title">Fact sheets</h5>
                      <div>
                        <p className="teaser__text-details">
                          Statt Flyer und Broschüre: Zu jedem Berufsbild ein
                          passendes Fact-Sheet, das Lust macht auf mehr. Und wer
                          sich noch nicht gleich für den geeigneten
                          Ausbildungsgang entscheiden kann, darf gerne auch
                          mehrere Sheets und weiteres Info-Material n der
                          passenden Mappe mitnehmen.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="column column--6"></div>
                  <div className="column column--6">
                    <img
                      ref="image"
                      src={Durr_Azubiansprache_Mockup_Mappe}
                      className="teaser__image-right"
                      alt="Referenz Durr Azubiansprache Mappe"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Spacer */}

          <div className="section__content columns container--padding">
            <div className="column column--8"></div>

            <div className="column column--4">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="teaser__text">
                    <div className="teaser__text-content">
                      <h5 className="teaser__text-title">Anzeigen</h5>
                      <div>
                        <p className="teaser__text-details">
                          Sichtbar in unterschiedlichen Medien – natürlich auch
                          in Print.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="column column--6"></div>
                  <div className="column column--6">
                    <img
                      ref="image"
                      src={Durr_Anzeige_Azubiansprache_A4_hoch}
                      className="teaser__image-right"
                      alt="Referenz Durr Azubiansprache Anzeige"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
