import React from "react";

import FV_CDManual_Doppels_A4_hoch_3 from "../../../assets/images/Referenzen/FLV/FV_CDManual_Doppels_A4_hoch_3.jpg";
import FV_CDManual_Doppels_A4_hoch_farben from "../../../assets/images/Referenzen/FLV/FV_CDManual_Doppels_A4_hoch_farben.jpg";
import FV_CDManual_Doppels_A4_hoch_geschaeftsausstattung from "../../../assets/images/Referenzen/FLV/FV_CDManual_Doppels_A4_hoch_geschaeftsausstattung.jpg";
import FV_Image_Doppels_A4_hoch_1 from "../../../assets/images/Referenzen/FLV/FV_Image_Doppels_A4_hoch_1.jpg";
import FV_Image_Doppels_A4_hoch_2 from "../../../assets/images/Referenzen/FLV/FV_Image_Doppels_A4_hoch_2.jpg";
import FV_Image_Doppels_A4_hoch_3 from "../../../assets/images/Referenzen/FLV/FV_Image_Doppels_A4_hoch_3.jpg";
import FV_Logo_web from "../../../assets/images/Referenzen/FLV/FV_Logo_web.jpg";
import FV_Referenzen_Mood_KfZ from "../../../assets/images/Referenzen/FLV/FV_Referenzen_Mood_KfZ.jpg";
import FV_Titel_Brosch_Image_A4_hoch from "../../../assets/images/Referenzen/FLV/FV_Titel_Brosch_Image_A4_hoch.jpg";
import FV_Titel_CDManual_A4_hoch_web from "../../../assets/images/Referenzen/FLV/FV_Titel_CDManual_A4_hoch_web.jpg";
import FV_001_Fahrlehrerkongress_Berlin_web_2 from "../../../assets/images/Referenzen/FLV/FV-001-Fahrlehrerkongress-Berlin_web_2.jpg";
import FV_001_Fahrlehrerkongress_Berlin_web from "../../../assets/images/Referenzen/FLV/FV-001-Fahrlehrerkongress-Berlin_web.jpg";
import gemeinsam_stark_alternative_RZ_web from "../../../assets/images/Referenzen/FLV/gemeinsam_stark_alternative_RZ_web.jpg";
import gemeinsam_stark_RZ_web_neu from "../../../assets/images/Referenzen/FLV/gemeinsam_stark_RZ_web_neu.jpg";
import platz_fuer_ihr_anliegen_web_neu from "../../../assets/images/Referenzen/FLV/platz_fuer_ihr_anliegen_web_neu.jpg";
import flvTeaserClip from "../../../assets/video/referenzen/flv/Clip_1.mp4";

export default class ReferenzComponent extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section id="section-flv-1" className="section--content">
          <div className="section__content columns infinite__container">
            <div className="column column--6">
              <div className="teaser__text--intro padding">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--flv">Markenrelaunch</p>
                  <h3 className="teaser__text-title">Relaunch mit Drive</h3>
                  <div>
                    <p className="teaser__text-details">
                      Nähe, Mobilität und Gemeinschaftssinn sind die Markenwerte
                      der Fahrlehrerversicherung. Dass diese Werte immer noch so
                      aktuell sind, wie bei der Vereinsgründung vor fast 70
                      Jahren, bringt der neue Look zum Ausdruck. Auch wenn die
                      Verbundenheit mit den berufsständischen Organisationen bei
                      der jüngeren Zielgruppe nicht mehr so selbstverständlich
                      ist, so eint doch die emotionale Ansprache, die Dynamik,
                      Fahrspaß und die Freude an Innovationen in den Mittelpunkt
                      rückt.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="column column--6"></div>
                  <div className="column column--6">
                    <img
                      ref="image"
                      src={FV_Referenzen_Mood_KfZ}
                      className="teaser__image-right"
                      alt="FV Mood"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="padding-small">
            <div className="section__content columns container--padding">
              <div className="column column--4">
                <div className="section__image-content">
                  <div className="section__image-content-wrapper">
                    <div className="teaser__text">
                      <div className="teaser__text-content">
                        <h5 className="teaser__text-title">Logo</h5>
                        <div>
                          <p className="teaser__text-details">
                            Offen für die Zukunft! Kürzer, reduzierter,
                            dynamischer und mit klarer Kante im Claim
                            präsentiert sich das neue Logo.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column column--8"></div>
            </div>

            <div className="section__content columns container--padding">
              <div className="column column--3"></div>
              <div className="column column--6">
                <div className="teaser__text">
                  <div className="teaser__text-content">
                    <div className="section__image-content">
                      <div className="section__image-content-wrapper">
                        <img
                          ref="image"
                          src={FV_Logo_web}
                          style={{
                            width: "100%",
                            height: "100%",
                            paddingBottom: "5rem",
                            paddingTop: "5rem",
                            marginTop: "5rem",
                          }}
                          alt="FLV Logo"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column column--3"></div>
            </div>
          </div>
          <div className="padding-small">
            <div className="section__content columns container--padding">
              <div className="column column--6">
                <div className="section__image-content">
                  <div className="section__image-content-wrapper">
                    <div className="teaser__text">
                      <div className="teaser__text-content">
                        <h5 className="teaser__text-title">CD-Handbuch</h5>
                        <div>
                          <p className="teaser__text-details">
                            Im Leitfaden für CD und Gestaltung sind alle Aspekte
                            des Corporate Designs dargestellt. Vom Logo über
                            Schriften und Farben bis zu den wichtigsten Print-
                            und Digital-Medien sind alle Gestaltungsregeln
                            detailliert aufgeführt, so dass alle involvierten
                            Mitarbeiter, Partner und Dienstleister unabhängig
                            von der technischen Plattform auf klare Vorgaben
                            zurückgreifen können.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column column--6"></div>
            </div>
            <div className="section__content columns container--padding">
              <div className="column column--6">
                <div className="teaser__text">
                  <div className="teaser__text-content">
                    <div className="section__image-content">
                      <div className="section__image-content-wrapper">
                        <img
                          ref="image"
                          src={FV_CDManual_Doppels_A4_hoch_farben}
                          style={{
                            width: "100%",
                            height: "100%",
                            paddingBottom: "5%",
                            paddingTop: "6rem",
                          }}
                          alt="FV Farbschema"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column column--6">
                <div className="teaser__text">
                  <div className="teaser__text-content">
                    <div className="section__image-content">
                      <div className="section__image-content-wrapper">
                        <img
                          ref="image"
                          src={FV_Titel_CDManual_A4_hoch_web}
                          style={{
                            width: "80%",
                            height: "80%",
                            paddingBottom: "5%",
                          }}
                          alt="FV CD Manual"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section__content columns container--padding">
              <div className="column column--3"></div>
              <div className="column column--6">
                <div className="teaser__text">
                  <div className="teaser__text-content">
                    <div className="section__image-content">
                      <div className="section__image-content-wrapper">
                        <img
                          ref="image"
                          src={
                            FV_CDManual_Doppels_A4_hoch_geschaeftsausstattung
                          }
                          style={{
                            width: "100%",
                            height: "100%",
                            paddingBottom: "5%",
                          }}
                          alt="CD Manial Geschäftsausstattung"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column column--3"></div>
            </div>
            <div className="section__content columns container--padding">
              <div className="column column--6">
                <div className="teaser__text">
                  <div className="teaser__text-content">
                    <div className="section__image-content">
                      <div className="section__image-content-wrapper">
                        <img
                          ref="image"
                          src={FV_CDManual_Doppels_A4_hoch_3}
                          style={{
                            width: "100%",
                            height: "100%",
                            paddingBottom: "5%",
                          }}
                          alt="CD Manual Seite 1"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column column--6"></div>
            </div>
            {/* Section Image Broschure */}
            <div
              className="section__content columns container--padding"
              style={{ marginTop: "10rem" }}
            >
              <div className="column column--8"></div>
              <div className="column column--4">
                <div className="section__image-content">
                  <div className="section__image-content-wrapper">
                    <div className="teaser__text">
                      <div className="teaser__text-content">
                        <h5 className="teaser__text-title">Imagebroschüre</h5>
                        <div>
                          <p className="teaser__text-details">
                            Nah an der Marke und mit kurzen, kompakten
                            Einstiegen in Philosophie und Leistungsangebote,
                            stellt die Imagebroschüre ein Basismedium zum
                            Kennenlernen dar.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section__content columns container--padding">
              <div className="column column--6">
                <div className="teaser__text">
                  <div className="teaser__text-content">
                    <div className="section__image-content">
                      <div className="section__image-content-wrapper">
                        <img
                          ref="image"
                          src={FV_Titel_Brosch_Image_A4_hoch}
                          style={{
                            width: "100%",
                            height: "100%",
                          }}
                          alt="Broschuere Titel "
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column column--6">
                <div className="teaser__text">
                  <div className="teaser__text-content">
                    <div className="section__image-content">
                      <div className="section__image-content-wrapper">
                        <img
                          ref="image"
                          src={FV_Image_Doppels_A4_hoch_1}
                          style={{
                            width: "100%",
                            height: "100%",
                            paddingTop: "25%",
                          }}
                          alt="Broschuere Seite 1"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section__content columns container--padding">
              <div className="column column--6">
                <div className="teaser__text">
                  <div className="teaser__text-content">
                    <div className="section__image-content">
                      <div className="section__image-content-wrapper">
                        <img
                          ref="image"
                          src={FV_Image_Doppels_A4_hoch_2}
                          style={{
                            width: "100%",
                            height: "100%",
                          }}
                          alt="Broschuere Seite 2"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column column--6">
                <div className="teaser__text">
                  <div className="teaser__text-content">
                    <div className="section__image-content">
                      <div className="section__image-content-wrapper">
                        <img
                          ref="image"
                          src={FV_Image_Doppels_A4_hoch_3}
                          style={{
                            width: "100%",
                            height: "100%",
                          }}
                          alt="Broschuere Seite 3"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Section KeyVisuals */}
            <div
              className="section__content columns container--padding"
              style={{ marginTop: "20rem" }}
            >
              <div className="column column--4">
                <div className="section__image-content">
                  <div className="section__image-content-wrapper">
                    <div className="teaser__text">
                      <div className="teaser__text-content">
                        <h5 className="teaser__text-title">Key Visuals</h5>
                        <div>
                          <p className="teaser__text-details">
                            Ein Set an Leitmotiven rund um die Marke inszeniert
                            die wichtigsten Werte und Attribute der
                            Fahrlehrerversicherung. Natürlich auf Basis der
                            wichtigsten Kunden-Insights
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column column--8"></div>
            </div>
            <div className="section__content columns container--padding">
              <div className="column column--8">
                <div className="teaser__text">
                  <div className="teaser__text-content">
                    <div className="section__image-content">
                      <div className="section__image-content-wrapper">
                        <img
                          ref="image"
                          src={gemeinsam_stark_alternative_RZ_web}
                          style={{
                            width: "100%",
                            height: "100%",
                          }}
                          alt="Keyvisual gemeinsam stark"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column column--4"></div>
            </div>
            <div
              className="section__content columns container--padding"
              style={{ marginTop: "5rem" }}
            >
              <div className="column column--4"></div>
              <div className="column column--8">
                <div className="teaser__text">
                  <div className="teaser__text-content">
                    <div className="section__image-content">
                      <div className="section__image-content-wrapper">
                        <img
                          ref="image"
                          src={gemeinsam_stark_RZ_web_neu}
                          style={{
                            width: "100%",
                            height: "100%",
                          }}
                          alt="Keyvisual gemeinsam stark neu"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="section__content columns container--padding"
              style={{ marginTop: "5rem" }}
            >
              <div className="column column--8">
                <div className="teaser__text">
                  <div className="teaser__text-content">
                    <div className="section__image-content">
                      <div className="section__image-content-wrapper">
                        <img
                          ref="image"
                          src={platz_fuer_ihr_anliegen_web_neu}
                          style={{
                            width: "100%",
                            height: "100%",
                          }}
                          alt="Keyvisual Platz fuer ihr Anliegen"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column column--4"></div>
            </div>
            {/* Section Messestand */}
            <div
              className="section__content columns container--padding"
              style={{ marginTop: "20rem" }}
            >
              <div className="column column--4">
                <div className="section__image-content">
                  <div className="section__image-content-wrapper">
                    <div className="teaser__text">
                      <div className="teaser__text-content">
                        <h5 className="teaser__text-title">Messedesign</h5>
                        <div>
                          <p className="teaser__text-details">
                            Beim Fahrlehrerkongress, der wichtigste
                            Branchenmesse, präsentiert sich die neue
                            Fahrlehrerversicherung mit neuem Drive. Gamification
                            statt trockener Materie.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column column--8"></div>
            </div>
            <div
              className="section__content columns container--padding"
              style={{ marginTop: "5rem" }}
            >
              <div className="column column--12">
                <div className="teaser__text">
                  <div className="teaser__text-content">
                    <div className="section__image-content">
                      <div className="section__image-content-wrapper">
                        <img
                          ref="image"
                          src={FV_001_Fahrlehrerkongress_Berlin_web_2}
                          style={{
                            width: "100%",
                            height: "100%",
                          }}
                          alt="Messestand Fahrlehrerkongress"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="section__content columns container--padding"
              style={{ marginTop: "5rem" }}
            >
              <div className="column column--12">
                <div className="teaser__text">
                  <div className="teaser__text-content">
                    <div className="section__image-content">
                      <div className="section__image-content-wrapper">
                        <img
                          ref="image"
                          src={FV_001_Fahrlehrerkongress_Berlin_web}
                          style={{
                            width: "100%",
                            height: "100%",
                          }}
                          alt="Messestand Fahrlehrerkongress"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Section Markteinführung Clip */}
            <div
              className="section__content columns container--padding"
              style={{ marginTop: "20rem" }}
            >
              <div className="column column--4">
                <div className="section__image-content">
                  <div className="section__image-content-wrapper">
                    <div className="teaser__text">
                      <div className="teaser__text-content">
                        <h5 className="teaser__text-title">Markeneinführung</h5>
                        <div>
                          <p className="teaser__text-details">
                            Auftakt mit Tempo: Die Präsentation der neuen Marke
                            übernimmt ein dynamischer Teaserclip.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column column--8"></div>
            </div>
            <div
              className="section__content columns container--padding"
              style={{ marginTop: "5rem" }}
            >
              <div className="column column--12">
                <div className="teaser__text">
                  <div className="teaser__text-content">
                    <div className="section__image-content">
                      <div className="section__image-content-wrapper">
                        <video
                          id="SikaVideo"
                          autostart="true"
                          playsInline
                          autoPlay
                          muted
                          loop
                          type="video/mp4"
                          src={flvTeaserClip}
                          style={{
                            width: "100%",
                            height: "auto",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
