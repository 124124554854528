import React from "react";


class SectionService extends React.Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { width } = this.state;
    const isMobile = width <= 812;

    if (isMobile) {
      return (
        <section id="section-service">
          <div className="section__content columns container--padding">
            <div className="teaser__text column--6">
              <div className="teaser__text-content">
                <p className="teaser__text-subtitle">Leistungen</p>
                <h3 className="teaser__text-title">Das m8! die 8!</h3>
              </div>
            </div>

            <div className="section__intro-content column--6">
              <p className="teaser__text-details">
                Was wir uns ausdenken, können wir auch umsetzen. Wir sind ein
                Team von Spezialisten für Marketing, Gestaltung, Text, Film, 3D,
                Webdesign, Programmierung, Media, Produktion und Full-Service.
                Wir verkaufen nur Leistungen, die wir auch beherrschen und
                versprechen nur, was wir auch halten können. Und wir lernen
                immer dazu. Auch deshalb werden wir jeden Tag ein bisschen
                besser.
              </p>
              <p>
                Wir haben Spaß an komplexen Themen und Kunden, die einen höheren
                gesellschaftlichen Beitrag leisten als viel Rendite und schnelle
                Umsätze. Am liebsten arbeiten wir für und mit Marken und
                Menschen, die inspirierende Ideen und eine partnerschaftliche
                Zusammenarbeit ebenso wie wir als Grundlage guten Wirtschaftens
                verstehen.
              </p>
            </div>
          </div>

          
        </section>
      );
    } else {
      return (
        <section id="section-service" >
   <div className="section__content columns container--padding">
            <div className="teaser__text column--6">
              <div className="teaser__text-content">
                <p className="teaser__text-subtitle">Leistungen</p>
                <h3 className="teaser__text-title">Das m8! die 8!</h3>
                <p className="teaser__text-details">
              Was wir uns ausdenken, können wir auch umsetzen. 
              Wir sind ein Team von Spezialisten für Marketing, Gestaltung, 
              Text, Film, 3D, Webdesign, Programmierung, Media, Produktion und Full-Service. 
              Wir verkaufen nur Leistungen, die wir auch beherrschen und versprechen nur, 
              was wir auch halten können. Und wir lernen immer dazu. 
              Auch deshalb werden wir jeden Tag ein bisschen besser.
              Wir haben Spaß an komplexen Themen und Kunden, die einen höheren 
              gesellschaftlichen Beitrag leisten als viel Rendite und schnelle Umsätze. 
              Am liebsten arbeiten wir für und mit Marken und Menschen, 
              die inspirierende Ideen und eine partnerschaftliche Zusammenarbeit 
              ebenso wie wir als Grundlage guten Wirtschaftens verstehen.
              </p>
              </div>
           
          
              </div>
        </div>
        </section>
      );
    }
  }
}
export default SectionService;
