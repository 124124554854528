import React from "react";

import EWG_Referenzen_Mood_Mann_1260px_breit from "../../../assets/images/Referenzen/EWG/Kampagne/EWG_Referenzen_Mood_Mann_1260px_breit.jpg";
import WuertStrahlmittelLogo from "../../../assets/images/Referenzen/EWG/Kampagne/EWG_Logo.jpg";

import EWG_Geschaeftsausstattung_1 from "../../../assets/images/Referenzen/EWG/Kampagne/EWG_Geschaeftsausstattung_1.jpg";
import EWG_Geschaeftsausstattung_2 from "../../../assets/images/Referenzen/EWG/Kampagne/EWG_Geschaeftsausstattung_2.jpg";
import EWG_Titel_Brosch_quadratisch_615px_breit from "../../../assets/images/Referenzen/EWG/Kampagne/EWG_Titel_Brosch_quadratisch_615px_breit_neu.jpg";
import EWG_Doppels_Brosch_1_quadratisch_615px_breit from "../../../assets/images/Referenzen/EWG/Kampagne/EWG_Doppels_Brosch_1_quadratisch_615px_breit.jpg";
import EWG_Doppels_Brosch_quadratisch_615px_breit from "../../../assets/images/Referenzen/EWG/Kampagne/EWG_Doppels_Brosch_quadratisch_615px_breit.jpg";
import EWGMockup from "../../../assets/video/referenzen/ewg/EWG.mp4";

export default class ReferenzContent extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section id="section-ewg-1" className="section--content">
          <div className="section__content columns container-section">
            <div className="column column--6">
              <img
                ref="image"
                src={EWG_Referenzen_Mood_Mann_1260px_breit}
                className="teaser__image-left"
                alt="Eisenwerk Würth - Part of Your Process"
              />
            </div>

            <div className="column column--6">
              <div className="teaser__text padding">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--ewg">Markenbild</p>
                  <h3 className="teaser__text-title">Part of your Process</h3>
                  <p className="teaser__text-details">
                    Metallische Strahlmittel sind Teil des Fertigungsprozesses
                    bei zahlreichen Verfahren der Oberflächenbehandlung.
                    Allerdings stehen sie in der Regel nicht im Mittelpunkt, so
                    dass Optimierungs- und Einsparpotentiale verkannt werden.
                    Als Premium-Hersteller verweist Würth deshalb zu Recht auf
                    die Möglichkeiten, die sich im Strahlprozess verbergen. Die
                    Ausgestaltung der Marke in Wort und Bild fokussiert deshalb
                    die technologischen Möglichkeiten auch im Hinblick auf den
                    digitalen Wandel. Ein modernes, prägnantes und zu Produkt
                    und Branche passendes Markenbild war der Anfang.
                  </p>
                </div>
              </div>
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <img
                    ref="image"
                    src={WuertStrahlmittelLogo}
                    style={{ width: "95%", height: "95%" }}
                    alt="Würth Strahlmittel Logo"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding-big">
            <div className="column column--6">
              <img
                ref="image"
                src={EWG_Geschaeftsausstattung_1}
                style={{ width: "100%", height: "100%" }}
                alt="Eisenwerk Würth Geschäftsaustattung"
              />
            </div>

            <div className="column column--6">
              <img
                ref="image"
                src={EWG_Geschaeftsausstattung_2}
                style={{ width: "100%", height: "100%" }}
                alt="Eisenwerk Würth Geschäftsaustattung"
              />
            </div>
          </div>

          <div className="section__content columns container--padding-big">
            <div className="column column--6">
              <div className="teaser__text">
                <div className="teaser__text-content">
                  <div className="section__image-content">
                    <div className="section__image-content-wrapper">
                      <img
                        ref="image"
                        src={EWG_Titel_Brosch_quadratisch_615px_breit}
                        style={{ width: "100%", height: "100%" }}
                        alt="Imagebroschüre Eisenwerk Würth"
                      />
                      <img
                        ref="image"
                        src={EWG_Doppels_Brosch_1_quadratisch_615px_breit}
                        style={{ width: "100%", height: "100%" }}
                        alt="Imagebroschüre Eisenwerk Würth"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <h5 className="teaser__text-title padding">Imagebroschüre</h5>
                  <p className="teaser__text-details">
                    Die handliche Imagebroschüre konzentriert sich auf das
                    Wesentliche: Was unterscheidet Würth Strahlmittel von
                    anderen Herstellern.
                  </p>
                  <img
                    ref="image"
                    src={EWG_Doppels_Brosch_quadratisch_615px_breit}
                    style={{ width: "100%", height: "100%" }}
                    alt="Imagebroschüre Eisenwerk Würth"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding-big">
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="teaser__text">
                    <div className="teaser__text-content">
                      <h5 className="teaser__text-title">Website</h5>
                      <div>
                        <p className="teaser__text-details">
                          Eine kompakte Website, die Unternehmen und Produkte
                          präsentiert, bildet den Ausgangspunkt für alle
                          Online-Marketing-Aktivitäten. Die Website bildet auch
                          die Grundlage des kommunikativen Roll-outs im Zuge der
                          Internationalisierung der Marke.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column column--6"></div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <video
                id="FlexMockup"
                autostart="true"
                playsInline
                autoPlay
                muted
                loop
                type="video/mp4"
                src={EWGMockup}
                style={{
                  width: "100%",
                  height: "auto"
                }}
              />
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
