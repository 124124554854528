import React from "react";
import Footer from "../../components/FooterComponent/Footer";
import Header from "../../components/HeaderComponent/Header";
import JobComponentTypo3 from "../../components/JobComponent/JobComponentTypo3";
import SectionImage from "../../components/SectionComponent/SectionImage";
import snippetTypo3 from "../../assets/snippets/snippetTypo3.json";
import { Helmet } from "react-helmet";

const JobTypo3 = () => {
  return (
    <React.Fragment>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(snippetTypo3)}
        </script>
      </Helmet>
      <Header />
      <SectionImage />
      <JobComponentTypo3 />
      <Footer />
    </React.Fragment>
  );
};

export default JobTypo3;
