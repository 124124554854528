import React from "react";
import AnimateCC from "react-adobe-animate";
import VideoDisplay from "../../VideoComponent/VideoDisplay";

import Flex_Referenzen_Mood_Lina_1260px_breit from "../../../assets/images/Referenzen/Flex/Kampagne/Polierer/Flex_Referenzen_Mood_Lina_1260px_breit.jpg";
import Flex_Titel_Buch_Lina_A4_hoch_615px_breit from "../../../assets/images/Referenzen/Flex/Kampagne/Polierer/Flex_Titel_Buch_Lina_A4_hoch_615px_breit.jpg";
import Flex_Doppels_Lina_A4_hoch_2_615px_breit from "../../../assets/images/Referenzen/Flex/Kampagne/Polierer/Flex_Doppels_Lina_A4_hoch_2_615px_breit.jpg";
import Flex_Doppels_Lina_A4_hoch_1_615px_breit from "../../../assets/images/Referenzen/Flex/Kampagne/Polierer/Flex_Doppels_Lina_A4_hoch_1_615px_breit.jpg";
import Flex_Poster_Lina_1260px_breit from "../../../assets/images/Referenzen/Flex/Kampagne/Polierer/Flex_Poster_Lina_1260px_breit.jpg";
import Flex_Titel_Brosch_Polierer_A4_hoch_615px_breit from "../../../assets/images/Referenzen/Flex/Kampagne/Polierer/Flex_Titel_Brosch_Polierer_A4_hoch_615px_breit_neu.jpg";
import Flex_Doppels_Polierer_A4_hoch_2_615px_breit from "../../../assets/images/Referenzen/Flex/Kampagne/Polierer/Flex_Doppels_Polierer_A4_hoch_2_615px_breit.jpg";
import Flex_Doppels_Polierer_A4_hoch_1_615px_breit from "../../../assets/images/Referenzen/Flex/Kampagne/Polierer/Flex_Doppels_Polierer_A4_hoch_1_615px_breit.jpg";
import Flex_Fade_Polierer from "../../../assets/video/referenzen/flex/Flex_Fade_Polierer.mp4";

import Flex_Referenzen_Mood_Freiheit_1260px_breit from "../../../assets/images/Referenzen/Flex/Kampagne/Freiheit/Flex_Referenzen_Mood_Freiheit_1260px_breit.jpg";
import Flex_RollUp_Freiheit from "../../../assets/images/Referenzen/Flex/Kampagne/Freiheit/Flex_RollUp_Freiheit_breiter_neu.jpg";
import Flex_POS_Regal_Akku_Polierer_615px_breit from "../../../assets/images/Referenzen/Flex/Kampagne/Freiheit/Flex_POS_Regal_Akku-Polierer_615px_breit.jpg";
import Flex_Werbemittel_Freiheit_615px_breit from "../../../assets/images/Referenzen/Flex/Kampagne/Freiheit/Flex_Werbemittel_Freiheit_615px_breit_neu.jpg";
import Flex_Fade_AkkuPolierer from "../../../assets/video/referenzen/flex/Flex_Fade_AkkuPolierer.mp4";

import Flex_Doppels_Katalog_A4_hoch_1_615px_breit from "../../../assets/images/Referenzen/Flex/Kampagne/Gesamtkatalog/Flex_Doppels_Katalog_A4_hoch_1_615px_breit.png";
import Flex_Doppels_Katalog_A4_hoch_2_615px_breit from "../../../assets/images/Referenzen/Flex/Kampagne/Gesamtkatalog/Flex_Doppels_Katalog_A4_hoch_2_615px_breit.png";
import Flex_Titel_Katalog_A4_hoch_615px_breit from "../../../assets/images/Referenzen/Flex/Kampagne/Gesamtkatalog/Flex_Titel_Katalog_A4_hoch_615px_breit.png";
import Flex_Doppels_Katalog_A4_hoch_3_615px_breit from "../../../assets/images/Referenzen/Flex/Kampagne/Gesamtkatalog/Flex_Doppels_Katalog_A4_hoch_3_615px_breit.png";

import Flex_Referenzen_Mood_FlextECy_1260px_breit from "../../../assets/images/Referenzen/Flex/Kampagne/FlextECy/Flex_Referenzen_Mood_FlextECy_1260px_breit.jpg";
import Flex_Doppels_FlextECy_1_A4_hoch_615px_breit from "../../../assets/images/Referenzen/Flex/Kampagne/FlextECy/Flex_Doppels_FlextECy_1_A4_hoch_615px_breit.jpg";
import Flex_Titel_Brosch_FlextECy_A4_hoch from "../../../assets/images/Referenzen/Flex/Kampagne/FlextECy/Flex_Titel_Brosch_FlextECy_A4_hoch_neu.jpg";
import Flex_Doppels_FlextECy_2_A4_hoch_615px_breit from "../../../assets/images/Referenzen/Flex/Kampagne/FlextECy/Flex_Doppels_FlextECy_2_A4_hoch_615px_breit.jpg";
import Flex_POS_Regal_FlextECy_615px_breit from "../../../assets/images/Referenzen/Flex/Kampagne/FlextECy/Flex_POS_Regal_FlextECy_615px_breit.jpg";
import Flex_Rollup_FlextECy_615px_breit from "../../../assets/images/Referenzen/Flex/Kampagne/FlextECy/Flex_Rollup_FlextECy_615px_breit_neu.jpg";
import Flex_Fade_Flextecy from "../../../assets/video/referenzen/flex/Flex_Fade_Flextecy.mp4";

import Flex_Referenzen_Mood_Sauger_1260px_breit from "../../../assets/images/Referenzen/Flex/Kampagne/Sicherheitssauger/Flex_Referenzen_Mood_Sauger_1260px_breit.jpg";
import Flex_Motive_Sauger_1_A4_hoch_615px_breit from "../../../assets/images/Referenzen/Flex/Kampagne/Sicherheitssauger/Flex_Motive_Sauger_1_A4_hoch_615px_breit.jpg";
import Flex_Doppels_Sauger_2_A4_hoch_1_615px_breit from "../../../assets/images/Referenzen/Flex/Kampagne/Sicherheitssauger/Flex_Doppels_Sauger_2_A4_hoch_1_615px_breit.jpg";
import Flex_Titel_Brosch_Sauger_A4_hoch_615px_breit from "../../../assets/images/Referenzen/Flex/Kampagne/Sicherheitssauger/Flex_Titel_Brosch_Sauger_A4_hoch_615px_breit_neu.jpg";
import Flex_POS_Sauger_615px_breit from "../../../assets/images/Referenzen/Flex/Kampagne/Sicherheitssauger/Flex_POS_Sauger_615px_breit.jpg";
import Flex_Doppels_Sauger_1_A4_hoch_1_615px_breit from "../../../assets/images/Referenzen/Flex/Kampagne/Sicherheitssauger/Flex_Doppels_Sauger_1_A4_hoch_1_615px_breit.jpg";
import Flex_Werbemittel_Display_Cleaner_615px_breit from "../../../assets/images/Referenzen/Flex/Kampagne/Sicherheitssauger/Flex_Werbemittel_Display_Cleaner_615px_breit_neu.jpg";

import Flex_Referenzen_Mood_DD4G_1260px_breit from "../../../assets/images/Referenzen/Flex/Kampagne/Akku/Flex_Referenzen_Mood_DD4G_1260px_breit.jpg";
import Flex_Doppels_DD4G_A4_hoch_1_615px_breit from "../../../assets/images/Referenzen/Flex/Kampagne/Akku/Flex_Doppels_DD4G_A4_hoch_1_615px_breit.jpg";
import Flex_Titel_Brosch_DD4G_A4_hoch_615px_breit from "../../../assets/images/Referenzen/Flex/Kampagne/Akku/Flex_Titel_Brosch_DD4G_A4_hoch_615px_breit_neu.jpg";

import Flex_Anzeige_Giraffe_A4_hoch_615px_breit from "../../../assets/images/Referenzen/Flex/Kampagne/Geburtstag/Flex_Anzeige_Giraffe_A4_hoch_615px_breit.png";
import Flex_Anzeige_Giraffe_A4_hoch_neu from "../../../assets/images/Referenzen/Flex/Kampagne/Geburtstag/Flex_Anzeige_Giraffe_A4_hoch_neu.jpg";
import Flex_Referenzen_Mood_Giraffe_2520px_breit from "../../../assets/images/Referenzen/Flex/Kampagne/Geburtstag/Flex_Referenzen_Mood_Giraffe_2520px_breit.jpg";

import Flex_Video_Polierer_Freiheit from "../../../assets/images/Referenzen/Flex/akku_poliererkampagne_nimm_dir_die_freiheit.webp";
import Flex_Video_Giraffe from "../../../assets/images/Referenzen/Flex/kampagne_20_jahre_giraffe.webp";
import Flex_Video_Testimonial_1 from "../../../assets/images/Referenzen/Flex/testimonial_1_akku_bohrschrauber_dd4g.webp";
import Flex_Video_Testimonial_2 from "../../../assets/images/Referenzen/Flex/testimonial_2_akku_bohrschrauber_dd4g.webp";
import Flex_Video_Sicherheitssauger from "../../../assets/images/Referenzen/Flex/kampagne_sicherheitssauger.webp";
import Flex_Video_Flextecy from "../../../assets/images/Referenzen/Flex/more_flextecy.webp";
import Flex_Video_Polierer_Glaenzen from "../../../assets/images/Referenzen/Flex/poliererkampagne_flex_laesst_alle_glaenzen.webp";
import FLEX_Jubilaeum_Web_Einstiegsmotiv from "../../../assets/images/Referenzen/Flex/FLEX_Jubilaeum_Web_Einstiegsmotiv.jpg";
import FLEX_Jubilaeum_Plakat_quer from "../../../assets/images/Referenzen/Flex/FLEX_Jubilaeum_Plakat_quer.jpg";
import FilmClip_EN from "../../../assets/images/Referenzen/Flex/FilmClip_EN.mp4";
import FLEX_Jubilaeum_Fahnen_Gebaeude from "../../../assets/images/Referenzen/Flex/FLEX_Jubilaeum_Fahnen_Gebaeude.jpg";
import FLEX_Busbeschriftung_HN_19722004_RZ_03 from "../../../assets/images/Referenzen/Flex/FLEX_Busbeschriftung_HN_19722004_RZ_03.jpg";

export default class ReferenzContent extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section id="section-flex-new" className="section--content">
          <div className="section__content columns container-section">
            <div className="column column--6">
              <img
                ref="image"
                src={FLEX_Jubilaeum_Web_Einstiegsmotiv}
                className="teaser__image-left"
                alt="Kampagne - FLEX laesst alle glaenzen"
              />
            </div>

            <div className="column column--6">
              <div className="teaser__text padding">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--flex">Kampagne</p>
                  <h3 className="teaser__text-title">100 Jahre FLEX</h3>
                  <p className="teaser__text-details">
                    Unter dem Motto „Proud Past – exicting future“ feierte die
                    Marke FLEX 2022 den großen, runden Geburtstag. DAS ACHTE
                    GEBOT entwickelte hierfür ein charakteristisches Key visual
                    ganz zu Ehren des Winkelschleifers. Immerhin war es „die
                    FLEX“, die die Marke durch turbulente Zeiten geführt hat und
                    mit Ihrer flexiblen Welle noch heute zum Markenkern gehört.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="padding-small">
            <div className="section__content columns container--padding">
              <div className="column column--4">
                <div className="section__image-content">
                  <div className="section__image-content-wrapper">
                    <div className="teaser__text">
                      <div className="teaser__text-content">
                        <h5 className="teaser__text-title">Großfläche</h5>
                        <div>
                          <p className="teaser__text-details">
                            Mit den OOH-Motiven sorgte FLEX für Aufmerksamkeit
                            im Jubiläumsjahr
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column column--8"></div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <div className="teaser__text">
                <div className="teaser__text-content">
                  <div className="section__image-content">
                    <div className="section__image-content-wrapper">
                      <img
                        ref="image"
                        src={FLEX_Jubilaeum_Plakat_quer}
                        style={{ width: "100%", height: "100%" }}
                        alt="FLEX Jubiläumsgroßfläche"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="padding-small">
            <div className="section__content columns container--padding">
              <div className="column column--4">
                <div className="section__image-content">
                  <div className="section__image-content-wrapper">
                    <div className="teaser__text">
                      <div className="teaser__text-content">
                        <h5 className="teaser__text-title">Fahnen</h5>
                        <div>
                          <p className="teaser__text-details">
                            Der Firmensitz in Steinheim / Murr wurde in den
                            vergangenen Jahren markenkonform neu gestaltet. Auch
                            hier strahlt das Jubiläumsmotiv zur Begrüßung von
                            Mitarbeitenden und Gästen und sorgt für optische
                            Reichweite.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column column--8"></div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <div className="teaser__text">
                <div className="teaser__text-content">
                  <div className="section__image-content">
                    <div className="section__image-content-wrapper">
                      <img
                        ref="image"
                        src={FLEX_Jubilaeum_Fahnen_Gebaeude}
                        style={{ width: "100%", height: "100%" }}
                        alt="FLEX Jubiläumsfahnen am Gebäude"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="padding-small">
            <div className="section__content columns container--padding">
              <div className="column column--6">
                <div className="section__image-content">
                  <div className="section__image-content-wrapper">
                    <div className="teaser__text">
                      <div className="teaser__text-content">
                        <h5 className="teaser__text-title">Film</h5>
                        <div>
                          <p className="teaser__text-details">
                            Mit der „RED BEAST INSIDE“ ist im Jubilläumsjahr
                            eine besondere Winkelschleifer-Edition entstanden.
                            Für die Sonderserie wurde ein 4.000 PS starker
                            Dragster-Motor eingeschmolzen, der „mit der Kraft
                            der Transformation“ in einer limitierten Auflage von
                            Winkelschleifern auferstanden ist. <br /> <br />
                            Den Film zur Produkt-Präsentation erstellte DAS
                            ACHTE GEBOT vom Konzept über den Dreh bis zum
                            Finishing.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column column--6"></div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <div className="teaser__text">
                <div className="teaser__text-content">
                  <div className="section__image-content">
                    <div className="section__image-content-wrapper">
                      <video
                        id="BeltonAZVideo"
                        autostart="true"
                        playsInline
                        autoPlay
                        muted
                        loop
                        type="video/mp4"
                        src={FilmClip_EN}
                        style={{
                          width: "100%",
                          height: "auto",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="padding-small">
            <div className="section__content columns container--padding">
              <div className="column column--4">
                <div className="section__image-content">
                  <div className="section__image-content-wrapper">
                    <div className="teaser__text">
                      <div className="teaser__text-content">
                        <h5 className="teaser__text-title">Busbeschriftung</h5>
                        <div>
                          <p className="teaser__text-details">
                            Medienpräsenz und neue Energie aus dem Jubiläumsjahr
                            wurden genutzt für eine HR- Kampagne in der Region.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column column--8"></div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <div className="teaser__text">
                <div className="teaser__text-content">
                  <div className="section__image-content">
                    <div className="section__image-content-wrapper">
                      <img
                        ref="image"
                        src={FLEX_Busbeschriftung_HN_19722004_RZ_03}
                        style={{ width: "100%", height: "100%" }}
                        alt="FLEX Jubiläumsgroßfläche"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="section-flex-1" className="section--content">
          <div className="section__content columns container-section">
            <div className="column column--6">
              <img
                ref="image"
                src={Flex_Referenzen_Mood_Lina_1260px_breit}
                className="teaser__image-left"
                alt="Kampagne - FLEX laesst alle glaenzen"
              />
            </div>

            <div className="column column--6">
              <div className="teaser__text padding">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--flex">Kampagne</p>
                  <h3 className="teaser__text-title">
                    FLEX lässt alle glänzen
                  </h3>
                  <p className="teaser__text-details">
                    Mit der begeisterten Autofachfrau Lina van de Mars als
                    Testimonial festigt FLEX seine Pole Position im Segment
                    Poliermaschinen. Die Idee zur erfolgreichen Zusammenarbeit
                    mit bekannten Profis aus der Szene entwickelte DAS ACHTE
                    GEBOT im Rahmen einer ganzheitlichen Kampagne, die
                    Infotainment für alle Anwender vom Profi-Lackierer bis zum
                    ambitionierten Hobby-Anwender mit hilfreichen Clips rund ums
                    Polieren verbindet.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="teaser__text">
                    <div className="teaser__text-content">
                      <h5 className="teaser__text-title">Video-Clip</h5>
                      <div>
                        <p className="teaser__text-details">
                          Do-it-Yourself leicht gemacht: Influencer Lina Van de
                          Mars zeigt Auswahl und Einsatz der richtigen
                          Arbeitsmaterialien aus dem FLEX Poliererprogramm. Das
                          nimmt potentiellen Hobby-Anwendern die Angst vorm
                          Selbermachen. Zu finden auf dem FLEX YouTube-Channel
                          und der Polierer Landingpage.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column column--6"></div>
          </div>

          <div className="section__content columns container--padding">
            <div className="video-container column column--12">
              <VideoDisplay
                src="https://www.youtube.com/embed/C5C7c8RlcBY?controls=0"
                fullScreen=""
                backImage={Flex_Video_Polierer_Glaenzen}
                title="Poliererkampagne „Flex lässt alle glänzen“"
                border="0"
              />
            </div>
          </div>

          <div className="section__content columns container--padding-big">
            <div className="column column--6">
              <div className="teaser__text-content padding">
                <div className="section__image-content">
                  <div className="section__image-content-wrapper">
                    <div className="teaser__text">
                      <div className="teaser__text-content">
                        <h5 className="teaser__text-title">
                          Das Buch vom Polieren
                        </h5>
                        <div>
                          <p className="teaser__text-details">
                            FLEX zeigt Kompetenz: Auf 36 Seiten gibt FLEX alles,
                            was man über das Polieren wissen muss, an den
                            Anwender weiter. So wird nicht nur der
                            Polierprozess, sondern auch die Markenbindung ein
                            voller Erfolg. Konzept, Idee, Inhalt und
                            Finalisierung entstand dabei in enger Zusammenarbeit
                            von Produktmanagement und DEM ACHTEN GEBOT.
                          </p>
                        </div>
                      </div>
                    </div>
                    <img
                      ref="image"
                      src={Flex_Doppels_Lina_A4_hoch_1_615px_breit}
                      style={{ width: "100%", height: "100%" }}
                      alt="FLEX Polierprogramm"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <img
                    ref="image"
                    src={Flex_Titel_Buch_Lina_A4_hoch_615px_breit}
                    style={{ width: "100%", height: "100%" }}
                    alt="FLEX Polierprogramm"
                  />
                  <img
                    ref="image"
                    src={Flex_Doppels_Lina_A4_hoch_2_615px_breit}
                    style={{ width: "100%", height: "100%" }}
                    alt="FLEX Polierprogramm"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding-big">
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="teaser__text">
                    <div className="teaser__text-content">
                      <h5 className="teaser__text-title">Landingpage</h5>
                      <div>
                        <p className="teaser__text-details">
                          Das ganze FLEX Poliererprogramm und alle nötigen
                          Informationen finden Nutzer auf der responsiven
                          Landingpage.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column column--6"></div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <video
                id="FlexMockup"
                autostart="true"
                playsInline
                autoPlay
                muted
                loop
                type="video/mp4"
                src={Flex_Fade_Polierer}
                style={{
                  width: "100%",
                  height: "auto",
                }}
              />
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="teaser__text">
                    <div className="teaser__text-content">
                      <h5 className="teaser__text-title">
                        Google Ads Online-Banner
                      </h5>
                      <div>
                        <p className="teaser__text-details">
                          Zielgerichtete Online-Kommunikation bringt das FLEX
                          Poliererprogramm mit potentiellen Neukunden in
                          Kontakt. Die verlinkte Landingpage lässt sie noch
                          tiefer in die Welt des Polierens abtauchen.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column column--6"></div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <AnimateCC
                animationName="FLX17015_polierer_banner_970x250"
                composition="4DA79473841E5B4BADDDA1D2C4F5036C"
              />
            </div>
          </div>

          <div className="section__content columns container--padding-big">
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="teaser__text">
                    <div className="teaser__text-content">
                      <h5 className="teaser__text-title">FLEX Poster</h5>
                      <div>
                        <p className="teaser__text-details">
                          Gewusst womit: Auf einen Blick sehen Anwender, welche
                          Produkte bei welchem Lackschaden zum Einsatz kommen
                          müssen. Neben dem hohen Nutzen der Grafik sorgen Foto
                          und Unterschrift von Testimonial Lina Van de Mars für
                          eine prominente Platzierung in der Werkstatt.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column column--6"></div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <div className="teaser__text">
                <div className="teaser__text-content">
                  <div className="section__image-content">
                    <div className="section__image-content-wrapper">
                      <img
                        ref="image"
                        src={Flex_Poster_Lina_1260px_breit}
                        style={{ width: "100%", height: "100%" }}
                        alt="FLEX Poster Polierprogramm"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding-big">
            <div className="column column--6">
              <div className="teaser__text">
                <div className="teaser__text-content">
                  <div className="section__image-content">
                    <div className="section__image-content-wrapper">
                      <img
                        ref="image"
                        src={Flex_Titel_Brosch_Polierer_A4_hoch_615px_breit}
                        style={{ width: "100%", height: "100%" }}
                        alt="FLEX Polierprogramm"
                      />
                      <img
                        ref="image"
                        src={Flex_Doppels_Polierer_A4_hoch_2_615px_breit}
                        style={{ width: "100%", height: "100%" }}
                        alt="FLEX Kampagnen-Teaser Test"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="column column--6">
              <div className="section__image-content padding">
                <div className="section__image-content-wrapper">
                  <h5 className="teaser__text-title">FLEX Broschüre</h5>
                  <p className="teaser__text-details">
                    Auf 20 Seiten wird das gesamte FLEX Polierprogramm
                    übersichtlich vorgestellt und die Einsatzmöglichkeiten für
                    Poliermaschine, Pad und Politur erklärt.
                  </p>
                  <img
                    ref="image"
                    src={Flex_Doppels_Polierer_A4_hoch_1_615px_breit}
                    style={{ width: "100%", height: "100%" }}
                    alt="FLEX Polierprogramm"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="section-flex-2" className="section--content">
          <div className="section__content columns infinite__container">
            <div className="column column--6">
              <div className="teaser__text--intro padding">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--flex">
                    Akku-Polierer Kampagne
                  </p>
                  <h3 className="teaser__text-title">Nimm Dir die Freiheit</h3>
                  <div>
                    <p className="teaser__text-details">
                      Mit der FLEX Akku-Technologie startet die Kultmarke des
                      Polierer-Handwerks in eine neue Ära. Kabellos und voll
                      flexibel, wird Oberflächenbearbeitung jetzt überall
                      möglich. Mit der sukzessiven Einführung der Akku-Technik
                      in alle Poliergattungen setzt sich FLEX erneut klar von
                      den Wettbewerbern ab.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="column column--6"></div>
                  <div className="column column--6">
                    <img
                      ref="image"
                      src={Flex_Referenzen_Mood_Freiheit_1260px_breit}
                      className="teaser__image-right"
                      alt="FLEX Kampagne - Nimm Dir die Freiheit"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container">
            <div className="column column--9">
              <div className="teaser__text">
                <div className="teaser__text-content">
                  <div className="section__image-content">
                    <div className="section__image-content-wrapper">
                      <img
                        ref="image"
                        src={Flex_RollUp_Freiheit}
                        style={{ width: "100%", height: "100%" }}
                        alt="FLEX Broschüre"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column column--3">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <h5 className="teaser__text-title">Key-Visual</h5>
                  <p className="teaser__text-details">
                    Revolution in technology! Das passt zum Produkt und zur
                    Szene. DAS ACHTE GEBOT entwickelte deshalb zur klaren
                    Bildsprache eine starke Ansage. So setzt sich die Kultmarke
                    FLEX in den Lackierwerkstätten klar an die Spitze und
                    verankert sich im Herzen der Rebellen am Lack.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <h5 className="teaser__text-title">Video-Clip</h5>
                  <p className="teaser__text-details">
                    Multimedial präsent in den sozialen Medien und auf der FLEX
                    Website.
                  </p>
                </div>
              </div>
            </div>
            <div className="column column--6"></div>
          </div>

          <div className="section__content columns container--padding">
            <div className="video-container column column--12">
              <VideoDisplay
                src="https://www.youtube.com/embed/sPzcAuRLAwA?controls=0"
                fullScreen=""
                backImage={Flex_Video_Polierer_Freiheit}
                title="Akku-Poliererkampagne „Nimm Dir die Freiheit“"
                border="0"
              />
            </div>
          </div>

          <div className="section__content columns container--padding-big">
            <div className="column column--6"></div>
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="teaser__text">
                    <div className="teaser__text-content">
                      <h5 className="teaser__text-title">
                        Google Ads Online-Banner
                      </h5>
                      <div>
                        <p className="teaser__text-details">
                          Zielgerichtete Online-Kommunikation steigert die
                          Bekanntheit des FLEX Akku-Polierprogramms und leitet
                          potentielle Neukunden auf die informative Landingpage.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <AnimateCC
                animationName="flex_akku_polierer_970x250"
                composition="367F2FC901DE444BBACEE1800505DBDB"
              />
            </div>
          </div>

          <div className="section__content columns container--padding-big">
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="teaser__text">
                    <div className="teaser__text-content">
                      <h5 className="teaser__text-title">Landingpage</h5>
                      <div>
                        <p className="teaser__text-details">
                          Das Ziel der Online-Marketingmaßnahmen ist die
                          Landingpage. Alle technischen Features, alle Antworten
                          auf Kundenfragen, alle Optionen kompakt präsentiert.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column column--6"></div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <video
                id="FlexMockup"
                autostart="true"
                playsInline
                autoPlay
                muted
                loop
                type="video/mp4"
                src={Flex_Fade_AkkuPolierer}
                style={{
                  width: "100%",
                  height: "auto",
                }}
              />
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--6">
              <div className="teaser__text">
                <div className="teaser__text-content">
                  <div className="section__image-content">
                    <div className="section__image-content-wrapper">
                      <img
                        ref="image"
                        src={Flex_POS_Regal_Akku_Polierer_615px_breit}
                        style={{ width: "100%", height: "100%" }}
                        alt="Flex Polierprogramm"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column column--6">
              <div className="section__content columns">
                <div className="column column--4"></div>
                <div className="column column--8">
                  <h5 className="teaser__text-title">Give aways</h5>
                  <p className="teaser__text-details">
                    Stylische Shirts und Snapback-Caps machen FLEX Kunden zu
                    FLEX Fans.
                  </p>
                </div>
              </div>

              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <img
                    ref="image"
                    src={Flex_Werbemittel_Freiheit_615px_breit}
                    style={{ width: "100%", height: "100%" }}
                    alt="Flex Kampagnen-Teaser Test"
                  />
                  <div className="section__content columns">
                    <div className="column column--8">
                      <h5 className="teaser__text-title">
                        Warenpräsentation am POS
                      </h5>
                      <p
                        className="teaser__text-details"
                        style={{ paddingBottom: "0em", margin: "0em" }}
                      >
                        Aufmerksamkeitsstark wird die neue Freiheit beim
                        Polieren am POS präsentiert.
                      </p>
                    </div>
                    <div className="column column--4"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="section-flex-3" className="section--content">
          <div className="section__content columns container--padding">
            <div className="column column--6">
              <div className="teaser__text">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--flex">Print</p>
                  <h3 className="teaser__text-title">
                    Gesamtkatalog 2019/2020
                  </h3>
                  <div>
                    <p className="teaser__text-details">
                      Mehr als 300 Seiten umfasst der FLEX Gesamtkatalog, bei
                      dessen Erstellung Logik und Datenbanktechnologie auf
                      Markenemotion und Design treffen. Erfahrung in der
                      Kataloggestaltung, ein profundes Verständnis für die Marke
                      und die nahtlose Zusammenarbeit zwischen Kunde und DEM
                      ACHTEN GEBOT waren Erfolgsfaktoren, die zu einem
                      verkaufsstarken Ergebnis führten.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="column column--6"></div>
          </div>

          <div className="section__content columns container">
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <img
                    ref="image"
                    src={Flex_Doppels_Katalog_A4_hoch_1_615px_breit}
                    style={{ width: "100%", height: "100%" }}
                    alt="Flex Kampagnen-Teaser Test"
                  />
                  <img
                    ref="image"
                    src={Flex_Doppels_Katalog_A4_hoch_2_615px_breit}
                    style={{ width: "100%", height: "100%" }}
                    alt="Flex Kampagnen-Teaser Test"
                  />
                </div>
              </div>
            </div>
            <div className="column column--6">
              <div className="teaser__text">
                <div className="teaser__text-content">
                  <div className="section__image-content">
                    <div className="section__image-content-wrapper">
                      <img
                        ref="image"
                        src={Flex_Titel_Katalog_A4_hoch_615px_breit}
                        style={{ width: "100%", height: "100%" }}
                        alt="Flex Polierprogramm"
                      />
                      <img
                        ref="image"
                        src={Flex_Doppels_Katalog_A4_hoch_3_615px_breit}
                        style={{ width: "100%", height: "100%" }}
                        alt="Flex Polierprogramm"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="section__content columns infinite__container"
            style={{
              marginTop: "0rem",
              zIndex: "-1",
              position: "relative",
              maxHeight: "1000px",
            }}
          >
            <div className="column column--12">
              <div className="section__image-content-wrapper">
                <div className="circle"></div>
              </div>
            </div>
          </div>
        </section>

        <section id="section-flex-4" className="section--content">
          <div className="section__content columns container-section">
            <div className="column column--6">
              <div className="section__image-content-wrapper">
                <img
                  ref="image"
                  src={Flex_Referenzen_Mood_FlextECy_1260px_breit}
                  className="teaser__image-left "
                  alt="Flex Kampagne - More FlextECy"
                />
              </div>
            </div>

            <div className="column column--6">
              <div className="teaser__text padding">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--flex">Kampagne</p>
                  <h3 className="teaser__text-title">More FLEXTECY</h3>
                  <div>
                    <p className="teaser__text-details">
                      Effizient, kraftvoll, länger und vor allem dauerhaft
                      wartungsfrei arbeitet man mit EC-Technologie. Die
                      bürstenlosen Motoren sind extrem robust, verschleißarm und
                      wahre Kraftpakete im Arbeitsalltag. In Kombination mit der
                      FLEX Akku-Technologie entsteht ein überlegenes Produkt-
                      und Antriebskonzept für höchste Leistung und maximale
                      Freiheit in zahlreichen Anwendungen. Die Idee zur
                      übergeordneten Kampagne stammt vom ACHTEN GEBOT. Mit der
                      verbalen, akustischen und optischen Inszenierung der
                      EC-Technologie wurde ein Rahmen geschaffen, der die
                      Produkte im Rahmen von Kampagnen klar markiert.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <h5 className="teaser__text-title">Video-Clip</h5>
                  <p className="teaser__text-details">
                    Unser Produktteaser trifft auf interessierte Zielgruppen in
                    allen relevanten sozialen Medien und auf der FLEX Website.
                  </p>
                </div>
              </div>
            </div>
            <div className="column column--6"></div>
          </div>
          <div className="section__content columns container--padding">
            <div className="video-container column column--12">
              <VideoDisplay
                src="https://www.youtube.com/embed/kVSvYHwg3uI?controls=0"
                fullScreen=""
                backImage={Flex_Video_Flextecy}
                title="More FLEXTECY"
                border="0"
              />
            </div>
          </div>

          <div className="section__content columns container--padding-big">
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="teaser__text">
                    <div className="teaser__text-content">
                      <h5 className="teaser__text-title">Digitalkampagne</h5>
                      <div>
                        <p className="teaser__text-details">
                          Für Google Display, Social Media oder als Online Clip
                          werden alle Kampagnen mit animierten Bannern
                          flankiert.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column column--6"></div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <AnimateCC
                animationName="FLX18038_ec_banner_970x250"
                composition="F0DFFDEACD928E4791ECADA73758243B"
              />
            </div>
          </div>

          <div className="section__content columns container--padding-big">
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="teaser__text">
                    <div className="teaser__text-content">
                      <h5 className="teaser__text-title">Landingpage</h5>
                      <div>
                        <p className="teaser__text-details">
                          Das gemeinsame Ziel aller Online-Aktivitäten ist die
                          Landingpage, die alle Produktfragen beantwortet und
                          auf Wunsch direkt zum Shop führt.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column column--6"></div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <video
                id="FlexMockup"
                autostart="true"
                playsInline
                autoPlay
                muted
                loop
                type="video/mp4"
                src={Flex_Fade_Flextecy}
                style={{
                  width: "100%",
                  height: "auto",
                }}
              />
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--5">
              <img
                ref="image"
                src={Flex_POS_Regal_FlextECy_615px_breit}
                style={{ width: "100%", height: "100%" }}
                alt="FLEX POS"
              />
            </div>
            <div className="column column--4">
              <img
                ref="image"
                src={Flex_Rollup_FlextECy_615px_breit}
                style={{ width: "100%", height: "100%" }}
                alt="FLEX POS"
              />
            </div>
            <div className="column column--3">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <h5 className="teaser__text-title">Stark am POS</h5>
                  <p className="teaser__text-details">
                    Das POS-Konzept bringt Kundenwünsche auf den Punkt und macht
                    das Verkaufsgespräch ganz einfach.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding-big">
            <div className="column column--6">
              <div className="teaser__text-content padding">
                <div className="section__image-content">
                  <div className="section__image-content-wrapper">
                    <div className="teaser__text">
                      <div className="teaser__text-content">
                        <h5 className="teaser__text-title">
                          FLEX Akku-Werkzeugbroschüre
                        </h5>
                        <div>
                          <p className="teaser__text-details">
                            Umfassende Range trifft auf komplexe Technologie.
                            Eine emotionale Bildsprache mit zahlreichen Features
                            vereint die Faszination für Technik und klare
                            Fakten.
                          </p>
                        </div>
                      </div>
                    </div>
                    <img
                      ref="image"
                      src={Flex_Doppels_FlextECy_1_A4_hoch_615px_breit}
                      style={{ width: "100%", height: "100%" }}
                      alt="FLEX Akku-Werkzeugbroschuere"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <img
                    ref="image"
                    src={Flex_Titel_Brosch_FlextECy_A4_hoch}
                    style={{ width: "100%", height: "100%" }}
                    alt="FLEX Akku-Werkzeugbroschuere"
                  />

                  <img
                    ref="image"
                    src={Flex_Doppels_FlextECy_2_A4_hoch_615px_breit}
                    style={{ width: "100%", height: "100%" }}
                    alt="FLEX Akku-Werkzeugbroschuere"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="section-flex-5" className="section--content">
          <div className="section__content columns infinite__container">
            <div className="column column--6">
              <div className="teaser__text--intro padding">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--flex">Kampagne</p>
                  <h3 className="teaser__text-title">Sicherheitssauger</h3>
                  <div>
                    <p className="teaser__text-details">
                      Zur Markteinführung der neuen VCE-Sicherheitssauger zeigt
                      FLEX unübersehbar Präsenz und Stärke: Online mit gezielten
                      Kampagnen und in den wichtigsten Printmedien der
                      Zielgruppen, auf Messen sowie am POS mit gezielten
                      Vkf-Maßnahmen.
                      <br />
                      Mit dem Rückenwind unserer strategischen
                      Marketing-Maßnahmen kommt kein Profi-Anwender mehr an den
                      neuen FLEX Saugern und den perfekt passenden
                      Elektrowerkzeugen vorbei. Maßgeschneiderte Kommunikation,
                      die bei der Zielgruppe ankommt und die Auswahl einfach
                      macht.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="column column--6">
              <div className="section__image-content-wrapper">
                <img
                  ref="image"
                  src={Flex_Referenzen_Mood_Sauger_1260px_breit}
                  className="teaser__image-right"
                  alt="Flex Kampagnen - Sicherheitssauger"
                />
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--6">
              <div className="teaser__text">
                <div className="teaser__text-content">
                  <div className="section__image-content">
                    <div className="section__image-content-wrapper">
                      <img
                        ref="image"
                        src={Flex_Motive_Sauger_1_A4_hoch_615px_breit}
                        style={{ width: "100%", height: "100%" }}
                        alt="Flex Polierprogramm"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="column column--6-bottom">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <h5 className="teaser__text-title">Starke Grundmotive</h5>
                  <p
                    className="teaser__text-details"
                    style={{ paddingBottom: "0em", marginBottom: "0em" }}
                  >
                    Mit zielgruppenspezifischen Themen und Motiven weist FLEX
                    auf die Leistungsfähigkeit der neuen Sauger-Generation hin
                    und stellt die aus Anwendersicht wichtigsten Attribute in
                    den Mittelpunkt.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding-big">
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <h5 className="teaser__text-title">Facebook/Online Clip</h5>
                  <p className="teaser__text-details">
                    Klare Argumente, überzeugende Technik: Die wichtigsten
                    Innovationen werden im Videoclip schnell und informativ
                    präsentiert.
                  </p>
                </div>
              </div>
            </div>
            <div className="column column--6"></div>
          </div>

          <div className="section__content columns container--padding">
            <div className="video-container column column--12">
              <VideoDisplay
                src="https://www.youtube.com/embed/X7xitP7VNVI?controls=0"
                fullScreen=""
                backImage={Flex_Video_Sicherheitssauger}
                title="Kampagne Sicherheitssauger"
                border="0"
              />
            </div>
          </div>

          <div className="section__content columns container--padding-big">
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <img
                    ref="image"
                    src={Flex_Titel_Brosch_Sauger_A4_hoch_615px_breit}
                    style={{ width: "100%", height: "100%" }}
                    alt="FLEX Broschüre"
                  />
                  <img
                    ref="image"
                    src={Flex_Doppels_Sauger_2_A4_hoch_1_615px_breit}
                    style={{ width: "100%", height: "100%" }}
                    alt="FLEX Broschüre"
                  />
                  <img
                    ref="image"
                    src={Flex_Doppels_Sauger_1_A4_hoch_1_615px_breit}
                    style={{ width: "100%", height: "100%" }}
                    alt="FLEX Broschüre"
                  />
                </div>
              </div>
            </div>
            <div className="column column--6">
              <div className="teaser__text">
                <div className="teaser__text-content">
                  <div className="section__image-content">
                    <div className="section__image-content-wrapper">
                      <h5 className="teaser__text-title">POS-Material</h5>
                      <p className="teaser__text-details">
                        Das Fachhandelsdisplay sorgt für hohe Aufmerksamkeit am
                        POS. Zur schnellen Übersicht über die neue Range steht
                        eine handliche Broschüre zur ersten Orientierung zur
                        Verfügung.
                      </p>
                      <img
                        ref="image"
                        src={Flex_POS_Sauger_615px_breit}
                        style={{ width: "100%", height: "100%" }}
                        alt="FLEX POS"
                      />
                      <img
                        ref="image"
                        src={Flex_Werbemittel_Display_Cleaner_615px_breit}
                        style={{ width: "100%", height: "100%" }}
                        alt="FLEX Display-Cleaner"
                      />
                      <h5 className="teaser__text-title">Saubere Sache</h5>
                      <p className="teaser__text-details">
                        Der praktische Display-Cleaner als Give-away.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="section-flex-6" className="section--content">
          <div className="section__content columns container-section">
            <div className="column column--6">
              <div className="section__image-content-wrapper">
                <img
                  ref="image"
                  src={Flex_Referenzen_Mood_DD4G_1260px_breit}
                  className="teaser__image-left"
                  alt="Flex Kampagnen-Teaser Test"
                />
              </div>
            </div>

            <div className="column column--6">
              <div className="teaser__text padding">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--flex">
                    Produkteinführung
                  </p>
                  <h3 className="teaser__text-title">
                    Akku-Bohrschrauber DD4G
                  </h3>
                  <div>
                    <p className="teaser__text-details">
                      Das ist was für echte Profis: 4 Gänge für alle Anwendungen
                      von der Holzbearbeitung bis zum knallharten Metall. Bei
                      der Einführungskampagne stehen deshalb echte Testimonials
                      direkt aus der Werkhalle bzw. von der Baustelle Rede und
                      Antwort, weil authentisch eben mehr ist als nur „echt“.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <h5 className="teaser__text-title">Broschüre One 4 all</h5>
                  <p className="teaser__text-details">
                    4 Gänge, 4 Anwendungen und alle Möglichkeiten beim Spiel
                    zwischen Kraft und Tempo. Weil sich eben nicht alles in 10
                    Sekunden kommunizieren lässt, finden Anwender und
                    Fachhändler alles wesentliche auch gedruckt am POS.
                  </p>
                  <img
                    ref="image"
                    src={Flex_Doppels_DD4G_A4_hoch_1_615px_breit}
                    style={{ width: "100%", height: "100%" }}
                    alt="Flex Broschuere One 4 all"
                  />
                </div>
              </div>
            </div>
            <div className="column column--6">
              <div className="teaser__text">
                <div className="teaser__text-content">
                  <div className="section__image-content">
                    <div className="section__image-content-wrapper">
                      <img
                        ref="image"
                        src={Flex_Titel_Brosch_DD4G_A4_hoch_615px_breit}
                        style={{ width: "100%", height: "100%" }}
                        alt="Flex Broschuere One 4 all"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding-big">
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <h5 className="teaser__text-title">Testimonial Videos</h5>
                  <p className="teaser__text-details">
                    Mehr Reichweite, mehr Fans und mehr echte Meinungen. Ohne
                    Skript aber dafür mit Herzblut und Überzeugung berichten die
                    FLEX Praxistester über ihre Erfahrungen.
                  </p>
                </div>
              </div>
            </div>
            <div className="column column--6"></div>
          </div>

          <div className="section__content columns container--padding">
            <div className="video-container column column--6">
              <VideoDisplay
                src="https://www.youtube.com/embed/9v36Tr2OW0M?controls=0"
                fullScreen=""
                backImage={Flex_Video_Testimonial_1}
                title="Testimonial 1 Akku-Bohrschrauber DD4G"
                border="0"
              />
            </div>

            <div className="video-container column column--6">
              <VideoDisplay
                src="https://www.youtube.com/embed/BuPc6N_c4HQ?controls=0"
                fullScreen=""
                backImage={Flex_Video_Testimonial_2}
                title="Testimonial 2 Akku-Bohrschrauber DD4G"
                border="0"
              />
            </div>
          </div>
        </section>

        <section id="section-flex-7" className="section--content">
          <div className="section__content columns container">
            <div className="column column--6">
              <div className="teaser__text">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--flex">Kampagne</p>
                  <h3 className="teaser__text-title">20 Jahre Giraffe</h3>
                  <div>
                    <p className="teaser__text-details">
                      Für Trockenbauer die klare Nummer 1. Die Giraffe steht für
                      die Gattung der Langhalsschleifer so synonym wie Tempo für
                      das Papiertaschentuch. Der Souveränität der Marke ist es
                      zu verdanken, dass die Jubiläumskampagne zündet, ohne dass
                      Produkt und Anwendung explizit gezeigt werden müssen.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="column column--6"></div>
          </div>

          <div className="section__content columns container--padding-big">
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="teaser__text">
                    <div className="teaser__text-content">
                      <h5 className="teaser__text-title">
                        Google Ads Banner-Werbung
                      </h5>
                      <div>
                        <p className="teaser__text-details">
                          Maximal User-Experience: Die Online-Kampagne verlinkt
                          den Besucher auf die informative Landingpage, die alle
                          Fragen zur neuen Sauger-Generation beantwortet und so
                          den Fachhandel deutlich entlastet.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column column--6"></div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <AnimateCC
                animationName="FLX16027_WebannerGiraffe_970x250"
                composition="D53F8AC83A071C4B9097CED1449144A0"
              />
            </div>
          </div>

          <div className="section__content columns container--padding-big">
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <h5 className="teaser__text-title">Youtube Clip</h5>
                  <p className="teaser__text-details">
                    Happy birthday FLEX Giraffe! Mit einem unterhaltsamen Clip
                    feiert FLEX sein Original und bringt es spielerisch in die
                    Köpfe der Anwender.
                  </p>
                  <div className="video-container--single">
                    <VideoDisplay
                      src="https://www.youtube.com/embed/goBjUpsZo08?controls=0"
                      fullScreen="allowFullScreen"
                      backImage={Flex_Video_Giraffe}
                      title="Kampagne 20 Jahre Giraffe"
                      border="0"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="column column--6">
              <div className="teaser__text">
                <div className="teaser__text-content">
                  <div className="section__image-content">
                    <div className="section__image-content-wrapper">
                      <h5 className="teaser__text-title">Anzeige</h5>
                      <p className="teaser__text-details">
                        Mit einer Kampagnen-Aktion zur extra langen
                        Produktgarantie feiert FLEX die Robustheit und hohe
                        Qualität des Original Langhalsschleifers.
                      </p>
                      <img
                        ref="image"
                        src={Flex_Anzeige_Giraffe_A4_hoch_neu}
                        style={{ width: "100%", height: "100%" }}
                        alt="Flex Polierprogramm"
                      />
                      <img
                        ref="image"
                        src={Flex_Anzeige_Giraffe_A4_hoch_615px_breit}
                        style={{ width: "100%", height: "100%" }}
                        alt="Flex Polierprogramm"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="section__content columns infinite__container"
            style={{ marginTop: "-50rem", zIndex: "-1", position: "relative" }}
          >
            <div className="column column--12">
              <div className="section__image-content-wrapper">
                <img
                  src={Flex_Referenzen_Mood_Giraffe_2520px_breit}
                  className="teaser__image-center"
                  style={{ top: "350px" }}
                  alt="Flex Kampagnen-Teaser Test"
                />
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
