import React from "react";

import Meva_Referenzen_Mood_Helm from "../../../assets/images/Referenzen/Meva/Kampagne/Meva_Referenzen_Mood_Helm.jpg";
import Meva_Titel_Brosch_Image_A4_quer from "../../../assets/images/Referenzen/Meva/Kampagne/Meva_Titel_Brosch_Image_A4_quer_neu.jpg";
import Meva_Doppels_Brosch_A4_quer_1 from "../../../assets/images/Referenzen/Meva/Kampagne/Meva_Doppels_Brosch_A4_quer_1.jpg";
import Meva_Doppels_Brosch_A4_quer_2 from "../../../assets/images/Referenzen/Meva/Kampagne/Meva_Doppels_Brosch_A4_quer_2.jpg";
import Meva_Doppels_Brosch_A4_quer_3 from "../../../assets/images/Referenzen/Meva/Kampagne/Meva_Doppels_Brosch_A4_quer_3.jpg";
import Meva_Keyvisual_Boardroom from "../../../assets/images/Referenzen/Meva/Kampagne/Meva_Keyvisual_Boardroom.jpg";
import Meva_Keyvisual_Hologramm from "../../../assets/images/Referenzen/Meva/Kampagne/Meva_Keyvisual_Hologramm.jpg";
import Meva_Titel_Handbuch_A4_quer from "../../../assets/images/Referenzen/Meva/Kampagne/Meva_Titel_Handbuch_A4_quer_neu.jpg";
import Meva_Doppels_Handbuch_A4_quer_1 from "../../../assets/images/Referenzen/Meva/Kampagne/Meva_Doppels_Handbuch_A4_quer_1.jpg";
import Meva_Doppels_Handbuch_A4_quer_2 from "../../../assets/images/Referenzen/Meva/Kampagne/Meva_Doppels_Handbuch_A4_quer_2.jpg";
import Meva_Doppels_Handbuch_A4_quer_3 from "../../../assets/images/Referenzen/Meva/Kampagne/Meva_Doppels_Handbuch_A4_quer_3.jpg";
import Meva_Messestand_1_615px from "../../../assets/images/Referenzen/Meva/Kampagne/Meva_Messestand_1_615px.jpg";
import Meva_Messestand_2_615px from "../../../assets/images/Referenzen/Meva/Kampagne/Meva_Messestand_2_615px.jpg";
import Meva_Messestand_3_615px from "../../../assets/images/Referenzen/Meva/Kampagne/Meva_Messestand_3_615px.jpg";
import Meva_Messestand_4_615px from "../../../assets/images/Referenzen/Meva/Kampagne/Meva_Messestand_4_615px.jpg";
import Meva_Mockup from "../../../assets/video/referenzen/meva/Meva.mp4";

export default class ReferenzContent extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section id="section-meva-1" className="section--content">
          <div className="section__content columns infinite__container">
            <div className="column column--6">
              <div className="teaser__text--intro padding">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--meva">Markenrelaunch</p>
                  <h3 className="teaser__text-title">
                    Auch optisch einfach leichter
                  </h3>
                  <div>
                    <p className="teaser__text-details">
                      Betonschalungen sind Schwergewichte und das Handling auf
                      der Baustelle ist harte Arbeit und mitunter auch eine
                      Kunst. Wie man Dank cleverer Details einfacher plant,
                      schalt und hervorragende Ergebnisse hervorbringt, ist das
                      besondere Know-how von MEVA. Mit dem Markenrelaunch hat
                      bei MEVA eine neue Leichtigkeit Einzug gehalten, die die
                      Produktvorteile ebenso wie die digitalen Zukunftsthemen am
                      Bau widerspiegeln.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <img
                    ref="image"
                    src={Meva_Referenzen_Mood_Helm}
                    className="teaser__image-right"
                    alt="MEVA Schalungs-Systeme"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--4">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <h5 className="teaser__text-title">Key-Visuals</h5>
                  <p className="teaser__text-details">
                    Kernwerte und Emotionen der Marke im Bild zu vereinen, ist
                    die Hauptaufgabe von Key-Visuals. Diese Aufgabe übernimmt
                    bei MEVA der Bauhelm, der flexibel überall dort hin passt,
                    wo sich Menschen mit Betonschalung beschäftigen.
                  </p>
                </div>
              </div>
            </div>
            <div className="column column--8"></div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <img
                ref="image"
                src={Meva_Keyvisual_Boardroom}
                style={{ width: "100%", height: "100%" }}
                alt="MEVA Key-Visual"
              />
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <img
                ref="image"
                src={Meva_Keyvisual_Hologramm}
                style={{ width: "100%", height: "100%" }}
                alt="MEVA Key-Visual"
              />
            </div>
          </div>

          <div className="section__content columns container--padding-big">
            <div className="column column--6">
              <img
                ref="image"
                src={Meva_Titel_Handbuch_A4_quer}
                style={{ width: "100%", height: "100%" }}
                alt="Handbuch"
              />
            </div>
            <div className="column column--6-bottom">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <h5 className="teaser__text-title">CD-Handbuch</h5>
                  <p className="teaser__text-details">
                    Der einheitliche Standard des optischen Auftritts wird im
                    CD-Handbuch definiert. Mit dieser Grundlage arbeiten
                    Marketingmitarbeiter ebenso wie Dienstleister von MEVA
                    weltweit.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--2"></div>
            <div className="column column--10">
              <img
                ref="image"
                src={Meva_Doppels_Handbuch_A4_quer_1}
                style={{ width: "100%", height: "100%" }}
                alt="CD-Handbuch"
              />
            </div>
          </div>

          <div className="section__content columns container">
            <div className="column column--10">
              <img
                ref="image"
                src={Meva_Doppels_Handbuch_A4_quer_2}
                style={{ width: "100%", height: "100%" }}
                alt="CD-Handbuch"
              />
            </div>
            <div className="column column--2"></div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--2"></div>
            <div className="column column--10">
              <img
                ref="image"
                src={Meva_Doppels_Handbuch_A4_quer_3}
                style={{ width: "100%", height: "100%" }}
                alt="CD-Handbuch"
              />
            </div>
          </div>

          <div className="section__content columns container--padding-big">
            <div className="column column--6">
              <img
                ref="image"
                src={Meva_Titel_Brosch_Image_A4_quer}
                style={{ width: "100%", height: "100%" }}
                alt="Imagebroschüre"
              />
            </div>
            <div className="column column--6-bottom">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <h5 className="teaser__text-title">Imagebroschüre</h5>
                  <p className="teaser__text-details">
                    Architektur, Ingenieurswissenschaft, Persönlichkeit – dies
                    sind nur einige Aspekte der Marken-DNA von MEVA. Dass das
                    Familienunternehmen für weit mehr steht als nur das Produkt
                    transportiert die Imagebroschüre. Eine
                    Unternehmensvorstellung, die Kunden, Stakeholder und
                    Mitarbeiter gleichermaßen anspricht und die Broschüre
                    deshalb sowohl als Vertriebs-, als auch als Image- und als
                    Recruiting-Tool einsetzbar macht.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--2"></div>
            <div className="column column--10">
              <img
                ref="image"
                src={Meva_Doppels_Brosch_A4_quer_1}
                style={{ width: "100%", height: "100%" }}
                alt="Imagebroschüre"
              />
            </div>
          </div>

          <div className="section__content columns container">
            <div className="column column--10">
              <img
                ref="image"
                src={Meva_Doppels_Brosch_A4_quer_2}
                style={{ width: "100%", height: "100%" }}
                alt="Imagebroschüre"
              />
            </div>
            <div className="column column--2"></div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--2"></div>
            <div className="column column--10">
              <img
                ref="image"
                src={Meva_Doppels_Brosch_A4_quer_3}
                style={{ width: "100%", height: "100%" }}
                alt="Imagebroschüre"
              />
            </div>
          </div>

          <div className="section__content columns container--padding-big">
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <h5 className="teaser__text-title">Messestand bauma 2019</h5>
                  <p className="teaser__text-details">
                    Die bauma ist die weltweite Leitmesse der Branche und die
                    wichtigste Plattform zur Markenpräsentation im direkten
                    Wettbewerbsumfeld. Mit dem Fokus auf Planung und
                    Projektrealisation in BIM (Building Information Management)
                    hat MEVA in München voll ins Schwarze getroffen.
                    Standvisualisierung, Ausgestaltung und Relaunch aller Medien
                    zur Messe hin waren eine Punktlandung für DAS ACHTE GEBOT.
                    Nach rund 15 Monaten Gesamtprojektzeitraum sportlich über
                    die Ziellinie.
                  </p>
                </div>
              </div>
            </div>
            <div className="column column--6"></div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--6">
              <img
                ref="image"
                src={Meva_Messestand_1_615px}
                style={{ width: "100%", height: "100%" }}
                alt="Messestand bauma 2019"
              />
            </div>
            <div className="column column--6">
              <img
                ref="image"
                src={Meva_Messestand_2_615px}
                style={{ width: "100%", height: "100%" }}
                alt="Messestand bauma 2019"
              />
            </div>
          </div>

          <div className="section__content columns container">
            <div className="column column--6">
              <img
                ref="image"
                src={Meva_Messestand_3_615px}
                style={{ width: "100%", height: "100%" }}
                alt="Messestand bauma 2019"
              />
            </div>
            <div className="column column--6">
              <img
                ref="image"
                src={Meva_Messestand_4_615px}
                style={{ width: "100%", height: "100%" }}
                alt="Messestand bauma 2019"
              />
            </div>
          </div>

          <div className="section__content columns container--padding-big">
            <div className="column column--6"></div>
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <h5 className="teaser__text-title">Website</h5>
                  <p className="teaser__text-details">
                    Die zentrale Website mit 8 Regionalseiten und zahlreichen
                    Sprachen für die internationalen Töchter und Niederlassungen
                    war eine Herausforderung für die Webarchitekten vom ACHTEN
                    GEBOT. Vom Artikelfinder, über die Landingpages mit
                    automatischer Zuordnung der Ansprechpartner bis zur
                    Stellenbörse gab es eine Vielzahl von Funktionen abzubilden,
                    die den User ans richtige Ziel bringen. Jetzt ist die Seite
                    fit für strategische Online-Kampagnen und kann als wichtiges
                    Vertriebstool 24/7 genutzt werden.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <video
                id="FlexMockup"
                autostart="true"
                playsInline
                autoPlay
                muted
                loop
                type="video/mp4"
                src={Meva_Mockup}
                style={{
                  width: "100%",
                  height: "auto"
                }}
              />
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
