import React from "react";

export default class ButtonScrollUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_visible: false
    };
  }

  componentDidMount() {
    var scrollComponent = this;
    document.addEventListener("scroll", function(e) {
      scrollComponent.toggleVisibility();
    });
  }

  toggleVisibility() {
    if (window.pageYOffset > 550) {
      this.setState({
        is_visible: true
      });
    } else {
      this.setState({
        is_visible: false
      });
    }
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  render() {
    const { is_visible } = this.state;
    return (
      <div className="scroll-to-top">
        {is_visible && (
          <div onClick={() => this.scrollToTop()}>
            <svg xmlns="http://www.w3.org/2000/svg" height="45" width="45">
              <style
                type="text/css"
                dangerouslySetInnerHTML={{
                  __html: "\n .button {\n fill: #AC1E1C;\n }\n "
                }}
              />
              <path
                className="button"
                d="M22.5,0C10.4,0,0,10.4,0,22.5C0,34.6,10.4,45,22.5,45C34.6,45,45,34.6,45,22.5C45,10.4,34.6,0,22.5,0z
	 M31.5,20.9c-0.4,0.4-1,0.4-1.4,0l-6.6-6.6v18.6c0,0.6-0.4,1-1,1s-1-0.4-1-1V14.3l-6.6,6.6c-0.4,0.4-1,0.4-1.4,0
	c-0.4-0.4-0.4-1,0-1.4l8.3-8.3c0,0,0,0,0,0c0.1-0.1,0.2-0.1,0.3-0.2l0,0c0,0,0,0,0,0c0.1,0,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0
	c0.1,0,0.2,0,0.3,0.1c0.1,0,0.2,0.1,0.3,0.2l8.3,8.3C31.9,19.9,31.9,20.5,31.5,20.9z"
              />
            </svg>
          </div>
        )}
      </div>
    );
  }
}
