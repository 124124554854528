import React from "react";

class ReferenzIntro extends React.Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { width } = this.state;
    const isMobile = width <= 812;

    if (isMobile) {
      return (
        <React.Fragment>
          <section id="section-flex">
            <div className="section__content columns container--padding-big">
              <div className="teaser__text column--6">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--sika">
                    SIKA
                  </p>
                  <h3 className="teaser__text-title">Interne Kommunikation</h3>
                </div>
              </div>
              <div className="section__intro-content column--6">
                <p className="teaser__text-details">
                Bei über 25.000 Mitarbeitern in 100 Ländern steht für das Spezialitätenchemie-Unternehmen Sika AG neben der Kunden- und Investorenkommunikation vor allem auch die Kommunikation mit den Mitarbeitern im Mittelpunkt. 
                Dabei muss die Zielgruppe nicht von Produkten oder Dienstleistungen, sondern von definierten Arbeitsweisen, neuen Tools und strukturellen oder technischen Veränderungen überzeugt werden.
                Auf diesem Weg begleitet DAS ACHTE GEBOT die Sika Services AG in der kreativen und grafischen Entwicklung von Kampagnen rund um das Thema Change-Management und -Communication.</p>
              </div>
            </div>
          </section>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <section id="section-flex">
            <div className="section__content columns container--padding-big">
              <div className="teaser__text column--6">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--sika">
                    SIKA
                  </p>
                  <h3 className="teaser__text-title">Interne Kommunikation</h3>
                </div>
              </div>
              <div className="section__intro-content column--6">
                <p className="teaser__text-details">
                Bei über 25.000 Mitarbeitern in 100 Ländern steht für das Spezialitätenchemie-Unternehmen Sika AG neben der Kunden- und Investorenkommunikation vor allem auch die Kommunikation mit den Mitarbeitern im Mittelpunkt. 
                Dabei muss die Zielgruppe nicht von Produkten oder Dienstleistungen, sondern von definierten Arbeitsweisen, neuen Tools und strukturellen oder technischen Veränderungen überzeugt werden.
                Auf diesem Weg begleitet DAS ACHTE GEBOT die Sika Services AG in der kreativen und grafischen Entwicklung von Kampagnen rund um das Thema Change-Management und -Communication.</p>
              </div>
            </div>
          </section>
        </React.Fragment>
      );
    }
  }
}
export default ReferenzIntro;
