import React from "react";

class ReferenzIntro extends React.Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { width } = this.state;
    const isMobile = width <= 812;

    if (isMobile) {
      return (
        <React.Fragment>
          <section id="section-flv">
            <div className="section__content columns container--padding-big">
              <div className="teaser__text column--6">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--devyl">DEVELOP-P</p>
                  <h3 className="teaser__text-title">Digitale Markenbildung</h3>
                </div>
              </div>
              <div className="section__intro-content column--6">
                <p className="teaser__text-details">
                  Beratungs- und Coaching-Dienstleister Develop-P hat sich einem
                  klaren Ziel verschrieben: „Mitarbeiter durch die passende Aus-
                  und Weiterbildung und mit mehr als nur fachlichen Inhalten zu
                  fördern und damit für Unternehmen einen echten Mehrwert zu
                  schaffen.“ Dafür haben sie neben ihrem täglich Brot auch
                  gleich eine eigene Learn-Management-Software auf Basis
                  jahrzehntelanger Erfahrung und eigenem Lern-Konzept
                  entwickelt.
                </p>
              </div>
            </div>
          </section>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <section id="section-flv">
            <div className="section__content columns container--padding-big">
              <div className="teaser__text column--6">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--devyl">DEVELOP-P</p>
                  <h3 className="teaser__text-title">Digitale Markenbildung</h3>
                </div>
              </div>
              <div className="section__intro-content column--6">
                <p className="teaser__text-details">
                  Beratungs- und Coaching-Dienstleister Develop-P hat sich einem
                  klaren Ziel verschrieben: „Mitarbeiter durch die passende Aus-
                  und Weiterbildung und mit mehr als nur fachlichen Inhalten zu
                  fördern und damit für Unternehmen einen echten Mehrwert zu
                  schaffen.“ Dafür haben sie neben ihrem täglich Brot auch
                  gleich eine eigene Learn-Management-Software auf Basis
                  jahrzehntelanger Erfahrung und eigenem Lern-Konzept
                  entwickelt.
                </p>
              </div>
            </div>
          </section>
        </React.Fragment>
      );
    }
  }
}
export default ReferenzIntro;
