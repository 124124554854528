import React from "react";

import SectionAnimation from "../SectionComponent/SectionAnimation";
class SectionIntro extends React.Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { width } = this.state;
    const isMobile = width <= 812;

    if (isMobile) {
      return (
        <section id="section-about">
          <div className="section__content columns container--padding">
            <div className="teaser__text column--6">
              <div className="teaser__text-content">
                <p className="teaser__text-subtitle">Das Achte Gebot</p>
                <h3 className="teaser__text-title">Ungelogen gute Werbung</h3>
              </div>
            </div>

            <div className="section__intro-content column--6">
              <p className="teaser__text-details">
                Wir sind die Werbeagentur, die den wahren Kern und damit den
                echten Wert Ihrer Marke, Ihres Produkts und Ihrer
                Dienstleistungen in den Mittelpunkt stellt. Klare Worte und
                klare Bilder statt Werbeblabla und optisches Chichi. Dabei
                beraten, konzipieren und kreieren wir umfassend. Von der
                Strategie bis zur crossmedialen Umsetzung: Digital und Print,
                Online und Offline, statisch und dynamisch.
              </p>
            </div>
          </div>
        </section>
      );
    } else {
      return (
        <section id="section-about" className="section">
          <div className="section__content columns container--padding">
            <div className="column column--4"></div>
            <div className="column column--4" style={{ marginTop: "-8%" }}>
              <SectionAnimation />
            </div>
            <div className="column column--4"></div>
          </div>

          <div className="section__content columns container">
            <div className="teaser__text column--6">
              <div className="teaser__text-content">
                <p className="teaser__text-subtitle">Das Achte Gebot</p>
                <h3 className="teaser__text-title">
                  Ungelogen <br />
                  gute Werbung
                </h3>
              </div>
            </div>

            <div className="section__intro-content column--6">
              <p className="margin">
                Wir sind die Werbeagentur, die den wahren Kern und damit den
                echten Wert Ihrer Marke, Ihres Produkts und Ihrer
                Dienstleistungen in den Mittelpunkt stellt. Klare Worte und
                klare Bilder statt Werbeblabla und optisches Chichi. Dabei
                beraten, konzipieren und kreieren wir umfassend. Von der
                Strategie bis zur crossmedialen Umsetzung: Digital und Print,
                Online und Offline, statisch und dynamisch.
              </p>
            </div>
          </div>
        </section>
      );
    }
  }
}
export default SectionIntro;
