import React from "react";

import ReferenzAnimation from "../JanusComponent/ReferenzAnimation";

class ReferenzIntro extends React.Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { width } = this.state;
    const isMobile = width <= 812;

    if (isMobile) {
      return (
        <React.Fragment>
          <section id="section-janus">
            <div className="section__content columns container--padding">
              <div className="teaser__text column--6">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--janus">
                    JANUS Engineering AG
                  </p>
                  <h3 className="teaser__text-title">Markenevolution</h3>
                </div>
              </div>
              <div className="section__intro-content column--6">
                <p className="teaser__text-details">
                  Janus Engineering ebnet den Weg für das digitale
                  Manufacturing. Als zertifizierter Solution Partner unterstützt
                  Janus Engineering in zahlreichen Industrien bei der
                  Integration, Individualisierung und Schulung der Siemens NX
                  PLM Lösungen. Um das internationale Wachstum mit einer
                  einheitlichen und klar positionierten Marke anzugehen,
                  schlossen sich die europäischen Partnerunternehmen in einer
                  starken Holding zusammen.
                </p>
              </div>
            </div>
          </section>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <section id="section-janus" className="section--intro">
            <div className="section__content columns container--padding">
              <div className="column column--4"></div>
              <div className="column column--4" style={{ marginTop: "-8%" }}>
                <ReferenzAnimation />
              </div>
              <div className="column column--4"></div>
            </div>

            <div className="section__content columns container">
              <div className="teaser__text column--6">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--janus">
                    JANUS Engineering AG
                  </p>
                  <h3 className="teaser__text-title">Markenevolution</h3>
                </div>
              </div>
              <div className="section__intro-content column--6">
                <p className="teaser__text-details margin">
                  Janus Engineering ebnet den Weg für das digitale
                  Manufacturing. Als zertifizierter Solution Partner unterstützt
                  Janus Engineering in zahlreichen Industrien bei der
                  Integration, Individualisierung und Schulung der Siemens NX
                  PLM Lösungen. Um das internationale Wachstum mit einer
                  einheitlichen und klar positionierten Marke anzugehen,
                  schlossen sich die europäischen Partnerunternehmen in einer
                  starken Holding zusammen.
                </p>
              </div>
            </div>
          </section>
        </React.Fragment>
      );
    }
  }
}
export default ReferenzIntro;
