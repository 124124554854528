import React from "react";

import ReferenzAnimation from "../DuerrComponent/ReferenzAnimation";

class ReferenzIntro extends React.Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { width } = this.state;
    const isMobile = width <= 812;

    if (isMobile) {
      return (
        <React.Fragment>
          <section id="section-duerr">
            <div className="section__content columns container--padding">
              <div className="teaser__text column--6">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--duerr">Dürr AG</p>
                  <h3 className="teaser__text-title">HR-Kampagnen</h3>
                </div>
              </div>
              <div className="section__intro-content column--6">
                <p className="teaser__text-details">
                  Die Dürr AG als Maschinen- und Anlagenbauer steht vor der
                  Aufgabe sich Fach- und Führungskräfte für die Zukunft zu
                  sichern. In einem wettbewerbsstarken Umfeld mit zahlreichen
                  attraktiven Arbeitgebermarken muss sich die HR-Marke bewähren.
                  Dürr sucht Mitarbeiter mit „Leidenschaft für neue
                  Technologien“. Bereits seit 2011 unterstützt DAS ACHTE GEBOT
                  die Personaler der Dürr AG in den Bereichen interne
                  Kommunikation, Recruiting-Kampagnen, Azubi-Marketing, sowie
                  Messe und Events
                </p>
              </div>
            </div>
          </section>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <section id="section-duerr" className="section--intro">
            <div className="section__content columns container--padding">
              <div className="column column--4"></div>
              <div className="column column--4" style={{ marginTop: "-8%" }}>
                <ReferenzAnimation />
              </div>
              <div className="column column--4"></div>
            </div>

            <div className="section__content columns container">
              <div className="teaser__text column--6">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--duerr">Dürr AG</p>
                  <h3 className="teaser__text-title">HR-Kampagnen</h3>
                </div>
              </div>
              <div className="section__intro-content column--6">
                <p className="teaser__text-details margin">
                  Die Dürr AG als Maschinen- und Anlagenbauer steht vor der
                  Aufgabe sich Fach- und Führungskräfte für die Zukunft zu
                  sichern. In einem wettbewerbsstarken Umfeld mit zahlreichen
                  attraktiven Arbeitgebermarken muss sich die HR-Marke bewähren.
                  Dürr sucht Mitarbeiter mit „Leidenschaft für neue
                  Technologien“. Bereits seit 2011 unterstützt DAS ACHTE GEBOT
                  die Personaler der Dürr AG in den Bereichen interne
                  Kommunikation, Recruiting-Kampagnen, Azubi-Marketing, sowie
                  Messe und Events
                </p>
              </div>
            </div>
          </section>
        </React.Fragment>
      );
    }
  }
}
export default ReferenzIntro;
