import React from "react";
import { NavLink } from "react-router-dom";
import Div100vh from "react-div-100vh";

export default class ReferenzImageMobile extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section className="slide">
          <NavLink to="/referenzen/meva">
            <Div100vh className="section__image--meva full-bg-size-component">
              <div className="section__intro--mobile-wrapper">
                <div className="section__intro--mobile-top">
                  <nav className="navigation--clients-mobile">
                    <ul>
                      <li>
                        <NavLink to="/referenzen/meva">
                          Meva Schalungs-Systeme
                        </NavLink>
                      </li>
                      <li></li>
                    </ul>
                  </nav>
                  <div className="section__intro--subtitle">
                    <span>Corporate Branding und Kommunikation</span>
                  </div>
                </div>
                <div className="section__intro--mobile-bottom">
                  <nav className="navigation--clients-mobile">
                    <ul>
                      <li>
                        <NavLink to="/referenzen/kunden">Alle Kunden</NavLink>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </Div100vh>
          </NavLink>
        </section>
      </React.Fragment>
    );
  }
}
