import React from "react";
import { NavLink } from "react-router-dom";

class Switch extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section id="section-switch">
          <div className="section__switch columns container">
            <div className="column column--6">
              <nav className="menu menu__switch">
                <div className="menu__switch-item-container">
                  <p className="teaser__text-subtitle">Vorherige Referenz</p>
                </div>
                <div className="menu__switch-item-container-back">
                  <NavLink to="/referenzen/geberit" className="menu__item">
                    <span className="menu__switch-item-name">
                      <h3 className="teaser__text-title">
                        Geberit International
                      </h3>
                    </span>
                  </NavLink>
                </div>
              </nav>
            </div>

            <div className="column column--6">
              <nav className="menu menu__switch-next">
                <div className="menu__switch-item-container">
                  <p className="teaser__text-subtitle">Nächste Referenz</p>
                </div>
                <div className="menu__switch-item-container-next">
                  <NavLink to="/referenzen/ewg" className="menu__item">
                    <span className="menu__switch-item-name">
                      <h3 className="teaser__text-title">Eisenwerk Würth</h3>
                    </span>
                  </NavLink>
                </div>
              </nav>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default Switch;
