import React from "react";

import ReferenzAnimation from "../SolidianComponent/ReferenzAnimation";

class ReferenzIntro extends React.Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { width } = this.state;
    const isMobile = width <= 812;

    if (isMobile) {
      return (
        <React.Fragment>
          <section id="section-solidian">
            <div className="section__content columns container--padding">
              <div className="teaser__text column--6">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--solidian">
                    Solidian GmbH
                  </p>
                  <h3 className="teaser__text-title">
                    Strategisches Marketing
                  </h3>
                </div>
              </div>
              <div className="section__intro-content column--6">
                <p className="teaser__text-details">
                  Der Hersteller von Textilbewehrung im Betonbau hat sich vom
                  Start-up zum führenden Anbieter entwickelt. Die Blue
                  Ocean-Marktsituation und die Entwicklung hin zu Lean Building
                  gilt es zu nutzen, um den technologischen Vorsprung weiter
                  auszubauen. Textilbeton wird dem Stahlbeton künftig in vielen
                  Anwendungen den Rang ablaufen. Für das weitere Wachstum
                  notwendig sind neben innovationsfreudigen Kunden auch
                  qualifizierte Mitarbeiter.
                </p>
              </div>
            </div>
          </section>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <section id="section-solidian" className="section--intro">
            <div className="section__content columns container--padding">
              <div className="column column--4"></div>
              <div className="column column--4" style={{ marginTop: "-8%" }}>
                <ReferenzAnimation />
              </div>
              <div className="column column--4"></div>
            </div>

            <div className="section__content columns container">
              <div className="teaser__text column--6">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--solidian">
                    Solidian GmbH
                  </p>
                  <h3 className="teaser__text-title">
                    Strategisches Marketing
                  </h3>
                </div>
              </div>
              <div className="section__intro-content column--6">
                <p className="teaser__text-details margin">
                  Der Hersteller von Textilbewehrung im Betonbau hat sich vom
                  Start-up zum führenden Anbieter entwickelt. Die Blue
                  Ocean-Marktsituation und die Entwicklung hin zu Lean Building
                  gilt es zu nutzen, um den technologischen Vorsprung weiter
                  auszubauen. Textilbeton wird dem Stahlbeton künftig in vielen
                  Anwendungen den Rang ablaufen. Für das weitere Wachstum
                  notwendig sind neben innovationsfreudigen Kunden auch
                  qualifizierte Mitarbeiter.
                </p>
              </div>
            </div>
          </section>
        </React.Fragment>
      );
    }
  }
}
export default ReferenzIntro;
