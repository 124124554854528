import React from "react";
import { HashLink as Link } from "react-router-hash-link";

import DuerrIntro from "../../../assets/images/Referenzen/Duerr/Teaserbild_Durr_Gaming_2560x1440px.jpg";

export default class ReferenzImage extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div
          className="full-bg-size-component-swipe"
          style={{
            backgroundImage: `url(${DuerrIntro})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            width: "100%",
            height: "100vh",
            display: "inline-block",
          }}
        ></div>
        <div className="scroll-down-arrow-wrapper">
          <Link to="#section-duerr">
            <div className="scroll-down-arrow"></div>
          </Link>
        </div>
      </React.Fragment>
    );
  }
}
