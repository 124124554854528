import React from "react";

import ReferenzAnimation from "./ReferenzAnimation";

class ReferenzIntro extends React.Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { width } = this.state;
    const isMobile = width <= 812;

    if (isMobile) {
      return (
        <React.Fragment>
          <section id="section-kwasny">
            <div className="section__content columns container--padding">
              <div className="teaser__text column--6">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--3bIDO">3b IDO</p>
                  <h3 className="teaser__text-title">Vertriebsmarketing</h3>
                </div>
              </div>
              <div className="section__intro-content column--6">
                <p className="teaser__text-details">
                  3b IDO macht Büroeinrichtungen, die mit ihren Aufgaben
                  wachsen. Dabei verbindet das mittelständische
                  Familienunternehmen klassisches Handwerk mit modernster
                  Fertigung und dem Know-how für ganzheitliche Bürokonzepte von
                  der Decke bis zur Raumbegrünung. DAS ACHTE GEBOT betreut 3b
                  IDO als Full-Service-Agentur in allen Bereichen der
                  Kundenkommunikation sowie im HR-Marketing
                </p>
              </div>
            </div>
          </section>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <section id="section-kwasny" className="section--intro">
            <div className="section__content columns container--padding">
              <div className="column column--4"></div>
              <div className="column column--4" style={{ marginTop: "-8%" }}>
                <ReferenzAnimation />
              </div>
              <div className="column column--4"></div>
            </div>

            <div className="section__content columns container">
              <div className="teaser__text column--6">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--3bIDO">3b IDO</p>
                  <h3 className="teaser__text-title">Vertriebsmarketing</h3>
                </div>
              </div>
              <div className="section__intro-content column--6">
                <p className="teaser__text-details margin">
                  3b IDO macht Büroeinrichtungen, die mit ihren Aufgaben
                  wachsen. Dabei verbindet das mittelständische
                  Familienunternehmen klassisches Handwerk mit modernster
                  Fertigung und dem Know-how für ganzheitliche Bürokonzepte von
                  der Decke bis zur Raumbegrünung. DAS ACHTE GEBOT betreut 3b
                  IDO als Full-Service-Agentur in allen Bereichen der
                  Kundenkommunikation sowie im HR-Marketing
                </p>
              </div>
            </div>
          </section>
        </React.Fragment>
      );
    }
  }
}
export default ReferenzIntro;
