import React from "react";
import { NavLink } from "react-router-dom";

class Kundenliste extends React.Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    

   
      return (
        <div>
          <React.Fragment>
            <section id="section-kunden" className="section__content-intro">
              <div className="section__content columns container">
                <div className="teaser__text">
                  <div className="teaser__text-content">
                    <p className="teaser__text-subtitle">Kundenliste</p>
                    <h3 className="teaser__text-title">
                      Wir danken den <br />
                      besten Kunden der Welt
                    </h3>
                  </div>
                </div>
              </div>
            </section>

            <section className="section-kunden">
              <div className="section__content columns container">
                <div class="column menu--customers">

                    <div className="menu__item-container">
                      <div className="menu__item">
                        <NavLink to="/referenzen/geberit">
                          <span className="menu__item-name">
                            <strong>Geberit International AG</strong>
                          </span>
                        </NavLink>
                      </div>
                    </div>
                    <div className="menu__item-container">
                      <div className="menu__item">
                        <NavLink to="/referenzen/flex">
                          <span className="menu__item-name">
                            <strong>FLEX-Elektrowerkzeuge GmbH</strong>
                          </span>
                        </NavLink>
                      </div>
                    </div>
                    <div className="menu__item-container">
                      <div className="menu__item">
                        <NavLink to="/referenzen/devyl">
                          <span className="menu__item-name">
                            <strong>DEVELOP-P GmbH</strong>
                          </span>
                        </NavLink>
                      </div>
                    </div>
                    <div className="menu__item-container">
                      <NavLink to="/referenzen/sika">
                        <div className="menu__item">
                          <span className="menu__item-name">
                            <strong>Sika AG</strong>
                          </span>
                        </div>
                      </NavLink>
                    </div>
                    <div className="menu__item-container">
                      <div className="menu__item">
                        <NavLink to="/referenzen/kwasny">
                          <span className="menu__item-name">
                            <strong>Peter Kwasny GmbH</strong>
                          </span>
                        </NavLink>
                      </div>
                    </div>
                    <div className="menu__item-container">
                      <div className="menu__item">
                        <NavLink to="/referenzen/3bido">
                          <span className="menu__item-name">
                            <strong> 3b IDO Jörg Scholz GmbH</strong>
                          </span>
                        </NavLink>
                      </div>
                    </div>
                    <div className="menu__item-container">
                      <div className="menu__item">
                        <NavLink to="/referenzen/duerr">
                          <span className="menu__item-name">
                            <strong>Dürr AG</strong>
                          </span>
                        </NavLink>
                      </div>
                    </div>
                    <div className="menu__item-container">
                      <NavLink to="/referenzen/fv">
                        <div className="menu__item">
                          <span className="menu__item-name">
                            <strong>Fahrlehrerversicherung VAG</strong>
                          </span>
                        </div>
                      </NavLink>
                    </div>
                    <div className="menu__item-container">
                      <div className="menu__item">
                        <NavLink to="/referenzen/ewg">
                          <span className="menu__item-name">
                            <strong>Eisenwerk Würth GmbH</strong>
                          </span>
                        </NavLink>
                      </div>
                    </div>
                    <div className="menu__item-container">
                      <div className="menu__item">
                        <NavLink to="/referenzen/janus">
                          <span className="menu__item-name">
                            <strong>Janus Engineering AG</strong>
                          </span>
                        </NavLink>
                      </div>
                    </div>
                    <div className="menu__item-container">
                      <div className="menu__item">
                        <NavLink to="/referenzen/felsomat">
                          <span className="menu__item-name">
                            <strong>Felsomat GmbH & Co. KG</strong>
                          </span>
                        </NavLink>
                      </div>
                    </div>
                    <div className="menu__item-container">
                      <div className="menu__item">
                        <NavLink to="/referenzen/solidian">
                          <span className="menu__item-name">
                            <strong>solidian GmbH</strong>
                          </span>
                        </NavLink>
                      </div>
                    </div>
                    <div className="menu__item-container">
                      <div className="menu__item">
                        <NavLink to="/referenzen/meva">
                          <span className="menu__item-name">
                            <strong>MEVA Schalungs-Systeme GmbH</strong>
                          </span>
                        </NavLink>
                      </div>
                    </div>
                    <div className="menu__item-container">
                      <div className="menu__item">
                        <NavLink to="/referenzen/klingbeil">
                          <span className="menu__item-name">
                            <strong>Klingbeil GmbH</strong>
                          </span>
                        </NavLink>
                      </div>
                    </div>
                    <div className="menu__item-container">
                      <div className="menu__item-none">
                          <span className="menu__item-name">
                            <strong>Frizz Production4Toolzz GmbH</strong>
                          </span>
                      </div>
                    </div>
                    <div className="menu__item-container">
                      <div className="menu__item-none">
                        <span className="menu__item-name">Glöckle Gärten</span>
                      </div>
                    </div>
                    <div className="menu__item-container">
                      <div className="menu__item-none">
                        <span className="menu__item-name">
                          Ingersoll Werkzeuge GmbH
                        </span>
                      </div>
                    </div>
                    <div className="menu__item-container">
                      <div className="menu__item-none">
                        <span className="menu__item-name">ITronic GmbH</span>
                      </div>
                    </div>
                    <div className="menu__item-container">
                      <div className="menu__item-none">
                        <span className="menu__item-name">Kemmer Hartmetallwerkzeuge GmbH</span>
                      </div>
                    </div>
                    <div className="menu__item-container">
                      <div className="menu__item-none">
                        <span className="menu__item-name">KWM Müller GmbH</span>
                      </div>
                    </div>
                    <div className="menu__item-container">
                      <div className="menu__item-none">
                        <span className="menu__item-name">
                          Nidec Motors & Actuators GmbH
                        </span>
                      </div>
                    </div>
                    
                    <div className="menu__item-container">
                      <div className="menu__item-none">
                        <span className="menu__item-name">QUEISSER GmbH</span>
                      </div>
                    </div>
                    <div className="menu__item-container">
                      <div className="menu__item-none">
                        <span className="menu__item-name">
                          Roweko Kunststofftechnik GmbH
                        </span>
                      </div>
                    </div>
                    <div className="menu__item-container">
                      <div className="menu__item-none">
                        <span className="menu__item-name">
                          Seiffer & Steiner Präzisionsteile GmbH
                        </span>
                      </div>
                    </div>
                    <div className="menu__item-container">
                      <div className="menu__item-none">
                        <span className="menu__item-name">SYSTECS GmbH</span>
                      </div>
                    </div>
             

                  
                                

                    
                    <div className="menu__item-container">
                      <div className="menu__item-none">
                        <span className="menu__item-name">
                          Filtration Group GmbH
                        </span>
                      </div>
                    </div>
                    
                    <div className="menu__item-container">
                      <div className="menu__item-none">
                        <span className="menu__item-name">
                          Inotec Electronics GmbH
                        </span>
                      </div>
                    </div>

                    <div className="menu__item-container">
                      <div className="menu__item-none">
                        <span className="menu__item-name">
                          Kaminsteinwerk Erich Müller GmbH
                        </span>
                      </div>
                    </div>
                    <div className="menu__item-container">
                      <div className="menu__item-none">
                        <span className="menu__item-name">
                          Feintool
                        </span>
                      </div>
                    </div>
                    
                    <div className="menu__item-container">
                      <div className="menu__item-none">
                        <span className="menu__item-name">
                          ORCA Industriemontagen GmbH & Co. KG
                        </span>
                      </div>
                    </div>
                    <div className="menu__item-container">
                      <div className="menu__item-none">
                        <span className="menu__item-name">Polytan GmbH</span>
                      </div>
                    </div>
                    
                    <div className="menu__item-container">
                      <div className="menu__item-none">
                        <span className="menu__item-name">
                          Schaefer Invest GmbH
                        </span>
                      </div>
                    </div>

                    <div className="menu__item-container">
                      <div className="menu__item-none">
                        <span className="menu__item-name">
                          Weinheimat Württemberg
                        </span>
                      </div>
                    </div>

                    
                    <div className="menu__item-container">
                      <div className="menu__item-none">
                        <span className="menu__item-name">Amparex GmbH</span>
                      </div>
                    </div>

                    <div className="menu__item-container">
                      <div className="menu__item-none">
                        <span className="menu__item-name">
                          EVG Lufttechnik GmbH
                        </span>
                      </div>
                    </div>
                    <div className="menu__item-container">
                      <div className="menu__item-none">
                        <span className="menu__item-name">
                          Gastechnik Kirchner GmbH
                        </span>
                      </div>
                    </div>
                    <div className="menu__item-container">
                      <div className="menu__item-none">
                        <span className="menu__item-name">
                          Härterei Reese Brackenheim GmbH
                        </span>
                      </div>
                    </div>
                    
                    <div className="menu__item-container">
                      <div className="menu__item-none">
                        <span className="menu__item-name">KB Toolzz GmbH</span>
                      </div>
                    </div>
                    <div className="menu__item-container">
                      <div className="menu__item-none">
                        <span className="menu__item-name">
                          Kunzmann GmbH & Co. KG
                        </span>
                      </div>
                    </div>
                    <div className="menu__item-container">
                      <div className="menu__item-none">
                        <span className="menu__item-name">Mahle</span>
                      </div>
                    </div>
                    
                   
                    <div className="menu__item-container">
                      <div className="menu__item-none">
                        <span className="menu__item-name">
                          Robert Bosch GmbH
                        </span>
                      </div>
                    </div>
                    
                    <div className="menu__item-container">
                      <div className="menu__item-none">
                        <span className="menu__item-name">Sommer GmbH</span>
                      </div>
                    </div>     
                </div>
              </div>
            </section>
          </React.Fragment>
        </div>
      );
  }
}
export default Kundenliste;
