import React from "react";



export default class VideoDisplay extends React.Component {
    rerenderComponent= ()=>{
        this.forceUpdate();
      }
      componentDidMount() {
        document.getElementById("cookiebot-selected").addEventListener("click", this.rerenderComponent);
        document.getElementById("cookiebot-all").addEventListener("click", this.rerenderComponent);
      }
    
      componentWillUnmount() {
        document.getElementById("cookiebot-selected").removeEventListener("click", this.rerenderComponent);
        document.getElementById("cookiebot-all").removeEventListener("click", this.rerenderComponent);
      }
     
    
      render() {

        function showCookie(){
          document.getElementById("cookie__popup").classList.add("show");
    
        }



    return (
      <React.Fragment>
        {typeof window.Cookiebot !== 'undefined' && window.Cookiebot.consent && window.Cookiebot.consent.marketing ? (
            <iframe
                src={this.props.src}
                allowFullScreen={this.props.fullScreen}
                title={this.props.title}
                frameBorder={this.props.border}
            ></iframe>
        ) : (
            <div style={{height:"60%"}}>
                <div style={{width:"50%", margin:"auto", marginTop:"2rem", marginBottom:"1rem"}}>
                    Zum Anzeigen der Videos, wählen Sie die "Marketing"-Cookies und "Auswahl speichern und schließen" oder "Alle akzeptieren".
                </div>
                <div className="video__button" style={{width:"50%"}} onClick={showCookie}>
                    Einstellungen ändern
                </div>
            </div>
        )}
      </React.Fragment>
    );
  }
}
