import React from "react";
import "../../assets/css/sociallinks.css";
import { NavLink as Link } from "react-router-dom";

const SocialLinkButton = ({ title, target, isLocal }) => {
  if (isLocal) {
    return (
      <Link to={target} className="social__button">
        <p>{title}</p>
      </Link>
    );
  } else {
    return (
      <a href={target} className="social__button">
        <p>{title}</p>
      </a>
    );
  }
};

export default SocialLinkButton;
