import React from "react";
import DynamicScrollToTop from "../components/ScrollToTopComponent/DynamicScrollToTop";
import Random from "../components/RandomComponent/Random";
import Header from "../components/HeaderComponent/Header";
import Contact from "../components/ContactComponent/contact";
import Footer from "../components/FooterComponent/Footer";

class Kontakt extends React.Component {
  componentDidMount() {
    document.title = "Offenes Ohr gesucht?";
  }
  render() {
    return (
      <React.Fragment>
        <DynamicScrollToTop />
        <Random />
        <Header />
        <Contact />
        <Footer />
      </React.Fragment>
    );
  }
}

export default Kontakt;
