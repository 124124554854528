import React from "react";
import { HashLink as Link } from "react-router-hash-link";

export default class Referenzdetail extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section id="section-detail">
          <div className="section__content columns container infinite-container">
            <div className="column column--4">
              <nav className="menu menu--campaigns">
                <div className="menu__item-container">
                  <div className="menu__item-work" href="/">
                    <span className="menu__item-name">Kunde</span>
                  </div>
                </div>
                <div className="menu__item-container">
                  <span className="menu__item-name">Peter Kwasny GmbH</span>
                </div>
              </nav>
            </div>
            <div className="column column--4">
              <nav className="menu menu--campaigns">
                <div className="menu__item-container">
                  <div className="menu__item-work">
                    <span className="menu__item-name">Aufgaben</span>
                  </div>
                </div>
                <div className="menu__item-container">
                  <span className="menu__item-name">
                    Markenführung, Einführungs- und Aktivierungskampagnen,
                    Print- und Online-Medienplanung und -erstellung,
                    Handelsaktionen, Content-Entwicklung für Web und Social
                  </span>
                </div>
              </nav>
            </div>
            <div className="column column--4">
              <nav className="menu menu--campaigns">
                <div className="menu__item-container">
                  <div className="menu__item-work">
                    <span className="menu__item-name">Referenzen</span>
                  </div>
                </div>
                <div className="menu__item-container">
                  <Link to="#section-flex-1">
                    <div className="menu__item">
                      <span className="menu__item-name--flex">
                        belton Markenrelaunch
                      </span>
                    </div>
                  </Link>
                </div>
                <div className="menu__item-container">
                  <Link to="#section-flex-2">
                    <div className="menu__item">
                      <span className="menu__item-name--flex">
                        Content Marketing und Social Media
                      </span>
                    </div>
                  </Link>
                </div>
                <div className="menu__item-container">
                  <Link to="#section-flex-3">
                    <div className="menu__item">
                      <span className="menu__item-name--flex">
                        Produkteinführung belton free
                      </span>
                    </div>
                  </Link>
                </div>
                <div className="menu__item-container">
                  <Link to="#section-flex-3">
                    <div className="menu__item">
                      <span className="menu__item-name--flex">
                        Verkaufsaktion Bienen
                      </span>
                    </div>
                  </Link>
                </div>
              </nav>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
