import React from "react";
import { NavLink } from "react-router-dom";

export default class ContentImprint extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      cookieListe:"keine Daten"
    };
  }
  
  componentDidMount () {
    const textDeclaratrion = document.getElementById("cookie__info").innerHTML;
  document.getElementById("second__cookielist").innerHTML= textDeclaratrion;
}

  
  render() {
    return (
      <React.Fragment>
        <section id="section-dataprotection" className="section__content-intro section--content">
          <div className="section__content columns container--padding-none">
            <div className="teaser__text column--6">
              <div className="teaser__text-content">
                <p className="teaser__text-subtitle">Das Achte Gebot</p>
                <h3 className="teaser__text-title">Datenschutz</h3>
              </div>
            </div>
            <div className="section__intro-content column--6">
              <h5 className="margin">Datenschutzerklärung dasachtegebot.de</h5>
              <p>
                Der Schutz Ihrer Daten ist uns besonders wichtig. Im Folgenden
                stellen wollen wir Ihnen deshalb den Umfang und Zweck der
                Verarbeitung von personenbezogenen Daten (nachfolgend kurz
                „Daten“) innerhalb unseres Onlineangebotes und der mit ihm
                verbundenen Webseiten, Funktionen und Inhalte sowie externen
                Onlinepräsenzen, wie z.B. unser Social Media Profile auf.
                (nachfolgend gemeinsam bezeichnet als „Onlineangebot“). Im
                Hinblick auf die verwendeten Begrifflichkeiten, wie z.B.
                „Verarbeitung“ oder „Verantwortlicher“ verweisen wir auf die
                Definitionen im Art. 4 der Datenschutzgrundverordnung (DSGVO).
              </p>

              <h5>Verantwortlicher</h5>
              <p>
                DAS ACHTE GEBOT Werbeagentur GmbH <br />
                Gröninger Weg 7<br />
                74379 Ingersheim
                <br />
              </p>
              <p>
                <a href="mailto:info@dasachtegebot.de" title="Keep in touch">
                  info@dasachtegebot.de
                </a>
                <br />
                <a href="https://www.dasachtegebot.de" title="Website">
                  https://www.dasachtegebot.de
                </a>
                <br />
                <br />
                <a href="tel:+497142774760" title="Keep in touch">
                  +49 (0)7142 - 77476 - 0
                </a>
                <br />
                <a href="tel:+497142774760" title="Keep in touch">
                  +49 (0)7142 - 77476 - 18
                </a>
              </p>
              <p>
                Geschäftsführung:
                <br />
                Harri Grimm, <br />
                Britta Krumrain-Ernst,
                <br />
                Marco Schrenk
              </p>
              <p>
                Impressum:{" "}
                <NavLink to="/impressum">
                  https://www.dasachtegebot.de/impressum
                </NavLink>
              </p>
              <p>
                Externer Datenschutzbeauftragter
                <br />
                PRIOLAN GmbH
                <br />
                Wolfgang Trostel
                <br />
                Gottlieb-Daimler-Str. 9<br />
                74076 Heilbronn
                <br />
              </p>
              <p>
                <a
                  href="mailto:datenschutz@dasachtegebot.de"
                  title="Datenschutz"
                >
                  datenschutz@dasachtegebot.de
                </a>
              </p>

              <h5>Arten der verarbeiteten Daten:</h5>
              <ul>
                <li>- Bestandsdaten (z.B., Namen, Adressen).</li>
                <li>- Kontaktdaten (z.B., E-Mail, Telefonnummern).</li>
                <li>
                  - Inhaltsdaten (z.B., Texteingaben, Fotografien, Videos).
                </li>
                <li>
                  - Nutzungsdaten (z.B., besuchte Webseiten, Interesse an
                  Inhalten, Zugriffszeiten).
                </li>
                <li>
                  - Meta-/Kommunikationsdaten (z.B., Geräte-Informationen,
                  IP-Adressen).
                </li>
              </ul>

              <h5>Kategorien betroffener Personen</h5>
              <p>
                Besucher und Nutzer des Onlineangebotes (Nachfolgend bezeichnen
                wir die betroffenen Personen zusammenfassend auch als „Nutzer“).{" "}
              </p>

              <h5>Zweck der Verarbeitung</h5>
              <ul>
                <li>
                  - Zurverfügungstellung des Onlineangebotes, seiner Funktionen
                  und Inhalte.
                </li>
                <li>
                  - Beantwortung von Kontaktanfragen und Kommunikation mit
                  Nutzern.
                </li>
                <li>- Sicherheitsmaßnahmen.</li>
                <li>- Reichweitenmessung/Marketing</li>
              </ul>

              <h5>Verwendete Begrifflichkeiten</h5>
              <p>
                „Personenbezogene Daten“ sind alle Informationen, die sich auf
                eine identifizierte oder identifizierbare natürliche Person (im
                Folgenden „betroffene Person“) beziehen; als identifizierbar
                wird eine natürliche Person angesehen, die direkt oder indirekt,
                insbesondere mittels Zuordnung zu einer Kennung wie einem Namen,
                zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung
                (z.B. Cookie) oder zu einem oder mehreren besonderen Merkmalen
                identifiziert werden kann, die Ausdruck der physischen,
                physiologischen, genetischen, psychischen, wirtschaftlichen,
                kulturellen oder sozialen Identität dieser natürlichen Person
                sind. <br />
                <br />
                „Verarbeitung“ ist jeder mit oder ohne Hilfe automatisierter
                Verfahren ausgeführten Vorgang oder jede solche Vorgangsreihe im
                Zusammenhang mit personenbezogenen Daten. Der Begriff reicht
                weit und umfasst praktisch jeden Umgang mit Daten. <br />
                <br />
                Als „Verantwortlicher“ wird die natürliche oder juristische
                Person, Behörde, Einrichtung oder andere Stelle, die allein oder
                gemeinsam mit anderen über die Zwecke und Mittel der
                Verarbeitung von personenbezogenen Daten entscheidet,
                bezeichnet.
              </p>

              <h5>Maßgebliche Rechtsgrundlagen</h5>
              <p>
                Nach Maßgabe des Art. 13 DSGVO teilen wir Ihnen die
                Rechtsgrundlagen unserer Datenverarbeitungen mit. Sofern die
                Rechtsgrundlage in der Datenschutzerklärung nicht genannt wird,
                gilt Folgendes: Die Rechtsgrundlage für die Einholung von
                Einwilligungen ist Art. 6 Abs. 1 lit. a und Art. 7 DSGVO, die
                Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer
                Leistungen und Durchführung vertraglicher Maßnahmen sowie
                Beantwortung von Anfragen ist Art. 6 Abs. 1 lit. b DSGVO, die
                Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer
                rechtlichen Verpflichtungen ist Art. 6 Abs. 1 lit. c DSGVO, und
                die Rechtsgrundlage für die Verarbeitung zur Wahrung unserer
                berechtigten Interessen ist Art. 6 Abs. 1 lit. f DSGVO. Für den
                Fall, dass lebenswichtige Interessen der betroffenen Person oder
                einer anderen natürlichen Person eine Verarbeitung
                personenbezogener Daten erforderlich machen, dient Art. 6 Abs. 1
                lit. d DSGVO als Rechtsgrundlage.{" "}
              </p>

              <h5>Zusammenarbeit mit Auftragsverarbeitern und Dritten</h5>
              <p>
                Sofern wir im Rahmen unserer Verarbeitung Daten gegenüber
                anderen Personen und Unternehmen (Auftragsverarbeitern oder
                Dritten) offenbaren, sie an diese übermitteln oder ihnen sonst
                Zugriff auf die Daten gewähren, erfolgt dies nur auf Grundlage
                einer gesetzlichen Erlaubnis Sie eingewilligt haben, eine
                rechtliche Verpflichtung dies vorsieht oder auf Grundlage
                unserer berechtigten Interessen (z.B. beim Einsatz von
                Beauftragten, Webhostern, etc.). <br />
                <br />
                Sofern wir Dritte mit der Verarbeitung von Daten auf Grundlage
                eines sog. „Auftragsverarbeitungsvertrages“ beauftragen,
                geschieht dies auf Grundlage des Art. 28 DSGVO.
              </p>

              <h5>Rechte der betroffenen Personen</h5>
              <p>
                Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob
                betreffende Daten verarbeitet werden und auf Auskunft über diese
                Daten sowie auf weitere Informationen und Kopie der Daten
                entsprechend Art. 15 DSGVO. <br />
                <br />
                Sie haben entsprechend. Art. 16 DSGVO das Recht, die
                Vervollständigung der Sie betreffenden Daten oder die
                Berichtigung der Sie betreffenden unrichtigen Daten zu
                verlangen. <br />
                <br />
                Sie haben nach Maßgabe des Art. 17 DSGVO das Recht zu verlangen,
                dass betreffende Daten unverzüglich gelöscht werden, bzw.
                alternativ nach Maßgabe des Art. 18 DSGVO eine Einschränkung der
                Verarbeitung der Daten zu verlangen. <br />
                <br />
                Sie haben das Recht zu verlangen, dass die Sie betreffenden
                Daten, die Sie uns bereitgestellt haben nach Maßgabe des Art. 20
                DSGVO zu erhalten und deren Übermittlung an andere
                Verantwortliche zu fordern. <br />
                <br />
                Sie haben ferner gem. Art. 77 DSGVO das Recht, eine Beschwerde
                bei der zuständigen Aufsichtsbehörde einzureichen.
              </p>

              <h5>Widerrufsrecht</h5>
              <p>
                Sie haben das Recht, erteilte Einwilligungen gem. Art. 7 Abs. 3
                DSGVO mit Wirkung für die Zukunft zu widerrufen.{" "}
              </p>

              <h5>Widerspruchsrecht</h5>
              <p>
                Sie können der künftigen Verarbeitung der Sie betreffenden Daten
                nach Maßgabe des Art. 21 DSGVO jederzeit widersprechen. Der
                Widerspruch kann insbesondere gegen die Verarbeitung für Zwecke
                der Direktwerbung erfolgen.{" "}
              </p>

              <h5>Löschung von Daten</h5>
              <p>
                Die von uns verarbeiteten Daten werden nach Maßgabe der Art. 17
                und 18 DSGVO gelöscht oder in ihrer Verarbeitung eingeschränkt.
                Sofern nicht im Rahmen dieser Datenschutzerklärung ausdrücklich
                angegeben, werden die bei uns gespeicherten Daten gelöscht,
                sobald sie für ihre Zweckbestimmung nicht mehr erforderlich sind
                und der Löschung keine gesetzlichen Aufbewahrungspflichten
                entgegenstehen. Sofern die Daten nicht gelöscht werden, weil sie
                für andere und gesetzlich zulässige Zwecke erforderlich sind,
                wird deren Verarbeitung eingeschränkt. D.h. die Daten werden
                gesperrt und nicht für andere Zwecke verarbeitet. Das gilt z.B.
                für Daten, die aus handels- oder steuerrechtlichen Gründen
                aufbewahrt werden müssen. <br />
                <br />
                Nach gesetzlichen Vorgaben in Deutschland erfolgt die
                Aufbewahrung insbesondere für 6 Jahre gemäß § 257 Abs. 1 HGB
                (Handelsbücher, Inventare, Eröffnungsbilanzen, Jahresabschlüsse,
                Handelsbriefe, Buchungsbelege, etc.) sowie für 10 Jahre gemäß §
                147 Abs. 1 AO (Bücher, Aufzeichnungen, Lageberichte,
                Buchungsbelege, Handels- und Geschäftsbriefe, Für Besteuerung
                relevante Unterlagen, etc.).
              </p>

              <h5>Hosting</h5>
              <p>
                Die von uns in Anspruch genommenen Hosting-Leistungen dienen der
                Zurverfügungstellung der folgenden Leistungen: Infrastruktur-
                und Plattformdienstleistungen, Rechenkapazität, Speicherplatz
                und Datenbankdienste, Sicherheitsleistungen sowie technische
                Wartungsleistungen, die wir zum Zwecke des Betriebs dieses
                Onlineangebotes einsetzen. <br />
                <br />
                Hierbei verarbeiten wir, bzw. unser Hostinganbieter
                Bestandsdaten, Kontaktdaten, Inhaltsdaten, Vertragsdaten,
                Nutzungsdaten, Meta- und Kommunikationsdaten von Kunden,
                Interessenten und Besuchern dieses Onlineangebotes auf Grundlage
                unserer berechtigten Interessen an einer effizienten und
                sicheren Zurverfügungstellung dieses Onlineangebotes gem. Art. 6
                Abs. 1 lit. f DSGVO i.V.m. Art. 28 DSGVO (Abschluss
                Auftragsverarbeitungsvertrag).
              </p>

              <h5>Erhebung von Zugriffsdaten und Logfiles</h5>
              <p>
                Wir, bzw. unser Hostinganbieter, erhebt auf Grundlage unserer
                berechtigten Interessen im Sinne des Art. 6 Abs. 1 lit. f. DSGVO
                Daten über jeden Zugriff auf den Server, auf dem sich dieser
                Dienst befindet (sogenannte Serverlogfiles). Zu den
                Zugriffsdaten gehören Name der abgerufenen Webseite, Datei,
                Datum und Uhrzeit des Abrufs, übertragene Datenmenge, Meldung
                über erfolgreichen Abruf, Browsertyp nebst Version, das
                Betriebssystem des Nutzers, Referrer URL (die zuvor besuchte
                Seite), IP-Adresse und der anfragende Provider. <br />
                <br />
                Logfile-Informationen werden aus Sicherheitsgründen (z.B. zur
                Aufklärung von Missbrauchs- oder Betrugshandlungen) für die
                Dauer von maximal 7 Tagen gespeichert, danach anonymisiert und
                spätestens nach 90 Tagen gelöscht. Daten, deren weitere
                Aufbewahrung zu Beweiszwecken erforderlich ist, sind bis zur
                endgültigen Klärung des jeweiligen Vorfalls von der Löschung
                ausgenommen.
              </p>

              <h5>Agenturdienstleistungen</h5>
              <p>
                Wir verarbeiten die Daten unserer Kunden im Rahmen unserer
                vertraglichen Leistungen zu denen konzeptionelle und
                strategische Beratung, Kampagnenplanung, Software- und
                Designentwicklung/-beratung oder Pflege, Umsetzung von Kampagnen
                und Prozessen/ Handling, Serveradministration, Datenanalyse/
                Beratungsleistungen und Schulungsleistungen gehören. <br />{" "}
                <br />
                Hierbei verarbeiten wir Bestandsdaten (z.B., Kundenstammdaten,
                wie Namen oder Adressen), Kontaktdaten (z.B., E-Mail,
                Telefonnummern), Inhaltsdaten (z.B., Texteingaben, Fotografien,
                Videos), Vertragsdaten (z.B., Vertragsgegenstand, Laufzeit),
                Zahlungsdaten (z.B., Bankverbindung, Zahlungshistorie),
                Nutzungs- und Metadaten (z.B. im Rahmen der Auswertung und
                Erfolgsmessung von Marketingmaßnahmen). Besondere Kategorien
                personenbezogener Daten verarbeiten wir grundsätzlich nicht,
                außer wenn diese Bestandteile einer beauftragten Verarbeitung
                sind. Zu den Betroffenen gehören unsere Kunden, Interessenten
                sowie deren Kunden, Nutzer, Websitebesucher oder Mitarbeiter
                sowie Dritte. Der Zweck der Verarbeitung besteht in der
                Erbringung von Vertragsleistungen, Abrechnung und unserem
                Kundenservice. Die Rechtsgrundlagen der Verarbeitung ergeben
                sich aus Art. 6 Abs. 1 lit. b DSGVO (vertragliche Leistungen),
                Art. 6 Abs. 1 lit. f DSGVO (Analyse, Statistik, Optimierung,
                Sicherheitsmaßnahmen). Wir verarbeiten Daten, die zur Begründung
                und Erfüllung der vertraglichen Leistungen erforderlich sind und
                weisen auf die Erforderlichkeit ihrer Angabe hin. Eine
                Offenlegung an Externe erfolgt nur, wenn sie im Rahmen eines
                Auftrags erforderlich ist. Bei der Verarbeitung der uns im
                Rahmen eines Auftrags überlassenen Daten handeln wir
                entsprechend den Weisungen der Auftraggeber sowie der
                gesetzlichen Vorgaben einer Auftragsverarbeitung gem. Art. 28
                DSGVO und verarbeiten die Daten zu keinen anderen, als den
                auftragsgemäßen Zwecken. <br />
                <br />
                Wir löschen die Daten nach Ablauf gesetzlicher Gewährleistungs-
                und vergleichbarer Pflichten. die Erforderlichkeit der
                Aufbewahrung der Daten wird alle drei Jahre überprüft; im Fall
                der gesetzlichen Archivierungspflichten erfolgt die Löschung
                nach deren Ablauf (6 J, gem. § 257 Abs. 1 HGB, 10 J, gem. § 147
                Abs. 1 AO). Im Fall von Daten, die uns gegenüber im Rahmen eines
                Auftrags durch den Auftraggeber offengelegt wurden, löschen wir
                die Daten entsprechend den Vorgaben des Auftrags, grundsätzlich
                nach Ende des Auftrags.
              </p>

              <h5>Datenschutzhinweise im Bewerbungsverfahren</h5>
              <p>
                Wir verarbeiten die Bewerberdaten nur zum Zweck und im Rahmen
                des Bewerbungsverfahrens im Einklang mit den gesetzlichen
                Vorgaben. Die Verarbeitung der Bewerberdaten erfolgt zur
                Erfüllung unserer (vor)vertraglichen Verpflichtungen im Rahmen
                des Bewerbungsverfahrens im Sinne des Art. 6 Abs. 1 lit. b.
                DSGVO Art. 6 Abs. 1 lit. f. DSGVO sofern die Datenverarbeitung
                z.B. im Rahmen von rechtlichen Verfahren für uns erforderlich
                wird (in Deutschland gilt zusätzlich § 26 BDSG). <br />
                <br />
                Das Bewerbungsverfahren setzt voraus, dass Bewerber uns die
                Bewerberdaten mitteilen. Die notwendigen Bewerberdaten sind,
                sofern wir ein Onlineformular anbieten gekennzeichnet, ergeben
                sich sonst aus den Stellenbeschreibungen und grundsätzlich
                gehören dazu die Angaben zur Person, Post- und Kontaktadressen
                und die zur Bewerbung gehörenden Unterlagen, wie Anschreiben,
                Lebenslauf und die Zeugnisse. Daneben können uns Bewerber
                freiwillig zusätzliche Informationen mitteilen. <br />
                <br />
                Mit der Übermittlung der Bewerbung an uns, erklären sich die
                Bewerber mit der Verarbeitung ihrer Daten zu Zwecken des
                Bewerbungsverfahrens entsprechend der in dieser
                Datenschutzerklärung dargelegten Art und Umfang einverstanden.{" "}
                <br />
                <br />
                Soweit im Rahmen des Bewerbungsverfahrens freiwillig besondere
                Kategorien von personenbezogenen Daten im Sinne des Art. 9 Abs.
                1 DSGVO mitgeteilt werden, erfolgt deren Verarbeitung zusätzlich
                nach Art. 9 Abs. 2 lit. b DSGVO (z.B. Gesundheitsdaten, wie z.B.
                Schwerbehinderteneigenschaft oder ethnische Herkunft). <br />
                Bewerber können uns ihre Bewerbungen via E-Mail übermitteln.
                Hierbei bitten wir jedoch zu beachten, dass E-Mails
                grundsätzlich nicht verschlüsselt versendet werden und die
                Bewerber selbst für die Verschlüsselung sorgen müssen. Wir
                können daher für den Übertragungsweg der Bewerbung zwischen dem
                Absender und dem Empfang auf unserem Server keine Verantwortung
                übernehmen und empfehlen daher eher ein Online-Formular oder den
                postalischen Versand zu nutzen. <br />
                <br />
                Die von den Bewerbern zur Verfügung gestellten Daten, können im
                Fall einer erfolgreichen Bewerbung für die Zwecke des
                Beschäftigungsverhältnisses von uns weiterverarbeitet werden.
                Andernfalls, sofern die Bewerbung auf ein Stellenangebot nicht
                erfolgreich ist, werden die Daten der Bewerber gelöscht. Die
                Daten der Bewerber werden ebenfalls gelöscht, wenn eine
                Bewerbung zurückgezogen wird, wozu die Bewerber jederzeit
                berechtigt sind. <br />
                <br />
                Die Löschung erfolgt, vorbehaltlich eines berechtigten Widerrufs
                der Bewerber, nach dem Ablauf eines Zeitraums von sechs Monaten,
                damit wir etwaige Anschlussfragen zu der Bewerbung beantworten
                und unseren Nachweispflichten aus dem Gleichbehandlungsgesetz
                genügen können. Rechnungen über etwaige Reisekostenerstattung
                werden entsprechend den steuerrechtlichen Vorgaben archiviert.
              </p>

              <h5>Kontaktaufnahme</h5>
              <p>
                Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular,
                E-Mail, Telefon oder via sozialer Medien) werden die Angaben des
                Nutzers zur Bearbeitung der Kontaktanfrage und deren Abwicklung
                gem. Art. 6 Abs. 1 lit. b) DSGVO verarbeitet. Die Angaben der
                Nutzer können in einem Customer-Relationship-Management System
                ("CRM System") oder vergleichbarer Anfragenorganisation
                gespeichert werden. <br />
                <br />
                Wir löschen die Anfragen, sofern diese nicht mehr erforderlich
                sind. Wir überprüfen die Erforderlichkeit alle zwei Jahre;
                Ferner gelten die gesetzlichen Archivierungspflichten.
              </p>

              <h5>Onlinepräsenzen in sozialen Medien</h5>
              <p>
                Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke wie
                Facebook, Youtube, Xing, LinkedIn und Instagram, um mit den dort aktiven
                Kunden, Interessenten und Nutzern kommunizieren und sie dort
                über unsere Leistungen informieren zu können. Beim Aufruf der
                jeweiligen Netzwerke und Plattformen gelten die
                Geschäftsbedingungen und die Datenverarbeitungsrichtlinien deren
                jeweiligen Betreiber. <br />
                <br />
                Soweit nicht anders im Rahmen unserer Datenschutzerklärung
                angegeben, verarbeiten wir die Daten der Nutzer sofern diese mit
                uns innerhalb der sozialen Netzwerke und Plattformen
                kommunizieren, z.B. Beiträge auf unseren Onlinepräsenzen
                verfassen oder uns Nachrichten zusenden. <br />
                <br />
                Die auf unserer Seite angebotenen Links zu sozialen Netzwerken
                wie Facebook, Youtube, Xing, LinkedIn oder Instagram müssen aktiv
                angeklickt werden um auf die entsprechenden Plattformen zu
                gelangen. Bei Aufruf gelten die Geschäfts- und
                Datenschutzbestimmungen dieser Plattform.
              </p>
              <h5>Cookies</h5>
              <div id="second__cookielist">
                 
              </div>
            </div>
           
          </div>
        </section>
      </React.Fragment>
    );
  }
}
