import React from "react";

import Sika_Video_Community from "../../../assets/images/Referenzen/Sika/CommunitySpirit.mp4";
import Sika_Video_Features from "../../../assets/images/Referenzen/Sika/ConnectFeatures.mp4"; 
import Sika_Video_Start from "../../../assets/images/Referenzen/Sika/SIKA_GetStartet.mp4"; 
import Sika_Referenzen_Mood_Supporter_1260px_breit from "../../../assets/images/Referenzen/Sika/SIKA_Referenzen_Mood_Supporter_1260px_breit.jpg";
import Sika_booster_start from "../../../assets/images/Referenzen/Sika/Sika_booster_start.jpg";
import Sika_booster_run from "../../../assets/images/Referenzen/Sika/Sika_booster_run.jpg";
import Sika_booster from "../../../assets/images/Referenzen/Sika/Sika_booster.jpg";
import Sika_Poster_O365_hoch from "../../../assets/images/Referenzen/Sika/Sika_Poster_O365_hoch.jpg";
import Sika_Postkarte from "../../../assets/images/Referenzen/Sika/Sika_Postkarte_blur.jpg";
import SIKA_Referenzen_Mailings_1260px_breit from "../../../assets/images/Referenzen/Sika/SIKA_Referenzen_Mailings_1260px_breit.jpg";
import SIKA_Referenzen_Mood_SikaConnect from "../../../assets/images/Referenzen/Sika/SIKA_Referenzen_Mood_SikaConnect.jpg";
import Sika_connect_Giveaways_Kopfhoerer from "../../../assets/images/Referenzen/Sika/Sika_connect_Giveaways_Kopfhoerer.jpg";
import Sika_Connect_Grafik from "../../../assets/images/Referenzen/Sika/Sika_Connect_Grafik.jpg";
import Sika_Connect_Vibes from "../../../assets/images/Referenzen/Sika/Sika_Connect_Vibes.jpg";

export default class ReferenzContentMobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      videos: false
    };
  }
  rerenderComponent= ()=>{
    this.forceUpdate();
  }
  componentDidMount() {
    document.getElementById("cookiebot-selected").addEventListener("click", this.rerenderComponent);
    document.getElementById("cookiebot-all").addEventListener("click", this.rerenderComponent);
  }

  componentWillUnmount() {
    document.getElementById("cookiebot-selected").removeEventListener("click", this.rerenderComponent);
    document.getElementById("cookiebot-all").removeEventListener("click", this.rerenderComponent);
  }
 

  render() { 
    
    return (
   
      <React.Fragment>

        <section id="section-sika-1" className="section--content">
          <div className="section__content columns container-section">
            <div className="column column--6">
              <img
                ref="image"
                src={Sika_Referenzen_Mood_Supporter_1260px_breit}
                className="teaser__image-left"
                alt="Kampagne - FLEX laesst alle glaenzen"
              />
            </div>

            <div className="column column--6">
              <div className="teaser__text padding">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--sika">CHANGE-KAMPAGNE</p>
                  <h3 className="teaser__text-title">Let‘s get started!</h3>
                  <p className="teaser__text-details">
                    Wenn der bisherige Anbieter der Arbeits- und
                    Kommunikationstools ankündigt, kurzfristig die Dienste
                    einzustellen, wird es plötzlich sportlich. Neben der
                    organisatorischen und technischen Umstellung auf Office365
                    innerhalb eines Jahres durften aber auch die Mitarbeiter,
                    die von der Veränderung direkt betroffen waren, nicht
                    vergessen werden. DAS ACHTE GEBOT entwickelte eine Kampagne,
                    die durch Tonalität und Stimmung – von der ersten
                    Ankündigung an – positive Motivation und Vorfreude schaffte.
                    Dabei wurde nicht nur der grafische Rahmen, sondern auch die
                    Tonalität für die weiteren Maßnahmen vorgegeben: „Dieser
                    Hürdenlauf wird eine Teamaufgabe – die wir meistern.“
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="section__content columns">
              <div className="column column--12">
                <video
                  id="SikaVideo"
                  autostart="true"
                  playsInline
                  autoPlay
                  muted
                  loop
                  type="video/mp4"
                  src={Sika_Video_Start}
                  style={{
                    width: "100%",
                    height: "auto",
                  }}
                />
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--8"></div>
            <div className="column column--4">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="teaser__text">
                    <div className="teaser__text-content">
                      <h5 className="teaser__text-title">Teaservideo</h5>
                      <div>
                        <p className="teaser__text-details">
                          Bei der ersten Ankündigung einer großen Veränderung
                          und Herausforderung muss der Aufschlag sitzen. Das
                          Teaservideo für das weltweit übertragene
                          Townhall-Meeting wandelt in nur wenigen Sekunden den
                          Missmut gegenüber Veränderung in eine Let‘s get
                          started-Mentalität.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding-big">
            <div className="column column--4">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="teaser__text">
                    <div className="teaser__text-content">
                      <h5 className="teaser__text-title">Kampagnenmotive/Theme</h5>
                      <div>
                        <p className="teaser__text-details">
                          Das knappe Timing und die damit verbundenen Probleme werden zu 
                          sportlichen Herausforderungen, die es gemeinsam zu meistern gilt.
                          DAS ACHTE GEBOT entwickelte für jeden Milestone (Vorbereitung, Start, Finish) passende Key
                          Visuals, die die Kommunikationsmaßnahmen konsequent begleiten.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column column--1"></div>
            <div className="column column--7">
              <img
                ref="image"
                src={Sika_booster_start}
                style={{ width: "100%", height: "100%", paddingBottom: "5%", paddingTop: "5%" }}
                alt="Sika booster start"
              />
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--7">
              <img
                ref="image"
                src={Sika_booster_run}
                style={{ width: "100%", height: "100%" }}
                alt="Sika booster start"
              />
            </div>
            <div className="column column--5"></div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--5"></div>
            <div className="column column--7">
              <img
                ref="image"
                src={Sika_booster}
                style={{ width: "100%", height: "100%", paddingTop: "5%" }}
                alt="Sika booster start"
              />
            </div>
          </div>

          <div className="section__content columns container--padding-big">
            
            <div className="column column--6">
              <div className="column column--6">
                <div className="teaser__text">
                  <div className="teaser__text-content">
                    <h5 className="teaser__text-title">
                      Internes Influencermarketing
                    </h5>
                    <div>
                      <p className="teaser__text-details">
                        Passend zum Overall-Theme wurden auch die internen
                        Influencer als Booster erkannt und benannt. Naming,
                        Anschreiben, Flyer und Mailings kommen von DAS ACHTE
                        GEBOT.
                      </p>
                      <br/>
                    </div>
                  </div>
                </div>
              </div>  
            </div>

            <div className="column column--6">
                <img
                  ref="image"
                  src={Sika_Poster_O365_hoch}
                  style={{ width: "100%", height: "100%" }}
                  alt="Sika Poster O365 hoch"
                />
              </div>
              <div className="column column--6">
              <img
                ref="image"
                src={Sika_Postkarte}
                style={{
                  width: "100%",
                  height: "auto",
                }}
                alt="Sika Postkarte"
              />
              </div>
          </div>
          

          <div className="section__content columns container--padding-big">
            <div className="column column--8"></div>
            <div className="column column--4">
              <div className="teaser__text">
                <div className="teaser__text-content">
                  <h5 className="teaser__text-title">
                    Schulungs- und Ankündigungsmaterial
                  </h5>
                  <div>
                    <p className="teaser__text-details">
                      Grafisch ansprechende, aber auch schnell verständliche
                      Checklisten, Anleitungen, Präsentationen und Mailings
                      machen komplizierte und sehr technische Themen zu
                      übersichtlichen und mit Leichtigkeit zu bewältigenden
                      Aufgaben.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>



          <div className="section__content columns container--padding">
            <div className="column column--12">
              <img
                ref="image"
                src={SIKA_Referenzen_Mailings_1260px_breit}
                style={{
                  width: "100%",
                  height: "auto"
                }}
                alt="SIKA Referenzen Mailings 1260px breit"
              />
            </div>
          </div>





          </section>

          <section id="section-sika-2" className="section--content">
          <div class="column column--6"></div>
          <div className="section__content columns container-section">
            <div className="column column--6">
              <img
                ref="image"
                src={SIKA_Referenzen_Mood_SikaConnect}
                className="teaser__image-right"
                alt="Kampagne - Sika Connect Referenz"
              />
            </div>

            <div className="column column--6">
              <div className="teaser__text padding">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--sika">KAMPAGNE</p>
                  <h3 className="teaser__text-title">Einführung SikaConnect 2017</h3>
                  <p className="teaser__text-details">
                     Eins der Schlüssel-Tools in der internen Kommunikation großer Unternehmen gegenüber den Mitarbeitern
                     ist das Intranet. Doch wie sorgt man dafür, dass die weltweit agierenden Mitarbeiter auch untereinander und 
                     miteinander kommunizieren und kollaborieren? Für die Sika Services AG gibt es eine klare Lösung: Das interne soziale Netzwerk
                      SikaConnect bietet genau diese Möglichkeiten. Das Problem: Zu wenige Mitarbeiter kennen oder nutzen das Tool. Mit einem
                      Upgrade des bestehenden Angebots und einer begleitenden Kampagne sollte sich das ändern. DAS ACHTE GEBOT unterstützte die
                      Sika Services AG mit der Konzeption und Kreation der Kommunikationskampagne und der Umsetzung aller nötigen Tools und Maßnahmen.
                      Die Mechanik: Mit einem Teaservideo die Aufmerksamkeit anregen, mit dem Featurevideo die Vorteile argumentieren, mit Giveaways, 
                      Postern und einem eigenen Song Community-Spirit und -Verbundenheit generieren.
                  </p>
                </div>
              </div>
            </div>
          </div>

      


          <div className="section__content columns container--padding">
            <div className="column column--4">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="teaser__text">
                    <div className="teaser__text-content">
                      <h5 className="teaser__text-title">Teaservideo</h5>
                      <div>
                        <p className="teaser__text-details">
                        Vom kleinen Küken zum starken Adler – symbolisch steht die Weiterenwicklung für den neuen Community-Spirit, 
                          der durch die Einführung des neuen SikaConnect möglich wird. Der Adler impliziert Übersicht, Klarheit, Präzision
                          und Freiheit.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column column--8"></div>
          </div>

          <div className="section__content columns container--padding">
            <div className="section__content columns">
              <div className="column column--12">
                <video
                  id="SikaVideo"
                  autostart="true"
                  playsInline
                  autoPlay
                  muted
                  loop
                  type="video/mp4"
                  src={Sika_Video_Community}
                  style={{
                    width: "100%",
                    height: "auto",
                    paddingBottom: "5%",
                  }}
                />
              </div>
            </div>
          </div>



          



          <div className="section__content columns container--padding">
            <div className="section__content columns">
              <div className="column column--12">
                <video
                  id="SikaVideo"
                  autostart="true"
                  playsInline
                  autoPlay
                  muted
                  loop
                  type="video/mp4"
                  src={Sika_Video_Features}
                  style={{
                    width: "100%",
                    height: "auto",
                  }}
                />
              </div>
            </div>
          </div>

        </section>



        <section id="section-sika-5" className="section--content">
          <div className="section__content columns container--padding">
            
            <div className="column column--4">
              <h5 className="teaser__text-title">Giveaways</h5>
              <p className="teaser__text-details">
                Poster, Sticker und Kopfhörer machen es leicht, die Kampagne
                und die Ankündigung unter die Mitarbeiter zu bringen.
                Mehrere Nachbestellungen der Giveaways zeigen, dass DAS
                ACHTE GEBOT den Geschmack der Sika-Mitarbeiter getroffen
                hat.
              </p>
              </div>
              <div className="column column--12">
              <div className="teaser__text">
                <div className="teaser__text-content">
                  <div className="section__image-content">
                    <div className="section__image-content-wrapper">
                      <img
                        ref="image"
                        src={Sika_connect_Giveaways_Kopfhoerer}
                        style={{ width: "100%", height: "100%" }}
                        alt="Sika Kopfhoerer"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
                  <div className="column column--8"></div>  
          </div>
        </section>

        <section id="section-sika-6" className="section--content">
        

  
          <div className="section__content columns container--padding">
            <div className="column column--4">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="teaser__text">
                    <div className="teaser__text-content">
                      <h5 className="teaser__text-title">Grafiken</h5>
                      <div>
                        <p className="teaser__text-details">
                        Gute Argumente für SikaConnect gut und übersichtlich erklärt 
                        – das ist die Aufgabe der Grafiken für Ankündigungs- und Schulungspräsentationen.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column column--8"></div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <div className="teaser__text">
                <div className="teaser__text-content">
                  <div className="section__image-content">
                    <div className="section__image-content-wrapper">
                      <img
                        ref="image"
                        src={Sika_Connect_Grafik}
                        style={{ width: "100%", height: "100%", paddingBottom: "5%" }}
                        alt="Sika Connect Grafik"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>




          <div className="section__content columns container--padding">
            <div className="column column--12">
              <div className="teaser__text">
                <div className="teaser__text-content">
                  <div className="section__image-content">
                    <div className="section__image-content-wrapper">
                      <img
                        ref="image"
                        src={Sika_Connect_Vibes}
                        style={{ width: "100%", height: "100%" }}
                        alt="Sika Connect Vibes"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


        


          <div className="section__content columns container--padding">
            <div className="column column--4">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="teaser__text">
                    <div className="teaser__text-content">
                      <h5 className="teaser__text-title">Kampagnensong</h5>
                      <div>
                        <p className="teaser__text-details">
                        Welches soziale Netzwerk kann von sich behaupten,
                          einen eigenen Song zu haben? SikaConnect zum Beispiel.
                          Die eingängige Melodie ist als akustisches Intro und
                          Key acoustical für alle Präsentationen und Schulungen
                          nutzbar.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column column--8"></div>
          </div>




        </section>
      </React.Fragment>
    );
  }
}
