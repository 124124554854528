import React from "react";

import AchtEingang from "../../assets/images/Agentur/8_Eingang.jpg";
import AchtEingangWebP from "../../assets/images/Agentur/8_Eingang.webp";
import kufstein from "../../assets/images/Agentur/kufstein.jpg";
import kufsteinWebP from "../../assets/images/Agentur/kufstein.webp";
import Teddy from "../../assets/images/Agentur/Teddy.jpg";
import TeddyWebP from "../../assets/images/Agentur/Teddy.webp";
import Lilly from "../../assets/images/Agentur/Lilly.jpg";
import LillyWebP from "../../assets/images/Agentur/Lilly.webp";
import AchtindenBergen from "../../assets/images/Agentur/8_in_den_Bergen.jpg";
import AchtindenBergenWebP from "../../assets/images/Agentur/8_in_den_Bergen.webp";
import AchtimGarten from "../../assets/images/Agentur/Riesen_8.jpg";
import AchtimGartenWebP from "../../assets/images/Agentur/Riesen_8.webp";
import AchtamSee from "../../assets/images/Agentur/8_am_See.jpg";
import AchtamSeeWebP from "../../assets/images/Agentur/8_am_See.webp";
import IngersheimerHerbstlauf from "../../assets/images/Agentur/Ingersheimer_Herbstlauf.jpg";
import IngersheimerHerbstlaufWebP from "../../assets/images/Agentur/Ingersheimer_Herbstlauf.webp";
import AchtindenBergen1 from "../../assets/images/Agentur/8_in_den_Bergen_1.jpg";
import AchtindenBergen1WebP from "../../assets/images/Agentur/8_in_den_Bergen_1.webp";
import fullmember from "../../assets/images/Agentur/full_member.jpg";
import fullmemberWebP from "../../assets/images/Agentur/full_member.webp";
import weihnachtskekse from "../../assets/images/Agentur/Kekse.jpg";
import weihnachtskekseWebP from "../../assets/images/Agentur/Kekse.webp";
import Weinwandertag from "../../assets/images/Agentur/Weinwandertag.jpg";
import WeinwandertagWebP from "../../assets/images/Agentur/Weinwandertag.webp";
import Haeschen from "../../assets/images/Agentur/Haeschen.jpg";
import HaeschenWebP from "../../assets/images/Agentur/Haeschen.webp";
import Paddeltag from "../../assets/images/Agentur/Paddeltour.jpg";
import PaddeltagWebP from "../../assets/images/Agentur/Paddeltour.webp";
import Weihnachtsmarkt from "../../assets/images/Agentur/Weihnachtsmarkt.jpg";
import WeihnachtsmarktWebP from "../../assets/images/Agentur/Weihnachtsmarkt.webp";

class SectionMirror extends React.Component {
  render() {
    return (
      <section className="section__mirror section--content">
        <div className="section__content columns container--padding">
          <div className="mirror-50">
            <picture className="frame-100-first">
              <source srcSet={AchtEingangWebP} type="image/webp" />
              <source srcSet={AchtEingang} type="image/jpeg" />
              <img src={AchtEingang} alt="DAS ACHTE GEBOT Eingang" />
            </picture>
            <picture className="frame-100">
              <source srcSet={WeihnachtsmarktWebP} type="image/webp" />
              <source srcSet={Weihnachtsmarkt} type="image/jpeg" />
              <img
                src={Weihnachtsmarkt}
                alt="DAS ACHTE GEBOT auf dem Weihnachtsmarkt"
              />
            </picture>
            <picture className="frame-80">
              <source srcSet={HaeschenWebP} type="image/webp" />
              <source srcSet={Haeschen} type="image/jpeg" />
              <img src={Haeschen} alt="Herrscher oder Häschen?" />
            </picture>
            <picture className="frame-45">
              <source srcSet={weihnachtskekseWebP} type="image/webp" />
              <source srcSet={weihnachtskekse} type="image/jpeg" />
              <img
                src={weihnachtskekse}
                alt="DAS ACHTE GEBOT Weihnachtskekse"
              />
            </picture>
            <picture className="frame-45">
              <source srcSet={fullmemberWebP} type="image/webp" />
              <source srcSet={fullmember} type="image/jpeg" />
              <img
                src={fullmember}
                alt="DAS ACHTE GEBOT Nachwuchs im Team"
              />
            </picture>
            <picture className="frame-100">
              <source srcSet={PaddeltagWebP} type="image/webp" />
              <source srcSet={Paddeltag} type="image/jpeg" />
              <img src={Paddeltag} alt="DAS ACHTE GEBOT Agenturausflug" />
            </picture>
            <picture className="frame-100">
              <source srcSet={WeinwandertagWebP} type="image/webp" />
              <source srcSet={Weinwandertag} type="image/jpeg" />
              <img
                src={Weinwandertag}
                alt="DAS ACHTE GEBOT Weinwandertag"
              />
            </picture>
          </div>
          <div className="mirror-50">
            <picture className="frame-100">
              <source srcSet={kufsteinWebP} type="image/webp" />
              <source srcSet={kufstein} type="image/jpeg" />
              <img src={kufstein} alt="Agentururlaub in Kufstein" />
            </picture>
            <picture className="frame-50">
              <source srcSet={TeddyWebP} type="image/webp" />
              <source srcSet={Teddy} type="image/jpeg" />
              <img src={Teddy} alt="Agenturhund Teddy" />
            </picture>
            <picture className="frame-50">
              <source srcSet={LillyWebP} type="image/webp" />
              <source srcSet={Lilly} type="image/jpeg" />
              <img src={Lilly} alt="Agenturhund Lilly" />
            </picture>
            <picture className="frame-100">
              <source srcSet={AchtindenBergenWebP} type="image/webp" />
              <source srcSet={AchtindenBergen} type="image/jpeg" />
              <img
                src={AchtindenBergen}
                alt="DAS ACHTE GEBOT in den Bergen"
              />
            </picture>
            <picture className="frame-90">
              <source srcSet={AchtimGartenWebP} type="image/webp" />
              <source srcSet={AchtimGarten} type="image/jpeg" />
              <img src={AchtimGarten} alt="DAS ACHTE GEBOT im Garten" />
            </picture>
            <picture className="frame-100">
              <source srcSet={AchtamSeeWebP} type="image/webp" />
              <source srcSet={AchtamSee} type="image/jpeg" />
              <img src={AchtamSee} alt="Das Achte Gebot am See" />
            </picture>
            <picture className="frame-50">
              <source srcSet={IngersheimerHerbstlaufWebP} type="image/webp" />
              <source srcSet={IngersheimerHerbstlauf} type="image/jpeg" />
              <img
                src={IngersheimerHerbstlauf}
                alt="DAS ACHTE GEBOT auf dem Ingersheimer Herbstlauf"
              />
            </picture>
            <picture className="frame-50">
              <source srcSet={AchtindenBergen1WebP} type="image/webp" />
              <source srcSet={AchtindenBergen1} type="image/jpeg" />
              <img
                src={AchtindenBergen1}
                alt="DAS ACHTE GEBOT in den Bergen"
              />
            </picture>
          </div>
        </div>
        <div className="Cleaner"></div>
      </section>
    );
  }
}
export default SectionMirror;
