import React from "react";

export default class ContentImprint extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section id="section-imprint" className="section__content-intro section--content">
          <div className="section__content columns container--padding-none">
            <div className="teaser__text column--6">
              <div className="teaser__text-content">
                <p className="teaser__text-subtitle">Das Achte Gebot</p>
                <h3 className="teaser__text-title">Impressum</h3>
              </div>
            </div>
            <div className="section__intro-content column column--6">
              <h5 className="margin">
                DAS <span>ACHTE</span> GEBOT Werbeagentur GmbH
              </h5>
              <p>
                Gröninger Weg 7<br />
                74379 Ingersheim
                <br />
              </p>
              <p>
                <a
                  href="mailto:info@dasachtegebot.de"
                  title="info@dasachtegebot.de"
                >
                  info@dasachtegebot.de
                </a>
                <br />
                <a href="https://www.dasachtegebot.de" title="Website">
                  https://www.dasachtegebot.de
                </a>
                <br />
                <br />
                <a href="tel:007142774760" title="Offenes Ohr gesucht?">
                  +49 (0)7142 - 77476 - 0
                </a>
              </p>
              <p>
                Geschäftsführung:
                <br />
                Harri Grimm, <br />
                Britta Krumrain-Ernst,
                <br />
                Marco Schrenk
              </p>
              <p>
                HRB Stuttgart 738447
                <br />
                USt-IdNr. DE278056635
              </p>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
