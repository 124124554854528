import React from "react";
import videoIntro from "../../assets/video/dasachtegebot_werbeagentur_intro.mp4";

import styled from "styled-components";


const Video = styled.video`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  object-fit: cover;
`;

const VideoContainer = styled.section`
  text-align: center;
`;

const PageContent = styled.div`
`;

export const HomeTeaserVideo = () => {
  
  return (
      <PageContent>
          <VideoContainer>
                <Video
                  autostart="true"
                  playsInline
                  autoPlay
                  muted
                  loop
                  type="video/mp4"
                  src={videoIntro}                  
                ></Video>
          </VideoContainer>
      </PageContent>
  );
};
