import React from "react";

class ReferenzIntro extends React.Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { width } = this.state;
    const isMobile = width <= 812;

    if (isMobile) {
      return (
        <React.Fragment>
          <section id="section-flv">
            <div className="section__content columns container--padding-big">
              <div className="teaser__text column--6">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--flv">
                    FAHRLEHRERVERSICHERUNG VAG
                  </p>
                  <h3 className="teaser__text-title">Markenrelaunch</h3>
                </div>
              </div>
              <div className="section__intro-content column--6">
                <p className="teaser__text-details">
                  1952 vom Berufsstand der deutschen Fahrlehrer als „Verein auf
                  Gegenseitigkeit“ geründet, versichert das Unternehmen heute
                  rund 75.000 Mitglieder, die von Landesagenturen regional sowie
                  zentral in Stuttgart betreut werden. Unter wachsendem
                  Wettbewerb im Versicherungsumfeld kann sich der
                  Versicherungsverein durch Spezialkompetenzen in allen Fragen
                  zum Thema Fahrschule und Berufskraftfahrt behaupten und
                  punktet durch besondere Nähe zu den Kunden. Das besondere
                  Profil und die hohe Dynamik bildeten die Grundlage für den
                  optischen Relaunch der Marke.
                </p>
              </div>
            </div>
          </section>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <section id="section-flv">
            <div className="section__content columns container--padding-big">
              <div className="teaser__text column--6">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--flv">
                    FAHRLEHRERVERSICHERUNG VAG
                  </p>
                  <h3 className="teaser__text-title">Markenrelaunch</h3>
                </div>
              </div>
              <div className="section__intro-content column--6">
                <p className="teaser__text-details">
                  1952 vom Berufsstand der deutschen Fahrlehrer als „Verein auf
                  Gegenseitigkeit“ geründet, versichert das Unternehmen heute
                  rund 75.000 Mitglieder, die von Landesagenturen regional sowie
                  zentral in Stuttgart betreut werden. Unter wachsendem
                  Wettbewerb im Versicherungsumfeld kann sich der
                  Versicherungsverein durch Spezialkompetenzen in allen Fragen
                  zum Thema Fahrschule und Berufskraftfahrt behaupten und
                  punktet durch besondere Nähe zu den Kunden. Das besondere
                  Profil und die hohe Dynamik bildeten die Grundlage für den
                  optischen Relaunch der Marke.
                </p>
              </div>
            </div>
          </section>
        </React.Fragment>
      );
    }
  }
}
export default ReferenzIntro;
