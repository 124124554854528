import React from "react";
import ScrollUp from "../components/ButtonComponent/ButtonScrollUp";
import Random from "../components/RandomComponent/Random";
import Header from "../components/HeaderComponent/Header";
import Footer from "../components/FooterComponent/Footer";
import SectionImage from "../components/SectionComponent/SectionImage";
import SectionIntro from "../components/SectionComponent/SectionIntro";
import SectionService from "../components/SectionComponent/SectionService";
import SectionMirror from "../components/SectionComponent/SectionMirror";
import SectionCareer from "../components/SectionComponent/SectionCareer";
import SectionContact from "../components/SectionComponent/SectionContact";
import SectionSwitch from "../components/SectionComponent/SectionSwitch";
import { Helmet } from "react-helmet";

class Agentur extends React.Component {
  componentDidMount() {
    document.title = "Unsere Agentur: Ungelogen gute Werbung";
  }
  render() {
    return (
      <React.Fragment>
        <ScrollUp />
        <Random />
        <Header />
        <Helmet>
          <meta name="description" content="Your description" />
          <meta
            property="og:image"
            content="https://www.dasachtegebot.de/images/corporate/og_agentur.jpg"
          />
        </Helmet>
        <SectionImage />
        <SectionIntro />
        <SectionService />       
        { 
          // <SectionWheel /> 
        } 
        <SectionMirror />
        <SectionCareer />
        <SectionContact />
        <SectionSwitch />
        <Footer />
      </React.Fragment>
    );
  }
}
export default Agentur;
