import React from "react";

import Random from "../components/RandomComponent/Random";
import ScrollUp from "../components/ButtonComponent/ButtonScrollUp";
import Header from "../components/HeaderComponent/Header";
import ContentDataProtection from "../components/ContentComponent/ContentDataProtection";
import Footer from "../components/FooterComponent/Footer";

class Datenschutz extends React.Component {
  componentDidMount() {
    document.title = "Datenschutz";
  }
  render() {
    return (
      <React.Fragment>
        <ScrollUp />
        <Random />
        <Header />
        <ContentDataProtection />
        <Footer />
      </React.Fragment>
    );
  }
}

export default Datenschutz;
