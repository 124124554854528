import React from "react";
import { HashLink as Link } from "react-router-hash-link";

export default class Referenzdetail extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section id="section-detail">
          <div className="section__content columns container infinite-container">
            <div className="column column--4">
              <nav className="menu menu--campaigns">
                <div className="menu__item-container">
                  <div className="menu__item-work" href="/">
                    <span className="menu__item-name">Kunde</span>
                  </div>
                </div>
                <div className="menu__item-container">
                  <span className="menu__item-name">
                    Geberit International AG
                  </span>
                </div>
              </nav>
            </div>
            <div className="column column--4">
              <nav className="menu menu--campaigns">
                <div className="menu__item-container">
                  <div className="menu__item-work">
                    <span className="menu__item-name">Aufgaben</span>
                  </div>
                </div>
                <div className="menu__item-container">
                  <span className="menu__item-name">
                    Internationale Kampagnen, Printmedien,
                    Produktvisualisierung, interaktive Tools, Filme und
                    Animationen, interaktive Tools
                  </span>
                </div>
              </nav>
            </div>
            <div className="column column--4">
              <nav className="menu menu--campaigns">
                <div className="menu__item-container">
                  <div className="menu__item-work">
                    <span className="menu__item-name">Beispielkampagnen</span>
                  </div>
                </div>
                <div className="menu__item-container">
                  <Link to="#section-geberit-flowfit">
                    <div className="menu__item">
                      <span className="menu__item-name--geberit">
                        Kampagne "FlowFit"
                      </span>
                    </div>
                  </Link>
                </div>
                <div className="menu__item-container">
                  <Link to="#section-geberit-connect">
                    <div className="menu__item">
                      <span className="menu__item-name--geberit">
                        Kampagne "Connect"
                      </span>
                    </div>
                  </Link>
                </div>
                <div className="menu__item-container">
                  <Link to="#section-geberit-protect">
                    <div className="menu__item">
                      <span className="menu__item-name--geberit">
                        Kampagne "Protect"
                      </span>
                    </div>
                  </Link>
                </div>
                <div className="menu__item-container">
                  <Link to="#section-geberit-1">
                    <div className="menu__item">
                      <span className="menu__item-name--geberit">
                        Kampagne "Pluvia"
                      </span>
                    </div>
                  </Link>
                </div>
              </nav>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
