import React from "react";
import { NavLink } from "react-router-dom";
import facebook from "../../assets/icons/facebook.svg";
import linkedin from "../../assets/icons/linkedin.png";
import instagram from "../../assets/icons/instagram.png";
import youtube from "../../assets/icons/youtube.svg";
const Footer = () => (
  <section id="section-footer">
    <footer className="page__footer__wrapper">
      <div id="page__footer" className="page__footer-bottom columns container">
        <div className="page__footer-section column column--4">
          <p>
            DAS ACHTE GEBOT Werbeagentur GmbH
            <br />
            <a
              href="mailto:info@dasachtegebot.de"
              title="info@dasachtegebot.de"
            >
              info@dasachtegebot.de
            </a>
            <span className="divider"></span>
            <a href="tel:007142774760" title="Offenes Ohr gesucht?">
              +49 (0)7142 - 77476 - 0
            </a>
            <br />
            <a
              href="https://www.google.de//maps/place/DAS+ACHTE+GEBOT+Werbeagentur+GmbH/@48.9576357,9.1740557,15z/data=!4m12!1m6!3m5!1s0x4799d3a7abd997ab:0xc125fc30a38dc69a!2sDAS+ACHTE+GEBOT+Werbeagentur+GmbH!8m2!3d48.9575224!4d9.1741188!3m4!1s0x4799d3a7abd997ab:0xc125fc30a38dc69a!8m2!3d48.9575224!4d9.1741188"
              title="Google Maps - DAS ACHTE GEBOT Werbeagentur GmbH"
              className="footer__adress"
              target="_blank"
              rel="noopener noreferrer"
            >
              Gröninger Weg 7 <span className="divider"></span>74379 Ingersheim
            </a>
          </p>
        </div>
        <div className="page__footer-section column column--8">
          <ul className="flex">
            <li>
              <p>
                <NavLink to="/" className="disabled">
                  Home
                </NavLink>
              </p>
            </li>
            <li>
              <span className="divider"></span>
            </li>
            <li>
              <p>
                <NavLink to="/referenzen">Kunden</NavLink>
              </p>
            </li>
            <li>
              <span className="divider"></span>
            </li>
            <li>
              <p>
                <NavLink to="/agentur">Werbeagentur</NavLink>
              </p>
            </li>
            <li>
              <span className="divider"></span>
            </li>
            <li>
              <p>
                <NavLink to="/kontakt">Kontakt</NavLink>
              </p>
            </li>
            <li>
              <span className="divider"></span>
            </li>
            <li>
              <p>
                <NavLink to="/agb">AGB</NavLink>
              </p>
            </li>
            <li>
              <span className="divider"></span>
            </li>
            <li>
              <p>
                <NavLink to="/impressum">Impressum</NavLink>
              </p>
            </li>
            <li>
              <span className="divider"></span>
            </li>
            <li>
              <p>
                <NavLink to="/datenschutz">Datenschutz</NavLink>
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div className="columns container">
        <div className="column column--10"></div>
        <div className="column column--2" style={{marginRight: "0px"}}>
          <ul className="flex" style={{flexWrap: "nowrap"}}>
           
            <li className="social">
              <a
                href="https://www.instagram.com/dasachtegebot"
                title="Finden Sie uns auf Instagram"
                target="_blank"
                rel="noopener noreferrer"
              >
          <img
                src={instagram}
                alt="Instagram Link"
              />
              </a>
            </li>
            <li className="social">
              <a
                href="https://de.linkedin.com/company/das-achte-gebot-werbeagentur-gmbh"
                title="Finden Sie uns auf LinkedIn"
                target="_blank"
                rel="noopener noreferrer"
              >
            <img
                src={linkedin}
                alt="LinkedIn Link"
              />
              </a>
            </li>
            <li className="social">
              <a
                href="https://www.youtube.com/user/dasachtegebot"
                title="Finden Sie uns auf YouTube"
                target="_blank"
                rel="noopener noreferrer"
              >
              <img
                src={youtube}
                alt="youtube Link"
              />
              </a>
            </li>
            <li className="social">
              <a
                href="https://de-de.facebook.com/dasachtegebot/"
                title="Finden Sie uns auf Facebook"
                target="_blank"
                rel="noopener noreferrer"
              >
                 <img
                src={facebook}
                alt="facebook Link"
              />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  </section>
);
export default Footer;
