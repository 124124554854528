import React from "react";

import bIDO_Referenzen_Mood from "../../../assets/images/Referenzen/3bIDO/3bIDO_Referenzen_Mood.jpg";
import bIDO_anzeige_moebelmacher from "../../../assets/images/Referenzen/3bIDO/anzeige_moebelmacher.jpg";
import bIDO_Web from "../../../assets/images/Referenzen/3bIDO/3bIDO_Web.jpg";
import bIDO_Titel_Brosch_Image_A4_hoch from "../../../assets/images/Referenzen/3bIDO/3bIDO_Titel_Brosch_Image_A4_hoch.jpg";
import bIDO_Doppels_Brosch_Image_A4_hoch_1 from "../../../assets/images/Referenzen/3bIDO/3bIDO_Imagebrosch_Doppels_A4_hoch_1.jpg";
import bIDO_Doppels_Brosch_Image_A4_hoch_2 from "../../../assets/images/Referenzen/3bIDO/3bIDO_Imagebrosch_Doppels_A4_hoch_2.jpg";
import bIDO_Doppels_Brosch_Image_A4_hoch_3 from "../../../assets/images/Referenzen/3bIDO/3bIDO_Imagebrosch_Doppels_A4_hoch_3.jpg";

export default class ReferenzContent extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section id="section-kwasny-1" className="section--content">
          <div className="section__content columns container-section">
            <div className="column column--12">
              <img
                ref="image"
                src={bIDO_Referenzen_Mood}
                className="teaser__image-left"
                alt="KAMPAGNE - Möbelmacher sind Möglichmacher"
              />
            </div>

            <div className="column column--12">
              <div className="teaser__text padding">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--3bIDO">KAMPAGNE</p>
                  <h3 className="teaser__text-title">
                    Möbelmacher sind Möglichmacher
                  </h3>
                  <p className="teaser__text-details">
                    Die vergangenen Jahre haben dem Thema New Work in großem Maß
                    Vorschub geleistet. Home-Office, Job-Sharing, Collab-Spaces
                    und viele andere Themen sind zum Standard im Büroalltag
                    geworden und haben die Anforderungen an Büroräume verändert.
                    Und auch die wirtschaftlichen Rahmenbedingungen sind anders:
                    Statt Neubau liegt der Fokus auf Sanierung, Umstrukturierung
                    und Revitalisierung von Büroflächen. Gut, dass 3b IDO gerade
                    hier seine Stärken als individueller Einrichter ausspielen
                    kann. <br /> <br />
                    Unter dem Motto „Möbelmacher sind Möglichmacher“ startete 3b
                    IDO im Jahr 2022 eine große Vertriebsoffensive mit einer
                    Kampagne, die in allen Kundenkontaktpunkten ausgespielt
                    wurde.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div
            className="section__content columns container--padding"
            style={{ marginTop: "10rem" }}
          >
            <div className="column column--12">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="teaser__text">
                    <div className="teaser__text-content">
                      <h5 className="teaser__text-title">Print + OOH</h5>
                      <div>
                        <p className="teaser__text-details">
                          Großflächenmotive und Anzeigen machen aufmerksam.
                          Nebenbei zahlt das Thema ins HR-Kässchen, denn als
                          Arbeitgeber spricht 3b IDO gezielt Leute mit „Macher-
                          Qualitäten“ an.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <img
                ref="image"
                src={bIDO_anzeige_moebelmacher}
                style={{ width: "100%" }}
                alt="kwasny Geschäftsausstattung"
              />
            </div>
          </div>

          {/* Web */}

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="teaser__text">
                    <div className="teaser__text-content">
                      <h5 className="teaser__text-title">Website</h5>
                      <div>
                        <p className="teaser__text-details">
                          Mit der neuen „New Work“-Landingpage holt 3b IDO
                          gezielt die Kunden ab, die sich mit den neuen Trends
                          beschäftigen. Das Content-Konzept fokussiert dabei
                          einige wichtige Themen wie Digitalisierung, Gesundheit
                          am Arbeitsplatz, Coworking-Space und zeigt, wie sich
                          3b IDO den unterschiedlichen Herausforderungen und
                          Ihren Auswirkungen auf die Büroeinrichtung annimmt.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <img
                ref="image"
                src={bIDO_Web}
                style={{ width: "100%" }}
                alt="kwasny Geschäftsausstattung"
              />
            </div>
          </div>

          {/* Imagebroschüre */}

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="teaser__text">
                    <div className="teaser__text-content">
                      <h5 className="teaser__text-title">Imagebroschüre</h5>
                      <div>
                        <p className="teaser__text-details">
                          Ist der erste Kontakt hergestellt, informiert eine
                          kompakte Leistungsbroschüre über alles wichtige.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column column--12">
              <img
                ref="image"
                src={bIDO_Titel_Brosch_Image_A4_hoch}
                style={{ width: "100%" }}
                alt="kwasny Geschäftsausstattung"
              />
            </div>
            <div className="column column--12">
              <img
                ref="image"
                src={bIDO_Doppels_Brosch_Image_A4_hoch_2}
                style={{ width: "100%" }}
                alt="kwasny Geschäftsausstattung"
              />
            </div>

            <div className="column column--6">
              <img
                ref="image"
                src={bIDO_Doppels_Brosch_Image_A4_hoch_1}
                style={{ width: "100%" }}
                alt="kwasny Geschäftsausstattung"
              />
              <img
                ref="image"
                src={bIDO_Doppels_Brosch_Image_A4_hoch_3}
                style={{ width: "100%" }}
                alt="kwasny Geschäftsausstattung"
              />
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
