import React from "react";

import "../assets/css/sociallinks.css";
import logo_dasachtegebot from "../assets/images/Agentur/logo_dasachtegebot.jpg";
import SocialLinkButton from "../components/SocialLinksComponent/SocialLinkButton";

const SocialLiks = () => {
  return (
    <div className="social__wrapper">
      <div className="social__content">
        <img src={logo_dasachtegebot} alt="DAG Logo" />
        <div className="social__buttons">
          <SocialLinkButton
            title="Die Agentur"
            target="/agentur"
            isLocal={true}
          />
          <SocialLinkButton
            title="Referenzen"
            target="/referenzen"
            isLocal={true}
          />
          <SocialLinkButton
            title="Kunden"
            target="/referenzen/kunden"
            isLocal={true}
          />
          <SocialLinkButton
            title="Impressum"
            target="/impressum"
            isLocal={true}
          />
        </div>
      </div>
    </div>
  );
};

export default SocialLiks;
