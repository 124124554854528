import React from "react";
import { HashLink as Link } from "react-router-hash-link";

export default class Referenzdetail extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section id="section-detail">
          <div className="section__content columns container infinite-container">
            <div className="column column--4">
              <nav className="menu menu--campaigns">
                <div className="menu__item-container">
                  <div className="menu__item-work" href="/">
                    <span className="menu__item-name">Kunde</span>
                  </div>
                </div>
                <div className="menu__item-container">
                  <span className="menu__item-name">
                    Sika Services AG
                  </span>
                </div>
              </nav>
            </div>
            <div className="column column--4">
              <nav className="menu menu--campaigns">
                <div className="menu__item-container">
                  <div className="menu__item-work">
                    <span className="menu__item-name">Aufgaben</span>
                  </div>
                </div>
                <div className="menu__item-container">
                  <span className="menu__item-name">
                  Konzeption und Kreation für ChangeManagement Kampagnen, TeaserClips und Videos, Kampagnenmotive
                  und Key Visuals, Umsetzung in unterschiedliche Kommunikationsmedien
                  und Werbegeschenke, grafische Unterstützung für Info- und Schulungsmaterial
                  </span>
                </div>
              </nav>
            </div>
            <div className="column column--4">
              <nav className="menu menu--campaigns">
                <div className="menu__item-container">
                  <div className="menu__item-work">
                    <span className="menu__item-name">Kampagnen</span>
                  </div>
                </div>
                <div className="menu__item-container">
                  <Link to="#section-sika-1">
                    <div className="menu__item">
                      <span className="menu__item-name--sika">
                       Office 365
                      </span>
                    </div>
                  </Link>
                </div>
                <div className="menu__item-container">
                  <Link to="#section-sika-2">
                    <div className="menu__item">
                      <span className="menu__item-name--sika">
                        Einführung SikaConnect 2017
                      </span>
                    </div>
                  </Link>
                </div>
              </nav>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
