import React from "react";
import Footer from "../components/FooterComponent/Footer";
import Header from "../components/HeaderComponent/Header";
import SectionImage from "../components/SectionComponent/SectionImage";
import SectionIntro from "../components/KarriereComponent/SectionIntro";
import SectionJobs from "../components/KarriereComponent/SectionJobs";

class Karriere extends React.Component {
  componentDidMount() {
    document.title = "Karriere";
  }
  render() {
    return (
    <React.Fragment>
      <Header />
      <SectionImage />
      <SectionIntro />
      <SectionJobs />
      <Footer />
    </React.Fragment>
    );
  }
}

export default Karriere;
