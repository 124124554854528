import React from "react";

import ReferenzAnimation from "../FelsomatComponent/ReferenzAnimation";

class ReferenzIntro extends React.Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { width } = this.state;
    const isMobile = width <= 812;

    if (isMobile) {
      return (
        <React.Fragment>
          <section id="section-felsomat">
            <div className="section__content columns container--padding">
              <div className="teaser__text column--6">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--felsomat">
                    Felsomat GmbH & Co. KG
                  </p>
                  <h3 className="teaser__text-title">Markenführung</h3>
                </div>
              </div>
              <div className="section__intro-content column--6">
                <p className="teaser__text-details">
                  Felsomat schafft Fertigungswelten für die Zukunft und
                  begleitet die Produktionstechnik in ein neues Zeitalter. Die
                  Kommunikation hierzu begleitet DAS ACHTE GEBOT seit vielen
                  Jahren als markenführende und weiterentwickelnde Agentur. Da
                  gehört das gesamte Portfolio der B2B-Werbung mit besonderem
                  Fokus auf Messepräsentation und Vertriebsmarketing dazu.
                </p>
              </div>
            </div>
          </section>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <section id="section-felsomat" className="section--intro">
            <div className="section__content columns container--padding">
              <div className="column column--4"></div>
              <div className="column column--4" style={{ marginTop: "-8%" }}>
                <ReferenzAnimation />
              </div>
              <div className="column column--4"></div>
            </div>

            <div className="section__content columns container">
              <div className="teaser__text column--6">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--felsomat">
                    Felsomat GmbH & Co. KG
                  </p>
                  <h3 className="teaser__text-title">Markenführung</h3>
                </div>
              </div>
              <div className="section__intro-content column--6">
                <p className="teaser__text-details margin">
                  Felsomat schafft Fertigungswelten für die Zukunft und
                  begleitet die Produktionstechnik in ein neues Zeitalter. Die
                  Kommunikation hierzu begleitet DAS ACHTE GEBOT seit vielen
                  Jahren als markenführende und weiterentwickelnde Agentur. Da
                  gehört das gesamte Portfolio der B2B-Werbung mit besonderem
                  Fokus auf Messepräsentation und Vertriebsmarketing dazu.
                </p>
              </div>
            </div>
          </section>
        </React.Fragment>
      );
    }
  }
}
export default ReferenzIntro;
