import React from "react";

import Felsomat_Video_Fertigungsprozess from "../../../assets/images/Referenzen/Felsomat/felsomat_fertigungsprozess.webp";
import Gearbox_preview from "../../../assets/images/Referenzen/Felsomat/Felsomat_gearbox_preview.png";
import Felsomat_Doppels_Brosch_A4_1_quer from "../../../assets/images/Referenzen/Felsomat/Kampagne/Felsomat_Doppels_Brosch_A4_1_quer.png";
import Felsomat_Doppels_Brosch_A4_2_quer from "../../../assets/images/Referenzen/Felsomat/Kampagne/Felsomat_Doppels_Brosch_A4_2_quer.png";
import Felsomat_Doppels_Brosch_A4_3_quer from "../../../assets/images/Referenzen/Felsomat/Kampagne/Felsomat_Doppels_Brosch_A4_3_quer.png";
import Messestand_1 from "../../../assets/images/Referenzen/Felsomat/Kampagne/Felsomat_Messestand_1.jpg";
import Felsomat_Referenzen_Mood_Zahnrad from "../../../assets/images/Referenzen/Felsomat/Kampagne/Felsomat_Referenzen_Mood_Zahnrad.jpg";
import Felsomat_Titel_Brosch_Image_A4_quer from "../../../assets/images/Referenzen/Felsomat/Kampagne/Felsomat_Titel_Brosch_Image_A4_quer_neu.png";
import VideoDisplay from "../../VideoComponent/VideoDisplay";

export default class ReferenzContent extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section
          id="section-felsomat-1"
          className="section--content section--padding"
        >
          <div className="section__content columns container-section">
            <div className="column column--6">
              <div className="section__image-content-wrapper">
                <img
                  ref="image"
                  src={Felsomat_Referenzen_Mood_Zahnrad}
                  className="teaser__image-left"
                  alt="Felsomat"
                />
              </div>
            </div>

            <div className="column column--6">
              <div className="teaser__text padding">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--felsomat">Key-Visual</p>
                  <h3 className="teaser__text-title">
                    Vom Rohteil bis zum fertigen Zahnrad
                  </h3>
                  <div>
                    <p className="teaser__text-details">
                      Wie stellt man prägnant und in einem Bild dar, was
                      tatsächlich eine umfangreiche Verkettung von komplexen
                      Maschinen und Bearbeitungsschritten voraussetzt? Einfach
                      am Fortschritt des Werkstücks! Mit dem markanten
                      Key-Visual präsentiert Felsomat die einzelnen
                      Prozessschritte bis hin zum hartfeinbearbeiteten Zahnrad.
                      Denn genau in der ganzheitlichen Verkettung liegt die
                      Stärke des Unternehmens. Die inhaltliche Verknüpfung des
                      Motivs mit dem Bearbeitungsschritt und der dazu passenden
                      Maschine macht das Motiv flexibel und langlebig.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding-big">
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <h5 className="teaser__text-title">Imagebroschüre</h5>
                  <p className="teaser__text-details">
                    Reduziert auf wesentliche Fakten präsentiert die
                    Imagebroschüre die Firmenphilosophie der „Genius Production“
                    und baut eine innere Logik nach klassischer Werbekonzeption
                    Insight, Benefit, Reason why auf: Höchstproduktiv durch hohe
                    Taktung, hohe Taktung durch intelligente Verkettung. Das
                    kommt an: rational und emotional.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <img
                    ref="image"
                    src={Felsomat_Titel_Brosch_Image_A4_quer}
                    style={{ width: "100%", height: "100%" }}
                    alt="Imagebroschüre"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <img
                    ref="image"
                    src={Felsomat_Doppels_Brosch_A4_1_quer}
                    style={{ width: "100%", height: "100%" }}
                    alt="Imagebroschüre"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <img
                    ref="image"
                    src={Felsomat_Doppels_Brosch_A4_2_quer}
                    style={{ width: "100%", height: "100%" }}
                    alt="Imagebroschüre"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <img
                    ref="image"
                    src={Felsomat_Doppels_Brosch_A4_3_quer}
                    style={{ width: "100%", height: "100%" }}
                    alt="Imagebroschüre"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding-big">
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <h5 className="teaser__text-title">Animation</h5>
                  <p className="teaser__text-details">
                    Mehr als 70 Prozessfilme und 3D-Animationen hat DAS ACHTE
                    GEBOT in langjähriger Zusammenarbeit für Felsomat erstellt.
                    Komplexe Technologie lässt sich so
                    reduziert und damit anschaulich präsentieren.
                  </p>
                </div>
              </div>
            </div>
            <div className="column column--6"></div>
          </div>
          <div className="section__content columns container--padding">
            <div className="column column--12">
              <div className="teaser__text">
                <div className="teaser__text-content">
                  <div className="section__image-content">
                    <div className="section__image-content-wrapper">
                      <img
                        ref="image"
                        src={Gearbox_preview}
                        style={{ width: "100%", height: "100%" }}
                        alt="Gearbox Preview"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding-big">
            <div className="columns column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="teaser__text">
                    <div className="teaser__text-content">
                      <h5 className="teaser__text-title">
                        Video Fertigungsprozess
                      </h5>
                      <div>
                        <p className="teaser__text-details">
                          Wer Videos zur Kommunikation von Fertigungsprozessen
                          erstellt, muss wissen was B2B-Entscheider
                          interessiert. Für den guten Blick auf technische
                          Details schätzt Felsomat das Filmteam vom ACHTEN GEBOT
                          seit vielen Jahren. Und übrigens: Für
                          vollautomatisierte Getriebezahnradfertigung auf einem
                          Doppelspindel-Wälzfräszentrum quasi ohne Nebenzeiten
                          empfehlen wir dringend unseren Kunden Felsomat.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="columns column--6"></div>
          </div>

          <div className="section__content columns container--padding">
            <div className="video-container column column--12">
              <VideoDisplay
               src="https://www.youtube.com/embed/1xCMYvIpRr0?controls=0"
                fullScreen=""
                backImage={Felsomat_Video_Fertigungsprozess}
                title="Felsomat Fertigungsprozess"
                border="0"
              />
            </div>
          </div>

          <div className="section__content columns container--padding-big">
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="teaser__text">
                    <div className="teaser__text-content">
                      <h5 className="teaser__text-title">Messepräsentation</h5>
                      <div>
                        <p className="teaser__text-details">
                          Hannover, Mailand, Stuttgart – der Messeatlas für die
                          Maschinen-bauer und Metallbearbeiter weist klare Hot
                          Spots auf, die das DAS ACHTE GEBOT seit vielen Jahren
                          regelmäßig mit begleitet. Messeflächen von 100 -750 m²
                          und ein umfangreicher zu integrierender Maschinenpark
                          benötigen ein flexibles Kommunikationskonzept mit
                          hoher Wiedererkennung.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column column--6"></div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <div className="teaser__text">
                <div className="teaser__text-content">
                  <div className="section__image-content">
                    <div className="section__image-content-wrapper">
                      <img
                        ref="image"
                        src={Messestand_1}
                        style={{ width: "100%", height: "100%" }}
                        alt="Messestand"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
