import React from "react";

import Mood_Bewerbung from "../../assets/images/Karriere/8_Mood_Bewerbung.jpg";
import Mood_BewerbungWebP from "../../assets/images/Karriere/8_Mood_Bewerbung.webp";

class SectionJobs extends React.Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { width } = this.state;
    const isMobile = width <= 812;

    if (isMobile) {
      return (
        <section id="section-career">
          <div className="section__content columns container--padding">
            <div className="teaser__text">
              <div className="teaser__text-content">
                <p className="teaser__text-subtitle">Karriere</p>
                <h3 className="teaser__text-title">
                  Bereit für ein Abenteuer?
                </h3>
              </div>
            </div>
            <div className="section__intro-content column--6">
              <p className="teaser__text-details">
                Wir machen Marken- und Marketingkonzepte von der guten Idee bis
                zur crossmedialen Umsetzung. Vorrangig für technologisch
                spannende Branchen und Produkte. Und ausschließlich für
                Unternehmen, für die wir gerne arbeiten. Neugierig, tatkräftig
                und zuverlässig führen wir Projekte zum Erfolg. Dafür nehmen wir
                uns alle Freiheiten, die wir brauchen, damit am Ende ungelogen
                gute Werbung entsteht.
              </p>
              <strong>
                <a
                  href="mailto:bewerbung@dasachtegebot.de"
                  title="Keep in touch"
                >
                  <span className="link">bewerbung@dasachtegebot.de</span>
                </a>
              </strong>
            </div>
          </div>

         

          <div className="section__content-career columns infinite-container">
            <div className="column">
              <div
                className="section__image-content-wrapper"
                style={{ overflow: "hidden" }}
              >
                <picture>
                  <source srcSet={Mood_BewerbungWebP} type="image/webp" />
                  <source srcSet={Mood_Bewerbung} type="image/jpeg" />
                  <img
                    src={Mood_BewerbungWebP}
                    style={{
                      width: "200%",
                      height: "100%",
                      overflow: "hidden",
                      left: "-100px",
                      top: "-50px",
                      position: "relative",
                    }}
                    alt="Bewerbung"
                  />
                </picture>
              </div>
            </div>
          </div>
        </section>
      );
    } else {
      return (
        <section
          id="section-career"
          className="section--content"
          style={{ marginTop: "10rem" }}
        >
          <div className="section__content columns container--padding">
            <div className="teaser__text">
              <div className="teaser__text-content">
                <h3 className="teaser__text-title">
                  Bereit für ein <br />
                  Abenteuer?
                </h3>
              </div>
            </div>
          </div>
          <div className="section__content columns container--padding">
            <div className="section__intro-content column--6">
              <p>
                Wir machen Marken- und Marketingkonzepte von der guten Idee bis
                zur crossmedialen Umsetzung. Vorrangig für technologisch
                spannende Branchen und Produkte. Und ausschließlich für
                Unternehmen, für die wir gerne arbeiten. Neugierig, tatkräftig
                und zuverlässig führen wir Projekte zum Erfolg. Dafür nehmen wir
                uns alle Freiheiten, die wir brauchen, damit am Ende ungelogen
                gute Werbung entsteht.
              </p>
              <strong>
                <a
                  className="link"
                  href="mailto:bewerbung@dasachtegebot.de"
                  title="bewerbung@dasachtegebot.de"
                >
                  bewerbung@dasachtegebot.de
                </a>
              </strong>
            </div>
            <div className="column--6"></div>
          </div>
         
          <div className="section__content-career columns infinite-container">
            <div className="column">
              <div className="section__image-content-wrapper">
                <picture>
                  <source srcSet={Mood_BewerbungWebP} type="image/webp" />
                  <source srcSet={Mood_Bewerbung} type="image/jpeg" />
                  <img
                    src={Mood_BewerbungWebP}
                    style={{ width: "100%", height: "100%" }}
                    alt="Bewerbung"
                  />
                </picture>
              </div>
            </div>
          </div>
        </section>
      );
    }
  }
}
export default SectionJobs;
