import React from "react";
import { HashLink as Link } from "react-router-hash-link";

class SectionImage extends React.Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { width } = this.state;
    const isMobile = width <= 812;

    if (isMobile) {
      return (
        <div>
          <React.Fragment>
            <section className="section__image-mobile--acht full-bg-size-component"></section>
          </React.Fragment>
        </div>
      );
    } else {
      return (
        <React.Fragment>
          <section className="section__image-desktop--acht full-bg-size-component"></section>
          <div className="scroll-down-arrow-wrapper">
            <Link to="#section-about" aria-label="section-about">
              <div className="scroll-down-arrow"></div>
            </Link>
          </div>
        </React.Fragment>
      );
    }
  }
}
export default SectionImage;
