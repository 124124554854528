import React from "react";
// import AnimateCC from "react-adobe-animate";
import VideoDisplay from "../../VideoComponent/VideoDisplay";

import Geberit_Referenzen_Mood_Pluvia from "../../../assets/images/Referenzen/Geberit/Kampagne/Pluvia/Geberit_Referenzen_Mood_Pluvia.jpg";
import Geberit_Titel_Brosch_Pluvia_A4_hoch from "../../../assets/images/Referenzen/Geberit/Kampagne/Pluvia/Geberit_Titel_Brosch_Pluvia_A4_hoch_neu.jpg";
import Geberit_Doppels_Pluvia_A4_hoch_1 from "../../../assets/images/Referenzen/Geberit/Kampagne/Pluvia/Geberit_Doppels_Pluvia_A4_hoch_1.jpg";
import Geberit_Doppels_Pluvia_A4_hoch_2 from "../../../assets/images/Referenzen/Geberit/Kampagne/Pluvia/Geberit_Doppels_Pluvia_A4_hoch_2.jpg";
import Geberit_Doppels_Pluvia_A4_hoch_3 from "../../../assets/images/Referenzen/Geberit/Kampagne/Pluvia/Geberit_Doppels_Pluvia_A4_hoch_3.jpg";
import Geberit_Online_Konfigurator_Pluvia from "../../../assets/images/Referenzen/Geberit/Kampagne/Pluvia/Geberit_Online_Konfigurator_Pluvia.jpg";

// import Geberit_Referenzen_Mood_Supertube from "../../../assets/images/Referenzen/Geberit/Kampagne/Supertube/Geberit_Referenzen_Mood_Supertube_neu.jpg";
// import Geberit_Titel_Brosch_Supertube_A4_hoch from "../../../assets/images/Referenzen/Geberit/Kampagne/Supertube/Geberit_Titel_Brosch_Supertube_A4_hoch_neu.jpg";
// import Geberit_Doppels_Supertube_A4_hoch_1 from "../../../assets/images/Referenzen/Geberit/Kampagne/Supertube/Geberit_Doppels_Supertube_A4_hoch_1.jpg";
// import Geberit_Doppels_Supertube_A4_hoch_2 from "../../../assets/images/Referenzen/Geberit/Kampagne/Supertube/Geberit_Doppels_Supertube_A4_hoch_2.jpg";
// import Geberit_Doppels_Supertube_A4_hoch_3 from "../../../assets/images/Referenzen/Geberit/Kampagne/Supertube/Geberit_Doppels_Supertube_A4_hoch_3.jpg";
// import Geberit_Keyvisual_Supertube_plus_Text from "../../../assets/images/Referenzen/Geberit/Kampagne/Supertube/Geberit_Keyvisual_Supertube_plus_Text.jpg";
// import Geberit_Online_Konfigurator_Supertube from "../../../assets/images/Referenzen/Geberit/Kampagne/Supertube/Geberit_Online_Konfigurator_Supertube.jpg";

// import Geberit_Referenzen_Mood_Visuals from "../../../assets/images/Referenzen/Geberit/Kampagne/Keyvisuals/Geberit_Referenzen_Mood_Visuals.jpg";
// import Geberit_Covermotiv_1 from "../../../assets/images/Referenzen/Geberit/Kampagne/Keyvisuals/Geberit_Covermotiv_1.jpg";
// import Geberit_Covermotiv_2 from "../../../assets/images/Referenzen/Geberit/Kampagne/Keyvisuals/Geberit_Covermotiv_2.jpg";
// import Geberit_Covermotiv_3 from "../../../assets/images/Referenzen/Geberit/Kampagne/Keyvisuals/Geberit_Covermotiv_3.jpg";
// import Geberit_Covermotiv_4 from "../../../assets/images/Referenzen/Geberit/Kampagne/Keyvisuals/Geberit_Covermotiv_4.jpg";
// import Geberit_Covermotiv_5 from "../../../assets/images/Referenzen/Geberit/Kampagne/Keyvisuals/Geberit_Covermotiv_5.jpg";
// import Geberit_Supertube_Erklaerclip from "../../../assets/images/Referenzen/Geberit/geberit_supertube_erklaerclip.webp";

import Geberit_Pluvia_Erklaerclip from "../../../assets/images/Referenzen/Geberit/geberit_pluvia_erklaerclip.webp";
import Geberit_Pluvia_Teaser from "../../../assets/images/Referenzen/Geberit/geberit_pluvia_teaser.webp";
import Testimonial_Geberit_Pluvia from "../../../assets/images/Referenzen/Geberit/testimonial_geberit_pluvia.webp";

import Geberit_FlowFit_Doppels_Brosch_A4_quer_00 from "../../../assets/images/Referenzen/Geberit/Content/Geberit_FlowFit_Doppels_Brosch_A4_quer_00.jpg";
import Geberit_FlowFit_Doppels_Brosch_A4_quer_01 from "../../../assets/images/Referenzen/Geberit/Content/Geberit_FlowFit_Doppels_Brosch_A4_quer_01.jpg";
import Geberit_FlowFit_Doppels_Brosch_A4_quer_02 from "../../../assets/images/Referenzen/Geberit/Content/Geberit_FlowFit_Doppels_Brosch_A4_quer_02.jpg";
import Geberit_FlowFit_Doppels_Brosch_A4_quer_03 from "../../../assets/images/Referenzen/Geberit/Content/Geberit_FlowFit_Doppels_Brosch_A4_quer_03.jpg";

import Geberit_FlowFit_Zeitung_Einzelseiten_01 from "../../../assets/images/Referenzen/Geberit/Content/Geberit_FlowFit_Zeitung_Einzelseiten_01.jpg";
import Geberit_FlowFit_Zeitung_Einzelseiten_02 from "../../../assets/images/Referenzen/Geberit/Content/Geberit_FlowFit_Zeitung_Einzelseiten_02.jpg";

import Screen_Animationen_Durchmesser from "../../../assets/images/Referenzen/Geberit/Content/Screen_Animationen_Durchmesser.jpg";
import Screen_Animationen_Koffer from "../../../assets/images/Referenzen/Geberit/Content/Screen_Animationen_Koffer.jpg";
import Screen_Animationen_Verpressung from "../../../assets/images/Referenzen/Geberit/Content/Screen_Animationen_Verpressung.jpg";

import Geberit_FlowFit_Mockup_Dose_retusche from "../../../assets/images/Referenzen/Geberit/Content/Geberit_FlowFit_Mockup_Dose_retusche.jpg";
import Geberit_FlowFit_Pfefferminz_schwarz from "../../../assets/images/Referenzen/Geberit/Content/Geberit_FlowFit_Pfefferminz_schwarz.jpg";

import Geberit_FlowFit_Koffer from "../../../assets/images/Referenzen/Geberit/Content/Geberit_FlowFit_Koffer.jpg";
import Geberit_FlowFit_Sprinter from "../../../assets/images/Referenzen/Geberit/Content/Geberit_FlowFit_Sprinter.jpg";

import Geberit_Connect_Web_Einstiegsmotiv from "../../../assets/images/Referenzen/Geberit/Content/Geberit_Connect_Web_Einstiegsmotiv.jpg";
import Geberit_Connect_Titel_Brosch_A4_hoch from "../../../assets/images/Referenzen/Geberit/Content/Geberit_Connect_Titel_Brosch_A4_hoch.jpg";
import Geberit_Connect_Broschuere_A4_hoch_01 from "../../../assets/images/Referenzen/Geberit/Content/Geberit_Connect_Broschuere_A4_hoch_01.jpg";
import Geberit_Connect_Broschuere_A4_hoch_02 from "../../../assets/images/Referenzen/Geberit/Content/Geberit_Connect_Broschuere_A4_hoch_02.jpg";
import Geberit_Connect_Animationsclip_Screenshot from "../../../assets/images/Referenzen/Geberit/Content/Geberit_Connect_Animationsclip_Screenshot.jpg";

import Geberit_Protect_Web_Einstiegsmotiv from "../../../assets/images/Referenzen/Geberit/Content/Geberit_Protect_Web_Einstiegsmotiv.jpg";
import Geberit_Protect_Brosch_Titel_A4_hoch_flat from "../../../assets/images/Referenzen/Geberit/Content/Geberit_Protect_Brosch_Titel_A4_hoch_flat.jpg";

import Geberit_Protect_Broschuere_A4_hoch_01 from "../../../assets/images/Referenzen/Geberit/Content/Geberit_Protect_Broschuere_A4_hoch_01.jpg";
import Geberit_Protect_Broschuere_A4_hoch_02 from "../../../assets/images/Referenzen/Geberit/Content/Geberit_Protect_Broschuere_A4_hoch_02.jpg";
import Geberit_Protect_Broschuere_A4_hoch_03 from "../../../assets/images/Referenzen/Geberit/Content/Geberit_Protect_Broschuere_A4_hoch_03.jpg";
import Geberit_Protect_Broschuere_A4_hoch_04 from "../../../assets/images/Referenzen/Geberit/Content/Geberit_Protect_Broschuere_A4_hoch_04.jpg";

import Geberit_Protect_Animation_Schall from "../../../assets/images/Referenzen/Geberit/Content/Geberit_Protect_Animation_Schall.jpg";
import Geberit_Protect_webbanner from "../../../assets/images/Referenzen/Geberit/Content/Geberit_Protect_webbanner.jpg";

import Geberit_FlowFit_Web_Einstiegsmotiv from "../../../assets/images/Referenzen/Geberit/Content/Geberit_FlowFit_Web_Einstiegsmotiv.jpg";
import Geberit_FlowFit_Anzeigen_Postermotive_01 from "../../../assets/images/Referenzen/Geberit/Content/Geberit_FlowFit_Anzeigen_Postermotive_01.jpg";
import Geberit_FlowFit_Anzeigen_Postermotive_02 from "../../../assets/images/Referenzen/Geberit/Content/Geberit_FlowFit_Anzeigen_Postermotive_02.jpg";
import Geberit_FlowFit_Anzeigen_Postermotive_03 from "../../../assets/images/Referenzen/Geberit/Content/Geberit_FlowFit_Anzeigen_Postermotive_03.jpg";
import Geberit_FlowFit_Anzeigen_Postermotive_04 from "../../../assets/images/Referenzen/Geberit/Content/Geberit_FlowFit_Anzeigen_Postermotive_04.jpg";
import Geberit_Protect_Messewand_Rollo from "../../../assets/images/Referenzen/Geberit/Content/Geberit_Protect_Messewand_Rollo.jpg";

export default class ReferenzContentMobile extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section id="section-geberit-flowfit" className="section--content">
          <div className="section__content columns infinite__container">
            <div className="column column--12">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="colmns column--6">
                    <img
                      ref="image"
                      src={Geberit_FlowFit_Web_Einstiegsmotiv}
                      className="teaser__image-right"
                      alt="Geberit FlowFit Einstiegsbild"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="column column--12">
              <div className="teaser__text--intro padding">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--geberit">Kampagne</p>
                  <h3 className="teaser__text-title">Kampagne FlowFit</h3>
                  <div>
                    <p className="teaser__text-details">
                      Mit FlowFit hat Geberit die Sanitärinstallation quasi neu
                      erschaffen. Das innovative Rohrleitungssystem ist optimal
                      auf den Workflow des Installateurs ausgerichtet und
                      überzeugt mit vielen durchdachten Details. Für die
                      weltweite Markteinführung hat DAS ACHTE GEBOT eine
                      modulare Kampagne entwickelt, die unterschiedliche
                      Arbeitserleichterungen in den Mittelpunkt rückt.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Section KeyVisuals */}

          <div className="padding-small">
            <div className="section__content columns container--padding">
              <div className="column column--12">
                <div className="teaser__text">
                  <div className="teaser__text-content">
                    <h5 className="teaser__text-title">Key visuals</h5>
                    <div>
                      <p className="teaser__text-details">
                        Nah an den technischen Vorteilen, aber ohne klassische
                        Argumentation. Alles, was bislang schwierig war oder zu
                        Problemen führen konnte, entfällt einfach.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section__content columns container--padding">
              <div className="column column--12">
                <img
                  ref="image"
                  src={Geberit_FlowFit_Anzeigen_Postermotive_01}
                  style={{ width: "100%" }}
                  alt="Geberit Flowfit Anzeigen Postermotiv"
                />
              </div>
            </div>
            <div className="section__content columns container--padding">
              <div className="column column--12">
                <img
                  ref="image"
                  src={Geberit_FlowFit_Anzeigen_Postermotive_02}
                  style={{ width: "100%" }}
                  alt="Geberit Flowfit Anzeigen Postermotiv"
                />
              </div>
            </div>
            <div className="section__content columns container--padding">
              <div className="column column--12">
                <img
                  ref="image"
                  src={Geberit_FlowFit_Anzeigen_Postermotive_03}
                  style={{ width: "100%" }}
                  alt="Geberit Flowfit Anzeigen Postermotiv"
                />
              </div>
            </div>
            <div className="section__content columns container--padding">
              <div className="column column--12">
                <img
                  ref="image"
                  src={Geberit_FlowFit_Anzeigen_Postermotive_04}
                  style={{ width: "100%" }}
                  alt="Geberit Flowfit Anzeigen Postermotiv"
                />
              </div>
            </div>
          </div>
          {/* Section FlowFit Content */}

          <div className="padding-small">
            <div className="section__content columns container--padding">
              <div className="column column--12">
                <div className="teaser__text">
                  <div className="teaser__text-content">
                    <h5 className="teaser__text-title">Content</h5>
                    <div>
                      <p className="teaser__text-details">
                        Motive und Visualisierungen für Print und Web
                        inszenieren die unterschiedlichen Produktvorteile im
                        Detail und erläutern die neue Art zu installieren
                        Schritt für Schritt.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="section__content columns container--padding">
                <div className="column column--12">
                  <img
                    ref="image"
                    src={Geberit_FlowFit_Doppels_Brosch_A4_quer_00}
                    style={{ width: "100%" }}
                    alt="Geberit Flow Fit Broschüre Titelseite"
                  />
                </div>
              </div>
            </div>
            <div className="section__content columns container--padding">
              <div className="column column--12">
                <img
                  ref="image"
                  src={Geberit_FlowFit_Doppels_Brosch_A4_quer_01}
                  style={{ width: "100%" }}
                  alt="Geberit Flow Fit Broschüre Titelseite"
                />
              </div>
            </div>
            <div className="section__content columns container--padding">
              <div className="column column--12">
                <img
                  ref="image"
                  src={Geberit_FlowFit_Doppels_Brosch_A4_quer_02}
                  style={{ width: "100%" }}
                  alt="Geberit Flow Fit Broschüre Titelseite"
                />
              </div>
            </div>
            <div className="section__content columns container--padding">
              <div className="column column--12">
                <img
                  ref="image"
                  src={Geberit_FlowFit_Doppels_Brosch_A4_quer_03}
                  style={{ width: "100%" }}
                  alt="Geberit Flow Fit Broschüre Titelseite"
                />
              </div>
            </div>
          </div>

          {/* Section Zeitung */}

          <div className="padding-small">
            <div className="section__content columns container--padding">
              <div className="column column--12">
                <div className="teaser__text">
                  <div className="teaser__text-content">
                    <h5 className="teaser__text-title">Zeitung</h5>
                    <div>
                      <p className="teaser__text-details">
                        Storytelling vom ersten Entwicklungsschritt bis zur
                        Success Story. Einfach gute Nachrichten für die Branche.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section__content columns container--padding">
              <div className="column column--12">
                <img
                  ref="image"
                  src={Geberit_FlowFit_Zeitung_Einzelseiten_01}
                  style={{ width: "100%" }}
                  alt="Geberit Flow Fit Broschüre Titelseite"
                />
              </div>
            </div>
            <div className="section__content columns container--padding">
              <div className="column column--12">
                <img
                  ref="image"
                  src={Geberit_FlowFit_Zeitung_Einzelseiten_02}
                  style={{ width: "100%" }}
                  alt="Geberit Flow Fit Broschüre Titelseite"
                />
              </div>
            </div>
          </div>

          {/* Section  Banner */}

          <div className="padding-small">
            <div className="section__content columns container--padding">
              <div className="column column--12">
                <div className="teaser__text">
                  <div className="teaser__text-content">
                    <h5 className="teaser__text-title">Animationen + Banner</h5>
                    <div>
                      <p className="teaser__text-details">
                        Kurze Animation statt langatmiger Erklärungen. Für
                        Social Media, Web und Präsentationen sind prägnante
                        Clips entstanden.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* TODO: Animationen / Banner einfügen */}

            <div className="section__content columns container--padding">
              <div className="column column--12">
                <img
                  ref="image"
                  src={Screen_Animationen_Koffer}
                  style={{ width: "100%" }}
                  alt="Geberit Flow Fit Broschüre Titelseite"
                />
              </div>
            </div>
            <div className="section__content columns container--padding">
              <div className="column column--12">
                <img
                  ref="image"
                  src={Screen_Animationen_Durchmesser}
                  style={{ width: "100%" }}
                  alt="Geberit Flow Fit Broschüre Titelseite"
                />
              </div>
            </div>
            <div className="section__content columns container--padding">
              <div className="column column--12">
                <img
                  ref="image"
                  src={Screen_Animationen_Verpressung}
                  style={{ width: "100%" }}
                  alt="Geberit Flow Fit Broschüre Titelseite"
                />
              </div>
            </div>
          </div>

          <div className="padding-small">
            <div className="section__content columns container--padding">
              <div className="column column--12">
                <div className="teaser__text">
                  <div className="teaser__text-content">
                    <h5 className="teaser__text-title">
                      Marketing-Kommunikation
                    </h5>
                    <div>
                      <p className="teaser__text-details">
                        Passende Werbemittel und Merchandising- Material für
                        Events.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section__content columns container--padding">
              <div className="column column--12">
                <img
                  ref="image"
                  src={Geberit_FlowFit_Mockup_Dose_retusche}
                  style={{ width: "100%" }}
                  alt="Geberit Marketing Goodies Dosen"
                />
              </div>
              <div className="column column--12">
                <img
                  ref="image"
                  src={Geberit_FlowFit_Pfefferminz_schwarz}
                  style={{ width: "100%" }}
                  alt="Geberit Marketing Goodies Dosen"
                />
              </div>
              <div className="column column--3"></div>
            </div>
          </div>

          {/* Section Produktionskoffer */}

          <div className="padding-small">
            <div className="section__content columns container--padding">
              <div className="column column--12">
                <div className="teaser__text" style={{ paddingTop: "20%" }}>
                  <div className="teaser__text-content">
                    <h5 className="teaser__text-title">Präsentationskoffer</h5>
                    <div>
                      <p className="teaser__text-details">
                        Mustermaterial zum Kennenlernen, Anfassen und Testen.
                        Damit motiviert man Kunden und den eigenen Vertrieb.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column column--12">
                <img
                  ref="image"
                  src={Geberit_FlowFit_Koffer}
                  style={{ width: "100%" }}
                  alt="Geberit Präsentationskoffer für FlowFit Produkt"
                />
              </div>
            </div>
          </div>

          {/* Section FlowFit Sprinter */}

          <div className="padding-small">
            <div className="section__content columns container--padding">
              <div className="column column--12">
                <div className="teaser__text" style={{ paddingTop: "20%" }}>
                  <div className="teaser__text-content">
                    <h5 className="teaser__text-title">Roadshow</h5>
                    <div>
                      <p className="teaser__text-details">
                        Das FlowFit-Mobil für Händler-Aktionstage.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section__content columns container--padding">
              <div className="column column--12">
                <img
                  ref="image"
                  src={Geberit_FlowFit_Sprinter}
                  style={{ width: "100%" }}
                  alt="Geberit Sprinter für Präsentation und Aktionstages"
                />
              </div>
            </div>
          </div>
        </section>

        <section id="section-geberit-connect" className="section--content">
          <div className="section__content columns container-section">
            <div className="column column--6">
              <img
                ref="image"
                src={Geberit_Connect_Web_Einstiegsmotiv}
                className="teaser__image-left"
                alt="Geberit Connect Einstiegsmotiv"
              />
            </div>

            <div className="column column--6">
              <div className="teaser__text padding">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--geberit">Kampagne</p>
                  <h3 className="teaser__text-title">Geberit Connect</h3>
                  <p className="teaser__text-details">
                    Für gut besuchte Räume: Digitale Lösungen im Sanitärbereich
                    unterstützen das Facility Management und sorgen für
                    Sicherheit und Sauberkeit. Mit Geberit Connect lassen sich
                    Sanitärprodukte vernetzen und zentral steuern. Die
                    Einführungskampagne setzt Zeichen und vereint verschiedene
                    Geberit-Produktwelten.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Section Connect Broschuere */}

          <div className="padding-small">
            <div className="section__content columns container--padding">
              <div className="column column--12">
                <div className="teaser__text" style={{ paddingBottom: "2rem" }}>
                  <div className="teaser__text-content">
                    <h5 className="teaser__text-title">Content</h5>
                    <div>
                      <p className="teaser__text-details">
                        Mit einer Feature-Broschüre und aussagekräftigen
                        Visuals, werden Planer, Installateure, Betreiber und
                        Investoren abgeholt.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column column--12">
                <img
                  ref="image"
                  src={Geberit_Connect_Titel_Brosch_A4_hoch}
                  style={{ width: "100%" }}
                  alt="Connect Broschüre Titelseite"
                />
              </div>
            </div>
            <div className="section__content columns container--padding">
              <div className="column column--12">
                <img
                  ref="image"
                  src={Geberit_Connect_Broschuere_A4_hoch_01}
                  style={{ width: "100%" }}
                  alt="Connect Broschüre Innenteil"
                />
              </div>
            </div>
            <div className="section__content columns container--padding">
              <div className="column column--12">
                <img
                  ref="image"
                  src={Geberit_Connect_Broschuere_A4_hoch_02}
                  style={{ width: "100%" }}
                  alt="Connect Broschüre Innenteil"
                />
              </div>
            </div>
          </div>

          {/* Geberit Connect Clip */}

          <div className="padding-small">
            <div className="section__content columns container--padding">
              <div className="column column--4">
                <div className="teaser__text">
                  <div className="teaser__text-content">
                    <h5 className="teaser__text-title">Geberit Connect Clip</h5>
                    <div>
                      <p className="teaser__text-details">
                        Der kleine Info-Teaser zeigt in 30 Sekunden, warum
                        Geberit Connect das Gebäudemanagement zukünftig deutlich
                        einfacher macht.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column column--8"></div>
            </div>
            <div className="section__content columns container--padding">
              <div className="column column--12">
                <img
                  ref="image"
                  src={Geberit_Connect_Animationsclip_Screenshot}
                  style={{ width: "100%" }}
                  alt="Connect Broschüre Innenteil"
                />
              </div>
            </div>
          </div>
        </section>

        {/* Section Content Protect Kampagne */}
        <section id="section-geberit-protect" className="section--content">
          <div className="section__content columns container-section">
            <div className="column column--6">
              <img
                ref="image"
                src={Geberit_Protect_Web_Einstiegsmotiv}
                className="teaser__image-left"
                alt="Kampagne - Geberit Protect"
              />
            </div>

            <div className="column column--6">
              <div className="teaser__text padding">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--geberit">Kampagne</p>
                  <h3 className="teaser__text-title">Geberit Protect</h3>
                  <p className="teaser__text-details">
                    Einladung zum Kennenlernen: Das neuartige Schall- und
                    Brandschutzsystem für die Sanitärwand macht den Installateur
                    unabhängiger. Die neuartigen Produkte muss die Zielgruppe
                    aber erst mal kennenlernen. Warum mit dem System alles viel
                    einfacher wird, zeigt die Kampagne vom ACHTEN GEBOT.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="padding-small">
            <div className="section__content columns container--padding">
              <div className="column column--12">
                <div className="teaser__text">
                  <div className="teaser__text-content">
                    <h5 className="teaser__text-title">Keyvisual</h5>
                    <div>
                      <p className="teaser__text-details">
                        Für das optische Leitmotiv haben wir eine ungewöhnliche
                        Perspektive gewählt. Die neue Wand wird aus der Wand
                        heraus präsentiert. Und der Installateur weiß sofort
                        womit er es zu tun hat.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section__content columns container--padding">
              <div className="column column--12">
                <img
                  ref="image"
                  src={Geberit_Protect_Brosch_Titel_A4_hoch_flat}
                  style={{ width: "100%" }}
                  alt="Connect Broschüre Innenteil"
                />
              </div>
            </div>
          </div>

          {/* Section Protect Print */}

          <div className="padding-small">
            <div className="section__content columns container--padding">
              <div className="column column--12">
                <div className="teaser__text" style={{ paddingBottom: "4rem" }}>
                  <div className="teaser__text-content">
                    <h5 className="teaser__text-title">Print</h5>
                    <div>
                      <p className="teaser__text-details">
                        Neue Produkte und völlig neue Arbeitsschritte. Die
                        Broschüre zeigt alle Funktionen und Elemente des neuen
                        Systems Schritt für Schritt.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section__content columns container--padding">
              <img
                ref="image"
                src={Geberit_Protect_Broschuere_A4_hoch_01}
                style={{ width: "100%" }}
                alt="Protect Broschüre Print Innenteil"
              />
            </div>
            <div className="section__content columns container--padding">
              <img
                ref="image"
                src={Geberit_Protect_Broschuere_A4_hoch_02}
                style={{ width: "100%" }}
                alt="Protect Broschüre Print Innenteil"
              />
            </div>
            <div className="section__content columns container--padding">
              <img
                ref="image"
                src={Geberit_Protect_Broschuere_A4_hoch_03}
                style={{ width: "100%" }}
                alt="Protect Broschüre Print Innenteil"
              />
            </div>
            <div className="section__content columns container--padding">
              <img
                ref="image"
                src={Geberit_Protect_Broschuere_A4_hoch_04}
                style={{ width: "100%" }}
                alt="Protect Broschüre Print Innenteil"
              />
            </div>
          </div>

          {/* Protect Schall Animation */}

          <div className="padding-small">
            <div className="section__content columns container--padding">
              <div className="column column--12">
                <div className="teaser__text">
                  <div className="teaser__text-content">
                    <h5 className="teaser__text-title">Animation</h5>
                    <div>
                      <p className="teaser__text-details">
                        Schallschutz sichtbar gemacht
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section__content columns container--padding">
              <div className="column column--12">
                <img
                  ref="image"
                  src={Geberit_Protect_Animation_Schall}
                  style={{ width: "100%" }}
                  alt="Protect Broschüre Print Seite 4"
                />
              </div>
            </div>
          </div>

          {/* Protect Banner */}

          <div className="padding-small">
            <div className="section__content columns container--padding">
              <div className="column column--12">
                <div className="teaser__text">
                  <div className="teaser__text-content">
                    <h5 className="teaser__text-title">Banner</h5>
                    <div>
                      <p className="teaser__text-details">
                        Die Online-Kampagne sorgt für Traffic auf der
                        Landingpage.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section__content columns container--padding">
              <div className="column column--12">
                <img
                  ref="image"
                  src={Geberit_Protect_webbanner}
                  style={{ width: "100%" }}
                  alt="Geberit Protect Webbanner"
                />
              </div>
            </div>
          </div>

          {/* Protect Präsentationstool */}
          <div className="padding-small">
            <div className="section__content columns container--padding">
              <div className="column column--12">
                <div className="teaser__text">
                  <div className="teaser__text-content">
                    <h5 className="teaser__text-title">Präsentationstool</h5>
                    <div>
                      <p className="teaser__text-details">
                        Demo-Installationen im Bild und Realaufbau informieren
                        am POS und auf der Messe.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="section__content columns container--padding">
              <div className="column column--12">
                <img
                  ref="image"
                  src={Geberit_Protect_Messewand_Rollo}
                  style={{ width: "100%" }}
                  alt="Geberit Protect Webbanner"
                />
              </div>
            </div>
          </div>
        </section>

        <section
          id="section-geberit-1"
          className="section--content section--padding"
        >
          <div className="section__content columns infinite__container">
            <div className="column column--12">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <img
                    ref="image"
                    src={Geberit_Referenzen_Mood_Pluvia}
                    className="teaser__image-right"
                    alt="Dachentwässerung Pluvia"
                  />
                </div>
              </div>
            </div>
            <div className="column column--12">
              <div className="teaser__text--intro padding">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--geberit">Kampagne</p>
                  <h3 className="teaser__text-title">
                    Dachentwässerung "Pluvia"
                  </h3>
                  <div>
                    <p className="teaser__text-details">
                      Für den Relaunch des bewährten Dachentwässerungssystems
                      Pluvia ist eine modulare, international einsetzbare
                      Kampagne entstanden, die die technologischen Vorteile für
                      Architekten, Planer, Investoren und Sanitärhandwerk in den
                      Mittelpunkt rücken und die Überlegenheit des
                      Unterdrucksystems klar demonstrieren. Die Visualisierung
                      als Grundlage für alle Medien gelang dabei lange bevor das
                      neue Produkt verfügbar war mit 3D-Renderings und
                      3D-Animationen durch die 3D-Crew des ACHTEN GEBOTS.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding-big">
            <div className="columns column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="teaser__text">
                    <div className="teaser__text-content">
                      <h5 className="teaser__text-title">Teaser-Video</h5>
                      <div>
                        <p className="teaser__text-details">
                          Starkregen ist ein zunehmendes und gefürchtetes
                          Problem bei der Gebäudeentwässerung, besonders wenn es
                          um große Dachflächen geht. Der Teaser-Clip
                          dramatisiert dieses Szenario, das für Pluvia keine
                          große Herausforderung darstellt.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="columns column--6"></div>
          </div>

          <div className="section__content columns container--padding">
            <div className="video-container column column--12">
              <VideoDisplay
                src="https://www.youtube.com/embed/4Pod6QhlCFQ?controls=0"
                fullScreen=""
                backImage={Geberit_Pluvia_Teaser}
                title="LET IT RAIN - Teaser"
                border="0"
              />
            </div>
          </div>

          <div className="section__content columns container--padding-big">
            <div className="column column--6">
              <div className="teaser__text">
                <div className="teaser__text-content">
                  <div className="section__image-content-wrapper">
                    <h5 className="teaser__text-title">Broschüre</h5>
                    <p className="teaser__text-details">
                      Technisch klare Bildsprache und deutlich kommunizierter
                      Kundennutzen stehen im Mittelpunkt der Kommunikation. Mit
                      der Inszenierung von spannenden Flagship-Projekten vom
                      Fussball-stadion bis zum Museum wird deutlich, dass Pluvia
                      technische Möglichkeiten bietet, an die
                      Wettbewerbsprodukte nicht hinreichen.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--6">
              <div className="teaser__text">
                <div className="teaser__text-content">
                  <div className="section__image-content-wrapper">
                    <img
                      ref="image"
                      src={Geberit_Titel_Brosch_Pluvia_A4_hoch}
                      style={{ width: "100%", height: "100%" }}
                      alt="Broschüre Dachentwässerung Pluvia"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <img
                    ref="image"
                    src={Geberit_Doppels_Pluvia_A4_hoch_1}
                    style={{ width: "100%", height: "100%" }}
                    alt="Broschüre Dachentwässerung Pluvia"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <img
                    ref="image"
                    src={Geberit_Doppels_Pluvia_A4_hoch_2}
                    style={{ width: "100%", height: "100%" }}
                    alt="Broschüre Dachentwässerung Pluvia"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <img
                    ref="image"
                    src={Geberit_Doppels_Pluvia_A4_hoch_3}
                    style={{ width: "100%", height: "100%" }}
                    alt="Broschüre Dachentwässerung Pluvia"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding-big">
            <div className="columns column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="teaser__text">
                    <div className="teaser__text-content">
                      <h5 className="teaser__text-title">Erklärclip</h5>
                      <div>
                        <p className="teaser__text-details">
                          Unterdruckdachentwässerung ist kein Thema, das sich in
                          zwei Sätzen selbst erklärt. Leicht verdaulich wird die
                          komplexe Technologie aber durch einen unterhaltsam
                          bebilderten Film.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="columns column--6"></div>
          </div>

          <div className="section__content columns container--padding">
            <div className="video-container column column--12">
              <VideoDisplay
                src="https://www.youtube.com/embed/LGMOWk13j5g?controls=0"
                fullScreen=""
                backImage={Geberit_Pluvia_Erklaerclip}
                title="Geberit Pluvia Erklärclip"
                border="0"
              />
            </div>
          </div>

          <div className="section__content columns container--padding-big">
            <div className="columns column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="teaser__text">
                    <div className="teaser__text-content">
                      <h5 className="teaser__text-title">Testimonial-Clip</h5>
                      <div>
                        <p className="teaser__text-details">
                          Andere Länder, andere Klima- und Wetterphänomene –
                          dass Geberit Pluvia für alles gerüstet ist, zeigen die
                          internationalen Testimonial-Clips, die auf regionale
                          oder lokale Besonderheiten in Planung und Bau
                          eingehen.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="columns column--6"></div>
          </div>

          <div className="section__content columns container--padding">
            <div className="video-container column column--12">
              <VideoDisplay
                src="https://www.youtube.com/embed/TD0rCTw8MiQ?controls=0"
                fullScreen=""
                backImage={Testimonial_Geberit_Pluvia}
                title="Testimonial Geberit Pluvia"
                border="0"
              />
            </div>
          </div>

          <div className="section__content columns container--padding-big">
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <h5 className="teaser__text-title">
                    Online-Konfigurator Pluvia Produktfinder
                  </h5>
                  <p className="teaser__text-details">
                    Höhere Anwenderfreundlichkeit und schnellere Orientierung im
                    Produktsortiment bietet der Konfigurator, der vom ACHTEN
                    GEBOT konzipiert und programmiert wurde. Damit für jede
                    Dachsituation der richtige Aufbau gewählt wird, steht der
                    Produktfinder in vielen Sprachen zur Verfügung.
                  </p>
                </div>
              </div>
            </div>
            <div className="column column--6"></div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <img
                ref="image"
                src={Geberit_Online_Konfigurator_Pluvia}
                style={{ width: "100%", height: "100%" }}
                alt="Online-Konfigurator Pluvia Produktfinder"
              />
            </div>
          </div>
        </section>

        {/* 
        <section
          id="section-geberit-2"
          className="section--content section--padding-big"
        >
          <div className="section__content columns container-section">
            <div className="column column--6">
              <div className="section__image-content-wrapper">
                <img
                  ref="image"
                  src={Geberit_Referenzen_Mood_Supertube}
                  className="teaser__image-left"
                  alt="Geberit SuperTube Produkteinführung"
                />
              </div>
            </div>

            <div className="column column--6">
              <div className="teaser__text padding">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--geberit">Kampagne</p>
                  <h3 className="teaser__text-title">
                    Produktneueinführung SuperTube
                  </h3>
                  <div>
                    <p className="teaser__text-details">
                      Die SuperTube-Technologie sorgt mit optimierten
                      hydraulischen Formstücken für höhere Flexibilität und
                      schlankere Konstruktionen im Abwassersystem von
                      Hochhäusern. DAS ACHTE GEBOT inszenierte dieses Thema
                      unter dem Kampagnen-Claim „Das Raum-Gewinn-System“ und
                      stellt damit den Hauptproduktnutzen für die Entscheider in
                      den Mittelpunkt.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section__content columns container--padding-big">
            <div className="column column--6">
              <div className="teaser__text">
                <div className="teaser__text-content">
                  <div className="section__image-content-wrapper">
                    <h5 className="teaser__text-title">Broschüre</h5>
                    <p className="teaser__text-details">
                      Abwasserhydraulik kann ästhetisch und spannend in Szene
                      gesetzt werden und unterstreicht damit die hohe Wertigkeit
                      und technische Überlegenheit des Geberit-Systems.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--6">
              <div className="teaser__text">
                <div className="teaser__text-content">
                  <div className="section__image-content-wrapper">
                    <img
                      ref="image"
                      src={Geberit_Titel_Brosch_Supertube_A4_hoch}
                      style={{ width: "100%", height: "100%" }}
                      alt="Broschüre SuperTube"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <img
                    ref="image"
                    src={Geberit_Doppels_Supertube_A4_hoch_1}
                    style={{ width: "100%", height: "100%" }}
                    alt="Broschüre SuperTube"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <img
                    ref="image"
                    src={Geberit_Doppels_Supertube_A4_hoch_2}
                    style={{ width: "100%", height: "100%" }}
                    alt="Broschüre SuperTube"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <img
                    ref="image"
                    src={Geberit_Doppels_Supertube_A4_hoch_3}
                    style={{ width: "100%", height: "100%" }}
                    alt="Broschüre SuperTube"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding-big">
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <h5 className="teaser__text-title">Key-Visuals</h5>
                  <p className="teaser__text-details">
                    Ob Hoch- oder Querformat, statisch oder dynamisch – das
                    optische Leitmotiv wurde so gewählt, dass es in alle Medien
                    und in alle Formate flexibel übertragbar ist.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <img
                    ref="image"
                    src={Geberit_Keyvisual_Supertube_plus_Text}
                    style={{ width: "100%", height: "100%" }}
                    alt="SuperTube Key-Visual"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding-big">
            <div className="column column--12">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <h5 className="teaser__text-title">
                    Master-Templates für SEM
                  </h5>
                  <p className="teaser__text-details">
                    Animierte HTML-Banner auf Basis der Kampagnen Key-Visual
                    werden vom ACHTEN GEBOT als Mastervorlage erstellt und dann
                    länder- oder marktspezifisch adaptiert.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <div className="section__image-content">
                <AnimateCC
                  animationName="GEB18SuperTube300x600"
                  composition="9AC8135487D2F74ABE178186E8DD0E62"
                />
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding-big">
            <div className="columns column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="teaser__text">
                    <div className="teaser__text-content">
                      <h5 className="teaser__text-title">Erklärclip</h5>
                      <div>
                        <p className="teaser__text-details">
                          Der Erklärclip erläutert Komplexes ganz einfach in
                          wenigen Schritten und ist so neutral gehalten, dass er
                          weltweit eingesetzt werden kann.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="columns column--6"></div>
          </div>

          <div className="section__content columns container--padding">
            <div className="video-container column column--12">
            <VideoDisplay
                src="https://www.youtube.com/embed/JBSyRDuC8Lk?controls=0"
                fullScreen=""
                backImage={Geberit_Supertube_Erklaerclip}
                title="SuperTube - Erklärclip"
                border="0"
              />
            </div>
          </div>

          <div className="section__content columns container--padding-big">
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <h5 className="teaser__text-title">
                    Online-Konfigurator SuperTube Planungstool
                  </h5>
                  <p className="teaser__text-details">
                    Bevor es an die Feinjustierung geht, können Planer mit dem
                    Online-Tool einen PreTest über die Einsatzmöglichkeit von
                    SuperTube fahren. Logik und Programmierung erstellte DAS
                    ACHTE GEBOT.
                  </p>
                </div>
              </div>
            </div>
            <div className="column column--6"></div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <img
                ref="image"
                src={Geberit_Online_Konfigurator_Supertube}
                style={{ width: "100%", height: "100%" }}
                alt="Online-Konfigurator SuperTube Planungstool"
              />
            </div>
          </div>
        </section>

        <section
          id="section-geberit-3"
          className="section--content section--padding-big"
        >
          <div className="section__content columns infinite__container">
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="columns column--6"></div>
                  <div className="colmns column--6">
                    <img
                      ref="image"
                      src={Geberit_Referenzen_Mood_Visuals}
                      className="teaser__image-right"
                      alt="Geberit Key-Visuals/Claims"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="column column--6">
              <div className="teaser__text--intro padding">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--geberit">
                    Key-Visuals/Claims
                  </p>
                  <h3 className="teaser__text-title">Branding</h3>
                  <div>
                    <p className="teaser__text-details">
                      Im Zuge des internationalen Corporate Design Relaunchs von
                      Geberit setzte DAS ACHTE GEBOT den Produktbereich
                      Rohrleitungssysteme und seine Medien um. Hauptaugenmerk
                      lag auf der Systematisierung der Key-Visuals pro
                      Produktfamilie sowie auf der einheitlichen Auslobung der
                      Produkte über passende Claims. Auf Basis von 3D-Renderings
                      wurde eine neuartige Produktästhetik geschaffen, die sich
                      variabel in allen Medien einsetzen lässt.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <img
                    ref="image"
                    src={Geberit_Covermotiv_1}
                    style={{ width: "100%", height: "100%" }}
                    alt="Key-Visuals"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <img
                    ref="image"
                    src={Geberit_Covermotiv_2}
                    style={{ width: "100%", height: "100%" }}
                    alt="Key-Visuals"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <img
                    ref="image"
                    src={Geberit_Covermotiv_3}
                    style={{ width: "100%", height: "100%" }}
                    alt="Key-Visuals"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <img
                    ref="image"
                    src={Geberit_Covermotiv_4}
                    style={{ width: "100%", height: "100%" }}
                    alt="Key-Visuals"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <img
                    ref="image"
                    src={Geberit_Covermotiv_5}
                    style={{ width: "100%", height: "100%" }}
                    alt="Key-Visuals"
                  />
                </div>
              </div>
            </div>
          </div>
        </section> 
        */}
      </React.Fragment>
    );
  }
}
