import React from "react";

import ReferenzAnimation from "../MevaComponent/ReferenzAnimation";

class ReferenzIntro extends React.Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { width } = this.state;
    const isMobile = width <= 812;

    if (isMobile) {
      return (
        <React.Fragment>
          <section id="section-meva">
            <div className="section__content columns container--padding">
              <div className="teaser__text column--6">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--meva">
                    MEVA Schalungs-Systeme GmbH
                  </p>
                  <h3 className="teaser__text-title">Corporate Branding <br />
                  und Kommunikation</h3>
                </div>
              </div>
              <div className="section__intro-content column--6">
                <p className="teaser__text-details">
                  MEVA positioniert sich als Pionier und Impulsgeber für die
                Schalungsbranche. Mögen Wettbewerber größer und umsatzstärker
                sein – die besseren Ideen und ganzheitlichen Lösungen kommen
                doch aus Haiterbach im Schwarzwald. Als familiengeführtes
                mittelständisches Unternehmen in zweiter Generation kennt MEVA
                seine Stärken und spielt diese souverän aus.
                </p>
              </div>
            </div>
          </section>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
           <section id="section-meva" className="section--intro">
          <div className="section__content columns container--padding">
            <div className="column column--4"></div>
            <div className="column column--4" style={{ marginTop: "-8%" }}>
              <ReferenzAnimation />
            </div>
            <div className="column column--4"></div>
          </div>

          <div className="section__content columns container">
            <div className="teaser__text column--6">
              <div className="teaser__text-content">
                <p className="teaser__text-subtitle--meva">
                  MEVA Schalungs-Systeme GmbH
                </p>
                <h3 className="teaser__text-title">
                  Corporate Branding <br />
                  und Kommunikation
                </h3>
              </div>
            </div>

            <div className="section__intro-content column--6">
              <p className="teaser__text-details margin">
                MEVA positioniert sich als Pionier und Impulsgeber für die
                Schalungsbranche. Mögen Wettbewerber größer und umsatzstärker
                sein – die besseren Ideen und ganzheitlichen Lösungen kommen
                doch aus Haiterbach im Schwarzwald. Als familiengeführtes
                mittelständisches Unternehmen in zweiter Generation kennt MEVA
                seine Stärken und spielt diese souverän aus.
              </p>
            </div>
          </div>
        </section>
        </React.Fragment>
      );
    }
  }
}
export default ReferenzIntro;
