import React from "react";

import solidian_Referenzen_Mood_1260px_breit from "../../../assets/images/Referenzen/Solidian/Kampagne/solidian_Referenzen_Mood_1260px_breit.jpg";
import solidian_KeyVisual_1_Fertigteilwerke from "../../../assets/images/Referenzen/Solidian/Kampagne/solidian_KeyVisual_1_Fertigteilwerke.jpg";
import solidian_KeyVisual_2_Architekturbau from "../../../assets/images/Referenzen/Solidian/Kampagne/solidian_KeyVisual_2_Architekturbau.jpg";
import solidian_KeyVisual_3_Instandsetzung from "../../../assets/images/Referenzen/Solidian/Kampagne/solidian_KeyVisual_3_Instandsetzung.jpg";
import solidian_Titel_Brandnook_quer from "../../../assets/images/Referenzen/Solidian/Kampagne/solidian_Titel_Brandnook_quer_neu.jpg";
import solidian_Doppels_Brandnook_quer_2 from "../../../assets/images/Referenzen/Solidian/Kampagne/solidian_Doppels_Brandnook_quer_2.jpg";
import solidian_Doppels_Brandnook_quer_3 from "../../../assets/images/Referenzen/Solidian/Kampagne/solidian_Doppels_Brandnook_quer_3.jpg";
import solidian_Doppels_Brandnook_quer_4 from "../../../assets/images/Referenzen/Solidian/Kampagne/solidian_Doppels_Brandnook_quer_4.jpg";
import Solidian_Mockup from "../../../assets/video/referenzen/solidian/Solidian.mp4";

export default class ReferenzContent extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section
          id="section-solidian-1"
          className="section--content section--padding"
        >
          <div className="section__content columns infinite__container">
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <img
                    ref="image"
                    src={solidian_Referenzen_Mood_1260px_breit}
                    className="teaser__image-right"
                    alt="solidian Bildkonzept"
                  />
                </div>
              </div>
            </div>
            <div className="column column--6">
              <div className="teaser__text--intro padding">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--solidian">Bildsprache</p>
                  <h3 className="teaser__text-title">Echt wirksam</h3>
                  <div>
                    <p className="teaser__text-details">
                      Wie kommuniziert man für einen neuen Markt mit unzähligen
                      Möglichkeiten, aber ohne nennenswerte Referenzen, die man
                      zeigen könnte? Wie zeigt man unterschiedliche Anwendungen,
                      wenn man noch nicht genau weiß, welche relevanten
                      Entscheider man ansprechen sollte? Wie zeigt man
                      Zukunftstechnologie, die aber nicht Sciene Fiction,
                      sondern schon heute Realität ist? Die Antwort auf diese
                      und viele andere Fragen fand DAS ACHTE GEBOT für solidian
                      in einer Bildwelt, die realistische Architekturszenarien
                      zeigt, die die Brücke schlagen zwischen Idee, Produkt und
                      fertigem Bauprojekt.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding-big">
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <h5 className="teaser__text-title">Bildkonzept</h5>
                  <p className="teaser__text-details">
                    Von der textilen Struktur ins fertige Betonelement
                    visualisiert das Bildkonzept von solidian: Unterschiedliche
                    denkbare Szenarien, die es noch nicht gibt, aber jederzeit
                    geben könnte.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <img
                    ref="image"
                    src={solidian_KeyVisual_1_Fertigteilwerke}
                    style={{ width: "100%", height: "100%" }}
                    alt="solidian Bildkonzept"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <img
                    ref="image"
                    src={solidian_KeyVisual_2_Architekturbau}
                    style={{ width: "100%", height: "100%" }}
                    alt="solidian Bildkonzept"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <img
                    ref="image"
                    src={solidian_KeyVisual_3_Instandsetzung}
                    style={{ width: "100%", height: "100%" }}
                    alt="solidian Bildkonzept"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding-big">
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <h5 className="teaser__text-title">Brandbook</h5>
                  <p className="teaser__text-details">
                    Kleines Start-up auf großem Kurs. Um Vertrauen bei Kunden,
                    Stakeholdern und potentiellen neuen Mitarbeitern zu
                    schaffen, gibt es nur einen Weg: Man muss die Menschen
                    emotional mitnehmen, in dem man klar und ehrlich
                    kommuniziert. Das Markenhandbuch gibt den Kurs vor und
                    bietet Orientierung.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <img
                    ref="image"
                    src={solidian_Titel_Brandnook_quer}
                    style={{ width: "100%", height: "100%" }}
                    alt="solidian Brandbook"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <img
                    ref="image"
                    src={solidian_Doppels_Brandnook_quer_2}
                    style={{ width: "100%", height: "100%" }}
                    alt="solidian Brandbook"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <img
                    ref="image"
                    src={solidian_Doppels_Brandnook_quer_3}
                    style={{ width: "100%", height: "100%" }}
                    alt="solidian Brandbook"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <img
                    ref="image"
                    src={solidian_Doppels_Brandnook_quer_4}
                    style={{ width: "100%", height: "100%" }}
                    alt="solidian Brandbook"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding-big">
            <div className="column column--8"></div>
            <div className="column column--4">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <h5 className="teaser__text-title">Website</h5>
                  <p className="teaser__text-details">
                    Auch im Web sichert sich solidian die Pole Position. Die
                    solidian-Website ist ein Portal, das wichtige Keywords des
                    neuen Marktes besetzt. Dabei ist die Seite von Grund auf
                    ebenfalls auf Wachstum ausgelegt. TYPO3 als Content
                    Management System bietet einfache Möglichkeiten zum Ergänzen
                    von Referenzprojekten und Produkten und legt die Basis für
                    strategische Online-Marketing-Aktivitäten wie SEA und SEO
                    aller Art.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <video
                id="FlexMockup"
                autostart="true"
                playsInline
                autoPlay
                muted
                loop
                type="video/mp4"
                src={Solidian_Mockup}
                style={{
                  width: "100%",
                  height: "auto"
                }}
              />
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
