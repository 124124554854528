import React from "react";

import Belton_Referenzen_Mood_belton_Relaunch_1 from "../../../assets/images/Referenzen/Kwasny/Belton/belton_Referenzen_Mood_belton_Relaunch_1.jpg";
import Belton_Dosenrange from "../../../assets/images/Referenzen/Kwasny/Belton/belton_Dosenrange.jpg";
import belton_Titel_Katalog_A4_hoch from "../../../assets/images/Referenzen/Kwasny/Belton/belton_Titel_Katalog_A4_hoch.jpg";
import belton_Doppels_Katalog_A4_hoch_1 from "../../../assets/images/Referenzen/Kwasny/Belton/belton_Doppels_Katalog_A4_hoch_1.jpg";
import belton_Doppels_Katalog_A4_hoch_2 from "../../../assets/images/Referenzen/Kwasny/Belton/belton_Doppels_Katalog_A4_hoch_2.jpg";
import belton_Doppels_Katalog_A4_hoch_3 from "../../../assets/images/Referenzen/Kwasny/Belton/belton_Doppels_Katalog_A4_hoch_3.jpg";
import belton_Doppels_Katalog_A4_hoch_4 from "../../../assets/images/Referenzen/Kwasny/Belton/belton_Doppels_Katalog_A4_hoch_4.jpg";
import belton_web from "../../../assets/images/Referenzen/Kwasny/Belton/belton_web.jpg";
import belton_DIY_Referenzen_Mood from "../../../assets/images/Referenzen/Kwasny/Belton/belton-DIY_Referenzen_Mood.jpg";
import Kwasny_Sprueh_Video from "../../../assets/images/Referenzen/Kwasny/Belton/videos/Animation_wie_sprueht_man_richtig_DE.mp4";
import belton_free_Produkteinfuehrung from "../../../assets/images/Referenzen/Kwasny/Belton/belton_free_Produkteinfuehrung.jpg";
import belton_free_Produkteinfuehrung_dosen from "../../../assets/images/Referenzen/Kwasny/Belton/belton_free_Produkteinfuehrung_dosen.jpg";
import belton_social_media from "../../../assets/images/Referenzen/Kwasny/Belton/belton_social_media.jpg";
import belton_social_media_youtube from "../../../assets/images/Referenzen/Kwasny/Belton/belton_social_media_youtube.jpg";
import belton_mobile_social_media_diy_box from "../../../assets/images/Referenzen/Kwasny/Belton/belton_mobile_social_media_diy_box.jpg";
import Kwasny_Referenzen_Mood_belton_Produkteinfuehrung from "../../../assets/images/Referenzen/Kwasny/Belton/Kwasny_Referenzen_Mood_belton_Produkteinfuehrung.jpg";
import belton_DIY_Referenzen_Mood_Bienen from "../../../assets/images/Referenzen/Kwasny/Belton/belton-DIY_Referenzen_Mood_Bienen_rechts.jpg";
import belton_Bienenaktion_Regale from "../../../assets/images/Referenzen/Kwasny/Belton/belton_Referenzen_Bienenaktion_Regal.jpg";
import A_Z_Video_Musik from "../../../assets/images/Referenzen/Kwasny/Belton/videos/A-Z_Video_Musik.mp4";
import Wintervideo from "../../../assets/images/Referenzen/Kwasny/Belton/videos/Wintervideo.mp4";
import Herbst_Video_Final from "../../../assets/images/Referenzen/Kwasny/Belton/videos/Herbst_Video_Final.mp4";
import Ostervideo from "../../../assets/images/Referenzen/Kwasny/Belton/videos/DIY_Ostervideo_Final.mp4";
import igposts_mobile from "../../../assets/images/Referenzen/Kwasny/Belton/igposts_mobile.jpg";
import belton_bienen_web from "../../../assets/images/Referenzen/Kwasny/Belton/belton_Referenzen_Bienenaktion_Laptop.jpg";
import belton_Referenzen_Bienenaktion_flyer from "../../../assets/images/Referenzen/Kwasny/Belton/belton_Referenzen_Bienenaktion_flyer.jpg";
import belton_Referenzen_Biene_mit_Samentuetchen from "../../../assets/images/Referenzen/Kwasny/Belton/belton_Referenzen_Biene_mit_Samentuetchen.jpg";

export default class ReferenzContent extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section
          id="section-spraymax-1"
          className="section--content section--padding"
        >
          <div className="section__content columns infinite__container">
            <div className="column column--12">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <img
                    ref="image"
                    src={Belton_Referenzen_Mood_belton_Relaunch_1}
                    className="teaser__image-left"
                    alt="belton Bildkonzept"
                  />
                </div>
              </div>
            </div>
            <div className="column column--12">
              <div className="teaser__text--intro padding">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--belton">
                    BELTON MARKENRELAUNCH
                  </p>
                  <h3 className="teaser__text-title">
                    Bring Farbe in dein Leben
                  </h3>
                  <div>
                    <p className="teaser__text-details">
                      Vom Handwerker, über den Heimwerker hin zum Deko- und
                      Bastelfan: die belton Marken-Familie hält mit dem breiten
                      Produktsortiment für jeden Anwender eine passende Lösung
                      bereit. Das spiegelt nun auch die neue Marke wider.
                      <br />
                      <br />
                      DAS ACHTE GEBOT unterstützte beim Marken-Refresh
                      strategisch, konzeptionell, grafisch und textlich und
                      setzt den Anwender klar in den Fokus der Kommunikation. So
                      findet sich jeder schnell zurecht!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Belton Dosen Range */}

          <div className="section__content columns container--padding">
            <div className="section__content columns">
              <div className="column column--12">
                <img
                  ref="image"
                  src={Belton_Dosenrange}
                  style={{ width: "100%", height: "100%" }}
                  alt="Belton"
                />
              </div>
            </div>
          </div>

          {/* Broschuere Content */}

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="teaser__text">
                    <div className="teaser__text-content">
                      <h5 className="teaser__text-title">Produktgestaltung</h5>
                      <div>
                        <p className="teaser__text-details">
                          Klare Unterscheidbarkeit der Produktpositionierung
                          kombiniert mit einem eindeutigen
                          Dachmarken-Wiedererkennungswert.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="section__content columns">
              <div className="column column--12">
                <img
                  ref="image"
                  src={belton_Titel_Katalog_A4_hoch}
                  style={{ width: "100%", height: "100%" }}
                  alt="Belton"
                />
              </div>
            </div>
          </div>
          <div className="section__content columns container--padding">
            <div className="section__content columns">
              <div className="column column--12">
                <img
                  ref="image"
                  src={belton_Doppels_Katalog_A4_hoch_1}
                  style={{ width: "100%", height: "100%" }}
                  alt="Belton"
                />
              </div>
            </div>
          </div>
          <div className="section__content columns container--padding">
            <div className="section__content columns">
              <div className="column column--12">
                <img
                  ref="image"
                  src={belton_Doppels_Katalog_A4_hoch_2}
                  style={{ width: "100%", height: "100%" }}
                  alt="Belton"
                />
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--8"></div>
            <div className="column column--4">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="teaser__text">
                    <div className="teaser__text-content">
                      <h5 className="teaser__text-title">Katalog</h5>
                      <div>
                        <p className="teaser__text-details">
                          Das breite Produktsortiment der belton Marken- Familie
                          in einem Katalog. Neben der übersichtlichen
                          Aufschlüsselung aller Produkte, standen die
                          Produktmarken und ihre Vorteile für den Verbraucher im
                          Fokus.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="section__content columns">
              <div className="column column--12">
                <img
                  ref="image"
                  src={belton_Doppels_Katalog_A4_hoch_3}
                  style={{ width: "100%", height: "100%" }}
                  alt="Belton"
                />
              </div>
            </div>
          </div>
          <div className="section__content columns container--padding">
            <div className="section__content columns">
              <div className="column column--12">
                <img
                  ref="image"
                  src={belton_Doppels_Katalog_A4_hoch_4}
                  style={{ width: "100%", height: "100%" }}
                  alt="Belton"
                />
              </div>
            </div>
          </div>

          {/* Web Content */}
          {/* TODO: Web Grafik tauschen */}

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="teaser__text">
                    <div className="teaser__text-content">
                      <h5 className="teaser__text-title">Website</h5>
                      <div>
                        <p className="teaser__text-details">
                          Hier findet der belton Lackspraynutzer alle
                          Informationen zu Produkten, praktische Tipps fürs
                          Lackieren und Inspiration für neue DIY-Projekte.
                          Konzeption, Design und Inhalte für den Website-
                          Relaunch kommen von DAS ACHTE GEBOT.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="section__content columns">
              <div className="column column--12">
                <img
                  ref="image"
                  src={belton_web}
                  style={{ width: "100%", height: "100%" }}
                  alt="Belton"
                />
              </div>
            </div>
          </div>

          <div className="section__content columns infinite__container">
            <div className="column column--12">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <img
                    ref="image"
                    src={belton_DIY_Referenzen_Mood}
                    className="teaser__image-right"
                    alt="belton Bildkonzept"
                  />
                </div>
              </div>
            </div>
            <div className="column column--12">
              <div className="teaser__text--intro padding">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--belton">
                    CONTENT MARKETING UND SOCIAL MEDIA{" "}
                  </p>
                  <h3 className="teaser__text-title">
                    Grenzenlose Kreativität
                  </h3>
                  <div>
                    <p className="teaser__text-details">
                      Unter dem Motto „Bring Farbe in dein Leben!“ inspiriert
                      die Marke belton ihre Kunden und Follower mit regelmäßig
                      neuen Do-it-Yourself Content auf der eigenen Website und
                      Social Media. DAS ACHTE GEBOT entwickelte die Idee und
                      Strategie, aber auch den gesamten Content, einschließlich
                      der Umsetzung der Projekte, Fotografie, Film und Text, der
                      wöchentlich veröffentlichten Inhalte.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* TODO: belton DIY Bilder fehlen */}

          <div style={{ paddingTop: "2rem" }}>
            <div className="section__content columns container--padding">
              <div className="column column--12">
                <div className="section__image-content">
                  <div className="section__image-content-wrapper">
                    <div className="teaser__text">
                      <div className="teaser__text-content">
                        <h5 className="teaser__text-title">
                          Content Marketing
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column column--12">
                <img
                  ref="image"
                  src={igposts_mobile}
                  style={{ width: "100%" }}
                  alt="Variation an DIY Instagram Posts"
                />
              </div>
            </div>
          </div>

          <div className="padding-small">
            <div className="section__content columns container--padding">
              <div className="column column--12">
                <video
                  id="BeltonAZVideo"
                  autostart="true"
                  playsInline
                  autoPlay
                  muted
                  loop
                  type="video/mp4"
                  src={A_Z_Video_Musik}
                  style={{
                    width: "100%",
                    height: "auto",
                  }}
                />
              </div>
              <div className="column column--12">
                <video
                  id="BeltonWinterVideo"
                  autostart="true"
                  playsInline
                  autoPlay
                  muted
                  loop
                  type="video/mp4"
                  src={Wintervideo}
                  style={{
                    width: "100%",
                    height: "auto",
                  }}
                />
              </div>
            </div>
          </div>
          <div>
            <div className="section__content columns container--padding">
              <div className="column column--12">
                <video
                  id="BeltonHerbstVideo"
                  autostart="true"
                  playsInline
                  autoPlay
                  muted
                  loop
                  type="video/mp4"
                  src={Herbst_Video_Final}
                  style={{
                    width: "100%",
                    height: "auto",
                  }}
                />
              </div>
              <div className="column column--12">
                <video
                  id="BeltonOsterVideo"
                  autostart="true"
                  playsInline
                  autoPlay
                  muted
                  loop
                  type="video/mp4"
                  src={Ostervideo}
                  style={{
                    width: "100%",
                    height: "auto",
                  }}
                />
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="teaser__text">
                    <div className="teaser__text-content">
                      <h5 className="teaser__text-title">Social Media</h5>
                      <div>
                        <p className="teaser__text-details">
                          Pinterest, Facebook, Instagram und YouTube - überall
                          wo sich die Zielgruppe der DIY-Begeisterten aufhält,
                          findet man auch die Marke belton und die
                          inspirierenden Projekt-Ideen.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="section__content columns">
              <div className="column column--12">
                <img
                  ref="image"
                  src={belton_social_media}
                  style={{ width: "100%", height: "100%" }}
                  alt="Belton"
                />
              </div>
            </div>
          </div>
          <div className="section__content columns container--padding">
            <div className="section__content columns">
              <div className="column column--12">
                <img
                  ref="image"
                  src={belton_social_media_youtube}
                  style={{ width: "100%", height: "100%" }}
                  alt="Belton"
                />
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="teaser__text">
                    <div className="teaser__text-content">
                      <h5 className="teaser__text-title">
                        Influencer Marketing
                      </h5>
                      <div>
                        <p className="teaser__text-details">
                          belton legt Wert auf ihre Community und deren Meinung.
                          Deshalb erhielten ausgewählte Nutzer vor der belton
                          free Produkteinführung ein Produkt-Test-Paket. Idee
                          und Durchführung der Aktion kam von DAS ACHTE GEBOT.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="section__content columns">
              <div className="column column--12">
                <img
                  ref="image"
                  src={belton_mobile_social_media_diy_box}
                  style={{ width: "100%", height: "100%" }}
                  alt="Belton"
                />
              </div>
            </div>
          </div>

          {/* belton free section NEW SECTION */}

          <div className="section__content columns infinite__container">
            <div className="column column--12">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <img
                    ref="image"
                    src={Kwasny_Referenzen_Mood_belton_Produkteinfuehrung}
                    className="teaser__image-left"
                    alt="belton Bildkonzept"
                  />
                </div>
              </div>
            </div>
            <div className="column column--12">
              <div className="teaser__text--intro padding">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--belton">
                    PRODUKTEINFÜHRUNG BELTON FREE
                  </p>
                  <h3 className="teaser__text-title">
                    Natürlich Sprühlackieren!
                  </h3>
                  <div>
                    <p className="teaser__text-details">
                      Ein ökologisches Lackspray entwickeln, das die gleichen
                      qualitativ hochwertigen Ergebnisse erzielt, wie
                      herkömmliche lösemittelhaltige Lacksprays - das war die
                      Mission. Mit belton free ist dies gelungen.
                      <br /> <br />
                      Zur Produkteinführung des umweltfreundlichen Lacksprays
                      unterstützte DAS ACHTE GEBOT strategisch und grafisch -
                      vom Produktfacing, über Keyvisual und Wording, bis hin zur
                      Umsetzung aller Medien Online, Print und am POS. Video
                      Alle Produktvorteile und Lackierhinweise praktisch
                      verpackt in einem Animationsvideo von DAS ACHTE GEBOT.
                      Medien POS, Online und Print - viele Kontaktpunkte für
                      potenzielle Kunden. Social Media Pinterest, Facebook,
                      Instagram und YouTube - überall wo sich die Zielgruppe der
                      DIY-Begeisterten aufhält, findet man auch die Marke belton
                      und die inspirierenden Projekt-Ideen. Content Marketing
                      Influencer Marketing belton legt Wert auf ihre Community
                      und deren Meinung. Deshalb erhielten ausgewählte Nutzer
                      vor der belton free Produkteinführung ein
                      Produkt-Test-Paket. Idee und Durchführung der Aktion kam
                      von DAS ACHTE GEBOT.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Dosen belton free Content */}

          <div className="section__content columns container--padding">
            <div className="section__content columns">
              <div className="column column--12">
                <img
                  ref="image"
                  src={belton_free_Produkteinfuehrung_dosen}
                  style={{ width: "100%", height: "100%" }}
                  alt="Belton"
                />
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="teaser__text">
                    <div className="teaser__text-content">
                      <h5 className="teaser__text-title">Video</h5>
                      <div>
                        <p className="teaser__text-details">
                          Alle Produktvorteile und Lackierhinweise praktisch
                          verpackt in einem Animationsvideo von DAS ACHTE GEBOT.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <video
                id="KwasnySpruehVideoAnimation"
                autostart="true"
                playsInline
                autoPlay
                muted
                loop
                type="video/mp4"
                src={Kwasny_Sprueh_Video}
                style={{
                  width: "100%",
                  height: "auto",
                }}
              />
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="teaser__text">
                    <div className="teaser__text-content">
                      <h5 className="teaser__text-title">Medien</h5>
                      <div>
                        <p className="teaser__text-details">
                          POS, Online und Print - viele Kontaktpunkte für
                          potenzielle Kunden.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="section__content columns">
              <div className="column column--12">
                <img
                  ref="image"
                  src={belton_free_Produkteinfuehrung}
                  style={{ width: "100%", height: "100%" }}
                  alt="Belton"
                />
              </div>
            </div>
          </div>

          {/* NEW SECTION Ein Herz für Bienen */}

          <div className="section__content columns infinite__container">
            <div className="column column--12">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <img
                    ref="image"
                    src={belton_DIY_Referenzen_Mood_Bienen}
                    className="teaser__image-right"
                    alt="belton Bildkonzept"
                  />
                </div>
              </div>
            </div>
            <div className="column column--12">
              <div className="teaser__text--intro padding">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--belton">
                    VERKAUFSAKTION
                  </p>
                  <h3 className="teaser__text-title">Ein Herz für Bienen</h3>
                  <div>
                    <p className="teaser__text-details">
                      Als Aktivierungskampagne für das umweltfreundliche
                      Lackspray belton free entwickelte DAS ACHTE GEBOT die
                      Verkaufsaktion „Ein Herz für Bienen“ inklusive aller
                      Medien. Beim Kauf eines belton free Lacksprays gab es eine
                      biologisch abbaubare Pflanzschale und Bienenwiesen-Samen
                      gratis dazu.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--8">
              <img
                ref="image"
                src={belton_Referenzen_Bienenaktion_flyer}
                style={{ width: "100%" }}
                alt="belton Bildkonzept"
              />
            </div>
            <div className="column column--4" style={{ paddingTop: "10%" }}>
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="teaser__text">
                    <div className="teaser__text-content">
                      <h5 className="teaser__text-title">Samentütchen</h5>
                      <div>
                        <p className="teaser__text-details">
                          Gestaltung der Samentütchen mit hohem Kampagnen-
                          Wiedererkennunswert.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <img
                ref="image"
                src={belton_Referenzen_Biene_mit_Samentuetchen}
                style={{ width: "100%" }}
                alt="belton Bildkonzept"
              />
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="teaser__text">
                    <div className="teaser__text-content">
                      <h5 className="teaser__text-title">Bestückung am POS</h5>
                      <div>
                        <p className="teaser__text-details">
                          Schilder, Wobbler und Flyer für eine hohe
                          Aufmerksamkeit am POS.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="section__content columns">
              <div className="column column--12">
                <img
                  ref="image"
                  src={belton_Bienenaktion_Regale}
                  style={{ width: "100%", height: "100%" }}
                  alt="Belton"
                />
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="teaser__text">
                    <div className="teaser__text-content">
                      <h5 className="teaser__text-title">Landingpage</h5>
                      <div>
                        <p className="teaser__text-details">
                          Alle Informationen auf einen Blick und schneller
                          Zugang zu den nötigen Funktionen.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="section__content columns">
              <div className="column column--12">
                <img
                  ref="image"
                  src={belton_bienen_web}
                  style={{ width: "100%", height: "100%" }}
                  alt="Belton"
                />
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
