import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../../components/FooterComponent/Footer";
import Header from "../../components/HeaderComponent/Header";
import JobComponentWebdev from "../../components/JobComponent/JobComponentWebdev";
import SectionImage from "../../components/SectionComponent/SectionImage";
import snippetWebdev from "../../assets/snippets/snippetWebdev.json";

const JobWebdev = () => {
  return (
    <React.Fragment>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(snippetWebdev)}
        </script>
      </Helmet>
      <Header />
      <SectionImage />
      <JobComponentWebdev />
      <Footer />
    </React.Fragment>
  );
};

export default JobWebdev;
