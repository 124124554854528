import React from "react";

export default class ContentPrivacy extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section
          id="section-conditions"
          className="section__content-intro section--content"
        >
          <div className="section__content columns container--padding-none">
            <div className="teaser__text column--6">
              <div className="teaser__text-content">
                <p className="teaser__text-subtitle">Das Achte Gebot</p>
                <h3 className="teaser__text-title">
                  Allgemeine <br />
                  Geschäftsbedingungen
                </h3>
              </div>
            </div>
            <div className="section__intro-content column column--6">
              <h5 className="margin">1. Geltungsbereich</h5>
              <p>
                1.1 Die nachstehenden allgemeinen Geschäftsbedingungen (AGB)
                gelten für alle Rechtsgeschäfte des Unternehmens „DAS ACHTE
                GEBOT Werbeagentur GmbH“ nachfolgend „Agentur“ genannt, mit
                ihren Vertragspartnern, nachstehend „Kunde“ genannt,
                insbesondere für Dienstleistungen und/oder Werke auf dem Gebiet
                Marketing und Werbung. Die Art der Dienstleistungen und Werke im
                Einzelnen ergibt sich aus der von der Agentur entwickelten
                Konzeption, dem Angebot bzw. den Einzelaufträgen.
              </p>
              <p>
                1.2 Diese AGB sind wesentlicher Bestandteil jedes
                abgeschlossenen Vertrages, soweit nicht im Einzelnen
                Abweichendes vereinbart ist. Sie gelten auch für die zukünftigen
                Geschäftsbeziehungen mit dem Kunden, ohne dass ihre erneute
                ausdrückliche Einbeziehung erforderlich ist. Sie werden vom
                Kunden in vollem Umfang in der zum Zeitpunkt des
                Geschäftsabschlusses geltenden Fassung akzeptiert.
              </p>
              <p>
                1.3 Entgegenstehende oder von diesen Geschäftsbedingungen
                abweichende, sowie solche Bedingungen des Kunden, die in diesen
                Geschäftsbedingungen nicht geregelt sind, erkennt die Agentur
                nicht an. Abweichende Geschäftsbedingungen des Kunden sowie
                Änderungen und Ergänzungen dieser AGB haben nur Gültigkeit,
                soweit sie von der Agentur schriftlich anerkannt sind. Dies gilt
                auch, wenn den Geschäfts- und/oder Lieferungsbedingungen des
                Kunden nicht ausdrücklich widersprochen worden ist.
              </p>

              <h5>2. Präsentation</h5>
              <p>
                2.1 Wird nach einer Präsentation kein Auftrag erteilt, so
                bleiben alle Leistungen, insbesondere die
                Präsentationsunterlagen und die darin enthaltenen Entwürfe,
                Werke, Ideen, Bilder, Texte etc. Eigentum der Agentur. Der Kunde
                ist nicht berechtigt, dieses Material gleich in welcher Form, zu
                nutzen, zu bearbeiten oder als Grundlage zur Herstellung eigenen
                Materials zu nutzen.
              </p>
              <p>
                2.2 Falls kein Auftrag erteilt wird, bleibt es der Agentur
                unbenommen, die präsentierten Ideen, Werke, Entwürfe etc. für
                andere Projekte und Kunden zu verwenden.
              </p>
              <p>
                2.3 Die Weitergabe von Präsentationsunterlagen und Angeboten an
                Dritte, sowie deren Veröffentlichung, Vervielfältigung,
                Verbreitung oder sonstige Nutzung durch den Kunden oder seiner
                Bevollmächtigten, verpflichten den Kunden zur Honorarzahlung in
                Höhe der betreffenden Leistung. Diese orientiert sich an dem
                Angebot der Agentur oder, sofern ein solches noch nicht
                vorliegt, an den marktüblichen Konditionen.
              </p>

              <h5>3. Leistungsumfang und Vergütung</h5>
              <p>
                3.1 Umfang und Inhalt der Leistungen ergeben sich jeweils aus
                den schriftlichen Angeboten der Agentur, es sei denn, der Kunde
                und die Agentur haben eine abweichende Vereinbarung getroffen.
              </p>
              <p>
                3.2 Zwischen den Parteien kommt ein Vertrag zustande, wenn der
                Kunde das Angebot mündlich, schriftlich oder schlüssig durch die
                Inanspruchnahme der Agentur-Leistungen annimmt.
              </p>
              <p>
                3.3 Soweit nichts anderes schriftlich vereinbart wurde, wird auf
                der Grundlage der Stundensätze der Agentur nach tatsächlichem
                Aufwand abgerechnet, oder wenn vorhanden nach dem vorliegenden
                Angebot.{" "}
              </p>
              <p>
                3.4 Die vereinbarte Vergütung versteht sich zzgl. der geltenden
                Mehrwertsteuer. Die Kosten für Verpackung, Fracht, Porto,
                Versicherungen usw. trägt – soweit nichts anderes schriftlich
                vereinbart wurde – der Kunde und werden gesondert in Rechnung
                gestellt.
              </p>
              <p>
                3.5 Rechnungen sind sofort ohne jeden Abzug zur Zahlung fällig.
              </p>
              <p>
                3.6 Die Agentur kann vom Kunden Abschlagszahlungen für erbrachte
                Teilleistungen verlangen, ohne dass diese Teilleistungen in
                einer für den Kunden nutzbaren Form vorliegen müssen.
              </p>
              <p>
                3.7 Bei Fremdleistungen ist die Agentur berechtigt, Vorschüsse
                von dem Kunden zu fordern.
              </p>
              <p>
                3.8 Die Aufrechnung mit Gegenansprüchen stehen dem Kunden nur
                zu, wenn seine Gegenansprüche rechtskräftig festgestellt,
                unbestritten oder von Agentur anerkannt sind. Zur Ausübung eines
                Zurückbehaltungsrechts ist der Kunde nur insoweit befugt, als
                sein Gegenanspruch auf dem gleichen Vertragsverhältnis beruht.
              </p>

              <h5>4. Kontaktberichte und Freigaben</h5>
              <p>
                4.1 Der Inhalt eines von der Agentur erstellten Kontaktberichts
                ist für die Vertragsparteien verbindlich, sofern ihm der
                Auftraggeber nicht binnen 7 Werktage nach Zugang schriftlich
                widerspricht.
              </p>
              <p>
                4.2 Sollte der Kunde mit der Agentur schriftlich vereinbart
                haben, dass im Rahmen der Vertragsdurchführung vor Ausführung
                die Erteilung der Freigabe durch den Kunden erforderlich ist,
                gilt die Freigabe spätestens 7 Werktage nach Zugang der Vorlage
                als erteilt, es sei denn, der Kunde hat dem Inhalt vorher
                ausdrücklich und schriftlich widersprochen.
              </p>
              <p>
                4.3 Die Agentur verpflichtet sich, den Kunden spätestens bei
                Zugang des Kontaktberichts/der Vorlage auf die o. g. Folge
                seines Schweigens (fingierte
                Einverständniserklärung/Freigabeerklärung) hinzuweisen.
              </p>

              <h5>5. Nutzungs- und Urheberrechte</h5>
              <p>
                5.1 Die Entwürfe und Reinzeichnungen dürfen ohne Einwilligung
                der Agentur weder im Original noch bei der Reproduktion
                verändert werden. Jede Nachahmung – auch von Teilen oder Details
                – ist unzulässig. Die Nutzung ist nur mit der Einwilligung der
                Agentur und nach Vereinbarung eines zusätzlichen
                Nutzungshonorars gestattet.
              </p>
              <p>
                5.2 An Entwürfen und Reinzeichnungen werden nur Nutzungsrechte
                eingeräumt, nicht jedoch Eigentumsrechte übertragen. Die Vorlage
                und/ oder Originale sind daher nach angemessener Frist
                unbeschädigt zurückzugeben, falls nicht ausdrücklich eine andere
                Vereinbarung getroffen wurde.
              </p>
              <p>
                5.3 Die Zusendung und etwaige Rücksendung der Arbeiten gehen auf
                Gefahr und für Rechnung des Auftraggebers.{" "}
              </p>
              <p>
                5.4 Mit der Zahlung der vereinbarten Vergütung überträgt die
                Agentur dem Kunden die für den jeweiligen Verwendungszweck
                erforderlichen Nutzungsrechte. Soweit nichts anderes vereinbart
                ist, wird nur ein einfaches Nutzungsrecht übertragen. Die
                Agentur bleibt in jedem Fall, auch wenn sie das ausschließliche
                Nutzungsrecht eingeräumt hat, berechtigt, ihre Entwürfe und
                seine Vervielfältigung davon im Rahmen der Eigenwerbung zu
                verwenden.
              </p>
              <p>
                5.5 Nutzungsrechte des Auftraggebers entstehen erst nach
                vollständiger Bezahlung der Vergütung.
              </p>
              <p>
                5.6 Wünscht der Kunde die Herausgabe offener, editierbarer Daten
                bedarf dies einer gesonderten schriftlichen Vereinbarung und ist
                gesondert zu vergüten. Die Agentur übernimmt nach Herausgabe der
                Daten keine Haftung. Eine Änderung der herausgegebenen Daten ist
                nur mit vorheriger Zustimmung der Agentur zulässig.
              </p>

              <h5>6. Termine</h5>
              <p>
                6.1 Die Einhaltung vereinbarter Termine und Fristen setzt die
                rechtzeitige und ordnungsgemäße Erfüllung aller
                Mitwirkungspflichten des Kunden voraus, insbesondere hat der
                Kunde alle Layouts und Entwürfe unverzüglich mündlich oder
                schriftlich freizugeben bzw. Einwendungen mitzuteilen.
              </p>

              <h5>7. Rücktritt/Kündigung</h5>
              <p>
                7.1 Kündigt der Kunde den Vertrag oder erklärt der Kunde den
                Rücktritt vom Vertrag, ist die Agentur berechtigt, die
                vereinbarte Vergütung zu verlangen; Die Agentur muss sich jedoch
                dasjenige anrechnen lassen, was sie infolge der Beendigung an
                Aufwendungen erspart oder durch anderweitige Verwendung der
                Arbeitskraft erwirbt oder zu erwerben böswillig unterlässt.
              </p>

              <h5>8. Haftung und Versand</h5>
              <p>
                8.1 Die Agentur haftet nach den gesetzlichen Bestimmungen für
                Schäden an Leben, Körper und Gesundheit, die auf einer
                fahrlässigen oder vorsätzlichen Pflichtverletzung der Agentur,
                ihrer gesetzlichen Vertreter oder ihrer Erfüllungsgehilfen
                beruhen, sowie für Schäden, die von der Haftung nach dem
                Produkthaftungsgesetz umfasst werden, sowie für alle Schäden,
                die auf vorsätzlichen oder grob fahrlässigen
                Vertragsverletzungen sowie Arglist der Agentur, ihrer
                gesetzlichen Vertreter oder ihrer Erfüllungsgehilfen beruhen.{" "}
              </p>
              <p>
                8.2 Soweit die Agentur bezüglich des Vertragsgegenstandes oder
                Teilen desselben eine Beschaffenheits- und/oder
                Haltbarkeitsgarantie abgegeben hat, haftet sie auch im Rahmen
                dieser Garantie. Für Schäden, die auf dem Fehlen der
                garantierten Beschaffenheit oder Haltbarkeit beruhen, aber nicht
                unmittelbar am Vertragsgegenstand eintreten, haftet die Agentur
                allerdings nur dann, wenn das Risiko eines solchen Schadens
                ersichtlich von der Beschaffenheits- und Haltbarkeitsgarantie
                erfasst ist.{" "}
              </p>
              <p>
                8.3 Die Agentur haftet auch für Schäden, die er durch einfache
                fahrlässige Verletzung solcher vertraglichen Verpflichtungen
                verursacht, deren Erfüllung die ordnungsgemäße Durchführung des
                Vertrages überhaupt erst ermöglicht und auf deren Einhaltung der
                Kunde regelmäßig vertraut und vertrauen darf. Das Gleiche gilt,
                wenn dem Kunden Ansprüche auf Schadensersatz statt der Leistung
                zustehen. Die Agentur haftet jedoch nur, soweit die Schäden in
                typischer Weise mit dem Vertrag verbunden und vorhersehbar sind.
              </p>
              <p>
                8.4 Eine weiter gehende Haftung der Agentur ist ohne Rücksicht
                auf die Rechtsnatur des geltend gemachten Anspruchs
                ausgeschlossen; dies gilt insbesondere auch für deliktische
                Ansprüche oder Ansprüche auf Ersatz vergeblicher Aufwendungen
                statt der Leistung.
              </p>
              <p>
                8.5 Soweit die Haftung des Verkäufers ausgeschlossen oder
                beschränkt ist, gilt dies auch für die persönliche Haftung
                seiner Angestellten, Arbeitnehmer, Mitarbeiter, Vertreter und
                Erfüllungsgehilfen.
              </p>
              <p>
                8.6 Der Versand von Unterlagen durch die Agentur erfolgt auf
                eigene Gefahr des Kunden.
              </p>
              <p>
                8.7 Die Agentur haftet nicht für von dem Kunden beigestellte
                Unterlagen, Daten, Informationen usw., insbesondere nicht dafür,
                dass diese frei von Schutzrechten sind. Die Agentur haftet auch
                nicht für die in der Werbung enthaltenen Sachaussagen über
                Produkte und Leistungen des Kunden. Der Kunde hat die Agentur
                von allen Ansprüchen Dritter wegen einer solchen
                Rechtsverletzung freizustellen und alle der Agentur durch eine
                solche schuldhafte Pflichtverletzung entstanden Schäden,
                Aufwendungen und Kosten zu ersetzen.
              </p>
              <p>
                8.8. Für Werbeaussagen hinsichtlich Leistungen und Produkten
                haftet der Kunde grundsätzlich selbst. Dies schließt auch Bilder
                und alle weiteren Medien ein. Die rechtliche Überprüfung erfolgt
                ausschließlich durch den Kunden. Mit erteilter Freigabe
                übernimmt der Kunde die Verantwortung für das Werbemedium. Dies
                betrifft etwaige Tipp- oder Rechtschreibfehler sowie Bild-,
                Text-und Toninhalte. Schadensersatzansprüche des Kunden
                jeglicher Art sind ausgeschlossen.{" "}
              </p>

              <h5>9. Eigentumsvorbehalt</h5>
              <p>
                9.1 Das Eigentum an gelieferten Gegenständen der Agentur -
                soweit Gegenstand des Vertrages - geht erst mit vollständiger
                Bezahlung auf den Kunden über.{" "}
              </p>

              <h5>10. Verwertungsgesellschaften und Künstlersozialabgabe</h5>
              <p>
                10.1 Der Kunde ist verpflichtet, etwaig bestehende Ansprüche von
                Urhebern oder Verwertungs-gesellschaften (z.B. Foto-, Film-,
                GEMA-Rechte) zu erfüllen. Werden diese Ansprüche von der Agentur
                erfüllt, hat der Kunde der Agentur die verauslagten Zahlungen zu
                ersetzen.
              </p>
              <p>
                10.2 Der Kunde ist darüber informiert, dass bei der
                Auftragsvergabe im künstlerischen, konzeptionellen und
                werbeberaterischen Bereich an eine nicht-juristische Person eine
                Künstlersozialabgabe an die Künstlersozialkasse zu leisten ist.
                Diese Abgabe darf vom Kunden nicht von der Agenturrechnung in
                Abzug gebracht werden.
              </p>

              <h5>11. Eigenwerbung und Urheberbenennung</h5>
              <p>
                11.1 Die Agentur darf die von ihr entwickelten Werbemittel
                angemessen und branchenüblich signieren und die Ergebnisse des
                erteilten Auftrages ganz oder teilweise für Eigenwerbung , auch
                nach Beendigung der Vertragszeit, unentgeltlich nutzen und bei
                Wettbewerben einsetzen.
              </p>

              <h5>12. Schlussbestimmungen</h5>
              <p>
                12.1 Für alle sich aus Lieferungen und Leistungen der Agentur
                ergebenden Rechte und Pflichten gilt für beide Teile der Sitz
                der Agentur als Erfüllungsort.
              </p>
              <p>
                12.2 Ansprüche aus dem Vertrag können ohne schriftliche
                Einwilligung der Agentur nicht abgetreten werden.{" "}
              </p>
              <p>
                12.3 Auf diesen Kaufvertrag findet ausschließlich das Recht der
                Bundesrepublik Deutschland Anwendung.{" "}
              </p>
              <p>
                12.4 Alle Änderungen und Ergänzungen dieser Bedingungen oder des
                zwischen der Agentur und dem Kunden geschlossenen Vertrages
                bedürfen zur Wirksamkeit der Schriftform. Die Vertragspartner
                genügen diesem Erfordernis auch durch Übersendung von Dokumenten
                in 6 Textform, insbesondere durch Fax oder E-Mail, soweit nicht
                für einzelne Erklärungen etwas anderes bestimmt ist. Die
                Schriftformabrede kann selbst nur schriftlich aufgehoben werden.
              </p>
              <p>
                12.5 Sollte einzelne Regelung dieser Bedingungen oder des
                zwischen der Agentur und dem Kunden geschlossenen Vertrages
                unwirksam oder undurchführbar sein oder werden, so berührt dies
                die Wirksamkeit Bedingungen/des Vertrages im Übrigen nicht. Die
                Parteien verpflichten sich vielmehr, in einem derartigen Fall
                eine wirksame und durchführbare Bestimmung an die Stelle der
                unwirksamen oder undurchführbaren Bestimmung zu setzen, die den
                wirtschaftlichen und ideellen Bestimmungen innerhalb der
                gesetzlichen Grenzen soweit wie möglich entspricht. Dasselbe
                gilt für Vertragslücken.
              </p>
              <p>
                12.6 Gerichtsstand für alle Streitigkeiten ist Sitz der Agentur,
                sofern der Kunde Kaufmann oder einem solchen gleichgestellt ist
                oder falls er seinen Sitz oder seine Niederlassung im Ausland
                hat.
              </p>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
