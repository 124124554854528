import React from "react";
import ScrollUp from "../components/ButtonComponent/ButtonScrollUp";
import Random from "../components/RandomComponent/Random";
import Header from "../components/HeaderComponent/Header";
import ContentImprint from "../components/ContentComponent/ContentImprint";
import Footer from "../components/FooterComponent/Footer";

class Impressum extends React.Component {
  componentDidMount() {
    document.title = "Impressum";
  }
  render() {
    return (
      <React.Fragment>
        <ScrollUp />
        <Random />
        <Header />
        <ContentImprint />
        <Footer />
      </React.Fragment>
    );
  }
}

export default Impressum;
