import React from "react";

import ReferenzAnimation from "../FlexComponent/ReferenzAnimation";

class ReferenzIntro extends React.Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { width } = this.state;
    const isMobile = width <= 812;

    if (isMobile) {
      return (
        <React.Fragment>
          <section id="section-flex">
            <div className="section__content columns container--padding">
              <div className="teaser__text column--6">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--flex">
                    FLEX-Elektrowerkzeuge GmbH
                  </p>
                  <h3 className="teaser__text-title">Marketing-Offensive</h3>
                </div>
              </div>
              <div className="section__intro-content column--6">
                <p className="teaser__text-details">
                  Wenn der Firmenname zum Gattungsbegriff wird, hat die Marke
                  alles richtig gemacht. Doch die Winkelschleifer sind nur ein
                  kleiner Ausschnitt aus dem Leistungsspektrum des Power
                  Tool-Herstellers, der besonders im Profi-Segment seine Stärken
                  ausspielt. FLEX Käufer sind häufig überzeugte und begeisterte
                  Fans der Marke und dürfen zu recht hohe Erwartungen stellen.
                  DAS ACHTE GEBOT betreut die Marke FLEX seit mehreren Jahren im
                  Bereich Kampagnen und Verkaufsförderung und setzt die Vorgaben
                  des strategischen Marketings in starke crossmediale Aktionen
                  um.
                </p>
              </div>
            </div>
          </section>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <section id="section-flex" className="section--intro">
            <div className="section__content columns container--padding">
              <div className="column column--4"></div>
              <div className="column column--4" style={{ marginTop: "-8%" }}>
                <ReferenzAnimation />
              </div>
              <div className="column column--4"></div>
            </div>

            <div className="section__content columns container">
              <div className="teaser__text column--6">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--flex">
                    FLEX-Elektrowerkzeuge GmbH
                  </p>
                  <h3 className="teaser__text-title">Marketing-Offensive</h3>
                </div>
              </div>
              <div className="section__intro-content column--6">
                <p className="teaser__text-details margin">
                  Wenn der Firmenname zum Gattungsbegriff wird, hat die Marke
                  alles richtig gemacht. Doch die Winkelschleifer sind nur ein
                  kleiner Ausschnitt aus dem Leistungsspektrum des Power
                  Tool-Herstellers, der besonders im Profi-Segment seine Stärken
                  ausspielt. FLEX Käufer sind häufig überzeugte und begeisterte
                  Fans der Marke und dürfen zu recht hohe Erwartungen stellen.
                  DAS ACHTE GEBOT betreut die Marke FLEX seit mehreren Jahren im
                  Bereich Kampagnen und Verkaufsförderung und setzt die Vorgaben
                  des strategischen Marketings in starke crossmediale Aktionen
                  um.
                </p>
              </div>
            </div>
          </section>
        </React.Fragment>
      );
    }
  }
}
export default ReferenzIntro;
