import React from "react";
import styled from "styled-components";

import ReferenzenDesktop from "./ReferenzenDesktop";
import ReferenzenMobile from "../screen/ReferenzenMobile";

class Referenzen extends React.Component {

  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
    };
  }

  componentDidMount() {
    document.title = "Auszug unserer Referenzen";
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { width } = this.state;
    const isMobile = width <= 812;

    const Scrolling = styled.div`
      overflow-y: scroll;
      margin-right: -30px;
      width: 100vw;
}
`;

    if (isMobile) {
      return <ReferenzenMobile />;
    } else {
      return (
        <Scrolling>
          <ReferenzenDesktop />
        </Scrolling>
      );
    }
  }
}
export default Referenzen;
