import React from "react";

import Kwasny_Titel_Imagebroschuere_A4_quer from "../../../assets/images/Referenzen/Kwasny/Kwasny/Kwasny_Titel_Imagebroschuere_A4_quer.jpg";
import Kwasny_Doppels_Imagebroschuere_A4_quer_1 from "../../../assets/images/Referenzen/Kwasny/Kwasny/Kwasny_Doppels_Imagebroschuere_A4_quer_1.jpg";
import Kwasny_Doppels_Imagebroschuere_A4_quer_2 from "../../../assets/images/Referenzen/Kwasny/Kwasny/Kwasny_Doppels_Imagebroschuere_A4_quer_2.jpg";
import Kwasny_Doppels_Imagebroschuere_A4_quer_3 from "../../../assets/images/Referenzen/Kwasny/Kwasny/Kwasny_Doppels_Imagebroschuere_A4_quer_3.jpg";
import Kwasny_Hauszeitung_Innen from "../../../assets/images/Referenzen/Kwasny/Kwasny/Kwasny_Hauszeitung_Innen.jpg";
import Kwasny_Broschueren_A4hoch_Unternehmensmarke_titel from "../../../assets/images/Referenzen/Kwasny/Kwasny/Kwasny_Broschueren_A4hoch_Unternehmensmarke_titel.jpg";
import Kwasny_Broschueren_A4hoch_Unternehmensmarke_doppels from "../../../assets/images/Referenzen/Kwasny/Kwasny/Kwasny_Broschueren_A4hoch_Unternehmensmarke_doppels.jpg";
import Kwasny_Verschiedenes from "../../../assets/images/Referenzen/Kwasny/Kwasny/Kwasny_Verschiedenes.jpg";
import Kwasny_Roll_Up_EmployerBranding from "../../../assets/images/Referenzen/Kwasny/Kwasny/Kwasny_Roll-Up_EmployerBranding.jpg";
import Kwasny_Referenzen_Mood_Purecolor from "../../../assets/images/Referenzen/Kwasny/Kwasny/Kwasny_Referenzen_Mood_Purecolor.jpg";
import Kwasny_Referenzen_Mood_Unternehmensmarke from "../../../assets/images/Referenzen/Kwasny/Kwasny/Kwasny_Referenzen_Mood_Unternehmensmarke.jpg";
import Kwasny_Titel_Buch_Jubilaeum_A4_quer_titel from "../../../assets/images/Referenzen/Kwasny/Kwasny/Kwasny_Titel_Buch_Jubilaeum_A4_quer.png";
import Kwasny_Innsenseiten_Buch_Jubilaeum_A4_quer_1 from "../../../assets/images/Referenzen/Kwasny/Kwasny/Kwasny_Innsenseiten_Buch_Jubilaeum_A4_quer_1.png";
import Kwasny_Innsenseiten_Buch_Jubilaeum_A4_quer_2 from "../../../assets/images/Referenzen/Kwasny/Kwasny/Kwasny_Innsenseiten_Buch_Jubilaeum_A4_quer_2.png";
import Kwasny_Innsenseiten_Buch_Jubilaeum_A4_quer_3 from "../../../assets/images/Referenzen/Kwasny/Kwasny/Kwasny_Innsenseiten_Buch_Jubilaeum_A4_quer_3.png";
import Kwasny_PureColor_Titel from "../../../assets/images/Referenzen/Kwasny/Kwasny/Kwasny_PureColor_Titel.jpg";
import Kwasny_PureColor_Innen_1 from "../../../assets/images/Referenzen/Kwasny/Kwasny/Kwasny_PureColor_Innen_1.jpg";
import Kwasny_PureColor_Innen_2 from "../../../assets/images/Referenzen/Kwasny/Kwasny/Kwasny_PureColor_Innen_2.jpg";
import Kwansy_Titel_Einladung_Messe_DinLang_quer_titel from "../../../assets/images/Referenzen/Kwasny/Kwasny/Kwansy_Titel_Einladung_Messe_DinLang_quer_titel.jpg";
import Kwansy_Innenseiten_Einladung_Messe_DinLang_quer from "../../../assets/images/Referenzen/Kwasny/Kwasny/Kwansy_Innenseiten_Einladung_Messe_DinLang_quer.jpg";
import Kwasny_Mockup_Hauszeitung from "../../../assets/images/Referenzen/Kwasny/Kwasny/Kwasny_Mockup_Hauszeitung.jpg";

export default class ReferenzContent extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section id="section-kwasny-1" className="section--content">
          <div className="section__content columns infinite__container">
            <div className="column column--6">
              <div className="teaser__text--intro padding">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--kwasny">
                    UNTERNHEMENSMARKE
                  </p>
                  <h3 className="teaser__text-title">
                    The World of Spraypaint
                  </h3>
                  <div>
                    <p className="teaser__text-details">
                      Als Dachmarke hinter drei der größten
                      Lackspray-Produktmarken steht die Peter Kwasny GmbH nicht
                      im Vordergrund - sondern meist auf der Rückseite des
                      Dosenetiketts. Umso wichtiger ist es, die
                      Unternehmensmarke für OEM-Kunden als Technologieführer und
                      für potenzielle Arbeitnehmer als guter Arbeitgeber zu
                      positionieren und zu stärken. <br /> <br />
                      DAS ACHTE GEBOT unterstützt dabei strategisch,
                      konzeptionell, grafisch, technisch und textlich bei der
                      Markenführung und der Umsetzung unterschiedlichster Medien
                      in Print und Online, aber auch am POS und auf der Messe.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="column column--6"></div>
                  <div className="column column--6">
                    <img
                      ref="image"
                      src={Kwasny_Referenzen_Mood_Unternehmensmarke}
                      className="teaser__image-right"
                      alt="Kwasny Referenz Einstieg Unternehmensmarke"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Section Image Broschüre */}

          <div className="section__content columns container--padding">
            <div className="column column--6">
              <div className="teaser__text" style={{ paddingTop: "30%" }}>
                <div className="teaser__text-content">
                  <h5 className="teaser__text-title">Imagebroschüre</h5>
                  <div>
                    <p className="teaser__text-details">
                      Das ganze Unternehmen in einer Broschüre zusammengefasst.
                      Persönlich, echt und ehrlich.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="column column--6"></div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--6">
              <img
                ref="image"
                src={Kwasny_Titel_Imagebroschuere_A4_quer}
                style={{ width: "100%" }}
                alt="kwasny Geschäftsausstattung"
              />
            </div>
            <div className="column column--6"></div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--4"></div>
            <div className="column column--8">
              <img
                ref="image"
                src={Kwasny_Doppels_Imagebroschuere_A4_quer_1}
                style={{ width: "100%" }}
                alt="kwasny Geschäftsausstattung"
              />
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--8">
              <img
                ref="image"
                src={Kwasny_Doppels_Imagebroschuere_A4_quer_2}
                style={{ width: "100%" }}
                alt="kwasny Geschäftsausstattung"
              />
            </div>
            <div className="column column--4"></div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--4"></div>
            <div className="column column--8">
              <img
                ref="image"
                src={Kwasny_Doppels_Imagebroschuere_A4_quer_3}
                style={{ width: "100%" }}
                alt="kwasny Geschäftsausstattung"
              />
            </div>
          </div>

          {/* Section Hauszeitung */}

          <div className="section__content columns container--padding">
            <div className="column column--6">
              <div className="teaser__text" style={{ paddingTop: "30%" }}>
                <div className="teaser__text-content">
                  <h5 className="teaser__text-title">Hauszeitung</h5>
                  <div>
                    <p className="teaser__text-details">
                      Aktuelles rund um die Peter Kwasny GmbH und die
                      Produktmarken. So werden Kunden, Dienstleister und
                      Arbeitnehmer regelmäßig über interessante Neuigkeiten des
                      Unternehmens informiert.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="column column--6"></div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--6">
              <img
                ref="image"
                src={Kwasny_Mockup_Hauszeitung}
                style={{ width: "100%" }}
                alt="kwasny Geschäftsausstattung"
              />
            </div>
            <div className="column column--6">
              <img
                ref="image"
                src={Kwasny_Hauszeitung_Innen}
                style={{ width: "100%", paddingTop: "15%" }}
                alt="kwasny Geschäftsausstattung"
              />
            </div>
          </div>

          {/* Section Anzeigen */}

          {/* TODO: Anzeigen fehlen */}

          <div
            className="section__content columns container--padding"
            style={{ paddingTop: "6rem" }}
          >
            <div className="column column--6">
              <img
                ref="image"
                src={Kwasny_Broschueren_A4hoch_Unternehmensmarke_titel}
                style={{ width: "100%" }}
                alt="kwasny Geschäftsausstattung"
              />
            </div>
            <div className="column column--6">
              <div className="teaser__text">
                <div className="teaser__text-content">
                  <h5 className="teaser__text-title">
                    Führungskräfte-Workshop
                  </h5>
                  <div>
                    <p className="teaser__text-details">
                      Zur Implementierung der Unternehmensleitwerte wurde ein
                      Führungskräfte-Workshop durchgeführt. DAS ACHTE GEBOT
                      unterstützte mit der Umsetzung der Print-Medien:
                      Broschüre, Rollups und Notizzettel.
                    </p>
                  </div>
                </div>
              </div>
              <img
                ref="image"
                src={Kwasny_Broschueren_A4hoch_Unternehmensmarke_doppels}
                style={{ width: "100%" }}
                alt="kwasny Geschäftsausstattung"
              />
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--6">
              <img
                ref="image"
                src={Kwasny_Verschiedenes}
                style={{ width: "100%" }}
                alt="kwasny Geschäftsausstattung"
              />
            </div>
            <div className="column column--6">
              <img
                ref="image"
                src={Kwasny_Roll_Up_EmployerBranding}
                style={{ width: "100%" }}
                alt="kwasny Geschäftsausstattung"
              />
            </div>
          </div>

          {/* Section Jubiläumsbuch */}

          <div className="section__content columns container--padding">
            <div className="column column--6">
              <img
                ref="image"
                src={Kwasny_Titel_Buch_Jubilaeum_A4_quer_titel}
                style={{ width: "100%" }}
                alt="kwasny Geschäftsausstattung"
              />
            </div>
            <div className="column column--6">
              <div className="teaser__text">
                <div className="teaser__text-content">
                  <h5 className="teaser__text-title">Jubiläumsbuch</h5>
                  <div>
                    <p className="teaser__text-details">
                      Luptatus sini ut lat. Ignimendae placerum enimilla
                      comnisim duciendae moluptate desersp
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--4"></div>
            <div className="column column--8">
              <img
                ref="image"
                src={Kwasny_Innsenseiten_Buch_Jubilaeum_A4_quer_1}
                style={{ width: "100%" }}
                alt="kwasny Geschäftsausstattung"
              />
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--8">
              <img
                ref="image"
                src={Kwasny_Innsenseiten_Buch_Jubilaeum_A4_quer_2}
                style={{ width: "100%" }}
                alt="kwasny Geschäftsausstattung"
              />
            </div>
            <div className="column column--4"></div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--4"></div>
            <div className="column column--8">
              <img
                ref="image"
                src={Kwasny_Innsenseiten_Buch_Jubilaeum_A4_quer_3}
                style={{ width: "100%" }}
                alt="kwasny Geschäftsausstattung"
              />
            </div>
          </div>

          <div
            className="section__content columns infinite__container"
            style={{
              marginTop: "0rem",
              zIndex: "-1",
              position: "relative",
              maxHeight: "1000px",
            }}
          >
            <div className="column column--12">
              <div className="section__image-content-wrapper">
                <div className="circle"></div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="section__content columns container-section">
            <div className="column column--6">
              <img
                ref="image"
                src={Kwasny_Referenzen_Mood_Purecolor}
                className="teaser__image-left"
                alt="kwasny Geschäftsausstattung"
              />
            </div>

            <div className="column column--6">
              <div className="teaser__text padding">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--flex">
                    PURECOLOR TECHNOLOGIE
                  </p>
                  <h3 className="teaser__text-title">
                    Entwicklung aus gutem Hause
                  </h3>
                  <p className="teaser__text-details">
                    Die Peter Kwasny GmbH entwickelt laufend neue Technologien
                    und Ideen, welche sowohl in den eigenen Produktmarken
                    umgesetzt, aber auch den OEMKunden angeboten werden. <br />{" "}
                    <br />
                    Purecolor ist die wasserbasierende Lackspray-Technologie,
                    die auf der Farbe 2019 zum ersten Mal vorgestellt wurde. DAS
                    ACHTE GEBOT setzte alle Printmedien um und gestaltete den
                    Messe-Stand mit.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Purecolor Broschüre */}

          <div
            className="section__content columns container--padding"
            style={{ paddingTop: "10rem" }}
          >
            <div className="column column--6">
              <img
                ref="image"
                src={Kwasny_PureColor_Titel}
                style={{ width: "100%" }}
                alt="kwasny Geschäftsausstattung"
              />
              <img
                ref="image"
                src={Kwasny_PureColor_Innen_2}
                style={{ width: "100%" }}
                alt="kwasny Geschäftsausstattung"
              />
            </div>
            <div className="column column--6">
              <div className="teaser__text" style={{ paddingTop: "30%" }}>
                <div className="teaser__text-content">
                  <h5 className="teaser__text-title">
                    Purecolour Broschüre Farbe 2019
                  </h5>
                  <div>
                    <p className="teaser__text-details">
                      Alle Informationen zur purecolor-Technologie
                      zusammengefasst in einer Broschüre für OEMKunden.
                    </p>
                  </div>
                </div>
              </div>
              <img
                ref="image"
                src={Kwasny_PureColor_Innen_1}
                style={{ width: "100%" }}
                alt="kwasny Geschäftsausstattung"
              />
            </div>
          </div>

          <div
            className="section__content columns container--padding"
            style={{ paddingTop: "10rem" }}
          >
            <div className="column column--6">
              <div className="teaser__text" style={{ paddingTop: "35%" }}>
                <div className="teaser__text-content">
                  <h5 className="teaser__text-title">
                    Purecolor Einladung Farbe 2019{" "}
                  </h5>
                  <div>
                    <p className="teaser__text-details">
                      Eine Einladung, die das Messestand-Motto deutlich macht.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="column column--6">
              <img
                ref="image"
                src={Kwansy_Titel_Einladung_Messe_DinLang_quer_titel}
                style={{ width: "100%" }}
                alt="kwasny Geschäftsausstattung"
              />
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <img
                ref="image"
                src={Kwansy_Innenseiten_Einladung_Messe_DinLang_quer}
                style={{ width: "100%" }}
                alt="kwasny Geschäftsausstattung"
              />
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <p>Messestand kommt hier her, fehlt noch</p>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
