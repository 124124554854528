import React from "react";

import ReferenzAnimation from "../KlingbeilComponent/ReferenzAnimation";

class ReferenzIntro extends React.Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { width } = this.state;
    const isMobile = width <= 812;

    if (isMobile) {
      return (
        <React.Fragment>
          <section id="section-klingbeil">
            <div className="section__content columns container--padding">
              <div className="teaser__text column--6">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--klingbeil">
                    Klingbeil GmbH
                  </p>
                  <h3 className="teaser__text-title">Generationswechsel</h3>
                </div>
              </div>
              <div className="section__intro-content column--6">
                <p className="teaser__text-details">
                  Klingbeil Klebetechnik ist ein etablierter Anbieter für
                technische Etiketten und Klebelösungen für die Industrie. Im
                Zuge der Unternehmensnachfolge durch die zweite Generation
                sollte die Marke zeitgemäß und leistungsaffin neu aufgestellt
                werden.
                </p>
              </div>
            </div>
          </section>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <section id="section-klingbeil" className="section--intro">
          <div className="section__content columns container--padding">
            <div className="column column--4"></div>
            <div className="column column--4" style={{ marginTop: "-8%" }}>
              <ReferenzAnimation />
            </div>
            <div className="column column--4"></div>
          </div>

          <div className="section__content columns container">
            <div className="teaser__text column--6">
              <div className="teaser__text-content">
                <p className="teaser__text-subtitle--klingbeil">
                  Klingbeil GmbH
                </p>
                <h3 className="teaser__text-title">Generationswechsel</h3>
              </div>
            </div>
            <div className="section__intro-content column--6">
              <p className="teaser__text-details margin">
                Klingbeil Klebetechnik ist ein etablierter Anbieter für
                technische Etiketten und Klebelösungen für die Industrie. Im
                Zuge der Unternehmensnachfolge durch die zweite Generation
                sollte die Marke zeitgemäß und leistungsaffin neu aufgestellt
                werden.
              </p>
            </div>
          </div>
        </section>
        </React.Fragment>
      );
    }
  }
}
export default ReferenzIntro;
