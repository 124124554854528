import React from "react";

import KWA_Referenzen_Mood_CDRel_SprayMax from "../../../assets/images/Referenzen/Kwasny/SprayMax/KWA_Referenzen_Mood_CDRel_SprayMax.jpg";
import SprayMax_Broschuere_Titel_A4 from "../../../assets/images/Referenzen/Kwasny/SprayMax/SprayMax_Broschuere_Titel_A4.jpg";
import SprayMax_Broschueren_A4 from "../../../assets/images/Referenzen/Kwasny/SprayMax/SprayMax_Broschueren_A4.jpg";
import SprayMax_Broschueren_A4_2 from "../../../assets/images/Referenzen/Kwasny/SprayMax/SprayMax_Broschueren_A4-2.jpg";
import SprayMax_Broschueren_A4_3 from "../../../assets/images/Referenzen/Kwasny/SprayMax/SprayMax_Broschueren_A4-3.jpg";
import SprayMax_Katalog_Titel_A4 from "../../../assets/images/Referenzen/Kwasny/SprayMax/SprayMax_Katalog_Titel_A4.jpg";
import SprayMax_Katalog_Doppels_A4 from "../../../assets/images/Referenzen/Kwasny/SprayMax/SprayMax_Katalog_Doppels_A4.jpg";
import SprayMax_Katalog_Doppels_A4_2 from "../../../assets/images/Referenzen/Kwasny/SprayMax/SprayMax_Katalog_Doppels_A4-2.jpg";
import SprayMax_Katalog_Doppels_A4_3 from "../../../assets/images/Referenzen/Kwasny/SprayMax/SprayMax_Katalog_Doppels_A4-3.jpg";
import SprayMax_Plakat from "../../../assets/images/Referenzen/Kwasny/SprayMax/SprayMax_Plakat.jpg";
import Kwasny_Referenzen_Mood_SprayMax from "../../../assets/images/Referenzen/Kwasny/SprayMax/Kwasny_Referenzen_Mood_SprayMax.jpg";
import KWA_Spot_Repair_PPT_Vorlage_bearb from "../../../assets/images/Referenzen/Kwasny/SprayMax/KWA_Spot_Repair_PPT_Vorlage_bearb.jpg";
import KWA_Spot_Repair_PPT_bearb from "../../../assets/images/Referenzen/Kwasny/SprayMax/KWA_Spot_Repair_PPT_bearb.jpg";
import SprayMax_Doppels_Brosch_Werkstatt_A4_hoch_2 from "../../../assets/images/Referenzen/Kwasny/SprayMax/SprayMax_Doppels_Brosch_Werkstatt_A4_hoch-2.jpg";
import SprayMax_Doppels_Brosch_Werkstatt_A4_hoch from "../../../assets/images/Referenzen/Kwasny/SprayMax/SprayMax_Doppels_Brosch_Werkstatt_A4_hoch.jpg";
import SprayMax_Titel_Brosch_Werkstatt_A4_hoch from "../../../assets/images/Referenzen/Kwasny/SprayMax/SprayMax_Titel_Brosch_Werkstatt_A4_hoch.jpg";
import SprayMax_Anzeigen_Haendler_neu from "../../../assets/images/Referenzen/Kwasny/SprayMax/SprayMax_Anzeigen_Haendler_neu.jpg";
import SprayMax_Messeeinladung_DinLang_quer_innen from "../../../assets/images/Referenzen/Kwasny/SprayMax/SprayMax_Messeeinladung_DinLang_quer_innen.jpg";
import SprayMax_Messeinladung_Mockup from "../../../assets/images/Referenzen/Kwasny/SprayMax/SprayMax_Messeinladung_Mockup.jpg";
import spraymax_messe_anzeige from "../../../assets/images/Referenzen/Kwasny/SprayMax/spraymax-messe-anzeige.jpg";
import Kwasny_SprayMax_2k_Ausloeseprozess_v2 from "../../../assets/images/Referenzen/Kwasny/SprayMax/Kwasny_SprayMax_2k_Ausloeseprozess_v2.mp4";
import spraymax_ppt_1 from "../../../assets/images/Referenzen/Kwasny/SprayMax/spraymax_ppt_1.jpg";
import spraymax_ppt_2 from "../../../assets/images/Referenzen/Kwasny/SprayMax/spraymax_ppt_2.jpg";
import spraymax_ppt_3 from "../../../assets/images/Referenzen/Kwasny/SprayMax/spraymax_ppt_3.jpg";
import spraymax_ppt_4 from "../../../assets/images/Referenzen/Kwasny/SprayMax/spraymax_ppt_4.jpg";

export default class ReferenzContent extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section
          id="section-spraymax-1"
          className="section--content section--padding"
        >
          <div className="section__content columns infinite__container">
            <div className="column column--12">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <img
                    ref="image"
                    src={KWA_Referenzen_Mood_CDRel_SprayMax}
                    className="teaser__image-right"
                    alt="Referenz Kwasny Spraymax"
                  />
                </div>
              </div>
            </div>
            <div className="column column--12">
              <div className="teaser__text--intro padding">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--kwasny">SPRAYMAX</p>
                  <h3 className="teaser__text-title">CD Relaunch</h3>
                  <div>
                    <p className="teaser__text-details">
                      Nach 25 Jahren Erfolgsgeschichte war die Zeit reif für
                      einen optischen und inhaltlichen Generationswechsel.
                      Während in den Anfangsjahren die technische
                      Vorteilsargumentation im Mittelpunkt der Kommunikation
                      stand, kann SprayMax heute souverän auf die
                      wissenschaftliche Beweisführung verzichten: Der Markt weiß
                      Bescheid und liebt die unkomplizierten Helfer aus der
                      Dose. Der CD Relaunch setzt deshalb auf plakative
                      Statements und klare Ansagen und spielt mit Oberflächen
                      und Farben. Aufmerksamkeitsstark und selbstbewusst.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Broschuere Content */}

          <div className="section__content columns container--padding">
            <div className="column column--8"></div>
            <div className="column column--4">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="teaser__text">
                    <div className="teaser__text-content">
                      <h5 className="teaser__text-title">Broschüren</h5>
                      <div>
                        <p className="teaser__text-details">
                          Für unterschiedliche Zielgruppen präsentiert SprayMax
                          Lösungen, Anwendungen und Erfolgsgeschichten direkt
                          aus der Praxis. Und auch die Technik, die in der Dose
                          steckt, wird einfach und plakativ erklärt
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="section__content columns">
              <div className="column column--12">
                <img
                  ref="image"
                  src={SprayMax_Broschuere_Titel_A4}
                  style={{ width: "100%", height: "100%" }}
                  alt="kwasny Geschäftsausstattung"
                />
              </div>
            </div>
          </div>
          <div className="section__content columns container--padding">
            <div className="section__content columns">
              <div className="column column--12">
                <img
                  ref="image"
                  src={SprayMax_Broschueren_A4_2}
                  style={{ width: "100%", height: "100%" }}
                  alt="kwasny Geschäftsausstattung"
                />
              </div>
            </div>
          </div>
          <div className="section__content columns container--padding">
            <div className="section__content columns">
              <div className="column column--12">
                <img
                  ref="image"
                  src={SprayMax_Broschueren_A4}
                  style={{ width: "100%", height: "100%" }}
                  alt="kwasny Geschäftsausstattung"
                />
              </div>
            </div>
          </div>
          <div className="section__content columns container--padding">
            <div className="section__content columns">
              <div className="column column--12">
                <img
                  ref="image"
                  src={SprayMax_Broschueren_A4_3}
                  style={{ width: "100%", height: "100%" }}
                  alt="kwasny Geschäftsausstattung"
                />
              </div>
            </div>
          </div>

          {/* Katalog Content */}

          <div className="section__content columns container--padding">
            <div className="column column--8"></div>
            <div className="column column--4">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="teaser__text">
                    <div className="teaser__text-content">
                      <h5 className="teaser__text-title">Katalog</h5>
                      <div>
                        <p className="teaser__text-details">
                          Struktur ist alles. Beim neuen Katalog weisen Farben
                          den Weg ins richtige Produktsegment.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="section__content columns">
              <div className="column column--12">
                <img
                  ref="image"
                  src={SprayMax_Katalog_Titel_A4}
                  style={{ width: "100%", height: "100%" }}
                  alt="kwasny Geschäftsausstattung"
                />
              </div>
            </div>
          </div>
          <div className="section__content columns container--padding">
            <div className="section__content columns">
              <div className="column column--12">
                <img
                  ref="image"
                  src={SprayMax_Katalog_Doppels_A4}
                  style={{ width: "100%", height: "100%" }}
                  alt="kwasny Geschäftsausstattung"
                />
              </div>
            </div>
          </div>
          <div className="section__content columns container--padding">
            <div className="section__content columns">
              <div className="column column--12">
                <img
                  ref="image"
                  src={SprayMax_Katalog_Doppels_A4_2}
                  style={{ width: "100%", height: "100%" }}
                  alt="kwasny Geschäftsausstattung"
                />
              </div>
            </div>
          </div>
          <div className="section__content columns container--padding">
            <div className="section__content columns">
              <div className="column column--12">
                <img
                  ref="image"
                  src={SprayMax_Katalog_Doppels_A4_3}
                  style={{ width: "100%", height: "100%" }}
                  alt="kwasny Geschäftsausstattung"
                />
              </div>
            </div>
          </div>

          {/* Plakat Content */}

          <div className="section__content columns container--padding">
            <div className="column column--8"></div>
            <div className="column column--4">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="teaser__text">
                    <div className="teaser__text-content">
                      <h5 className="teaser__text-title">Plakat</h5>
                      <div>
                        <p className="teaser__text-details">
                          Klare Ansage auch am POS
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="section__content columns">
              <div className="column column--12">
                <img
                  ref="image"
                  src={SprayMax_Plakat}
                  style={{ width: "100%", height: "100%" }}
                  alt="kwasny Geschäftsausstattung"
                />
              </div>
            </div>
          </div>
        </section>

        <section
          id="section-spraymax-1"
          className="section--content section--padding"
        >
          <div className="section__content columns infinite__container">
            <div className="column column--12">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <img
                    ref="image"
                    src={Kwasny_Referenzen_Mood_SprayMax}
                    className="teaser__image-left"
                    alt="kwasny Geschäftsausstattung"
                  />
                </div>
              </div>
            </div>
            <div className="column column--12">
              <div className="teaser__text--intro padding">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--kwasny">
                    VERTRIEBS-KAMPAGNE
                  </p>
                  <h3 className="teaser__text-title">Spot Repair</h3>
                  <div>
                    <p className="teaser__text-details">
                      Der Kfz-Markt ist im Wandel. Lackierereien und Werkstätten
                      müssen Verfahren verändern und verschlanken um
                      wettbewerbsfähig zu bleiben. In der Nische Spot Repair
                      kann SprayMax mit einem ganzheitlichen System punkten. Für
                      die neuen Messages hat DAS ACHTE GEBOT eine Kampagne
                      entwickelt, die dem Vertrieb die notwendigen Tools für ein
                      erfolgreiches Verkaufsgespräch an die Hand gibt.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Key Visuals */}
          <div className="section__content columns container--padding">
            <div className="column column--8"></div>
            <div className="column column--4">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="teaser__text">
                    <div className="teaser__text-content">
                      <h5 className="teaser__text-title">Key visuals</h5>
                      <div>
                        <p className="teaser__text-details">
                          Kampagnenmotive für Messe, Social Media und interne
                          Kommunikation
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="section__content columns">
              <div className="column column--12">
                <img
                  ref="image"
                  src={KWA_Spot_Repair_PPT_Vorlage_bearb}
                  style={{ width: "100%", height: "100%" }}
                  alt="kwasny Geschäftsausstattung"
                />
              </div>
            </div>
          </div>
          <div className="section__content columns container--padding">
            <div className="section__content columns">
              <div className="column column--12">
                <img
                  ref="image"
                  src={KWA_Spot_Repair_PPT_bearb}
                  style={{ width: "100%", height: "100%" }}
                  alt="kwasny Geschäftsausstattung"
                />
              </div>
            </div>
          </div>

          {/* TODO: PPT Slides einfügen */}

          <div
            className="section__content columns container--padding"
            style={{ marginTop: "10rem" }}
          >
            <div className="column column--12">
              <div className="teaser__text">
                <div className="teaser__text-content">
                  <h5 className="teaser__text-title">PowerPoint</h5>
                  <div>
                    <p className="teaser__text-details">
                      Eine strategische Präsentation hilft im Vertrieb die
                      notwendigen Botschaften im Gespräch zu platzieren.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <img
                ref="image"
                src={spraymax_ppt_1}
                style={{ width: "100%", border: "solid 1px #000" }}
                alt="kwasny Geschäftsausstattung"
              />
            </div>
            <div className="column column--12">
              <img
                ref="image"
                src={spraymax_ppt_2}
                style={{ width: "100%", border: "solid 1px #000" }}
                alt="kwasny Geschäftsausstattung"
              />
            </div>
          </div>
          <div className="section__content columns container--padding">
            <div className="column column--12">
              <img
                ref="image"
                src={spraymax_ppt_3}
                style={{ width: "100%", border: "solid 1px #000" }}
                alt="kwasny Geschäftsausstattung"
              />
            </div>
            <div className="column column--12">
              <img
                ref="image"
                src={spraymax_ppt_4}
                style={{ width: "100%", border: "solid 1px #000" }}
                alt="kwasny Geschäftsausstattung"
              />
            </div>
          </div>

          {/* Broschüre Slides */}

          <div className="section__content columns container--padding">
            <div className="column column--8"></div>
            <div className="column column--4">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="teaser__text">
                    <div className="teaser__text-content">
                      <h5 className="teaser__text-title">Broschüren</h5>
                      <div>
                        <p className="teaser__text-details">
                          Hand-outs für Messe und Verkaufsgespräch
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="section__content columns">
              <div className="column column--12">
                <img
                  ref="image"
                  src={SprayMax_Titel_Brosch_Werkstatt_A4_hoch}
                  style={{ width: "100%", height: "100%" }}
                  alt="kwasny Geschäftsausstattung"
                />
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="section__content columns">
              <div className="column column--12">
                <img
                  ref="image"
                  src={SprayMax_Doppels_Brosch_Werkstatt_A4_hoch}
                  style={{ width: "100%", height: "100%" }}
                  alt="kwasny Geschäftsausstattung"
                />
              </div>
            </div>
          </div>
          <div className="section__content columns container--padding">
            <div className="section__content columns">
              <div className="column column--12">
                <img
                  ref="image"
                  src={SprayMax_Doppels_Brosch_Werkstatt_A4_hoch_2}
                  style={{ width: "100%", height: "100%" }}
                  alt="kwasny Geschäftsausstattung"
                />
              </div>
            </div>
          </div>

          {/* Messe Content */}

          <div className="section__content columns container--padding">
            <div className="column column--8"></div>
            <div className="column column--4">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="teaser__text">
                    <div className="teaser__text-content">
                      <h5 className="teaser__text-title">Messe</h5>
                      <div>
                        <p className="teaser__text-details">
                          Messekommunikation 360° von der Einladung über die
                          Standgestaltung bis hin zu Fachartikeln und Anzeigen –
                          an allen Touchpoints konnten Impulse gesetzt werden.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="section__content columns">
              <div className="column column--12">
                <img
                  ref="image"
                  src={SprayMax_Anzeigen_Haendler_neu}
                  style={{ width: "100%", height: "100%" }}
                  alt="SprayMax Anzeige für Händler"
                />
              </div>
            </div>
          </div>
          <div className="section__content columns container--padding">
            <div className="section__content columns">
              <div className="column column--12">
                <img
                  ref="image"
                  src={SprayMax_Messeinladung_Mockup}
                  style={{ width: "100%", height: "100%" }}
                  alt="SprayMax Messeeinladung"
                />
              </div>
            </div>
          </div>
          <div className="section__content columns container--padding">
            <div className="section__content columns">
              <div className="column column--12">
                <img
                  ref="image"
                  src={spraymax_messe_anzeige}
                  style={{ width: "100%", height: "100%" }}
                  alt="SprayMax Messe Anzeige"
                />
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="section__content columns">
              <div className="column column--12">
                <img
                  ref="image"
                  src={SprayMax_Messeeinladung_DinLang_quer_innen}
                  style={{ width: "100%", height: "100%" }}
                  alt="kwasny Geschäftsausstattung"
                />
              </div>
            </div>
          </div>

          <div className="padding-small">
            <div className="section__content columns container--padding">
              <div className="column column--12">
                <video
                  id="KwasnySpruehVideoAnimation"
                  autostart="true"
                  playsInline
                  autoPlay
                  muted
                  loop
                  type="video/mp4"
                  src={Kwasny_SprayMax_2k_Ausloeseprozess_v2}
                  style={{
                    width: "100%",
                    height: "auto",
                  }}
                />
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
