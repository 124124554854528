import React from "react";

export default class ButtonScrollUpMobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_visible: false
    };
  }

  componentDidMount() {
    var scrollComponent = this;
    document.addEventListener("scroll", function(e) {
      scrollComponent.toggleVisibility();
    });
  }

  toggleVisibility() {
    if (window.pageXOffset > 374 ) {
      this.setState({
        is_visible: true
      });
    } else {
      this.setState({
        is_visible: false
      });
    }
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  render() {
    return (
      <div className="scroll-to-top-mobile">
          <div onClick={() => this.scrollToTop()}>
            <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
              <style
                type="text/css"
                dangerouslySetInnerHTML={{
                  __html: "\n .button {\n fill: #AC1E1C;\n }\n "
                }}
              />
              <path
                className="button"
                d="M12,0C5.6,0,0,5.6,0,12c0,6.4,5.6,12,12,12c6.4,0,12-5.6,12-12C24,5.6,18.4,0,12,0z M16.8,11.2
	c-0.2,0.2-0.5,0.2-0.8,0l-3.5-3.5v9.9c0,0.3-0.2,0.5-0.5,0.5s-0.5-0.2-0.5-0.5V7.6l-3.5,3.5c-0.2,0.2-0.5,0.2-0.8,0
	c-0.2-0.2-0.2-0.5,0-0.8L11.6,6c0,0,0,0,0,0c0,0,0.1-0.1,0.2-0.1l0,0c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0
	c0.1,0,0.1,0.1,0.2,0.1l4.4,4.4C17,10.6,17,10.9,16.8,11.2z"
              />
            </svg>
          </div>
      </div>
    );
  }
}
