import React from "react";
import { HashLink as Link } from "react-router-hash-link";

export default class ReferenzImage extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="full-bg-size-component-swipe referenz__image--meva"></div>
        <div className="scroll-down-arrow-wrapper">
          <Link to="#section-meva">
            <div className="scroll-down-arrow"></div>
          </Link>
        </div>
      </React.Fragment>
    );
  }
}
