import React from "react";
import { HashLink as Link } from "react-router-hash-link";

export default class Referenzdetail extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section id="section-detail">
          <div className="section__content columns container infinite-container">
            <div className="column column--4">
              <nav className="menu menu--campaigns">
                <div className="menu__item-container">
                  <div className="menu__item-work" href="/">
                    <span className="menu__item-name">Kunde</span>
                  </div>
                </div>
                <div className="menu__item-container">
                  <span className="menu__item-name">
                    FLEX-Elektrowerkzeuge GmbH
                  </span>
                </div>
              </nav>
            </div>
            <div className="column column--4">
              <nav className="menu menu--campaigns">
                <div className="menu__item-container">
                  <div className="menu__item-work">
                    <span className="menu__item-name">Aufgaben</span>
                  </div>
                </div>
                <div className="menu__item-container">
                  <span className="menu__item-name">
                    Einführungs- und Aktivierungs-kampagnen, Verkaufsbroschüren,
                    Händler-Aktionen, Messe-Konzepte, Content-Entwicklung für
                    Web und Social, Mediaplanung und -abwicklung für Digital und
                    Print
                  </span>
                </div>
              </nav>
            </div>
            <div className="column column--4">
              <nav className="menu menu--campaigns">
                <div className="menu__item-container">
                  <div className="menu__item-work">
                    <span className="menu__item-name">Kampagnen</span>
                  </div>
                </div>
                <div className="menu__item-container">
                  <Link to="#section-flex-new">
                    <div className="menu__item">
                      <span className="menu__item-name--flex">
                        100 Jahre Flex
                      </span>
                    </div>
                  </Link>
                </div>
                <div className="menu__item-container">
                  <Link to="#section-flex-1">
                    <div className="menu__item">
                      <span className="menu__item-name--flex">
                        FLEX lässt alle glänzen
                      </span>
                    </div>
                  </Link>
                </div>
                <div className="menu__item-container">
                  <Link to="#section-flex-2">
                    <div className="menu__item">
                      <span className="menu__item-name--flex">
                        Nimm Dir die Freiheit
                      </span>
                    </div>
                  </Link>
                </div>
                <div className="menu__item-container">
                  <Link to="#section-flex-3">
                    <div className="menu__item">
                      <span className="menu__item-name--flex">
                        Gesamtkatalog 2019/2020
                      </span>
                    </div>
                  </Link>
                </div>
                <div className="menu__item-container">
                  <Link to="#section-flex-4">
                    <div className="menu__item">
                      <span className="menu__item-name--flex">
                        More FLEXTECY
                      </span>
                    </div>
                  </Link>
                </div>
                <div className="menu__item-container">
                  <Link to="#section-flex-5">
                    <div className="menu__item">
                      <span className="menu__item-name--flex">
                        Sicherheitssauger
                      </span>
                    </div>
                  </Link>
                </div>
                <div className="menu__item-container">
                  <Link to="#section-flex-6">
                    <div className="menu__item">
                      <span className="menu__item-name--flex">
                        Akku-Bohrschrauber DD4G
                      </span>
                    </div>
                  </Link>
                </div>
                <div className="menu__item-container">
                  <Link to="#section-flex-7">
                    <div className="menu__item">
                      <span className="menu__item-name--flex">
                        20 Jahre Giraffe
                      </span>
                    </div>
                  </Link>
                </div>
              </nav>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
