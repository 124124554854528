import React from "react";
import { NavLink } from "react-router-dom";
import Logo from "./Logo";
import Acht_Background from "../../assets/images/Acht-Background_Schatten.png";

export default class Header extends React.Component {
  componentDidMount() {
    const body = document.body;
    const triggerMenu = document.querySelector(".page-header .trigger-menu");
    document.querySelector(".page-header nav");
    document.querySelector(".page-header .menu");
    const scrollUp = "scroll-up";
    const scrollDown = "scroll-down";
    let lastScroll = 0;

    triggerMenu.addEventListener("click", () => {
      body.classList.toggle("menu-open");
    });

    window.addEventListener("scroll", () => {
      const currentScroll = window.pageYOffset;
      // console.log(currentScroll);
      // if (currentScroll === 0) {
      //   body.classList.remove(scrollUp);
      //   return;
      // }

      if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
        // down
        body.classList.remove(scrollUp);
        body.classList.add(scrollDown);
      } else if (
        currentScroll < lastScroll &&
        body.classList.contains(scrollDown)
      ) {
        // up
        body.classList.remove(scrollDown);
        body.classList.add(scrollUp);
      }
      lastScroll = currentScroll;
    });
  }

  render() {
    return (
      <>
        <header
          id="section-header"
          className="headroom show headroom--not-bottom headroom--pinned headroom--top page-header"
          style={{ display: "block" }}
        >
          <nav>
            <div
              className="trigger-menu-wrapper"
              style={{
                backgroundImage: `url(${Acht_Background})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                width: "100%",
                height: "auto",
                maxWidth: "850px",
                backgroundColor: "none",
                display: "flex"
              }}
            >
              <nav className="navigation--work">
                <ul>
                  <li>
                    <NavLink to="/referenzen">Kunden</NavLink>
                   
                  </li>
                  
                </ul>
              </nav>
              <NavLink to="/" style={{ display: "block" }} aria-label="home">
              <button className="trigger-menu" aria-label="trigger-menu">
                <Logo />
              </button>
              </NavLink>
              <nav className="navigation--about">
                <ul>
                  <li>
                    <NavLink to="/agentur">Agentur</NavLink>
                  </li>
                </ul>
              </nav>
            </div>
          </nav>
        </header>
      </>
    );
  }
}
