import React from "react";
import { HashLink as Link } from "react-router-hash-link";

export default class Referenzdetail extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section id="section-detail">
          <div className="section__content columns container infinite-container">
            <div className="column column--4">
              <nav className="menu menu--campaigns">
                <div className="menu__item-container">
                  <div className="menu__item-work" href="/">
                    <span className="menu__item-name">Kunde</span>
                  </div>
                </div>
                <div className="menu__item-container">
                  <span className="menu__item-name">
                    MEVA Schalungs-Systeme GmbH
                  </span>
                </div>
              </nav>
            </div>
            <div className="column column--4">
              <nav className="menu menu--campaigns">
                <div className="menu__item-container">
                  <div className="menu__item-work">
                    <span className="menu__item-name">Aufgaben</span>
                  </div>
                </div>
                <div className="menu__item-container">
                  <span className="menu__item-name">
                    Modernisierung des Corporate Designs mit stärkerem
                    Technologie-Fokus, Generierung von markenbildenden
                    Key-Visuals und visuelle Umsetzung des neuen Claims,
                    Neukonzeption aller Printmedien, Relaunch aller analogen und
                    digitalen Medien
                  </span>
                </div>
              </nav>
            </div>
            <div className="column column--4">
              <nav className="menu menu--campaigns">
                <div className="menu__item-container">
                  <div className="menu__item-work">
                    <span className="menu__item-name">Beispielkampagne</span>
                  </div>
                </div>
                <div className="menu__item-container">
                  <Link to="#section-meva-1">
                    <div className="menu__item">
                      <span className="menu__item-name--meva">
                        Markenrelaunch
                      </span>
                    </div>
                  </Link>
                </div>
              </nav>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
