import React from "react";
export default class Header extends React.Component {
  render() {
    return (
      <>
       <svg
            xmlns="http:www.w3.org/2000/svg"
            width={65}
            height={65}
            viewBox="0 0 57 57"
            >
            <style
            type="text/css"
            dangerouslySetInnerHTML={{
                __html: "\n .logo-svg {\n fill: #AC1E1C;\n }\n "
            }}
            />
            <g>
            <path
                className="logo-svg"
                d="M31.8,26.1c1.7-1.4,4.8-5.2,4.8-10.3c0-9.6-7.8-14.5-16.8-14.5C10.8,1.3,3,6.2,3,15.8c0,5.1,3.1,8.9,4.8,10.3
            c-4.8,2.9-7,7.7-7,12.4c0,9.5,7.5,16.9,19,16.9s19-7.4,19-16.9C38.8,33.8,36.6,29,31.8,26.1z M19.8,11.8c2.7,0,5,2.3,5,5
            s-2.3,5-5,5s-5-2.3-5-5C14.8,14.1,17.1,11.8,19.8,11.8z M19.8,44.1c-3.8,0-6.8-3-6.8-6.8s3-6.8,6.8-6.8s6.8,3,6.8,6.8
            C26.6,41.1,23.6,44.1,19.8,44.1z"
            />
            <path
                className="logo-svg"
                d="M49.5,39.3c3.2,0,5.1-2.7,5.1-5.7l1-30.8h-12l0.9,30.8C44.4,36.7,46.2,39.3,49.5,39.3z"
            />
            <path
                className="logo-svg"
                d="M49.5,42.5c-3.5,0-6.4,3-6.4,6.4c0,3.5,3,6.4,6.4,6.4s6.4-2.9,6.4-6.4C55.9,45.5,52.9,42.5,49.5,42.5z"
            />
            </g>
        </svg>
      </>
    );
  }
}