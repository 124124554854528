import React from "react";

import KLI_Referenzen_Mood_1260px_breit from "../../../assets/images/Referenzen/Klingbeil/Kampagne/KLI_Referenzen_Mood_1260px_breit.jpg";
import KLI_Geschaeftsausstattung_Briefb_Vordersseite from "../../../assets/images/Referenzen/Klingbeil/Kampagne/KLI_Geschaeftsausstattung_Briefb_Vordersseite.jpg";
import KLI_Geschaeftsausstattung_briefb_Rueckseite from "../../../assets/images/Referenzen/Klingbeil/Kampagne/KLI_Geschaeftsausstattung_briefb_Rueckseite.jpg";
import KLI_Geschaeftsausstattung_LogoundVK from "../../../assets/images/Referenzen/Klingbeil/Kampagne/KLI_Geschaeftsausstattung_Logo-undVK.jpg";
import KLI_Geschaeftsausstattung_Logo from "../../../assets/images/Referenzen/Klingbeil/Kampagne/KLI_Geschaeftsausstattung_Logo_mobile.jpg";
import KLI_Anzeigen_quer from "../../../assets/images/Referenzen/Klingbeil/Kampagne/KLI_Anzeigen_quer_mobile.jpg";
import KLI_Anzeigen_hoch from "../../../assets/images/Referenzen/Klingbeil/Kampagne/KLI_Anzeigen_hoch.jpg";
import KLI_Keyvisual_Formstanzteil from "../../../assets/images/Referenzen/Klingbeil/Kampagne/KLI-Keyvisual_Formstanzteil.jpg";
import KLI_Keyvisual_Fadenzaehler from "../../../assets/images/Referenzen/Klingbeil/Kampagne/KLI-Keyvisual_Fadenzaehler.jpg";
import KLI_Keyvisual_Zuschnitt from "../../../assets/images/Referenzen/Klingbeil/Kampagne/KLI-Keyvisual_Zuschnitt.jpg";
import Klingbeil_Mockup from "../../../assets/video/referenzen/klingbeil/Klingbeil.mp4";

export default class ReferenzContent extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section
          id="section-klingbeil-1"
          className="section--content section--padding"
        >
          <div className="section__content columns container-section">
            <div className="column column--6">
              <img
                ref="image"
                src={KLI_Referenzen_Mood_1260px_breit}
                className="teaser__image-left"
                alt="Klingbeil"
              />
            </div>

            <div className="column column--6">
              <div className="teaser__text padding">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--klingbeil">
                    Strategischer Relaunch
                  </p>
                  <h3 className="teaser__text-title">
                    Neue Bild- und Farbwelten
                  </h3>
                  <p className="teaser__text-details">
                    Klarer Schnitt statt Kompromisse war die Devise beim
                    Markenrelaunch der Firma Klingbeil. Auf einen
                    ergebnisoffenen Markenworkshop folgte eine Neuausrichtung
                    nach einem gemeinsam definierten Markenschema, welches einem
                    radikalen kommunikativen Neubeginn den Weg ebnete.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding-big">
            <div className="column column--6"></div>
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <h5 className="teaser__text-title">Logo</h5>
                  <p className="teaser__text-details">
                    Formgebung, Schnitt und Veredelung exakt nach Kundenwunsch
                    sind die Stärken der Klebetechnik-Produkte von Klingbeil.
                    Das neue Signet greift all diese Themen auf und präsentiert
                    diese in einem neuen Gesamtlogo, das sich farb- und
                    formmarkant auf alle Medien übertragen lässt. Das gewählte
                    Farbschema besetzt dabei selbstbewusst eine Lücke innerhalb
                    der Branche und verzichtet bewusst auf Anklänge an große
                    Klebstoffmarken.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--6">
              <img
                ref="image"
                src={KLI_Geschaeftsausstattung_Briefb_Vordersseite}
                style={{ width: "100%", height: "100%" }}
                alt="Klingbeil Geschäftsausstattung"
              />
            </div>
            <div className="column column--6">
              <img
                ref="image"
                src={KLI_Geschaeftsausstattung_briefb_Rueckseite}
                style={{ width: "100%", height: "100%" }}
                alt="Klingbeil Geschäftsausstattung"
              />
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--6">
              <img
                ref="image"
                src={KLI_Geschaeftsausstattung_LogoundVK}
                style={{ width: "100%", height: "100%" }}
                alt="Klingbeil Geschäftsausstattung"
              />
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--6">
              <img
                ref="image"
                src={KLI_Geschaeftsausstattung_Logo}
                style={{ width: "100%", height: "100%" }}
                alt="Klingbeil Geschäftsausstattung"
              />
            </div>
          </div>

          <div className="section__content columns container--padding-big">
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <h5 className="teaser__text-title">
                    Zielgruppenspezifische Anzeigen
                  </h5>
                  <p className="teaser__text-details">
                    Kennzeichnungen, Formstanzteile oder technische
                    Klebelösungen – so vielfältig wie die Anwendung von
                    Klebetechnik, so vielfältig sind die Zielgruppen und
                    Anforderungen. Das Anzeigenkonzept deckt alle Varianten ab.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <img
                    ref="image"
                    src={KLI_Anzeigen_quer}
                    style={{ width: "100%", height: "auto" }}
                    alt="Klingbeil Anzeigen"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <img
                    ref="image"
                    src={KLI_Anzeigen_hoch}
                    style={{ width: "100%", height: "100%" }}
                    alt="Klingbeil Anzeigen"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding-big">
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <h5 className="teaser__text-title">Key-Visuals</h5>
                  <p className="teaser__text-details">
                    Statt einem Sammelsurium an Referenzen und
                    Verarbeitungs-lösungen nutzt Klingbeil die Macht reduzierter
                    Bilder, um das Spektrum an Fertigungsmöglichkeiten
                    darzustellen. Die reduzierte Farbwelt sorgt dabei für hohe
                    Signifikanz.
                  </p>
                </div>
              </div>
            </div>
            <div className="column column--6"></div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--10">
              <img
                ref="image"
                src={KLI_Keyvisual_Formstanzteil}
                style={{ width: "100%", height: "100%" }}
                alt="Klingbeil Key-Visual"
              />
            </div>
            <div className="column column--2"></div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--2"></div>
            <div className="column column--10">
              <img
                ref="image"
                src={KLI_Keyvisual_Fadenzaehler}
                style={{ width: "100%", height: "100%" }}
                alt="Klingbeil Key-Visual"
              />
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--10">
              <img
                ref="image"
                src={KLI_Keyvisual_Zuschnitt}
                style={{ width: "100%", height: "100%" }}
                alt="Klingbeil Key-Visual"
              />
            </div>
            <div className="column column--2"></div>
          </div>

          <div className="section__content columns container--padding-big">
            <div className="column column--8"></div>
            <div className="column column--4">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <h5 className="teaser__text-title">Website</h5>
                  <p className="teaser__text-details">
                    Zur Vertriebsunterstützung, als Imageträger und als
                    Recruiting-Tool wurde die Unternehmenswebsite konzipiert.
                    Durch das Content Management System TYPO3 ist das
                    Marketing-Team von Klingbeil bei der Aktualisierung im
                    Alltag autark und kann neue Inhalte schnell und
                    unkompliziert publizieren.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <video
                id="FlexMockup"
                autostart="true"
                playsInline
                autoPlay
                muted
                loop
                type="video/mp4"
                src={Klingbeil_Mockup}
                style={{
                  width: "100%",
                  height: "auto"
                }}
              />
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
