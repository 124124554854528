import React from "react";
import { NavLink } from "react-router-dom";

class SectionSwitch extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section id="section-switch">
          <div className="section__switch columns container">
            <div className="column column--6">
              <nav className="menu menu__switch">
                <div className="menu__switch-item-container">
                  <p className="teaser__text-subtitle">
                    Schöne Referenzen gibt es hier
                  </p>
                </div>
                <div className="menu__switch-item-container-back">
                  <NavLink to="/referenzen" className="menu__item">
                    <span className="menu__switch-item-name">
                      <h3 className="teaser__text-title">Referenzen</h3>
                    </span>
                  </NavLink>
                </div>
              </nav>
            </div>

            <div className="column column--6">
              <nav className="menu menu__switch-next">
                <div className="menu__switch-item-container">
                  <p className="teaser__text-subtitle">
                    Zu den besten Kunden der Welt
                  </p>
                </div>
                <div className="menu__switch-item-container-next">
                  <NavLink to="/referenzen/kunden" className="menu__item">
                    <span className="menu__switch-item-name">
                      <h3 className="teaser__text-title">Kunden</h3>
                    </span>
                  </NavLink>
                </div>
              </nav>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default SectionSwitch;
