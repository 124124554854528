import React from "react";

import Belton_Referenzen_Mood_belton_Relaunch_1 from "../../../assets/images/Referenzen/Kwasny/Belton/belton_Referenzen_Mood_belton_Relaunch_1.jpg";
import Belton_Dosenrange from "../../../assets/images/Referenzen/Kwasny/Belton/belton_Dosenrange.jpg";
import belton_Titel_Katalog_A4_hoch from "../../../assets/images/Referenzen/Kwasny/Belton/belton_Titel_Katalog_A4_hoch.jpg";
import belton_Doppels_Katalog_A4_hoch_1 from "../../../assets/images/Referenzen/Kwasny/Belton/belton_Doppels_Katalog_A4_hoch_1.jpg";
import belton_Doppels_Katalog_A4_hoch_2 from "../../../assets/images/Referenzen/Kwasny/Belton/belton_Doppels_Katalog_A4_hoch_2.jpg";
import belton_Doppels_Katalog_A4_hoch_3 from "../../../assets/images/Referenzen/Kwasny/Belton/belton_Doppels_Katalog_A4_hoch_3.jpg";
import belton_Doppels_Katalog_A4_hoch_4 from "../../../assets/images/Referenzen/Kwasny/Belton/belton_Doppels_Katalog_A4_hoch_4.jpg";
import belton_web from "../../../assets/images/Referenzen/Kwasny/Belton/belton_web.jpg";
import belton_DIY_Referenzen_Mood from "../../../assets/images/Referenzen/Kwasny/Belton/belton-DIY_Referenzen_Mood.jpg";
import belton_social_media_left from "../../../assets/images/Referenzen/Kwasny/Belton/belton_social_media-left.jpg";
import belton_free_Produkteinfuehrung from "../../../assets/images/Referenzen/Kwasny/Belton/belton_free_Produkteinfuehrung.jpg";
import belton_free_Produkteinfuehrung_dosen from "../../../assets/images/Referenzen/Kwasny/Belton/belton_free_Produkteinfuehrung_dosen.jpg";
import belton_social_media_right from "../../../assets/images/Referenzen/Kwasny/Belton/belton_social_media-right.jpg";
import belton_social_media_youtube from "../../../assets/images/Referenzen/Kwasny/Belton/belton_social_media_youtube.jpg";
import belton_mobile_social_media_diy_box from "../../../assets/images/Referenzen/Kwasny/Belton/belton_mobile_social_media_diy_box.jpg";
import Kwasny_Referenzen_Mood_belton_Produkteinfuehrung from "../../../assets/images/Referenzen/Kwasny/Belton/Kwasny_Referenzen_Mood_belton_Produkteinfuehrung.jpg";
import belton_DIY_Referenzen_Mood_Bienen from "../../../assets/images/Referenzen/Kwasny/Belton/belton-DIY_Referenzen_Mood_Bienen_rechts.jpg";
import belton_Bienenaktion_Regale from "../../../assets/images/Referenzen/Kwasny/Belton/belton_Referenzen_Bienenaktion_Regal.jpg";
import A_Z_Video_Musik from "../../../assets/images/Referenzen/Kwasny/Belton/videos/A-Z_Video_Musik.mp4";
import Wintervideo from "../../../assets/images/Referenzen/Kwasny/Belton/videos/Wintervideo.mp4";
import Herbst_Video_Final from "../../../assets/images/Referenzen/Kwasny/Belton/videos/Herbst_Video_Final.mp4";
import Ostervideo from "../../../assets/images/Referenzen/Kwasny/Belton/videos/DIY_Ostervideo_Final.mp4";
import Kwasny_Sprueh_Video from "../../../assets/images/Referenzen/Kwasny/Belton/videos/Animation_wie_sprueht_man_richtig_DE.mp4";
import igpost1 from "../../../assets/images/Referenzen/Kwasny/Belton/igpost1.jpg";
import igpost2 from "../../../assets/images/Referenzen/Kwasny/Belton/igpost2.jpg";
import igpost3 from "../../../assets/images/Referenzen/Kwasny/Belton/igpost3.jpg";
import igpost4 from "../../../assets/images/Referenzen/Kwasny/Belton/igpost4.jpg";
import igpost5 from "../../../assets/images/Referenzen/Kwasny/Belton/igpost5.jpg";
import igpost6 from "../../../assets/images/Referenzen/Kwasny/Belton/igpost6.jpg";
import igpost7 from "../../../assets/images/Referenzen/Kwasny/Belton/igpost7.jpg";
import igpost8 from "../../../assets/images/Referenzen/Kwasny/Belton/igpost8.jpg";
import belton_bienen_web from "../../../assets/images/Referenzen/Kwasny/Belton/belton_Referenzen_Bienenaktion_Laptop.jpg";
import belton_Referenzen_Bienenaktion_flyer from "../../../assets/images/Referenzen/Kwasny/Belton/belton_Referenzen_Bienenaktion_flyer.jpg";
import belton_Referenzen_Biene_mit_Samentuetchen from "../../../assets/images/Referenzen/Kwasny/Belton/belton_Referenzen_Biene_mit_Samentuetchen.jpg";

export default class ReferenzContent extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section id="section-kwasny-1" className="section--content">
          <div className="section__content columns container-section">
            <div className="column column--6">
              <img
                ref="image"
                src={Belton_Referenzen_Mood_belton_Relaunch_1}
                className="teaser__image-left"
                alt="Belton Referenz Mood"
              />
            </div>

            <div className="column column--6">
              <div className="teaser__text padding">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--belton">
                    BELTON MARKENRELAUNCH
                  </p>
                  <h3 className="teaser__text-title">
                    Bring Farbe in dein Leben
                  </h3>
                  <p className="teaser__text-details">
                    Vom Handwerker, über den Heimwerker hin zum Deko- und
                    Bastelfan: die belton Marken-Familie hält mit dem breiten
                    Produktsortiment für jeden Anwender eine passende Lösung
                    bereit. Das spiegelt nun auch die neue Marke wider. DAS
                    ACHTE GEBOT unterstützte beim Marken-Refresh strategisch,
                    konzeptionell, grafisch und textlich und setzt den Anwender
                    klar in den Fokus der Kommunikation. So findet sich jeder
                    schnell zurecht!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="section__content columns container--padding"
            style={{ marginTop: "10rem" }}
          >
            <div className="column column--12">
              <img
                ref="image"
                src={Belton_Dosenrange}
                style={{ width: "100%" }}
                alt="Belton Referenz Dosenrange"
              />
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--4">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="teaser__text">
                    <div className="teaser__text-content">
                      <h5 className="teaser__text-title">Produktgestaltung</h5>
                      <div>
                        <p className="teaser__text-details">
                          Klare Unterscheidbarkeit der Produktpositionierung
                          kombiniert mit einem eindeutigen
                          Dachmarken-Wiedererkennungswert.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column column--8"></div>
          </div>

          <div className="padding-small">
            <div className="section__content columns container--padding">
              <div className="column column--6">
                <div className="teaser__text" style={{ paddingTop: "30%" }}>
                  <div className="teaser__text-content">
                    <h5 className="teaser__text-title">Katalog</h5>
                    <div>
                      <p className="teaser__text-details">
                        Das breite Produktsortiment der belton Marken- Familie
                        in einem Katalog. Neben der übersichtlichen
                        Aufschlüsselung aller Produkte, standen die
                        Produktmarken und ihre Vorteile für den Verbraucher im
                        Fokus.
                      </p>
                    </div>
                  </div>
                </div>
                <img
                  ref="image"
                  src={belton_Doppels_Katalog_A4_hoch_1}
                  style={{ width: "100%" }}
                  alt="Belton Referenz Katalog"
                />
                <img
                  ref="image2"
                  src={belton_Doppels_Katalog_A4_hoch_2}
                  style={{ width: "100%" }}
                  alt="Belton Referenz Katalog"
                />
              </div>
              <div className="column column--6">
                <img
                  ref="image"
                  src={belton_Titel_Katalog_A4_hoch}
                  style={{ width: "100%" }}
                  alt="Belton Referenz Katalog"
                />
                <img
                  ref="image"
                  src={belton_Doppels_Katalog_A4_hoch_3}
                  style={{ width: "100%" }}
                  alt="Belton Referenz Katalog"
                />
                <img
                  ref="image"
                  src={belton_Doppels_Katalog_A4_hoch_4}
                  style={{ width: "100%" }}
                  alt="Belton Referenz Katalog"
                />
              </div>
            </div>
          </div>
          <div className="padding-small">
            <div className="section__content columns container--padding">
              <div className="column column--4">
                <div className="section__image-content">
                  <div className="section__image-content-wrapper">
                    <div className="teaser__text">
                      <div className="teaser__text-content">
                        <h5 className="teaser__text-title">Website</h5>
                        <div>
                          <p className="teaser__text-details">
                            Hier findet der belton Lackspraynutzer alle
                            Informationen zu Produkten, praktische Tipps fürs
                            Lackieren und Inspiration für neue DIY-Projekte.
                            Konzeption, Design und Inhalte für den Website-
                            Relaunch kommen von DAS ACHTE GEBOT.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column column--8"></div>
            </div>
            {/* TODO: Replace web mockup */}
            <div className="section__content columns">
              <div className="column column--3"></div>
              <div className="column column--6">
                <div className="teaser__text">
                  <div className="teaser__text-content">
                    <div className="section__image-content">
                      <div className="section__image-content-wrapper">
                        <img
                          ref="image"
                          src={belton_web}
                          style={{
                            width: "100%",
                            height: "100%",
                            paddingBottom: "5rem",
                            paddingTop: "5rem",
                            marginTop: "5rem",
                          }}
                          alt="kwasny Logo"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column column--3"></div>
            </div>
          </div>

          <div className="section__content columns infinite__container">
            <div className="column column--6">
              <div className="teaser__text--intro padding">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--belton">
                    CONTENT MARKETING UND SOCIAL MEDIA
                  </p>
                  <h3 className="teaser__text-title">
                    Grenzenlose Kreativität
                  </h3>
                  <div>
                    <p className="teaser__text-details">
                      Unter dem Motto „Bring Farbe in dein Leben!“ inspiriert
                      die Marke belton ihre Kunden und Follower mit regelmäßig
                      neuen Do-it-Yourself Content auf der eigenen Website und
                      Social Media. <br />
                      <br />
                      DAS ACHTE GEBOT entwickelte die Idee und Strategie, aber
                      auch den gesamten Content, einschließlich der Umsetzung
                      der Projekte, Fotografie, Film und Text, der wöchentlich
                      veröffentlichten Inhalte.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="column column--6"></div>
                  <div className="column column--6">
                    <img
                      ref="image"
                      src={belton_DIY_Referenzen_Mood}
                      className="teaser__image-right"
                      alt="FV Mood"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="padding-small">
            <div className="section__content columns container--padding">
              <div className="column column--4">
                <div className="section__image-content">
                  <div className="section__image-content-wrapper">
                    <div className="teaser__text">
                      <div className="teaser__text-content">
                        <h5 className="teaser__text-title">
                          Content Marketing
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column column--8"></div>
            </div>
          </div>

          <div style={{ paddingTop: "2rem" }}>
            <div className="section__content columns container--padding">
              <div className="column column--3">
                <img
                  ref="image"
                  src={igpost1}
                  style={{ width: "100%" }}
                  alt="Belton Free Bilder Rahmen"
                />
              </div>
              <div className="column column--3">
                <img
                  ref="image"
                  src={igpost2}
                  style={{ width: "100%" }}
                  alt="Brillen Etui Bastelprojekt"
                />
              </div>
              <div className="column column--3">
                <img
                  ref="image"
                  src={igpost3}
                  style={{ width: "100%" }}
                  alt="Belton Special Gold Box"
                />
              </div>
              <div className="column column--3">
                <img
                  ref="image"
                  src={igpost4}
                  style={{ width: "100%" }}
                  alt="Adventskranz Bastelprojekt"
                />
              </div>
            </div>
          </div>
          <div style={{ paddingTop: "2rem" }}>
            <div className="section__content columns container--padding">
              <div className="column column--3">
                <img
                  ref="image"
                  src={igpost5}
                  style={{ width: "100%" }}
                  alt="Luftballon"
                />
              </div>
              <div className="column column--3">
                <img
                  ref="image"
                  src={igpost6}
                  style={{ width: "100%" }}
                  alt="Upcycling rostige Wage"
                />
              </div>
              <div className="column column--3">
                <img
                  ref="image"
                  src={igpost7}
                  style={{ width: "100%" }}
                  alt="Tonanhänger"
                />
              </div>
              <div className="column column--3">
                <img
                  ref="image"
                  src={igpost8}
                  style={{ width: "100%" }}
                  alt="Flasche"
                />
              </div>
            </div>
          </div>

          <div className="padding-small">
            <div className="section__content columns container--padding">
              <div className="column column--6">
                <video
                  id="BeltonAZVideo"
                  autostart="true"
                  playsInline
                  autoPlay
                  muted
                  loop
                  type="video/mp4"
                  src={A_Z_Video_Musik}
                  style={{
                    width: "100%",
                    height: "auto",
                  }}
                />
              </div>
              <div className="column column--6">
                <video
                  id="BeltonWinterVideo"
                  autostart="true"
                  playsInline
                  autoPlay
                  muted
                  loop
                  type="video/mp4"
                  src={Wintervideo}
                  style={{
                    width: "100%",
                    height: "auto",
                  }}
                />
              </div>
            </div>
          </div>
          <div style={{ paddingTop: "2rem" }}>
            <div className="section__content columns container--padding">
              <div className="column column--6">
                <video
                  id="BeltonHerbstVideo"
                  autostart="true"
                  playsInline
                  autoPlay
                  muted
                  loop
                  type="video/mp4"
                  src={Herbst_Video_Final}
                  style={{
                    width: "100%",
                    height: "auto",
                  }}
                />
              </div>
              <div className="column column--6">
                <video
                  id="BeltonOsterVideo"
                  autostart="true"
                  playsInline
                  autoPlay
                  muted
                  loop
                  type="video/mp4"
                  src={Ostervideo}
                  style={{
                    width: "100%",
                    height: "auto",
                  }}
                />
              </div>
            </div>
          </div>

          {/* TODO: 4er Image Section mit PPT content fehlt */}
          <div className="padding-small">
            <div className="section__content columns container--padding">
              <div className="column column--3">
                <img
                  ref="image"
                  src={belton_social_media_left}
                  style={{ width: "100%" }}
                  alt="Belton Referenz social media"
                />
              </div>
              <div className="column column--9">
                <div className="section__image-content">
                  <div className="section__image-content-wrapper">
                    <div className="teaser__text">
                      <div className="teaser__text-content">
                        <h5 className="teaser__text-title">Social Media</h5>
                        <div>
                          <p className="teaser__text-details">
                            Pinterest, Facebook, Instagram und YouTube - überall
                            wo sich die Zielgruppe der DIY-Begeisterten aufhält,
                            findet man auch die Marke belton und die
                            inspirierenden Projekt-Ideen.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <img
                  ref="image"
                  src={belton_social_media_right}
                  style={{ width: "100%", paddingTop: "15%" }}
                  alt="Belton Referenz social media"
                />
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <img
                ref="image"
                src={belton_social_media_youtube}
                style={{ width: "100%" }}
                alt="Belton Referenz social media youtube"
              />
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--4">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="teaser__text">
                    <div className="teaser__text-content">
                      <h5 className="teaser__text-title">
                        Influencer Marketing
                      </h5>
                      <div>
                        <p className="teaser__text-details">
                          belton legt Wert auf ihre Community und deren Meinung.
                          Deshalb erhielten ausgewählte Nutzer vor der belton
                          free Produkteinführung ein Produkt-Test-Paket. Idee
                          und Durchführung der Aktion kam von DAS ACHTE GEBOT.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column column--8"></div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <img
                ref="image"
                src={belton_mobile_social_media_diy_box}
                style={{ width: "100%" }}
                alt="Belton Referenz social media diy box"
              />
            </div>
          </div>

          <div className="section__content columns container-section">
            <div className="column column--6">
              <img
                ref="image"
                src={Kwasny_Referenzen_Mood_belton_Produkteinfuehrung}
                className="teaser__image-left"
                alt="Belton Referenz Produkteinfuehrung"
              />
            </div>

            <div className="column column--6">
              <div className="teaser__text padding">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--belton">
                    BELTON MARKENRELAUNCH
                  </p>
                  <h3 className="teaser__text-title">
                    Bring Farbe in dein Leben
                  </h3>
                  <p className="teaser__text-details">
                    Vom Handwerker, über den Heimwerker hin zum Deko- und
                    Bastelfan: die belton Marken-Familie hält mit dem breiten
                    Produktsortiment für jeden Anwender eine passende Lösung
                    bereit. Das spiegelt nun auch die neue Marke wider. DAS
                    ACHTE GEBOT unterstützte beim Marken-Refresh strategisch,
                    konzeptionell, grafisch und textlich und setzt den Anwender
                    klar in den Fokus der Kommunikation. So findet sich jeder
                    schnell zurecht!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="section__content columns container--padding">
            <div className="column column--12">
              <img
                ref="image"
                src={belton_free_Produkteinfuehrung_dosen}
                style={{ width: "100%" }}
                alt="Belton Free Dosen"
              />
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--4">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="teaser__text">
                    <div className="teaser__text-content">
                      <h5 className="teaser__text-title">Video</h5>
                      <div>
                        <p className="teaser__text-details">
                          Alle Produktvorteile und Lackierhinweise praktisch
                          verpackt in einem Animationsvideo von DAS ACHTE GEBOT.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column column--8"></div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <video
                id="KwasnySpruehVideoAnimation"
                autostart="true"
                playsInline
                autoPlay
                muted
                loop
                type="video/mp4"
                src={Kwasny_Sprueh_Video}
                style={{
                  width: "100%",
                  height: "auto",
                }}
              />
            </div>
          </div>

          <div className="padding-small">
            <div className="section__content columns container--padding">
              <div className="column column--4">
                <div className="section__image-content">
                  <div className="section__image-content-wrapper">
                    <div className="teaser__text">
                      <div className="teaser__text-content">
                        <h5 className="teaser__text-title">Medien</h5>
                        <div>
                          <p className="teaser__text-details">
                            POS, Online und Print - viele Kontaktpunkte für
                            potenzielle Kunden.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column column--8"></div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <img
                ref="image"
                src={belton_free_Produkteinfuehrung}
                style={{ width: "100%" }}
                alt="Belton Referenz Produkteinfuehrung"
              />
            </div>
          </div>
        </section>
        <section>
          <div className="section__content columns infinite__container">
            <div className="column column--6">
              <div className="teaser__text--intro padding">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--belton">
                    VERKAUFSAKTION
                  </p>
                  <h3 className="teaser__text-title">Ein Herz für Bienen</h3>
                  <div>
                    <p className="teaser__text-details">
                      Als Aktivierungskampagne für das umweltfreundliche
                      Lackspray belton free entwickelte DAS ACHTE GEBOT die
                      Verkaufsaktion „Ein Herz für Bienen“ inklusive aller
                      Medien. Beim Kauf eines belton free Lacksprays gab es eine
                      biologisch abbaubare Pflanzschale und Bienenwiesen-Samen
                      gratis dazu.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="column column--6"></div>
                  <div className="column column--6">
                    <img
                      ref="image"
                      src={belton_DIY_Referenzen_Mood_Bienen}
                      className="teaser__image-right"
                      alt="Belton Referenz Mood Bienen"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* TODO: Bienen Flyer fehlt? */}

          <div className="section__content columns container--padding">
            <div className="column column--8">
              <img
                ref="image"
                src={belton_Referenzen_Bienenaktion_flyer}
                style={{ width: "100%" }}
                alt="Belton Referenz Bienenaktion Flyer"
              />
            </div>
            <div className="column column--4" style={{ paddingTop: "10%" }}>
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="teaser__text">
                    <div className="teaser__text-content">
                      <h5 className="teaser__text-title">Samentütchen</h5>
                      <div>
                        <p className="teaser__text-details">
                          Gestaltung der Samentütchen mit hohem Kampagnen-
                          Wiedererkennunswert.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <img
                ref="image"
                src={belton_Referenzen_Biene_mit_Samentuetchen}
                style={{ width: "100%" }}
                alt="Belton Referenz Biene mit Samentuetchen"
              />
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--4">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="teaser__text">
                    <div className="teaser__text-content">
                      <h5 className="teaser__text-title">Bestückung am POS</h5>
                      <div>
                        <p className="teaser__text-details">
                          Schilder, Wobbler und Flyer für eine hohe
                          Aufmerksamkeit am POS.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column column--8"></div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <img
                ref="image"
                src={belton_Bienenaktion_Regale}
                style={{ width: "100%" }}
                alt="Belton Referenz Bienenaktion Regale"
              />
            </div>
          </div>
          <div className="padding-small">
            <div className="section__content columns container--padding">
              <div className="column column--4">
                <div className="section__image-content">
                  <div className="section__image-content-wrapper">
                    <div className="teaser__text">
                      <div className="teaser__text-content">
                        <h5 className="teaser__text-title">Landingpage</h5>
                        <div>
                          <p className="teaser__text-details">
                            Alle Informationen auf einen Blick und schneller
                            Zugang zu den nötigen Funktionen.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column column--8"></div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <img
                ref="image"
                src={belton_bienen_web}
                style={{ width: "100%" }}
                alt="Belton Referenz Bienen Web"
              />
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
