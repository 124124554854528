import React from "react";
import { HashLink as Link } from "react-router-hash-link";

export default class Referenzdetail extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section id="section-detail">
          <div className="section__content columns container infinite-container">
            <div className="column column--4">
              <nav className="menu menu--campaigns">
                <div className="menu__item-container">
                  <div className="menu__item-work" href="/">
                    <span className="menu__item-name">Kunde</span>
                  </div>
                </div>
                <div className="menu__item-container">
                  <span className="menu__item-name">Eisenwerk Würth</span>
                </div>
              </nav>
            </div>
            <div className="column column--4">
              <nav className="menu menu--campaigns">
                <div className="menu__item-container">
                  <div className="menu__item-work">
                    <span className="menu__item-name">Aufgaben</span>
                  </div>
                </div>
                <div className="menu__item-container">
                  <span className="menu__item-name">
                    Inhaltliche Neuausrichtung der Marke mit Fokus auf die
                    Stärke Prozess-Optimierung und Hersteller Know-how für
                    internationale Märkte. Workshops zu Branding und
                    Vertriebsstategie, Relaunch von Logo und Bildwelt,
                    CD-Relaunch, Umsetzung auf klassische und digitale Medien,
                    Messe-Konzeption
                  </span>
                </div>
              </nav>
            </div>
            <div className="column column--4">
              <nav className="menu menu--campaigns">
                <div className="menu__item-container">
                  <div className="menu__item-work">
                    <span className="menu__item-name">Beispielreferenz</span>
                  </div>
                </div>
                <div className="menu__item-container">
                  <Link to="#section-ewg-1">
                    <div className="menu__item">
                      <span className="menu__item-name--ewg">
                        Marken Relaunch
                      </span>
                    </div>
                  </Link>
                </div>
              </nav>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
