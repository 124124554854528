import React from "react";

import Devyl_Referenzen_Mood from "../../../assets/images/Referenzen/Devyl/Devyl_Referenzen_Mood.jpg";
import Devyl_Logo from "../../../assets/images/Referenzen/Devyl/Devyl_Logo_responsive.jpg";
import Devyl_Mockup_web from "../../../assets/images/Referenzen/Devyl/Devyl_Mockup_web.jpg";
import Devyl_PPT_Produktpraesentation_2022_3 from "../../../assets/images/Referenzen/Devyl/Devyl_PPT_Produktpraesentation_2022-3.jpg";
import Devyl_PPT_Produktpraesentation_2022_7 from "../../../assets/images/Referenzen/Devyl/Devyl_PPT_Produktpraesentation_2022-7.jpg";
import Devyl_PPT_Produktpraesentation_2022_17 from "../../../assets/images/Referenzen/Devyl/Devyl_PPT_Produktpraesentation_2022-17.jpg";
import Devyl_PPT_Produktpraesentation_2022_34 from "../../../assets/images/Referenzen/Devyl/Devyl_PPT_Produktpraesentation_2022-34.jpg";
import Devyl_RollUps_3er from "../../../assets/images/Referenzen/Devyl/Devyl_Roll-Ups_3er.jpg";
import Devyl_RollUps_breit from "../../../assets/images/Referenzen/Devyl/Devyl_Roll-Up_breit.jpg";
import DPP_Motivkarte_A6_Brennen_print from "../../../assets/images/Referenzen/Devyl/DPP_Motivkarte_A6_Brennen_print.jpg";
import DPP_Motivkarte_A6_Feuer_print from "../../../assets/images/Referenzen/Devyl/DPP_Motivkarte_A6_Feuer_print.jpg";
import DPP_Motivkarte_A6_Highway_print from "../../../assets/images/Referenzen/Devyl/DPP_Motivkarte_A6_Highway_print.jpg";
import Democode_Karte from "../../../assets/images/Referenzen/Devyl/Democode-Karte.jpg";
import Devy_Messe_Giveaway_Wasabinuesse from "../../../assets/images/Referenzen/Devyl/Devy_Messe_Giveaway_Wasabinuesse.jpg";
import DevylPPTSlide from "../../../assets/images/Referenzen/Devyl/DevylPPTVideo.mp4";

export default class ReferenzContent extends React.Component {
  render() {
    return (
      <React.Fragment>
        {/* Content Intro */}

        <section id="section-kwasny-1" className="section--content">
          <div className="padding-small">
            <div className="section__content columns container-section">
              <div className="column column--6">
                <img
                  ref="image"
                  src={Devyl_Referenzen_Mood}
                  className="teaser__image-left"
                  alt="Digitale Markenbildung - Develop your learning"
                />
              </div>

              <div className="column column--6">
                <div className="teaser__text padding">
                  <div className="teaser__text-content">
                    <p className="teaser__text-subtitle--devyl">
                    Digitale Markenbildung
                    </p>
                    <h3 className="teaser__text-title">Develop your learning</h3>
                    <p className="teaser__text-details">
                    Die Digitalisierung der Aus- und Weiterbildung mit einem einmaligen und prämierten
Lernkonzept – das ist die Devyl-Mission. Die Software vereint die Funktionen einer Learn-
Management-Software für die Lernenden mit effizienten Verwaltungs-Tools für Ausbilder
und ist damit für eine breite, generationenübergreifende Zielgruppe interessant.
DAS ACHTE GEBOT war von Anfang an dabei und unterstützte beim Launch der digitalen
Marke: Von der Naming- und Claim-Entwicklung über die Gestaltung des Corporate
Designs bis hin zur Realisierung aller relevanten On- und Offline-Marketingmaßnahmen.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Logo Heading */}

          <div className="section__content columns container--padding">
            <div className="column cilumn-8"></div>
            <div className="column column-4">
              <div className="teaser__text" style={{ paddingTop: "30%" }}>
                <div className="teaser__text-content">
                  <h5 className="teaser__text-title">
                    Wiedererkennbare Marke und responsives Logo
                  </h5>
                  <div>
                    <p className="teaser__text-details">
                      Klares Farbkonzept, prägnantes Wording und ein Name, der
                      den Produktnutzen auf den Punkt bringt – das macht Devyl
                      zu einer runden Marke. Dazu gehört auch, dass das Logo
                      selbst als App-Icon noch eindeutig wiederzuerkennen ist.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Logo Container */}

          <div className="section__content columns container--padding padding-small">
            <div className="column column--12">
              <img
                ref="image"
                src={Devyl_Logo}
                style={{ width: "100%" }}
                alt="Digitale Markenbildung"
              />
            </div>
          </div>

          {/* Screen Mockup Text */}

          <div className="section__content columns container--padding">
            <div className="column column-4">
              <div className="teaser__text" style={{ paddingTop: "30%" }}>
                <div className="teaser__text-content">
                  <h5 className="teaser__text-title">Devyl Landingpage</h5>
                  <div>
                    <p className="teaser__text-details">
                      Alle Vorteile auf einen Blick, klare Preisübersicht und
                      ein schneller Kontakt. Zwischen dem Website-Einstieg und
                      der erfolgreichen Testversion-Anfrage liegen nur zwei
                      Klicks.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="column cilumn-8"></div>
          </div>

          {/* Screen Mockup Image */}
          {/* TODO: Mockup animation AE */}
          <div className="section__content columns container--padding padding-small">
            <div className="column column--12">
              <img
                ref="image"
                src={Devyl_Mockup_web}
                style={{ width: "100%" }}
                alt="Digitale Markenbildung"
              />
            </div>
          </div>

          {/* PPT Devyl Text */}

          <div className="section__content columns container--padding">
            <div className="column column-4">
              <div className="teaser__text" style={{ paddingTop: "30%" }}>
                <div className="teaser__text-content">
                  <h5 className="teaser__text-title">
                    Devyl PPT Produktpräsentation animiert
                  </h5>
                  <div>
                    <p className="teaser__text-details">
                      Statt potenzielle Kunden mit eintöniger Historie über das
                      eigene Unternehmen zu langweilen, legt die
                      Devyl-Präsentation den Fokus auf das Wichtigste: Die
                      Vorteile für den Kunden. DAS ACHTE GEBOT sorgte für ein
                      spannendes Story-Konzept und ansprechende Gestaltung.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="column cilumn-8"></div>
          </div>

          {/* PPT Devyl Images */}

          <div className="section__content columns container--padding padding-small">
            <div className="column column--6">
              <img
                ref="image"
                src={Devyl_PPT_Produktpraesentation_2022_3}
                style={{ width: "100%", border: "solid 1px #000" }}
                alt="Digitale Markenbildung"
              />
            </div>
            <div className="column column--6">
              <img
                ref="image"
                src={Devyl_PPT_Produktpraesentation_2022_7}
                style={{ width: "100%", border: "solid 1px #000" }}
                alt="Digitale Markenbildung"
              />
            </div>
          </div>

          <div className="section__content columns container--padding padding-small">
            <div className="column column--6">
              <video
                id="DevylPPTSlide"
                autostart="true"
                playsInline
                autoPlay
                muted
                loop
                type="video/mp4"
                src={DevylPPTSlide}
                style={{
                  width: "100%",
                  height: "auto",
                  border: "solid 1px #000",
                }}
              />
            </div>
            <div className="column column--6">
              <img
                ref="image"
                src={Devyl_PPT_Produktpraesentation_2022_17}
                style={{ width: "100%", border: "solid 1px #000" }}
                alt="Digitale Markenbildung"
              />
            </div>
          </div>
          <div className="section__content columns container--padding padding-small">
            <div className="column column--3"></div>
            <div className="column column--6">
              <img
                ref="image"
                src={Devyl_PPT_Produktpraesentation_2022_34}
                style={{ width: "100%", border: "solid 1px #000" }}
                alt="Digitale Markenbildung"
              />
            </div>
            <div className="column column--3"></div>
          </div>

          {/* RollUp Text */}

          <div className="section__content columns container--padding">
            <div className="column column-4">
              <div className="teaser__text" style={{ paddingTop: "30%" }}>
                <div className="teaser__text-content">
                  <h5 className="teaser__text-title">Messewand und RollUps</h5>
                  <div>
                    <p className="teaser__text-details">
                      Digitale Marken müssen auch offline überzeugen können. Für
                      Devyl wurde daher ein Bannerkonzept für die nächste
                      Ausbildungsmesse entwickelt.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="column cilumn-8"></div>
          </div>

          {/* RollUp Images */}

          <div className="section__content columns container--padding padding-small">
            <div className="column column--6">
              <img
                ref="image"
                src={Devyl_RollUps_breit}
                style={{ width: "100%" }}
                alt="Digitale Markenbildung"
              />
            </div>
            <div className="column column--6">
              <img
                ref="image"
                src={Devyl_RollUps_3er}
                style={{ width: "100%", paddingTop: "20%" }}
                alt="Digitale Markenbildung"
              />
            </div>
          </div>

          {/* Werbemittel */}

          <div className="section__content columns container--padding padding-small">
            <div className="column column--6">
              <img
                ref="image"
                src={DPP_Motivkarte_A6_Brennen_print}
                style={{
                  width: "100%",
                  padding: "0.3rem",
                  marginBottom: "2.5rem",
                  border: "solid 1px #000",
                }}
                alt="Digitale Markenbildung"
              />
              <img
                ref="image"
                src={DPP_Motivkarte_A6_Feuer_print}
                style={{
                  width: "100%",
                  padding: "0.3rem",
                  marginBottom: "2.5rem",
                  border: "solid 1px #000",
                }}
                alt="Digitale Markenbildung"
              />
              <img
                ref="image"
                src={DPP_Motivkarte_A6_Highway_print}
                style={{
                  width: "100%",
                  padding: "0.3rem",
                  marginBottom: "2.5rem",
                  border: "solid 1px #000",
                }}
                alt="Digitale Markenbildung"
              />
            </div>
            <div className="column column--6">
              <div className="teaser__text" style={{}}>
                <div className="teaser__text-content">
                  <h5 className="teaser__text-title">Werbemittel</h5>
                  <div>
                    <p className="teaser__text-details">
                      Messe Give-Aways, die in Erinnerung bleiben und zur Marke
                      passen: Postkarten, Wasabi-Snacks und Visitenkarten mit
                      dem Zugang zu Software-Testversionen.
                    </p>
                  </div>
                </div>
              </div>
              <img
                ref="image"
                src={Devy_Messe_Giveaway_Wasabinuesse}
                style={{ width: "100%" }}
                alt="Digitale Markenbildung"
              />
              <img
                ref="image"
                src={Democode_Karte}
                style={{ width: "100%" }}
                alt="Digitale Markenbildung"
              />
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
