import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

const DynamicScrollToTop = (props) => {
    var element;

    useEffect(() => {

        if (props.history.action === 'POP') {
            return;
        }
        /* eslint-disable no-unused-vars */
        let hash = props.location.hash;
        if (hash) {
            let elememnt = document.querySelector(hash);
            if (element) {
                element.scrollIntoView({ block: 'start', behavior: 'smooth' });
            }
        } else {
            window.scrollTo(0, 0);
        }
    });
    /* eslint-enable no-unused-vars */
    return (
        <div />
    );
};

export default withRouter(DynamicScrollToTop);