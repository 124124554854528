import React from "react";

import Harri from "../../assets/images/Agentur/Rahmen_GF_Harri_neu.jpg";
import HarriWebP from "../../assets/images/Agentur/Rahmen_GF_Harri_neu.webp";
import Britta from "../../assets/images/Agentur/Rahmen_Britta_neu.jpg";
import BrittaWebP from "../../assets/images/Agentur/Rahmen_Britta_neu.webp";
import Marco from "../../assets/images/Agentur/Rahmen_Marco.jpg";
import MarcoWebP from "../../assets/images/Agentur/Rahmen_Marco.webp";

class SectionContact extends React.Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { width } = this.state;
    const isMobile = width <= 812;

    if (isMobile) {
      return (
        <section id="section-contact" className="section--content section--padding">
          <div
            className="section__content columns container--padding"
            style={{ paddingTop: "0px" }}
          >
            <div className="section__intro-content  column--6">
              <div className="teaser__text">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle">Offenes Ohr gesucht?</p>
                  <h3 className="teaser__text-title">
                    Ungelogen gute Werbung machen wir gerne auch für Ihr
                    Unternehmen.
                  </h3>
                </div>
              </div>
            </div>
            <div className="column--6 padding-small">
              <div className="teaser__text">
                <div className="teaser__text-content">
                  <p className="teaser__text-title">
                    Sie finden uns im Großraum Stuttgart und Heilbronn,
                    verkehrsgünstig gelegen zwischen Bietigheim-Bissingen und
                    Ludwigsburg. Wir freuen uns von Ihnen zu hören.
                  </p>
                  <h3 className="teaser__text-title">+49 (0)7142-77476-0</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="section__content columns container content-left">
            <div className="section__content column--3">
              <img
                src={Harri}
                style={{ width: "100%", height: "auto" }}
                alt="Harri Grimm"
              />
              <span className="section__content-headline">Harri Grimm</span>
              <p>
                Geschäftsführer
                <br />
                Kreation
              </p>
              <p>
                <span className="section__content-headline">E </span>
                <a
                  href="mailto:harri.grimm@dasachtegebot.de"
                  title="harri.grimm@dasachtegebot.de"
                >
                  harri.grimm@dasachtegebot.de
                </a>
              </p>
              <p className="padding-small-bottom"></p>
            </div>
            <div className="section__content column--3">
              <img
                src={Britta}
                style={{ width: "100%", height: "auto" }}
                alt="Britta Krumrain-Ernst"
              />
              <span className="section__content-headline">
                Britta Krumrain-Ernst
              </span>
              <p>
                Geschäftsführerin
                <br />
                Beratung und Strategie
              </p>
              <p>
                <span className="section__content-headline">E </span>
                <a
                  href="mailto:krumrain-ernst@dasachtegebot.de"
                  title="krumrain-ernst@dasachtegebot.de"
                >
                  krumrain-ernst@dasachtegebot.de
                </a>
              </p>
              <p className="padding-small-bottom"></p>
            </div>
            <div className="section__content column--3">
              <img
                src={Marco}
                style={{ width: "100%", height: "auto" }}
                alt="Marco Schrenk"
              />
              <span className="section__content-headline">Marco Schrenk</span>
              <p>
                Geschäftsführer
                <br />
                Multimedia
              </p>
              <p>
                <span className="section__content-headline">E </span>
                <a
                  href="mailto:schrenk@dasachtegebot.de"
                  title="schrenk@dasachtegebot.de"
                >
                  schrenk@dasachtegebot.de
                </a>
              </p>
              <p className="padding-small-bottom"></p>
            </div>
          </div>
        </section>
      );
    } else {
      return (
        <section id="section-contact" className="section--content">
          <div className="section__content columns container">
            <div className="section__content columns container">
              <div className="section__intro-content column--6">
                <div className="teaser__text">
                  <div className="teaser__text-content">
                    <p className="teaser__text-subtitle">
                      Offenes Ohr gesucht?
                    </p>
                    <h3 className="teaser__text-title">
                      Ungelogen gute Werbung <br />
                      machen wir gerne auch für <br />
                      Ihr Unternehmen.
                    </h3>
                  </div>
                </div>
              </div>
              <div className="section__intro-content column--6">
                <div className="teaser__text">
                  <div className="teaser__text-content">
                    <p className="teaser__text-title margin">
                      Sie finden uns im Großraum Stuttgart und Heilbronn,
                      verkehrsgünstig gelegen zwischen Bietigheim-Bissingen und
                      Ludwigsburg. Wir freuen uns von Ihnen zu hören.
                    </p>
                    <h3 className="teaser__text-title">
                      +49 (0)7142 - 77476 - 0
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section__content columns content-centered container">
            <div className="section__content column column--3">
              <picture
                style={{ width: "100%", height: "auto", vertiaclAlign: "mid" }}
              >
                <source srcSet={HarriWebP} type="image/webp" />
                <source srcSet={Harri} type="image/jpeg" />
                <img src={Harri} alt="Harri Grimm" />
              </picture>
            </div>
            <div className="section__content column column--3">
              <picture style={{ width: "100%", height: "auto" }}>
                <source srcSet={BrittaWebP} type="image/webp" />
                <source srcSet={Britta} type="image/jpeg" />
                <img src={Britta} alt="Britta Krumrain-Ernst" />
              </picture>
            </div>
            <div className="section__content column column--3">
              <picture style={{ width: "100%", height: "auto" }}>
                <source srcSet={MarcoWebP} type="image/webp" />
                <source srcSet={Marco} type="image/jpeg" />
                <img src={Marco} alt="Marco Schrenk" />
              </picture>
            </div>
          </div>
          <div className="section__content columns content-centered container">
            <div className="section__content column column--3">
              <span className="section__content-headline">Harri Grimm</span>
              <p className="section__content-detail">
                Geschäftsführer
                <br />
                Kreation
              </p>
              <p>
                <span className="section__content-headline">E </span>
                <a
                  className="section__content-contact"
                  href="mailto:harri.grimm@dasachtegebot.de"
                  title="harri.grimm@dasachtegebot.de"
                >
                  harri.grimm@dasachtegebot.de
                </a>
              </p>
            </div>

            <div className="section__content column column--3">
              <span className="section__content-headline">
                Britta Krumrain-Ernst
              </span>
              <p className="section__content-detail">
                Geschäftsführerin
                <br />
                Beratung und Strategie
              </p>
              <p>
                <span className="section__content-headline">E </span>
                <a
                  className="section__content-contact"
                  href="mailto:krumrain-ernst@dasachtegebot.de"
                  title="krumrain-ernst@dasachtegebot.de"
                >
                  krumrain-ernst@dasachtegebot.de
                </a>
              </p>
            </div>
            <div className="section__content column column--3">
              <span className="section__content-headline">Marco Schrenk</span>
              <p className="section__content-detail">
                Geschäftsführer
                <br />
                Multimedia
              </p>
              <p>
                <span className="section__content-headline">E </span>
                <a
                  className="section__content-contact"
                  href="mailto:schrenk@dasachtegebot.de"
                  title="schrenk@dasachtegebot.de"
                >
                  schrenk@dasachtegebot.de
                </a>
              </p>
            </div>
          </div>
        </section>
      );
    }
  }
}
export default SectionContact;
