import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "babel-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "react-router-scroll-top";
import "./index.css";
import App from "./App";

//import ReactGA from "react-ga";
//import TagManager from "react-gtm-module";

import reducer from "./store/reducer";
import { Provider } from "react-redux";
import { createStore } from "redux";
import * as serviceWorker from "./serviceWorker";

const store = createStore(reducer);
//const tagManagerArgs = {
// gtmId: "GTM-MXL524P",
//};
 
//ReactGA.initialize(trackingId);
//TagManager.initialize(tagManagerArgs);

// ReactGA.initialize(trackingId);
// TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter basename="/">
      <ScrollToTop>
        <App />
      </ScrollToTop>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
serviceWorker.unregister();
