import React from "react";

import ReferenzAnimation from "../GeberitComponent/ReferenzAnimation";

class ReferenzIntro extends React.Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { width } = this.state;
    const isMobile = width <= 812;

    if (isMobile) {
      return (
        <React.Fragment>
          <section id="section-geberit">
            <div className="section__content columns container--padding">
              <div className="teaser__text column--6">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--geberit">
                    Geberit International AG
                  </p>
                  <h3 className="teaser__text-title">
                    Internationale Kampagnen
                  </h3>
                </div>
              </div>
              <div className="section__intro-content column--6">
                <p className="teaser__text-details">
                  Den Marktführer unter den europäischen Sanitärherstellern
                  kennen die meisten Endverbraucher vom stillen Örtchen,
                  schließlich war es der Unternehmensgründer und Namensgeber
                  Albert Emil Gebert, der vor mehr als 100 Jahren den Spülkasten
                  erfunden hat. Doch richtig spannend wird es in Sachen
                  Sanitärtechnik erst hinter der Wand, wo die
                  Installationssysteme von Geberit für sichere Ver- und
                  Entsorgung sorgen. Mit Schweizer Gründlichkeit und einer
                  Hydraulik-Kompetenz, die man weltweit nur hier vorfindet, wird
                  das Geschäftsfeld auch marketingseitig immer stärker
                  ausgebaut. Seit mehreren Jahren unterstützt DAS ACHTE GEBOT
                  das Geberit Marketing aus Rapperswil-Jona bei der Entwicklung
                  der internationalen Kampagnen.
                </p>
              </div>
            </div>
          </section>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <section id="section-geberit" className="section--intro">
            <div className="section__content columns container--padding">
              <div className="column column--4"></div>
              <div className="column column--4" style={{ marginTop: "-8%" }}>
                <ReferenzAnimation />
              </div>
              <div className="column column--4"></div>
            </div>

            <div className="section__content columns container">
              <div className="teaser__text column--6">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--geberit">
                    Geberit International AG
                  </p>
                  <h3 className="teaser__text-title">
                    Internationale Kampagnen
                  </h3>
                </div>
              </div>
              <div className="section__intro-content column--6">
                <p className="teaser__text-details margin">
                  Den Marktführer unter den europäischen Sanitärherstellern
                  kennen die meisten Endverbraucher vom stillen Örtchen,
                  schließlich war es der Unternehmensgründer und Namensgeber
                  Albert Emil Gebert, der vor mehr als 100 Jahren den Spülkasten
                  erfunden hat. Doch richtig spannend wird es in Sachen
                  Sanitärtechnik erst hinter der Wand, wo die
                  Installationssysteme von Geberit für sichere Ver- und
                  Entsorgung sorgen. Mit Schweizer Gründlichkeit und einer
                  Hydraulik-Kompetenz, die man weltweit nur hier vorfindet, wird
                  das Geschäftsfeld auch marketingseitig immer stärker
                  ausgebaut. Seit mehreren Jahren unterstützt DAS ACHTE GEBOT
                  das Geberit Marketing aus Rapperswil-Jona bei der Entwicklung
                  der internationalen Kampagnen.
                </p>
              </div>
            </div>
          </section>
        </React.Fragment>
      );
    }
  }
}
export default ReferenzIntro;
