import React from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { createGlobalStyle } from "styled-components";

import TradeGothic from "./assets/fonts/webfonts/291759_0_0.ttf";
import Duerr from "./referenzen/Duerr";
import EWG from "./referenzen/EWG";
import Felsomat from "./referenzen/Felsomat";
import Flex from "./referenzen/Flex";
import FLV from "./referenzen/FLV";
import Geberit from "./referenzen/Geberit";
import Janus from "./referenzen/Janus";
import Klingbeil from "./referenzen/Klingbeil";
import Meva from "./referenzen/Meva";
import Sika from "./referenzen/Sika";
import Solidian from "./referenzen/Solidian";
import AGB from "./screen/AGB";
import Agentur from "./screen/Agentur";
import Datenschutz from "./screen/Datenschutz";
import Home from "./screen/Home";
import Impressum from "./screen/Impressum";
import JobKonzeption from "./screen/jobs/JobKonzeption";
import JobTypo3 from "./screen/jobs/JobTypo3";
import JobWebdev from "./screen/jobs/JobWebdev";
import Karriere from "./screen/Karriere";
import Kontakt from "./screen/Kontakt";
import Kunden from "./screen/Kunden";
import Referenzen from "./screen/Referenzen";
import SocialLiks from "./screen/SocialLinks";
import Kwasny from "./referenzen/Kwasny";
import SprayMax from "./referenzen/SprayMax";
import Devyl from "./referenzen/Devyl";
import Belton from "./referenzen/Belton";
import bIDO from "./referenzen/3bIDO";

import "./assets/css/card.css";
import "./assets/css/columns.css";
import "./assets/css/footer.css";
import "./assets/css/grid.css";
import "./assets/css/hover.css";
import "./assets/css/logo.css";
import "./assets/css/menu.css";
import "./assets/css/mirror.css";
import "./assets/css/mockup.css";
import "./assets/css/scroll.css";
import "./assets/css/section.css";
import "./assets/css/style.css";
import "./assets/css/transition.css";
import "./assets/css/video.css";
import "./assets/css/intro.css";

// import Test from "./components/FooterComponent/Footer";

createGlobalStyle`
  @font-face {
    font-family: 'TradeGothic';
    src: url(${TradeGothic}) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
`;

// const loader = document.querySelector('.loader');

// const showLoader = () => loader.classList.remove('loader--hide');
// const hideLoader = () => loader.classList.add('loader--hide');

const App = ({ location }) => {
  const currentKey = location.pathname.split("/")[1] || "/";
  const timeout = { enter: 0, exit: 0 };

  return (
    <TransitionGroup>
      <CSSTransition
        key={currentKey}
        timeout={timeout}
        classNames="page-transition"
        appear
      >
        <section id="section-page" className="page-main-inner">
          <Switch location={location}>
            <Route path="/agentur" component={Agentur} exact />
            <Route path="/referenzen" component={Referenzen} exact />
            <Route path="/kontakt" component={Kontakt} exact />
            <Route path="/agb" component={AGB} exact />
            <Route path="/impressum" component={Impressum} exact />
            <Route path="/datenschutz" component={Datenschutz} exact />
            <Route path="/referenzen/kunden" component={Kunden} exact />
            <Route path="/referenzen/flex" component={Flex} exact />
            <Route path="/referenzen/geberit" component={Geberit} exact />
            <Route path="/referenzen/duerr" component={Duerr} exact />
            <Route path="/referenzen/meva" component={Meva} exact />
            <Route path="/referenzen/ewg" component={EWG} exact />
            <Route path="/referenzen/felsomat" component={Felsomat} exact />
            <Route path="/referenzen/janus" component={Janus} exact />
            <Route path="/referenzen/klingbeil" component={Klingbeil} exact />
            <Route path="/referenzen/solidian" component={Solidian} exact />
            <Route path="/referenzen/sika" component={Sika} exact />
            <Route path="/referenzen/fv" component={FLV} exact />
            <Route path="/referenzen/kwasny" component={Kwasny} exact />
            <Route path="/referenzen/spraymax" component={SprayMax} exact />
            <Route path="/referenzen/belton" component={Belton} exact />
            <Route path="/referenzen/devyl" component={Devyl} exact />
            <Route path="/referenzen/3bido" component={bIDO} exact />
            <Route path="/karriere" component={Karriere} exact />
            <Route path="/jobs/webdev" component={JobWebdev} exact />
            <Route path="/jobs/konzeption" component={JobKonzeption} exact />
            <Route path="/jobs/typo3" component={JobTypo3} exact />
            <Route path="/sociallinks" component={SocialLiks} exact />
            <Route
              path="/"
              component={(props) => (
                <Home timestamp={new Date().toString()} {...props} />
              )}
              exact
            />
            <Redirect to="/" />
          </Switch>
        </section>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default withRouter(App);
