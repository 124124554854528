import React from "react";

import Devyl_Referenzen_Mood from "../../../assets/images/Referenzen/Devyl/Devyl_Referenzen_Mood.jpg";
import Devyl_Logo_mobile from "../../../assets/images/Referenzen/Devyl/Devyl_logo_responive_mobile.jpg";
import Devyl_Mockup_web from "../../../assets/images/Referenzen/Devyl/Devyl_Mockup_web.jpg";
import Devyl_PPT_Produktpraesentation_2022_3 from "../../../assets/images/Referenzen/Devyl/Devyl_PPT_Produktpraesentation_2022-3.jpg";
import Devyl_PPT_Produktpraesentation_2022_7 from "../../../assets/images/Referenzen/Devyl/Devyl_PPT_Produktpraesentation_2022-7.jpg";
import Devyl_PPT_Produktpraesentation_2022_17 from "../../../assets/images/Referenzen/Devyl/Devyl_PPT_Produktpraesentation_2022-17.jpg";
import Devyl_PPT_Produktpraesentation_2022_34 from "../../../assets/images/Referenzen/Devyl/Devyl_PPT_Produktpraesentation_2022-34.jpg";
import Devyl_RollUps_3er from "../../../assets/images/Referenzen/Devyl/Devyl_Roll-Ups_3er.jpg";
import Devyl_RollUps_breit from "../../../assets/images/Referenzen/Devyl/Devyl_Roll-Up_breit.jpg";
import DPP_Motivkarte_A6_Brennen_print from "../../../assets/images/Referenzen/Devyl/DPP_Motivkarte_A6_Brennen_print.jpg";
import DPP_Motivkarte_A6_Feuer_print from "../../../assets/images/Referenzen/Devyl/DPP_Motivkarte_A6_Feuer_print.jpg";
import DPP_Motivkarte_A6_Highway_print from "../../../assets/images/Referenzen/Devyl/DPP_Motivkarte_A6_Highway_print.jpg";
import Democode_Karte from "../../../assets/images/Referenzen/Devyl/Democode-Karte.jpg";
import Devy_Messe_Giveaway_Wasabinuesse from "../../../assets/images/Referenzen/Devyl/Devy_Messe_Giveaway_Wasabinuesse.jpg";
import DevylPPTSlide from "../../../assets/images/Referenzen/Devyl/DevylPPTVideo.mp4";

export default class ReferenzContentMobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      videos: false,
    };
  }
  rerenderComponent = () => {
    this.forceUpdate();
  };
  componentDidMount() {
    document
      .getElementById("cookiebot-selected")
      .addEventListener("click", this.rerenderComponent);
    document
      .getElementById("cookiebot-all")
      .addEventListener("click", this.rerenderComponent);
  }

  componentWillUnmount() {
    document
      .getElementById("cookiebot-selected")
      .removeEventListener("click", this.rerenderComponent);
    document
      .getElementById("cookiebot-all")
      .removeEventListener("click", this.rerenderComponent);
  }

  render() {
    return (
      <React.Fragment>
        <section id="section-sika-1" className="section--content">
          <div className="section__content columns container-section">
            <div className="column column--6">
              <img
                ref="image"
                src={Devyl_Referenzen_Mood}
                style={{ paddingTop: "2rem" }}
                className="teaser__image-left"
                alt="Digitale Markenbildung: Develop your learning"
              />
            </div>

            <div className="column column--6">
              <div className="teaser__text padding">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--devyl">Digitale Markenbildung</p>
                  <h3 className="teaser__text-title">
                    Develop your learning
                  </h3>
                  <p className="teaser__text-details">
                    Die Digitalisierung der Aus- und Weiterbildung mit einem
                    einmaligen und prämierten Lernkonzept – das ist die
                    Devyl-Mission. Die Software vereint die Funktionen einer
                    Learn-Management-Software für die Lernenden mit effizienten
                    Verwaltungs-Tools für Ausbildende und ist damit für eine
                    breite, generationenübergreifende Zielgruppe interessant.
                    DAS ACHTE GEBOT war von Anfang an dabei und unterstützte
                    beim Launch der digitalen Marke: Von der Naming- und
                    Claim-Entwicklung über die Gestaltung des Corporate Designs
                    bis hin zur Realisierung aller relevanten On- und
                    Offline-Marketingmaßnahmen.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="section__content columns">
              <div className="column column--12">
                <img
                  ref="image"
                  src={Devyl_Logo_mobile}
                  style={{ width: "100%", height: "100%" }}
                  alt="Sika booster start"
                />
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--8"></div>
            <div className="column column--4">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="teaser__text">
                    <div className="teaser__text-content">
                      <h5 className="teaser__text-title">
                        Wiedererkennbare Marke und responsives Logo
                      </h5>
                      <div>
                        <p className="teaser__text-details">
                          Klares Farbkonzept, prägnantes Wording und ein Name,
                          der den Produktnutzen auf den Punkt bringt – das macht
                          Devyl zu einer runden Marke. Dazu gehört auch, dass
                          das Logo selbst als App-Icon noch eindeutig
                          wiederzuerkennen ist.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding-big">
            <div className="column column--4">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <div className="teaser__text">
                    <div className="teaser__text-content">
                      <h5 className="teaser__text-title">
                        Kampagnenmotive/Theme
                      </h5>
                      <div>
                        <p className="teaser__text-details">
                          Das knappe Timing und die damit verbundenen Probleme
                          werden zu sportlichen Herausforderungen, die es
                          gemeinsam zu meistern gilt. DAS ACHTE GEBOT
                          entwickelte für jeden Milestone (Vorbereitung, Start,
                          Finish) passende Key Visuals, die die
                          Kommunikationsmaßnahmen konsequent begleiten.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* TODO: Add Video Animation */}
            <div className="column column--7">
              <img
                ref="image"
                src={Devyl_Mockup_web}
                style={{
                  width: "100%",
                  height: "100%",
                  paddingBottom: "5%",
                  paddingTop: "5%",
                }}
                alt="Sika booster start"
              />
            </div>
          </div>

          <div className="section__content columns container--padding-big">
            <div className="column column--6">
              <div className="column column--6">
                <div className="teaser__text">
                  <div className="teaser__text-content">
                    <h5 className="teaser__text-title">
                      Devyl PPT Produktpräsentation animiert
                    </h5>
                    <div>
                      <p className="teaser__text-details">
                        Statt potenzielle Kunden mit eintöniger Historie über
                        das eigene Unternehmen zu langweilen, legt die
                        Devyl-Präsentation den Fokus auf das Wichtigste: Die
                        Vorteile für den Kunden. DAS ACHTE GEBOT sorgte für ein
                        spannendes Story-Konzept und ansprechende Gestaltung.
                      </p>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="column column--6">
              <img
                ref="image"
                src={Devyl_PPT_Produktpraesentation_2022_3}
                style={{
                  width: "100%",
                  padding: "0.3rem",
                  marginBottom: "2.5rem",
                  border: "solid 1px #000",
                }}
                alt="Sika Poster O365 hoch"
              />
            </div>
            <div className="column column--12">
              <img
                ref="image"
                src={Devyl_PPT_Produktpraesentation_2022_7}
                style={{
                  width: "100%",
                  padding: "0.3rem",
                  marginBottom: "2.5rem",
                  border: "solid 1px #000",
                }}
                alt="Sika Postkarte"
              />
            </div>
            <div className="column column--12">
              <video
                id="DevylPPTSlide"
                autostart="true"
                playsInline
                autoPlay
                muted
                loop
                type="video/mp4"
                src={DevylPPTSlide}
                style={{
                  width: "100%",
                  height: "auto",
                  border: "solid 1px #000",
                }}
              />
            </div>
            <div className="column column--12">
              <img
                ref="image"
                src={Devyl_PPT_Produktpraesentation_2022_17}
                style={{
                  width: "100%",
                  padding: "0.3rem",
                  marginBottom: "2.5rem",
                  border: "solid 1px #000",
                }}
                alt="Sika Postkarte"
              />
            </div>
            <div className="column column--12">
              <img
                ref="image"
                src={Devyl_PPT_Produktpraesentation_2022_34}
                style={{
                  width: "100%",
                  padding: "0.3rem",
                  marginBottom: "2.5rem",
                  border: "solid 1px #000",
                }}
                alt="Sika Postkarte"
              />
            </div>
          </div>

          <div className="section__content columns container--padding-big">
            <div className="column column--8"></div>
            <div className="column column--4">
              <div className="teaser__text">
                <div className="teaser__text-content">
                  <h5 className="teaser__text-title">Messewand und RollUps</h5>
                  <div>
                    <p className="teaser__text-details">
                      Digitale Marken müssen auch offline überzeugen können. Für
                      Devyl wurde daher ein Bannerkonzept für die nächste
                      Ausbildungsmesse entwickelt.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <img
                ref="image"
                src={Devyl_RollUps_breit}
                style={{
                  width: "100%",
                  height: "auto",
                }}
                alt="SIKA Referenzen Mailings 1260px breit"
              />
            </div>
            <div className="column column--6">
              <img
                ref="image"
                src={Devyl_RollUps_3er}
                style={{
                  width: "100%",
                  height: "auto",
                }}
                className="teaser__image-right"
                alt="Kampagne - Sika Connect Referenz"
              />
            </div>
          </div>
        </section>

        <section id="section-sika-5" className="section--content">
          <div className="section__content columns container--padding">
            <div className="column column--4">
              <h5 className="teaser__text-title">Werbemittel</h5>
              <p className="teaser__text-details">
                Messe Give-Aways, die in Erinnerung bleiben und zur Marke
                passen: Postkarten, Wasabi-Snacks und Visitenkarten mit dem
                Zugang zu Software-Testversionen.
              </p>
            </div>
            <div className="column column--12">
              <div className="teaser__text">
                <div className="teaser__text-content">
                  <div className="section__image-content">
                    <div className="section__image-content-wrapper">
                      <img
                        ref="image"
                        src={Devy_Messe_Giveaway_Wasabinuesse}
                        style={{ width: "100%", height: "100%" }}
                        alt="Sika Kopfhoerer"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column column--12">
              <div className="teaser__text">
                <div className="teaser__text-content">
                  <div className="section__image-content">
                    <div className="section__image-content-wrapper">
                      <img
                        ref="image"
                        src={DPP_Motivkarte_A6_Brennen_print}
                        style={{ width: "100%", height: "100%" }}
                        alt="Sika Kopfhoerer"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column column--12">
              <div className="teaser__text">
                <div className="teaser__text-content">
                  <div className="section__image-content">
                    <div className="section__image-content-wrapper">
                      <img
                        ref="image"
                        src={DPP_Motivkarte_A6_Feuer_print}
                        style={{ width: "100%", height: "100%" }}
                        alt="Sika Kopfhoerer"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column column--12">
              <div className="teaser__text">
                <div className="teaser__text-content">
                  <div className="section__image-content">
                    <div className="section__image-content-wrapper">
                      <img
                        ref="image"
                        src={DPP_Motivkarte_A6_Highway_print}
                        style={{ width: "100%", height: "100%" }}
                        alt="Sika Kopfhoerer"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column column--12">
              <div className="teaser__text">
                <div className="teaser__text-content">
                  <div className="section__image-content">
                    <div className="section__image-content-wrapper">
                      <img
                        ref="image"
                        src={Democode_Karte}
                        style={{ width: "100%", height: "100%" }}
                        alt="Sika Kopfhoerer"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
