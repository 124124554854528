import React from "react";

import ScrollUp from "../components/ButtonComponent/ButtonScrollUp";
import Random from "../components/RandomComponent/Random";
import Header from "../components/HeaderComponent/Header";
import ReferenzImage from "../components/ReferenzComponent/FelsomatComponent/ReferenzImage";
import ReferenzIntro from "../components/ReferenzComponent/FelsomatComponent/ReferenzIntro";
import ReferenzDetail from "../components/ReferenzComponent/FelsomatComponent/ReferenzDetail";
import ReferenzContent from "../components/ReferenzComponent/FelsomatComponent/ReferenzContent";
import ReferenzContentMobile from "../components/ReferenzComponent/FelsomatComponent/ReferenzContentMobile";
import ReferenzSwitch from "../components/ReferenzComponent/FelsomatComponent/ReferenzSwitch";
import Footer from "../components/FooterComponent/Footer";

class Felsomat extends React.Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth
    };
  }

  componentDidMount() {
    document.title = "Referenz: Felsomat";
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { width } = this.state;
    const isMobile = width <= 812;

    if (isMobile) {
      return (
        <React.Fragment>
          <ScrollUp />
          <Random />
          <Header />
          <ReferenzImage />
          <ReferenzIntro />
          <ReferenzDetail />
          <ReferenzContentMobile />
          <ReferenzSwitch />
          <Footer />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
    <ScrollUp />
    <Random />
    <Header />
    <ReferenzImage />
    <ReferenzIntro />
    <ReferenzDetail />
    <ReferenzContent />
    <ReferenzSwitch />
    <Footer />
  </React.Fragment>
      );
    }
  }
}
export default Felsomat;
