import React from "react";

import Random from "../components/RandomComponent/Random";
import DynamicScrollToTop from "../components/ScrollToTopComponent/DynamicScrollToTop";
import Footer from "../components/FooterComponent/Footer";
import Header from "../components/HeaderComponent/Header";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import HomeTeaser from "../components/HomeTeaser/HomeTeaser";
const BackgroundWhite = styled.div`
  backdrop-filter: blur(1rem);
  position: fixed;
  bottom: 0;
  width: 100%;
  section {
    background: transparent!important;
  }
  * {
    color: white!important;
  }
  #page__footer, .divider {
    border-color: white!important;
  }
`;

class Home extends React.Component {
  componentDidMount() {
    document.title = "DAS ACHTE GEBOT Werbeagentur GmbH | Full Service Werbeagentur für B2B und B2C";
  }
  render() {
    return (
      <React.Fragment>
        <DynamicScrollToTop />
        <Random />
        <Header />
        <Helmet>
          <meta
            property="og:image"
            content="https://www.dasachtegebot.de/images/corporate/og__held.jpg"
          />
        </Helmet>
        <HomeTeaser />
        <BackgroundWhite>
          <Footer />
        </BackgroundWhite>
      </React.Fragment>
    );
  }
}

export default Home;
