import React from "react";
import styled from "styled-components";
import { Controller, Scene } from "react-scrollmagic";
import { Tween } from "react-gsap";

const SvgStyled = styled.div`
  overflow: hidden;
  position: absolute;
  width: 100%;
  top: -22rem;
  margin-left: -5rem;

  #intro-animation--acht {
    stroke: white;
    stroke-width: 4;
    stroke-linejoin: round;
    fill: white;
  }

  svg {
    display: block;
    height: 600px;
    width: auto;
    margin: auto;
    overflow: visible;
  }

  animation: draw-line 2000ms linear 0ms forwards;}
  animation:draw-line 2000ms linear 1000ms forwards;}

  @keyframes draw-line{
    0%{stroke-dashoffset:645}
    100%{stroke-dashoffset:0;}
  }
}
`;

const SectionAnimation = () => (
  <SvgStyled>
    <Controller>
      <Scene
        duration={300}
        pin={false}
        reverse={false}
        indicators={false}
        offset={0}
      >
        {(progress, event) => {
          return (
            <div className="section__content columns container--padding">
              <div className="column column--4"></div>
              <div className="column column--4">
                <div className="section__intro-animation">
                  <svg
                    id="intro-animation--acht"
                    viewBox="0 0 114.9 627.6"
                    x="0px"
                    y="0px"
                  >
                    <Tween
                      duration={2}
                      to={{
                        attr: {
                          points:
                            "M1224,2028.4c10.9,56.4,16.4,113.7,16.4,171.2c0,164.8-38.4,319.1-110.2,454.8"
                        },
                        strokeDasharray: "645",
                        strokeDashoffset: "2300",
                        animation: "draw-line 2000ms linear 0ms forwards",
                        transform: "translate(-1160.999 -2027.888)",
                        fill: "#ffffff",
                        stroke: "#ac1e1c",
                        ease: "Linear.easeNone"
                      }}
                      paused
                      playState={
                        event.type === "enter" &&
                        event.scrollDirection === "FORWARD"
                          ? "play"
                          : event.type === "enter" &&
                            event.scrollDirection === "REVERSE"
                          ? "reverse"
                          : null
                      }
                    >
                      <g>
                        <g transform="translate(-1160.999 -2027.888)">
                          <path d="M1224,2028.4c10.9,56.4,16.4,113.7,16.4,171.2c0,164.8-38.4,319.1-110.2,454.8" />
                        </g>
                      </g>
                    </Tween>
                  </svg>
                </div>
              </div>
              <div className="column column--4"></div>
            </div>
          );
        }}
      </Scene>
    </Controller>
  </SvgStyled>
);

export default SectionAnimation;
