import React from "react";
import { HashLink as Link } from "react-router-hash-link";

export default class Referenzdetail extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section id="section-detail">
          <div className="section__content columns container infinite-container">
            <div className="column column--4">
              <nav className="menu menu--campaigns">
                <div className="menu__item-container">
                  <div className="menu__item-work" href="/">
                    <span className="menu__item-name">Kunde</span>
                  </div>
                </div>
                <div className="menu__item-container">
                  <span className="menu__item-name">3b IDO</span>
                </div>
              </nav>
            </div>
            <div className="column column--4">
              <nav className="menu menu--campaigns">
                <div className="menu__item-container">
                  <div className="menu__item-work">
                    <span className="menu__item-name">Aufgaben</span>
                  </div>
                </div>
                <div className="menu__item-container">
                  <span className="menu__item-name">
                    Marketing-Kommunikation Online und Offline, Full-Service
                  </span>
                </div>
              </nav>
            </div>
            <div className="column column--4">
              <nav className="menu menu--campaigns">
                <div className="menu__item-container">
                  <div className="menu__item-work">
                    <span className="menu__item-name">Referenzen</span>
                  </div>
                </div>
                <div className="menu__item-container">
                  <Link to="#section-flex-1">
                    <div className="menu__item">
                      <span className="menu__item-name--flex">
                        Kampagne Möbelmacher
                      </span>
                    </div>
                  </Link>
                </div>
              </nav>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
