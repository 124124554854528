import React from "react";
import { HashLink as Link } from "react-router-hash-link";

import KlingbeilIntro from "../../../assets/images/Referenzen/Klingbeil/Teaserbild_Klingbeil_2560x1440px.jpg";

export default class ReferenzImage extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div
          className="full-bg-size-component-swipe"
          style={{
            backgroundImage: `url(${KlingbeilIntro})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            width: "100%",
            height: "100vh",
            display: "inline-block"
          }}
        ></div>
        <div className="scroll-down-arrow-wrapper">
          <Link to="#section-klingbeil">
            <div className="scroll-down-arrow"></div>
          </Link>
        </div>
      </React.Fragment>
    );
  }
}
