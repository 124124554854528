import React from "react";
import { NavLink } from "react-router-dom";
import Logo from "./Logo";

export default class Header extends React.Component {
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  UNSAFE_componentWillMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = event => {
    if (
      this.container.current &&
      !this.container.current.contains(event.target)
    ) {
      this.setState({
        open: false
      });
      document.getElementById("shadow").setAttribute("class", "withShadow");
    }
  };

  container = React.createRef();

  state = {
    open: true
  };

  handleButtonCLick = () => {
    this.setState(state => {
      return {
        open: !state.open
      };
    });
    document.getElementById("shadow").setAttribute("class", "withoutShadow");
  };

  render() {
    return (
      <>
        <header
          id="section-header"
          className="headroom show headroom--not-bottom headroom--pinned headroom--top page-header"
          style={{ display: "block" }}
          ref={this.container}
        >
          <nav>
            <div className="trigger-menu-wrapper-mobile-new">
              <button
                onClick={this.handleButtonCLick}
                className="trigger-menu-mobile-new"
                aria-label="trigger-menu"
              >
                <Logo />
              </button>
              {this.state.open ? <Menu /> : ""}
            </div>
          </nav>
        </header>
      </>
    );
  }
}

function Menu() {
  return (
    <React.Fragment>
      <nav className="navigation-mobile-wrapper" style={{ zIndex: "2" }}>
        <div className="navigation-mobile-wrapper-new">
          <nav className="navigation-mobile--work-new">
            <ul>
              <li>
                <NavLink to="/referenzen">Kunden</NavLink>
              </li>
            </ul>
          </nav>
          <NavLink to="/">
            <button
              className="trigger-menu-mobile-new"
              aria-label="trigger-menu"
            >
              <svg
                xmlns="http:www.w3.org/2000/svg"
                width={80}
                height={80}
                viewBox="0 0 115.2 115.2"
              >
                <g>
                  <path
                    className="logo-svg"
                    d="M59.5,53.8c1.7-1.4,4.8-5.2,4.8-10.3c0-9.6-7.8-14.5-16.8-14.5c-9,0-16.8,4.9-16.8,14.5
		c0,5.1,3.1,8.9,4.8,10.3c-4.8,2.9-7,7.7-7,12.4c0,9.5,7.5,16.9,19,16.9s19-7.4,19-16.9C66.5,61.5,64.3,56.7,59.5,53.8z M47.5,39.5
		c2.7,0,5,2.3,5,5c0,2.7-2.3,5-5,5s-5-2.3-5-5S44.8,39.5,47.5,39.5z M47.5,71.8c-3.8,0-6.8-3-6.8-6.8c0-3.8,3-6.8,6.8-6.8
		c3.8,0,6.8,3,6.8,6.8C54.3,68.8,51.3,71.8,47.5,71.8z"
                  />
                  <path
                    className="logo-svg"
                    d="M77.2,67c3.2,0,5.1-2.7,5.1-5.7l1-30.8h-12l0.9,30.8C72.1,64.4,73.9,67,77.2,67z"
                  />
                  <path
                    className="logo-svg"
                    d="M77.2,70.2c-3.5,0-6.4,3-6.4,6.4c0,3.5,3,6.4,6.4,6.4s6.4-2.9,6.4-6.4C83.6,73.2,80.6,70.2,77.2,70.2z"
                  />
                </g>
              </svg>
            </button>
          </NavLink>
          <nav className="navigation-mobile--about-new">
            <ul>
              <li>
                <NavLink to="/agentur">Agentur</NavLink>
              </li>
            </ul>
          </nav>
        </div>

        <svg
          xmlns="http:www.w3.org/2000/svg"
          width={350}
          height={350}
          x={0}
          y={0}
          viewBox="0 0 250 250"
          className="navigation-mobile-svg"
        >
          <g>
            <path
              className="logo-background"
              d="M208.3,0c32.3,19.6,46.9,51.8,46.9,83.6c0,63.6-50.4,113.4-127.6,113.4C50.4,197.1,0,147.2,0,83.6
		C0,51.8,14.7,19.6,46.9,0H208.3z"
            />
          </g>
        </svg>
      </nav>
    </React.Fragment>
  );
}
