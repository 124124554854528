import React from "react";
import { NavLink } from "react-router-dom";

import SikaIntro from "../../../assets/images/Referenzen/Sika/Sika_Teaserbild_2560x1440px_Farbe.jpg";

export default class ReferenzImageDesktop extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section className="section__wrapper-container full-bg-size-component">
          <NavLink to="/referenzen/sika" onClick={this.props.onPageSika}>
            <div
              style={{
                backgroundImage: `url(${SikaIntro})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                width: "100%",
                height: "100%",
                display: "inline-block",
              }}
            ></div>
            <div className="section__intro-wrapper">
              <div className="section__intro--title">
                <nav className="navigation--clients navigation--clients-intro">
                  <ul>
                    <li>
                      <NavLink
                        to="/referenzen/sika"
                        onClick={this.props.onPageSika}
                      >
                        <h3>Sika</h3>
                      </NavLink>
                    </li>
                  </ul>
                </nav>
                <div className="section__intro--subtitle">
                  <span>Let's get started!</span>
                </div>
              </div>
            </div>
          </NavLink>

          <div className="section__item-wrapper">
            <div className="section__item"></div>

            <div className="section__item-center">
              <nav className="navigation--clients">
                <ul>
                  <li>
                    <NavLink to="/referenzen/kunden">Alle Kunden</NavLink>
                  </li>
                </ul>
              </nav>
            </div>

            <div className="section__item">
              <nav className="navigation--clients">
                <ul>
                  <li>
                    <div className="navigation--clients-numbers">
                      <h3>{this.props.referenzNumber}</h3>
                      <p>/ {this.props.referenzCount}</p>
                    </div>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
