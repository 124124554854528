import React from "react";

import Janus_Referenzen_Mood from "../../../assets/images/Referenzen/Janus/Kampagne/Janus_Referenzen_Mood.jpg";
import Janus_Titel_Brosch_Image_A4_quer from "../../../assets/images/Referenzen/Janus/Kampagne/Janus_Titel_Brosch_Image_A4_quer_neu.jpg";
import Janus_Doppels_Brosch_A4_quer_1 from "../../../assets/images/Referenzen/Janus/Kampagne/Janus_Doppels_Brosch_A4_quer_1.jpg";
import Janus_Doppels_Brosch_A4_quer_2 from "../../../assets/images/Referenzen/Janus/Kampagne/Janus_Doppels_Brosch_A4_quer_2.jpg";
import Janus_Doppels_Brosch_A4_quer_3 from "../../../assets/images/Referenzen/Janus/Kampagne/Janus_Doppels_Brosch_A4_quer_3.jpg";
import Janus_Doppels_Brosch_A4_quer_4 from "../../../assets/images/Referenzen/Janus/Kampagne/Janus_Doppels_Brosch_A4_quer_4.jpg";
import Janus_Doppels_Brosch_A4_quer_5 from "../../../assets/images/Referenzen/Janus/Kampagne/Janus_Doppels_Brosch_A4_quer_5.jpg";
import Janus_Doppels_Brosch_A4_quer_6 from "../../../assets/images/Referenzen/Janus/Kampagne/Janus_Doppels_Brosch_A4_quer_6.jpg";
import Janus_Markenworkshop_1 from "../../../assets/images/Referenzen/Janus/Kampagne/Janus_Markenworkshop_1.jpg";
import Janus_Markenworkshop_2 from "../../../assets/images/Referenzen/Janus/Kampagne/Janus_Markenworkshop_2.jpg";
import Janus_Mockup from "../../../assets/video/referenzen/janus/Janus.mp4";

export default class ReferenzContent extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section id="section-janus-1" className="section--content">
          <div className="section__content columns container-section">
            <div className="column column--6">
              <div className="section__image-content-wrapper">
                <img
                  ref="image"
                  src={Janus_Referenzen_Mood}
                  className="teaser__image-left"
                  alt="Janus Engineering"
                />
              </div>
            </div>

            <div className="column column--6">
              <div className="teaser__text padding">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--janus">CD-Relaunch</p>
                  <h3 className="teaser__text-title">
                    Gemeinsam zusammen wachsen
                  </h3>
                  <div>
                    <p className="teaser__text-details">
                      Die strategische Neuausrichtung von Janus Engineering
                      gründet auf dem Zusammenschluss von vier bislang
                      unabhängigen Länder-gesellschaften zu einer starken
                      gemeinsamen Marke. Dabei sollte das Bestehende nicht über
                      Bord geworfen, sondern harmonisch zu einem gemeinsamen Weg
                      vereint werden. In einem internationalen Pitch in Paris,
                      Frankreich konnte sich DAS ACHTE GEBOT gegen drei
                      Wettbewerber durchsetzen und startete mit einem
                      Workshop-Konzept zur Markenharmonisierung, in dem sich
                      jede Niederlassung aktiv mit ihren Wünschen und Zielen
                      miteinbringen konnte. Entstanden sind grafische Grundlagen
                      für ein weiterführendes Marken- und Kommunikationskonzept,
                      das die Internationalisierung des Unternehmens begleitete.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--4-bottom">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <h5 className="teaser__text-title">Corporate Design</h5>
                  <p className="teaser__text-details">
                    Das CD-Handbuch ist Arbeitsergebnis und künftiges
                    Arbeitsmittel zugleich. Grundlagen wie Logo, Farben, Fonts,
                    grafische und bildliche Konstanten sowie exemplarische
                    Medien und Werbemittel sollen visuellem Wildwuchs vorbeugen
                    und Marketing-verantwortlichen weltweit das Leben leichter
                    machen.
                  </p>
                </div>
              </div>
            </div>
            <div className="column column--8">
              <img
                ref="image"
                src={Janus_Titel_Brosch_Image_A4_quer}
                style={{ width: "100%", height: "100%" }}
                alt="Janus Engineering Imagebroschuere"
              />
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--10">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <img
                    ref="image"
                    src={Janus_Doppels_Brosch_A4_quer_1}
                    style={{ width: "100%", height: "100%" }}
                    alt="Janus Engineering Imagebroschuere S1"
                  />
                  <img
                    ref="image"
                    src={Janus_Doppels_Brosch_A4_quer_2}
                    style={{ width: "100%", height: "100%" }}
                    alt="Janus Engineering Imagebroschüre S2"
                  />
                </div>
              </div>
            </div>
            <div className="column column--2"></div>
          </div>

          <div className="section__content columns container">
            <div className="column column--2"></div>
            <div className="column column--10">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <img
                    ref="image"
                    src={Janus_Doppels_Brosch_A4_quer_3}
                    style={{ width: "100%", height: "100%" }}
                    alt="Janus Engineering Imagebroschüre S3"
                  />
                  <img
                    ref="image"
                    src={Janus_Doppels_Brosch_A4_quer_4}
                    style={{ width: "100%", height: "100%" }}
                    alt="Janus Engineering Imagebroschüre S4"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--10">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <img
                    ref="image"
                    src={Janus_Doppels_Brosch_A4_quer_5}
                    style={{ width: "100%", height: "100%" }}
                    alt="Janus Engineering Imagebroschüre S5"
                  />
                  <img
                    ref="image"
                    src={Janus_Doppels_Brosch_A4_quer_6}
                    style={{ width: "100%", height: "100%" }}
                    alt="Janus Engineering Imagebroschüre S6"
                  />
                </div>
              </div>
            </div>
            <div className="column column--2"></div>
          </div>

          <div className="section__content columns container--padding-big">
            <div className="column column--6">
              <div className="teaser__text">
                <div className="teaser__text-content">
                  <div className="section__image-content-wrapper">
                    <h5 className="teaser__text-title">Markenworkshop</h5>
                    <p className="teaser__text-details">
                      Für alle wichtigen Steps von der Marke bis Medienrelaunch
                      nutzten wir die Kompetenzen und Insights von Vertrieb und
                      Marketing der jeweiligen Länder. So wurde vom ersten
                      Schritt an eine breite Akzeptanz in den Teams und ein
                      hohes Involvement geschaffen. Und Spaß hat es natürlich
                      auch gem8!
                    </p>
                  </div>
                  <div className="section__image-content">
                    <div className="section__image-content-wrapper">
                      <img
                        ref="image"
                        src={Janus_Markenworkshop_2}
                        style={{ width: "100%", height: "100%" }}
                        alt="Markenworkshop"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="column column--6-bottom">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <img
                    ref="image"
                    src={Janus_Markenworkshop_1}
                    style={{ width: "100%", height: "100%" }}
                    alt="Markenworkshop"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding-big">
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <h5 className="teaser__text-title">Webrelaunch</h5>
                  <p className="teaser__text-details">
                    Zahlreiche Länder- und Sprachvarianten, viel gemeinsamer
                    Content, aber auch viele länderspezifische Leistungen und
                    Produkte bildeten die komplexe Herausforderung für den
                    Relaunch der neuen gemeinsamen Website. Mit dem Content
                    Management System TYPO3 konnten wir die Aufgabe so umsetzen,
                    dass sich die künftige Pflege von Inhalten und das Aufsetzen
                    von Kampagnen so effizient wie möglich gestalten. Durch eine
                    Spracherkennung nach IP und Browser-Setting erhält jeder
                    User die richtige Länder- und Sprachversion automatisch
                    zugeordnet. Außerdem erfolgte die Portierung und Integration
                    einer umfangreichen Kontakt- und Schulungsmodulplattform.
                  </p>
                </div>
              </div>
            </div>
            <div className="column column--6"></div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--12">
              <video
                id="FlexMockup"
                autostart="true"
                playsInline
                autoPlay
                muted
                loop
                type="video/mp4"
                src={Janus_Mockup}
                style={{
                  width: "100%",
                  height: "auto"
                }}
              />
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
